import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toISOString'
})
export class ToISOStringPipe implements PipeTransform {

	/**
	 * Convert To iso date string wth timezone
	 * 
	 * @param {number | string} timestamp 
	 * @returns {string}
	 */
	static convertToDateString(timestamp: number | string): string {
		if (!timestamp) return "";
		let speedtestTimestamp = timestamp.toString();
		if (speedtestTimestamp.length < 12) speedtestTimestamp += "000";
		const TimeZone = sessionStorage.getItem("becentralTimeZone") ? sessionStorage.getItem("becentralTimeZone") : Intl.DateTimeFormat().resolvedOptions().timeZone;
		const DataObject = speedtestTimestamp.length <= 13 ? new Date(parseInt(speedtestTimestamp)) : new Date(speedtestTimestamp);
		return DataObject.toLocaleString("en-US", { timeZone: TimeZone });
	}

	/**
	 * Transform
	 * 
	 * @param {number | string} timestamp 
	 * @returns {string}
	 */
	transform(timestamp: number | string): string {
		return ToISOStringPipe.convertToDateString(timestamp);
	}
}