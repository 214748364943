import { SupportDeviceModel } from "./support-device-model";

export enum DeviceFeatures {
    WZL = "WZL",
    RUL = "RUL",
    MX = "MX",
    E_SERIES = "E_SERIES",
    GPS = "GPS",
    OUTDOOR_UNIT = "OUTDOOR_UNIT",
    _5G_NR = "_5G_NR",
    MULTIPLE_WAN = "MULTIPLE_WAN",
    WIRELESS_DEVICES = "WIRELESS_DEVICES",
    WIRELINE_DEVICES = "WIRELINE_DEVICES",
    WI_FI = "WI_FI", // 2.4g Wi-Fi
    _5G_WI_FI = "_5G_WI_FI",
    EWAN = "EWAN",
    EWAN_MULTIPLE_SERVICE = "EWAN_MULTIPLE_SERVICE",
    EWAN_BANDWIDTH = "EWAN_BANDWIDTH",
    SECOND_EWAN = "SECOND_EWAN",
    SECOND_EWAN_BRIDGE_MODE = "SECOND_EWAN_BRIDGE_MODE",
    _4G_LTE = "_4G_LTE",
    SECOND_4G_LTE = "SECOND_4G_LTE",
    WIRELESS_CLIENT = "WIRELESS_CLIENT",
    _5G_NR_CONFIG = "_5G_NR_CONFIG",
    PHYSICAL_PORTS = "PHYSICAL_PORTS",
    PHYSICAL_PORTS_LAN_1 = "PHYSICAL_PORTS_LAN_1",
    PHYSICAL_PORTS_LAN_2 = "PHYSICAL_PORTS_LAN_2",
    PHYSICAL_PORTS_LAN_3 = "PHYSICAL_PORTS_LAN_3",
    PHYSICAL_PORTS_LAN_4 = "PHYSICAL_PORTS_LAN_4",
    PHYSICAL_PORTS_WLAN_1 = "PHYSICAL_PORTS_WLAN_1",
    PHYSICAL_PORTS_WLAN_2 = "PHYSICAL_PORTS_WLAN_2",
    STATION_NUMBER = "STATION_NUMBER",
    WI_FI_ENTERPRISE_SECURITY_TYPE = "WI_FI_ENTERPRISE_SECURITY_TYPE",
    HARDWARE_MONITOR = "HARDWARE_MONITOR",
    DUAL_SIM_FAILOVER = "DUAL_SIM_FAILOVER",
    LAN_DESCRIPTION_NONE = "LAN_DESCRIPTION_NONE",
    LAN_DESCRIPTION_LAN1 = "LAN_DESCRIPTION_LAN1",
    LAN_DESCRIPTION_LAN2 = "LAN_DESCRIPTION_LAN2",
    TRIPLE_SIM = "TRIPLE_SIM",
    DUAL_SIM = "DUAL_SIM",
    OPENWRT_DEVICE = "OPENWRT_DEVICE",
    VPN = "VPN",
    VOIP = "VOIP",
    HOTSPOT = "HOTSPOT",
    FTP = "FTP",
    UTILITY = "UTILITY",
    OUT_SOURCING_DEVICE = "OUT_SOURCING_DEVICE"
};

export type FeaturesProfile = Array<DeviceFeatures>;

export type ModelFeaturesMap = {
    [key in SupportDeviceModel]: FeaturesProfile;
};