/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { CreateApiConsumerRequestBody } from '@core/types';

@Injectable()
export class ApiConsumers extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /api-consumers/me - get api consumer data
     */
    public getApiConsumer() {

        let method = "GET",
            url = "api-consumers/me",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /api-consumers/me - delete api consumer
     */
    public delteApiConsumer() {

        let method = "DELETE",
            url = "api-consumers/me",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /api-consumers/me - Sign up the api key
     * 
     * @param {CreateApiConsumerRequestBody} data  
     */
    public createApiConsumer(data: CreateApiConsumerRequestBody) {

        let method = "POST",
            url = "api-consumers/me",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /api-consumers/usage - get api consumer usage data
     */
    public getApiConsumerUsage() {

        let method = "GET",
            url = "api-consumers/usage",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}