import { Component } from "@angular/core";

@Component({
    selector: 'becentral-terminal',
    templateUrl: './terminal.component.html',
    styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent {

    constructor() {
        console.log("initial");
    }
}