import { RolePermission } from "../auth-types";

export interface ConfigCategory {
    title: string;
    items: Array<string>;
    permissionId?: RolePermission
};

export interface ConfigMap {
    defaultConfig: Array<ConfigCategory>;
    wzlrulConfig: Array<ConfigCategory>;
    _8920Config: Array<ConfigCategory>;
    openWrtConfig: Array<ConfigCategory>;
};

export interface WanItem {
    name: string;
    value: "PVC10" | "PVC11" | "PVC12" | "PVC13" | "PVC14" | "PVC15" | "PVC16";
    type: "LTE" | "EWAN" | "WirelessClient";
    node: "PVC10" | "PVC11" | "PVC12" | "PVC13" | "PVC14" | "PVC15" | "PVC16";
};

export enum OpenwrtNetworkMode {
    WCDMA_ONLY = '1',
    LTE_ONLY = '3',
    _5G_ONLY = '15',
    LTE_AND_5G = '19',
    AUTOMATIC = '21'
};

export enum OpenwrtProtocolType {
    IPV4 = '1',
    IPV6 = '2',
    IPV4_AND_IPV6 = '3'
};

export enum OpenwrtAuthenticationProtocol {
    NONE = '0',
    PAP = '1',
    CHAP = '2'
};

export enum OpenwrtNumberBoolean {
    NO = '0',
    YES = '1'
};

export enum OpenwrtConnectionMonitoringStatus {
    DISABLED = '0',
    ENABLE_WITH_MODEM_RECONNECT = '1'
};