import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/* TimePicker Requirement */
export function timepickerValidator(): ValidatorFn {
    
    return (control: AbstractControl): ValidationErrors | null => {
        
        let value = control.value;
        
        if(!value) return { required : true };
        else return value.hasOwnProperty("hour") && value.hasOwnProperty("minute") ? null : { required : true };
    };
}