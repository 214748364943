/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

/******* Plug-In Resourse *******/
import { Observable } from 'rxjs';

/******* API Resourse *******/
import { APIConfig } from '@api/api.config';

const USER_SERVICE_URL: string = environment.userServiceUrl;
const DEVICE_GATEWAY_URL: string = environment.deviceGatewayUrl;
const SCHEDULE_GATEWAY_URL: string = environment.scheduleGatewayUrl;

@Injectable({
    providedIn: 'root'
})
export abstract class BaseApi {

    private static API_PATH: string = APIConfig.getPath();

    constructor(@Inject(HttpClient) protected httpClient: HttpClient) { }

    /**
     * Make a request with assign payload
     * 
     * @param method GET / POST / PATCH / PUT / DELETE
     * @param url API url
     * @param routeParams Parameter in url ex: aaa/:params/vvv
     * @param urlParams Parameter after url ex: aaa/vvv?params=value
     * @param postBody Payload in Body
     * @returns Observable
     */
    public request(
        method: string,
        url: string,
        routeParams: any = {},
        urlParams: any = {},
        postBody: any = {}
    ): Observable<any> {

        // API Path Base
        const urlStartWith = url.split('/')[0];
        switch (urlStartWith) {
            case 'auth':
            case 'roles':
            case 'tenants':
            case 'users':
            case 'groups':
            case 'api-consumers':
            case 'audit-logs':
                APIConfig.setBaseURL(USER_SERVICE_URL);
                break;
            case 'Schedules':
                APIConfig.setBaseURL(SCHEDULE_GATEWAY_URL);
                break;
            default:
                APIConfig.setBaseURL(DEVICE_GATEWAY_URL);
                break;
        }

        const API_PATH_BASE = `${APIConfig.getPath()}`;

        // Process Header
        let headers = new HttpHeaders();

        // Modify Data enctype if needed // headers = headers.append('enctype', 'multipart/formdata');
        switch (method) {
            case 'POST_FILE':
            case 'PUT_FILE':
                method = method.split("_")[0];
                break;
            case 'PUT_PROGRESS':
                break;
            default:
                headers = headers.append('Content-Type', 'application/json');
                break;
        }

        // Process routeParams
        Object.keys(routeParams).forEach((key: string) => {
            url = url.replace(new RegExp(":" + key + "(\/|$)", "g"), routeParams[key] + "$1");
        });

        // Process urlParams
        let params = new HttpParams();

        // Append filter to urlParams
        if (urlParams.filter) {
            if ((url.startsWith("devices") || url.startsWith("licenses/devices")) && !url.endsWith("speedtest")) headers = headers.append('filter', JSON.stringify(urlParams.filter));
            else params = params.append('filter', JSON.stringify(urlParams.filter));
            delete urlParams.filter;
        }

        // Append where to urlParams
        if (urlParams.where) {
            if ((url.startsWith("devices") || url.startsWith("licenses/devices")) && !url.endsWith("speedtest/count")) headers = headers.append('filter', JSON.stringify(urlParams.where));
            else params = params.append('where', JSON.stringify(urlParams.where));
            delete urlParams.where;
        }

        Object.keys(urlParams).forEach((key: string) => {
            params = params.append(key, urlParams[key]);
        });

        // Pack the Request Option
        let requestOption = {
            headers,
            params
        };

        // switch method
        method = method.toUpperCase();

        switch (method) {
            case 'GET':
                return this.httpClient.get(API_PATH_BASE + url, requestOption) as Observable<any>;
            case 'GET_FILE':
                requestOption['responseType'] = 'text';
                return this.httpClient.get(API_PATH_BASE + url, requestOption) as Observable<any>;
            case 'POST':
                return this.httpClient.post(API_PATH_BASE + url, postBody, requestOption) as Observable<any>;
            case 'PUT_PROGRESS':
                return this.httpClient.put(API_PATH_BASE + url, postBody, { ...requestOption, reportProgress: true, observe: 'events' }) as Observable<HttpEvent<any>>;
            case 'PATCH':
                return this.httpClient.patch(API_PATH_BASE + url, postBody, requestOption) as Observable<any>;
            case 'PUT':
                return this.httpClient.put(API_PATH_BASE + url, postBody, requestOption) as Observable<any>;
            case 'DELETE':
                return this.httpClient.delete(API_PATH_BASE + url, requestOption) as Observable<any>;
            case 'DELETE_WITH_BODY':
                requestOption['body'] = postBody;
                return this.httpClient.delete(API_PATH_BASE + url, requestOption) as Observable<any>;
        }
    }

    /**
     * Query document's data with filter
     * 
     * @param document Query Document
     * @param filter where filter
     * @returns Observable
     */
    public find(document: string, filter: any): Observable<any> {

        let method = "GET",
            url = document,
            routeParams = {},
            urlParams = {
                filter: filter
            },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * Query One data in document by filter
     * 
     * @param document Query Document
     * @param filter where filter
     * @returns Observable
     */
    public findOne(document: string, filter: any): Observable<any> {

        let method = "GET",
            url = document + "/findOne",
            routeParams = {},
            urlParams = {
                filter: filter
            },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * Query One data in document By id
     * 
     * @param document Query Document
     * @param id id
     * @returns Observable
     */
    public findById(document: string, id: any): Observable<any> {

        let method = "GET",
            url = document + "/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * Query document's count with filter
     * 
     * @param document Query Document
     * @param filter where filter
     * @returns Observable
     */
    public count(document: string, filter: any): Observable<any> {

        let method = "GET",
            url = document + "/count",
            routeParams = {},
            urlParams = {
                where: filter
            },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * Create instance in document
     * 
     * @param document Query Document
     * @returns Observable
     */
    public create(document: string, data: any): Observable<any> {

        let method = "POST",
            url = document,
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * Delete instance in document By id
     * 
     * @param document Query Document
     * @param id id
     * @returns Observable
     */
    public deleteById(document: string, id: any): Observable<any> {

        let method = "DELETE",
            url = document + "/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST URL
     * 
     * @param {string} url 
     * @param {any} data
     */
    public postRequest(url: string, data: any): Observable<any> {
        return this.httpClient.post(url, data) as Observable<any>;
    }

}