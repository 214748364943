/******* Angular Resourse *******/
import { Injectable } from '@angular/core';

/******* Shared Resourse *******/
import { UtilsService } from './utils.service';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/******* API Resourse *******/
import { Tags } from '@api';
import { tag } from '@core/types';
import { GlobalUIBlockingService } from './global-ui-blocking.service';

/******* Core Resourse *******/

@Injectable({
    providedIn: 'root',
})
export class TagsService {

    // Public
    public currentTags: Observable<Array<tag>>;

    // Private
    private currentTagsSubject: BehaviorSubject<Array<tag>>;

    /**
     * Constructor
     *
     * @param {Tags} _tags
     * @param {UtilsService} _utilsService
     */
    constructor(private _tags: Tags,
        private _utilsService: UtilsService,
        private _globalUIBlockingService: GlobalUIBlockingService) {

        // Tags subject initial
        this.currentTagsSubject = new BehaviorSubject<Array<tag>>(null);
        this.currentTags = this.currentTagsSubject.asObservable().pipe(tap((x) => { if (!x) this._queryTagStatus(); }));
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get current tags value
     */
    public get currentTagsValue(): any {
        return this.currentTagsSubject.value;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query Tags Data
     */
    private _queryTagStatus(resolve = null, rejects = null): void {
        this._globalUIBlockingService.start();
        this._tags.getTags({}).subscribe((returnData) => {
            this._globalUIBlockingService.stop();
            this.currentTagsSubject.next(returnData.data);
            if (resolve) resolve(returnData.data);
        }, (error) => {
            this._globalUIBlockingService.stop();
            this._utilsService.alert('error', 'Fail to load Tags Data', error);
            if (rejects) rejects(false);
        });
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh Tags Pool
     */
    public refreshTagsPoolStatus(): Promise<tag> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._queryTagStatus(resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Clear the Current Tags Pool
     */
    public clearAllInfo(): void {
        this.currentTagsSubject.next(null);
    }
}