import { AlertPolicyRequestBody } from "../alert-types";

export interface GroupRegisterRequestBody {
    name: string;
    description: string;
    configProfileTemplateId: string;
    alertProfileTemplateId: string;
    userIds?: Array<string>;
};

export interface GroupUserRequestBody {
    firstName: string;
    lastName: string;
    emai: string;
    roleId: string;
    timeZone: string;
    userId?: string;
};