<div [ngClass]="containerClass">
    <label [ngClass]="labelClass" [for]="id">{{label}}</label>
    <div [ngClass]="inputClass">
        <textarea class="form-control" [ngClass]="{ 'is-invalid': invalid }" [id]="id" [cols]="cols" [rows]="rows"
            [(ngModel)]='value' [placeholder]="placeholder" [disabled]="isDisabled" (blur)="onTouch()"
            [autocomplete]="id" [maxlength]="maxlength"></textarea>
        <div *ngIf="invalid && displayErrorMessage" class="invalid-feedback">
            <ng-container *ngIf="isRequired && errors?.required">{{label}} is required</ng-container>
        </div>
    </div>
</div>