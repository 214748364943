<div class="modal-header">
    <h5 class="modal-title">Terms & conditions</h5>
    <button type="button" class="close" (click)="close('Cross click')" aria-label="Close">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <div>
        <!-- TERMS OF USE -->
        <article>
            <span style="text-align : center;">
                <h1>TERMS OF USE</h1>
                <p>Updated August 21, 2017</p>
            </span>
            <p>BY CLICKING ON THE “ACCEPT” OR “CONTINUE” BUTTON, YOU AND ANY ENTITY THAT YOU REPRESENT (THE
                “CUSTOMER”) AND ANYONE TO WHOM CUSTOMER PROVIDES ACCESS TO THE SERVICES OR THAT IS USING OR
                ACCESSING THE SERVICES ON CUSTOMER’S BEHALF (COLLECTIVELY, “AGENTS”) ARE UNCONDITIONALLY CONSENTING
                TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS TERMS OF SERVICE AND LICENSE AGREEMENT
                (“AGREEMENT”). THIS AGREEMENT DEFINES THE TERMS OF USE FOR THE SERVICE AND ANY SOFTWARE REQUIRED TO
                DELIVER THE SERVICE. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF YOUR EMPLOYER OR OTHER
                LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND CUSTOMER. CUSTOMER’S CONTINUED
                USE OF SERVICES AND SOFTWARE PROVIDED BY BEC TECHNOLOGIES, INC. (“BEC”) SHALL ALSO CONSTITUTE ASSENT
                TO THE TERMS OF THIS AGREEMENT. IF CUSTOMER DOES NOT UNCONDITIONALLY AGREE TO ALL OF THE TERMS OF
                THIS AGREEMENT, CLICK THE “CANCEL” OR BROWSER BACK BUTTON AND THE ACCOUNT REGISTRATION OR SETUP
                PROCESS WILL NOT CONTINUE. IF THESE TERMS ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED
                TO THESE TERMS.</p>
        </article>

        <!-- AGREEMENT TO TERMS -->
        <article>
            <h5>AGREEMENT TO TERMS</h5>
            <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on
                behalf of an entity (“you”) and BEC Technologies Inc (“we,” “us” or “our”), concerning your access
                to and use of the <strong>BECentral</strong> website as well as any other media form, media channel,
                mobile website or mobile application related, linked, or otherwise connected thereto (collectively,
                the “Site”). You agree that by accessing the Site, you have read, understood, and agree to be bound
                by all of these Terms of Use. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, THEN YOU ARE EXPRESSLY
                PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
            <p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are
                hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to
                make changes or modifications to these Terms of Use at any time and for any reason. We will alert
                you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any
                right to receive specific notice of each such change. It is your responsibility to periodically
                review these Terms of Use to stay informed of updates. You will be subject to, and will be deemed to
                have been made aware of and to have accepted, the changes in any revised Terms of Use by your
                continued use of the Site after the date such revised Terms of Use are posted.</p>
            <p>The information provided on the Site is not intended for distribution to or use by any person or
                entity in any jurisdiction or country where such distribution or use would be contrary to law or
                regulation or which would subject us to any registration requirement within such jurisdiction or
                country. Accordingly, those persons who choose to access the Site from other locations do so on
                their own initiative and are solely responsible for compliance with local laws, if and to the extent
                local laws are applicable.</p>
            <p>The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not
                permitted to use or register for the Site.</p>
        </article>

        <!-- SERVICE SUBSCRIPTION -->
        <article>
            <h5>SERVICE SUBSCRIPTION</h5>
            <p>Subject to the terms and conditions of this Agreement, BEC will provide the Customer with a
                subscription to access its cloud-based network management, connectivity, and other related services,
                provided by BEC through the Internet. The service underlying or used to deliver the Service will be
                hosted on servers under control or direction of BEC or its third-party providers. The Service is
                subject to modification from time to time at BEC’s sole discretion, for any purpose deemed
                appropriate by BEC. </p>
            <p>For paid Customer accounts in good standing, BEC will undertake commercially reasonable efforts to
                make the Service available in accordance with the Customer Service Level Agreement if any or as
                otherwise documented and made available by BEC to Customer upon request, except for BEC’s right to
                suspend Customer’s access to the Service: 1. for scheduled or emergency maintenance, 2. in the event
                Customer is in breach of this Agreement, including failure to pay any amounts due, or 3. as a result
                of circumstances beyond BEC’s reasonable control (including, but not limited to: acts of God, acts
                of government, flood, fire, earthquake, civil unrest, acts of terror, strike or other labor problem,
                hosting provider failure or delay, issues related to a third party, or denial of service attacks)
                (“Force Majeure”).</p>
            <p>Subject to the terms hereof and pursuant to the level of support for which the Customer is entitled,
                BEC will provide reasonable support to Customer for the Service and document made available by BEC
                to Customer upon request. Customer will designate an employee who will be responsible for all
                matters relating to this Agreement. </p>
        </article>

        <!-- RESTRICTIONS AND RESPONSIBILITIES-->
        <article>
            <h5>RESTRICTIONS AND RESPONSIBILITIES</h5>
            <p>Customer will not, and will not permit any third party to: reverse engineer, decompile, disassemble
                or otherwise attempt to discover the source code, object code or underlying structure, ideas or
                algorithms of any portion of the Service, documentation or data related to the Service, modify,
                translate, or create derivative works based on the Service; use the Services for timesharing or
                service bureau purposes or for any purpose other than its own internal use.</p>
            <p>Customer will cooperate with BEC in connection with the performance of this Agreement by making
                available such personnel and information as may be reasonably required, and taking such other
                actions as BEC may reasonably request. Customer will also cooperate with BEC in establishing a
                password or other procedures for verifying that only designated employees of Customer has access to
                any administrative functions of the Services.</p>
            <p>Customer will be responsible for maintaining the security of Customer’s account, passwords, including
                but not limited to administrative and user passwords and files, and for all uses of Customer account
                with or without BEC’s knowledge or consent.</p>
        </article>

        <!-- INTELLECTUAL PROPERTY RIGHTS -->
        <article>
            <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
            <p>Unless otherwise indicated, the Site is our proprietary property and all source code, databases,
                functionality, software, website designs, audio, video, text, photographs, and graphics on the Site
                (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
                “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and
                trademark laws and various other intellectual property rights and unfair competition laws of the
                United States, foreign jurisdictions, and international conventions. The Content and the Marks are
                provided on the Site “AS IS” for your information and personal use only. Except as expressly
                provided in these Terms of Use, no part of the Site and no Content or Marks may be copied,
                reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
                transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose
                whatsoever, without our express prior written permission.</p>
            <p>Provided that you are eligible to use the Site, you are granted a limited license to access and use
                the Site and to download or print a copy of any portion of the Content to which you have properly
                gained access solely for your personal or commercial use. We reserve all rights not expressly
                granted to you in and to the Site, the Content and the Marks.</p>
        </article>

        <!-- USE OF DATA -->
        <article>
            <h5>USE OF DATA</h5>
            <p>By using or accessing the Service, Customer hereby grants to BEC a non-exclusive, irrevocable,
                sublicensable right and license to use, copy, display, perform, store, distribute and modify Data as
                necessary to perform the Service. “Data” means all electronic data and information submitted by
                Customer for set up and provisioning of the Service, and information created, generated, collected
                or harvested by BEC in the furtherance of this Agreement and the security and performance of the
                Service. Data does not include any Content.</p>
            <p>Notwithstanding anything else in this Agreement or otherwise, BEC may monitor Customer’s use of the
                Service in an aggregate and anonymous manner, compile statistical and performance information
                related to the provision and operation of the Service, and may make such information publicly
                available, provided that such information does not identify Customer or Customer’s confidential
                information. BEC shall own all right, title and interest in and to the BEC Data. To the extent
                needed to perfect BEC’s ownership in the BEC Data, Customer hereby irrevocably assigns all right,
                title and interest in such BEC Data to BEC. </p>
            <p>The service may make use of location data (including, but not limited to, GPS coordinates, the MAC
                address and received signal strength of nearby Wi-Fi access points, nearby cell tower IDs, and the
                IP Address) that is sent by devices using the Service. In order to allow BEC to provide the best
                user experience, the Service may utilize a third-party provider to resolve location requests. At all
                times, Customer’s location information will be treated in accordance with such third party’s privacy
                policy. By using location services, Customer consents to BEC and its partners’ transmission,
                collection, maintenance, processing and use of Customer’s location data and queries to provide and
                improve location-based products and services.</p>
        </article>

        <!-- LICENSE TO CUSTOMER -->
        <article>
            <h5>LICENSE TO CUSTOMER</h5>
            <p>BEC agrees to grant Customer a non-exclusive, non-transferable, limited right to access the Location
                Services, solely for Customer’s own business purposes, provided that Customer comply fully with all
                of the provisions of the Agreement. BEC grants Customer the right to use the Location Services
                solely in conjunction with the use of Customer’s Registered Devices. All rights not expressly
                granted to Customer in the Agreement are reserved by BEC.</p>
            <p>By accessing and using the Location Services, Customer expressly agrees to and hereby grants BEC the
                right to collect, use, store and disclose Location Information relative to Customer’s Registered
                Devices and to enable GPS functionality on Customer’s Registered Devices. Customer further
                acknowledges and agrees that Location Information of Customer’s Registered Devices may be collected
                and utilized by BEC and its third-party licensors in support and provision of the Location Services.
                Customer may use the Location Services to make Location Queries for the best available Location
                Information of the Registered Devices and Customer acknowledges that no notifications are provided
                to Customer or the Registered Devices when such Location Queries are made.</p>
        </article>

        <!-- USER REPRESENTATIONS -->
        <article>
            <h5>USER REPRESENTATIONS</h5>
            <p>By using the Site, you represent and warrant that: (1) you have the legal capacity and you agree to
                comply with these Terms of Use; (2) you are not a minor in the jurisdiction in which you reside; (3)
                you will not access the Site through automated or non-human means, whether through a bot, script or
                otherwise; (4) you will not use the Site for any illegal or unauthorized purpose; and (5) your use
                of the Site will not violate any applicable law or regulation.</p>
            <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the
                right to suspend or terminate your account and refuse any and all current or future use of the Site
                (or any portion thereof).</p>
        </article>

        <!-- FEES AND PAYMENT -->
        <article>
            <h5>FEES AND PAYMENT</h5>
            <p> We accept the following forms of payment:</p>
            <ul>
                <li>Visa</li>
                <li>Mastercard</li>
                <li>American Express</li>
                <li>Discover</li>
            </ul>
            <p>You may be required to purchase or pay a fee to access some of our services. You agree to provide
                current, complete, and accurate purchase and account information for all purchases made via the
                Site. You further agree to promptly update account and payment information, including email address,
                payment method, and payment card expiration date, so that we can complete your transactions and
                contact you as needed. We bill you through an online billing account for purchases made via the
                Site. Sales tax will be added to the price of purchases as deemed required by us. We may change
                prices at any time. All payments shall be in U.S. dollars.</p>
            <p>You agree to pay all charges or fees at the prices then in effect for your purchases, and you
                authorize us to charge your chosen payment provider for any such amounts upon making your purchase.
                We reserve the right to correct any errors or mistakes in pricing, even if we have already requested
                or received payment. We also reserve the right to refuse any order placed through the Site.</p>
        </article>

        <!-- CANCELLATION -->
        <article>
            <h5>CANCELLATION</h5>
            <p>You may cancel your subscription by contacting us using the contact information provided below. </p>
            <p>If you are unsatisfied with our services, please email us at becentral&#64;bectechnologies.net or call us
                at (972)422-0877.</p>
        </article>

        <!-- PROHIBITED ACTIVITIES -->
        <article>
            <h5>PROHIBITED ACTIVITIES</h5>
            <p>You may not access or use the Site for any purpose other than that for which we make the Site
                available. The Site may not be used in connection with any commercial endeavors except those that
                are specifically endorsed or approved by us.</p>
            <p>As a user of the Site, you agree not to:</p>
            <ol>
                <li>Systematically retrieve data or other content from the Site to create or compile, directly or
                    indirectly, a collection, compilation, database, or directory without written permission from
                    us.</li>
                <li>Make any unauthorized use of the Site, including collecting usernames and/or email addresses of
                    users by electronic or other means for the purpose of sending unsolicited email, or creating
                    user accounts by automated means or under false pretenses.</li>
                <li>Use a buying agent or purchasing agent to make purchases on the Site.</li>
                <li>Use the Site to advertise or offer to sell goods and services.</li>
                <li>Circumvent, disable, or otherwise interfere with security-related features of the Site,
                    including features that prevent or restrict the use or copying of any Content or enforce
                    limitations on the use of the Site and/or the Content contained therein.</li>
                <li>Engage in unauthorized framing of or linking to the Site.</li>
                <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive
                    account information such as user passwords.</li>
                <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                <li>Engage in any automated use of the system, such as using scripts to send comments or messages,
                    or using any data mining, robots, or similar data gathering and extraction tools.</li>
                <li>Interfere with, disrupt, or create an undue burden on the Site or the networks or services
                    connected to the Site.</li>
                <li>Attempt to impersonate another user or person or use the username of another user.</li>
                <li>Sell or otherwise transfer your profile.</li>
                <li>Use any information obtained from the Site in order to harass, abuse, or harm another person.
                </li>
                <li>Use the Site as part of any effort to compete with us. </li>
                <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any
                    way making up a part of the Site.</li>
                <li>Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site,
                    or any portion of the Site.</li>
                <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any
                    portion of the Site to you.</li>
                <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                <li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or
                    other code.</li>
                <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other
                    material, including excessive use of capital letters and spamming (continuous posting of
                    repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site
                    or modifies, impairs, disrupts, alters, or interferes with the use, features, functions,
                    operation, or maintenance of the Site.</li>
                <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or
                    active information collection or transmission mechanism, including without limitation, clear
                    graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices
                    (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
                <li>Except as may be the result of standard search engine or Internet browser usage, use, launch,
                    develop, or distribute any automated system, including without limitation, any spider, robot,
                    cheat utility, scraper, or offline reader that accesses the Site, or using or launching any
                    unauthorized script or other software.</li>
                <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
                <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
            </ol>

        </article>

        <!-- SUBMISSIONS -->
        <article>
            <h5>SUBMISSIONS</h5>
            <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other
                information regarding the Site ("Submissions") provided by you to us are non-confidential and shall
                become our sole property. We shall own exclusive rights, including all intellectual property rights,
                and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful
                purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive
                all moral rights to any such Submissions, and you hereby warrant that any such Submissions are
                original with you or that you have the right to submit such Submissions. You agree there shall be no
                recourse against us for any alleged or actual infringement or misappropriation of any proprietary
                right in your Submissions.</p>
        </article>

        <!-- U.S. GOVERNMENT RIGHTS -->
        <article>
            <h5>U.S. GOVERNMENT RIGHTS</h5>
            <p>Our services are “commercial items” as defined in Federal Acquisition Regulation (“FAR”) 2.101. If
                our services are acquired by or on behalf of any agency not within the Department of Defense
                (“DOD”), our services are subject to the terms of these Terms of Use in accordance with FAR 12.212
                (for computer software) and FAR 12.211 (for technical data). If our services are acquired by or on
                behalf of any agency within the Department of Defense, our services are subject to the terms of
                these Terms of Use in accordance with Defense Federal Acquisition Regulation (“DFARS”) 227.7202-3.
                In addition, DFARS 252.227-7015 applies to technical data acquired by the DOD. This U.S. Government
                Rights clause is in lieu of, and supersedes, any other FAR, DFARS, or other clause or provision that
                addresses government rights in computer software or technical data under these Terms of Use.</p>
        </article>

        <!-- SITE MANAGEMENT -->
        <article>
            <h5>SITE MANAGEMENT</h5>
            <p>We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms
                of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the
                law or these Terms of Use, including without limitation, reporting such user to law enforcement
                authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit
                the availability of, or disable (to the extent technologically feasible) any of your Contributions
                or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to
                remove from the Site or otherwise disable all files and content that are excessive in size or are in
                any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect
                our rights and property and to facilitate the proper functioning of the Site.</p>
        </article>

        <!-- TERM AND TERMINATION -->
        <article>
            <h5>TERM AND TERMINATION</h5>
            <p>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY
                OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES),
                TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR
                INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>
            <p>If we terminate or suspend your account for any reason, you are prohibited from registering and
                creating a new account under your name, a fake or borrowed name, or the name of any third party,
                even if you may be acting on behalf of the third party. In addition to terminating or suspending
                your account, we reserve the right to take appropriate legal action, including without limitation
                pursuing civil, criminal, and injunctive redress.</p>
        </article>

        <!-- MODIFICATIONS AND INTERRUPTIONS -->
        <article>
            <h5>MODIFICATIONS AND INTERRUPTIONS</h5>
            <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any
                reason at our sole discretion without notice. However, we have no obligation to update any
                information on our Site. We also reserve the right to modify or discontinue all or part of the Site
                without notice at any time. We will not be liable to you or any third party for any modification,
                price change, suspension, or discontinuance of the Site.</p>
            <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or
                other problems or need to perform maintenance related to the Site, resulting in interruptions,
                delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or
                otherwise modify the Site at any time or for any reason without notice to you. You agree that we
                have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to
                access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of
                Use will be construed to obligate us to maintain and support the Site or to supply any corrections,
                updates, or releases in connection therewith.</p>
        </article>

        <!-- GOVERNING LAW -->
        <article>
            <h5>GOVERNING LAW</h5>
            <p>These Terms of Use and your use of the Site are governed by and construed in accordance with the laws
                of the State of Texas applicable to agreements made and to be entirely performed within the State of
                Texas, without regard to its conflict of law principles.</p>
        </article>

        <!-- DISPUTE RESOLUTION -->
        <article>
            <h5>DISPUTE RESOLUTION</h5>
            <h6><em>Informal Negotiations</em></h6>
            <p>To expedite resolution and control the cost of any dispute, controversy, or claim related to these
                Terms of Use (each a "Dispute" and collectively, the “Disputes”) brought by either you or us
                (individually, a “Party” and collectively, the “Parties”), the Parties agree to first attempt to
                negotiate any Dispute (except those Disputes expressly provided below) informally for at least
                thirty (30) days before initiating arbitration. Such informal negotiations commence upon written
                notice from one Party to the other Party.</p>
            <h6><em>Binding Arbitration</em></h6>
            <p>If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except
                those Disputes expressly excluded below) will be finally and exclusively resolved by binding
                arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT
                AND HAVE A JURY TRIAL. The arbitration shall be commenced and conducted under the Commercial
                Arbitration Rules of the American Arbitration Association ("AAA") and, where appropriate, the AAA’s
                Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of which are
                available at the AAA website www.adr.org. Your arbitration fees and your share of arbitrator
                compensation shall be governed by the AAA Consumer Rules and, where appropriate, limited by the AAA
                Consumer Rules. If such costs are determined to by the arbitrator to be excessive, we will pay all
                arbitration fees and expenses. The arbitration may be conducted in person, through the submission of
                documents, by phone, or online. The arbitrator will make a decision in writing, but need not provide
                a statement of reasons unless requested by either Party. The arbitrator must follow applicable law,
                and any award may be challenged if the arbitrator fails to do so. Except where otherwise required by
                the applicable AAA rules or applicable law, the arbitration will take place in Dallas County, TX.
                Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay
                proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award
                entered by the arbitrator.</p>
            <p>If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be
                commenced or prosecuted in the state and federal courts located in Dallas County, TX, and the
                Parties hereby consent to, and waive all defenses of lack of personal jurisdiction, and forum non
                conveniens with respect to venue and jurisdiction in such state and federal courts. Application of
                the United Nations Convention on Contracts for the International Sale of Goods and the Uniform
                Computer Information Transaction Act (UCITA) are excluded from these Terms of Use.</p>
            <p>In no event shall any Dispute brought by either Party related in any way to the Site be commenced
                more than one (1) years after the cause of action arose. If this provision is found to be illegal or
                unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of
                this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
                submit to the personal jurisdiction of that court.</p>
            <h6><em>Restrictions</em></h6>
            <p>The Parties agree that any arbitration shall be limited to the Dispute between the Parties
                individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other
                proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action
                basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute
                to be brought in a purported representative capacity on behalf of the general public or any other
                persons.</p>
            <h6><em>Exceptions to Informal Negotiations and Arbitration</em></h6>
            <p>The Parties agree that the following Disputes are not subject to the above provisions concerning
                informal negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or
                concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute
                related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use;
                and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable,
                then neither Party will elect to arbitrate any Dispute falling within that portion of this provision
                found to be illegal or unenforceable and such Dispute shall be decided by a court of competent
                jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the
                personal jurisdiction of that court.</p>
        </article>

        <!-- CORRECTIONS -->
        <article>
            <h5>CORRECTIONS</h5>
            <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions,
                including descriptions, pricing, availability, and various other information. We reserve the right
                to correct any errors, inaccuracies, or omissions and to change or update the information on the
                Site at any time, without prior notice.</p>
        </article>

        <!-- DISCLAIMER -->
        <article>
            <h5>DISCLAIMER</h5>
            <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR
                SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT
                LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
                RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
                INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
                GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD
                PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
                ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
                WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
                YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
        </article>

        <!-- LIMITATIONS OF LIABILITY -->
        <article>
            <h5>LIMITATIONS OF LIABILITY</h5>
            <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
                LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF
                WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6)
                MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
                IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                ADDITIONAL RIGHTS.</p>
        </article>

        <!-- INDEMNIFICATION -->
        <article>
            <h5>INDEMNIFICATION</h5>
            <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all
                of our respective officers, agents, partners, and employees, from and against any loss, damage,
                liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third
                party due to or arising out of: (1) use of the Site; (2) breach of these Terms of Use; (3) any
                breach of your representations and warranties set forth in these Terms of Use; (4) your violation of
                the rights of a third party, including but not limited to intellectual property rights; or (5) any
                overt harmful act toward any other user of the Site with whom you connected via the Site.
                Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive
                defense and control of any matter for which you are required to indemnify us, and you agree to
                cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to
                notify you of any such claim, action, or proceeding which is subject to this indemnification upon
                becoming aware of it.</p>
        </article>

        <!-- USER DATA -->
        <article>
            <h5>USER DATA</h5>
            <p>We will maintain certain data that you transmit to the Site for the purpose of managing the
                performance of the Site, as well as data relating to your use of the Site. Although we perform
                regular routine backups of data, you are solely responsible for all data that you transmit or that
                relates to any activity you have undertaken using the Site. You agree that we shall have no
                liability to you for any loss or corruption of any such data, and you hereby waive any right of
                action against us arising from any such loss or corruption of such data.</p>
        </article>

        <!-- ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES -->
        <article>
            <h5>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h5>
            <p>Visiting the Site, sending us emails, and completing online forms constitute electronic
                communications. You consent to receive electronic communications, and you agree that all agreements,
                notices, disclosures, and other communications we provide to you electronically, via email and on
                the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO
                THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY
                OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You
                hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other
                laws in any jurisdiction which require an original signature or delivery or retention of
                non-electronic records, or to payments or the granting of credits by any means other than electronic
                means.</p>
        </article>

        <!-- CALIFORNIA USERS AND RESIDENTS -->
        <article>
            <h5>CALIFORNIA USERS AND RESIDENTS</h5>
            <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance
                Unit of the Division of Consumer Services of the California Department of Consumer Affairs in
                writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at
                (800) 952-5210 or (916) 445-1254.</p>
        </article>

        <!-- MISCELLANEOUS -->
        <article>
            <h5>MISCELLANEOUS</h5>
            <p>These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the
                Site constitute the entire agreement and understanding between you and us. Our failure to exercise
                or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right
                or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any
                or all of our rights and obligations to others at any time. We shall not be responsible or liable
                for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If
                any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or
                unenforceable, that provision or part of the provision is deemed severable from these Terms of Use
                and does not affect the validity and enforceability of any remaining provisions. There is no joint
                venture, partnership, employment or agency relationship created between you and us as a result of
                these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed
                against us by virtue of having drafted them. You hereby waive any and all defenses you may have
                based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to
                execute these Terms of Use.</p>
        </article>

        <!-- CONTACT US -->
        <article>
            <h5>CONTACT US</h5>
            <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of
                the Site, please contact us at:</p>
            <em>BEC Technologies Inc</em><br />
            3301 Matrix Dr. #200 <br />
            Richardson, TX 75082 <br />
            (972) 422-0877 <br />
            (972) 422-0886 <br />
            becentral&#64;bectechnologies.net
        </article>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close('Accept click')" rippleEffect>
        Accept
    </button>
</div>