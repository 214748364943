import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from "@angular/core";

@Directive({ selector: '[ngx-datatable-resize-watcher]' })
export class NgxDatatableResizeWatcherDirective implements OnInit, OnDestroy {
    // Private
    private _latestWidth: number;
    private _resizeObserver: ResizeObserver;

    /**
     * 
     * @param table 
     * @param ref 
     */
    constructor(private table: DatatableComponent,
        public ref: ChangeDetectorRef) { }

    ngOnInit(): void {

        this._resizeObserver = new ResizeObserver((entries) => {

            const tableItself = entries[0].target;
            if (this.table && tableItself.clientWidth !== this._latestWidth) {
                this._latestWidth = tableItself.clientWidth;
                this.table.recalculate();
                this.ref.markForCheck();
                window.dispatchEvent(new Event('resize'));
            }
        });

        this._resizeObserver.observe(this.table.element);
    }

    ngOnDestroy(): void {
        this._resizeObserver.disconnect();
    }
}