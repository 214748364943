export const environment = {
	"production": true,
	"hmr": false,
	"apiUrl": "https://staging-app.becentral.io/api/accesses/",
	"userServiceUrl": "https://staging-app.becentral.io/api/accesses/",
	"deviceGatewayUrl": "https://staging-app.becentral.io/api/devices/",
	"scheduleGatewayUrl": "https://staging-app.becentral.io/api/schedulers/api/",
	"socketUrl": "https://staging-app.becentral.io/api/accesses",
	"rttysGatewayUrl": "https://rttys.becentral.io/web/",
	"recaptchaSiteKey": "6LcCeRYoAAAAAHVEb-V0ht4cbYdJnnpcr-t0nIWA",
	"becentralApiDocumentUrl": "https://docs.becentral.io/api",
	"internetFunction": true,
	"versionControl": '2.13.8.2'
}
