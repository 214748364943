<div class="auth-wrapper auth-v2">
	<div class="auth-inner row m-0 pb-0" style="overflow-x: hidden;">
		<div class="global-rllipse d-block d-lg-none"></div>

		<!-- Left Section-->
		<div class="d-flex col-lg-5 align-items-center auth-bg px-2 p-lg-5 reset-password-form-section">
			<div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="reset-password-form-header">
					<h1>Reset Password</h1>
					<p>Your new password should be different from previously used passwords.</p>
				</div>
				<div class="middle-container">
					<article class="reset-password-requirement mb-1" style="padding-left: 1rem;">
						<div class="row password-requirement">
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': minlengthValid}">
									at least 8 charaters
								</div>
							</div>
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': capitalValid}">
									one uppercase letter
								</div>
							</div>
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': digitValid}">
									one number
								</div>
							</div>
							<div class="col-6 item">
								<div [ngClass]="{'requirement-valid': specialValid}">
									one special charater
								</div>
							</div>
						</div>
					</article>
				</div>
				<div class="middle-container">
					<form class="auth-reset-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
						<ng-container *ngIf="submitted && formErrors && formErrors.Mismatch">
							<ngb-alert [type]="'danger'" [dismissible]="false">
								<div class="alert-body text-center"> Confrim Password must be match </div>
							</ngb-alert>
						</ng-container>
						<password-input [id]="'reset-password-new'" [label]="'New Password'"
							formControlName="newPassword" [placeholder]="'············'" [submitted]="submitted"
							[displayErrorMessage]="false"></password-input>
						<password-input [id]="'reset-password-confirm'" [label]="'Confirm Password'"
							formControlName="confirmPassword" [placeholder]="'············'" [submitted]="submitted"
							[displayErrorMessage]="false"></password-input>
						<terms-of-use-input [id]="'reset-password-terms-of-use'" [prefix]="'I agree to the'"
							[label]="'Terms & conditions'" formControlName="termOfUse" [submitted]="submitted"
							(action)="displayTermsOfUse($event)"></terms-of-use-input>
						<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block"
							rippleEffect>
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Set New Password
						</button>
					</form>
				</div>
				<p class="text-center mt-2">
					<a routerLink="/login" id="back-to-login">
						<i data-feather="chevron-left" class="mb-25"></i> Back to login
					</a>
				</p>
			</div>
		</div>

		<!-- Right Section -->
		<div class="d-none d-lg-flex col-lg-7 reset-password-illustration-section">
			<div class="w-100 reset-password-illustration-image">
				<div class="rllipse"></div>
				<img src="assets/images/pages/reset-password-v2-dark.svg" alt="Reset password" />
			</div>
		</div>
	</div>
</div>