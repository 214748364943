export const TAC_TO_RADIO_TYPE_MAP: { [key: string]: string } = {
    "353968": "R4",
    "356000": "R5",
    "359225": "R6",
    "354572": "R15",
    "359072": "R15",
    "359073": "R15",
    "861107": "R17",
    "866834": "R17",
    "864839": "R17",
    "868323": "R17",
    "861641": "R17",
    "864746": "R17",
    "860524": "R18",
    "862165": "R18",
    "353587": "R20",
    "354580": "R20",
    "869910": "R21",
    "869710": "R21",
    "867897": "R24",
    "356299": "R26",
    "862343": "R28"
};

export const RADIO_TYPE_TO_TAC_MAP: { [key: string]: Array<string> } = {
    "R4": [
        "353968"
    ],
    "R5": [
        "356000"
    ],
    "R6": [
        "359225"
    ],
    "R15": [
        "354572",
        "359072",
        "359073"
    ],
    "R17": [
        "861107",
        "866834",
        "864839",
        "868323",
        "861641",
        "864746"
    ],
    "R17-AF": [
        "86110703",
        "86683404",
        "86474604",
        "86483904",
    ],
    "R17-B41": [
        "86474604"
    ],
    "R18": [
        "860524",
        "862165"
    ],
    "R20": [
        "353587",
        "354580"
    ],
    "R21": [
        "869910",
        "869710"
    ],
    "R24": [
        "867897"
    ],
    "R26": [
        "356299"
    ],
    "R28": [
        "862343"
    ]
};

export const R17_ADVANCED_MAP: { [key: string]: string } = {
    "86110703": "R17-AF",
    "86683404": "R17-AF",
    "86164104": "R17",
    "86474604": "R17-B41",
    "86483904": "R17-AF"
};

export const SUPPORTED_RADIO_TYPE: Array<string> = [
    "R4", "R5", "R6", "R15", "R17", "R17-AF", "R17-B41", "R18", "R20", "R21", "R24", "R26", "R28"
];