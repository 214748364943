import { SelectOption } from "@core/types";

export interface TimeZone {
   name: string;
   value: string;
};

export const TIME_ZONE_LIST: Array<TimeZone> = [
   {
      "value": "Pacific/Midway",
      "name": "(GMT-11:00) Pacific/Midway"
   },
   {
      "value": "Pacific/Niue",
      "name": "(GMT-11:00) Pacific/Niue"
   },
   {
      "value": "Pacific/Pago_Pago",
      "name": "(GMT-11:00) Pacific/Pago_Pago"
   },
   {
      "value": "America/Adak",
      "name": "(GMT-10:00) America/Adak"
   },
   {
      "value": "Pacific/Honolulu",
      "name": "(GMT-10:00) Pacific/Honolulu"
   },
   {
      "value": "Pacific/Rarotonga",
      "name": "(GMT-10:00) Pacific/Rarotonga"
   },
   {
      "value": "Pacific/Tahiti",
      "name": "(GMT-10:00) Pacific/Tahiti"
   },
   {
      "value": "Pacific/Marquesas",
      "name": "(GMT-09:30) Pacific/Marquesas"
   },
   {
      "value": "America/Anchorage",
      "name": "(GMT-09:00) America/Anchorage"
   },
   {
      "value": "America/Juneau",
      "name": "(GMT-09:00) America/Juneau"
   },
   {
      "value": "America/Metlakatla",
      "name": "(GMT-09:00) America/Metlakatla"
   },
   {
      "value": "America/Nome",
      "name": "(GMT-09:00) America/Nome"
   },
   {
      "value": "America/Sitka",
      "name": "(GMT-09:00) America/Sitka"
   },
   {
      "value": "America/Yakutat",
      "name": "(GMT-09:00) America/Yakutat"
   },
   {
      "value": "Pacific/Gambier",
      "name": "(GMT-09:00) Pacific/Gambier"
   },
   {
      "value": "America/Los_Angeles",
      "name": "(GMT-08:00) America/Los_Angeles"
   },
   {
      "value": "America/Tijuana",
      "name": "(GMT-08:00) America/Tijuana"
   },
   {
      "value": "America/Vancouver",
      "name": "(GMT-08:00) America/Vancouver"
   },
   {
      "value": "Pacific/Pitcairn",
      "name": "(GMT-08:00) Pacific/Pitcairn"
   },
   {
      "value": "America/Boise",
      "name": "(GMT-07:00) America/Boise"
   },
   {
      "value": "America/Cambridge_Bay",
      "name": "(GMT-07:00) America/Cambridge_Bay"
   },
   {
      "value": "America/Creston",
      "name": "(GMT-07:00) America/Creston"
   },
   {
      "value": "America/Dawson",
      "name": "(GMT-07:00) America/Dawson"
   },
   {
      "value": "America/Dawson_Creek",
      "name": "(GMT-07:00) America/Dawson_Creek"
   },
   {
      "value": "America/Denver",
      "name": "(GMT-07:00) America/Denver"
   },
   {
      "value": "America/Edmonton",
      "name": "(GMT-07:00) America/Edmonton"
   },
   {
      "value": "America/Fort_Nelson",
      "name": "(GMT-07:00) America/Fort_Nelson"
   },
   {
      "value": "America/Hermosillo",
      "name": "(GMT-07:00) America/Hermosillo"
   },
   {
      "value": "America/Inuvik",
      "name": "(GMT-07:00) America/Inuvik"
   },
   {
      "value": "America/Mazatlan",
      "name": "(GMT-07:00) America/Mazatlan"
   },
   {
      "value": "America/Phoenix",
      "name": "(GMT-07:00) America/Phoenix"
   },
   {
      "value": "America/Whitehorse",
      "name": "(GMT-07:00) America/Whitehorse"
   },
   {
      "value": "America/Yellowknife",
      "name": "(GMT-07:00) America/Yellowknife"
   },
   {
      "value": "America/Bahia_Banderas",
      "name": "(GMT-06:00) America/Bahia_Banderas"
   },
   {
      "value": "America/Belize",
      "name": "(GMT-06:00) America/Belize"
   },
   {
      "value": "America/Chicago",
      "name": "(GMT-06:00) America/Chicago"
   },
   {
      "value": "America/Chihuahua",
      "name": "(GMT-06:00) America/Chihuahua"
   },
   {
      "value": "America/Costa_Rica",
      "name": "(GMT-06:00) America/Costa_Rica"
   },
   {
      "value": "America/El_Salvador",
      "name": "(GMT-06:00) America/El_Salvador"
   },
   {
      "value": "America/Guatemala",
      "name": "(GMT-06:00) America/Guatemala"
   },
   {
      "value": "America/Managua",
      "name": "(GMT-06:00) America/Managua"
   },
   {
      "value": "America/Matamoros",
      "name": "(GMT-06:00) America/Matamoros"
   },
   {
      "value": "America/Menominee",
      "name": "(GMT-06:00) America/Menominee"
   },
   {
      "value": "America/Merida",
      "name": "(GMT-06:00) America/Merida"
   },
   {
      "value": "America/Mexico_City",
      "name": "(GMT-06:00) America/Mexico_City"
   },
   {
      "value": "America/Monterrey",
      "name": "(GMT-06:00) America/Monterrey"
   },
   {
      "value": "America/Ojinaga",
      "name": "(GMT-06:00) America/Ojinaga"
   },
   {
      "value": "America/Rainy_River",
      "name": "(GMT-06:00) America/Rainy_River"
   },
   {
      "value": "America/Rankin_Inlet",
      "name": "(GMT-06:00) America/Rankin_Inlet"
   },
   {
      "value": "America/Regina",
      "name": "(GMT-06:00) America/Regina"
   },
   {
      "value": "America/Resolute",
      "name": "(GMT-06:00) America/Resolute"
   },
   {
      "value": "America/Swift_Current",
      "name": "(GMT-06:00) America/Swift_Current"
   },
   {
      "value": "America/Tegucigalpa",
      "name": "(GMT-06:00) America/Tegucigalpa"
   },
   {
      "value": "America/Winnipeg",
      "name": "(GMT-06:00) America/Winnipeg"
   },
   {
      "value": "Pacific/Galapagos",
      "name": "(GMT-06:00) Pacific/Galapagos"
   },
   {
      "value": "America/Atikokan",
      "name": "(GMT-05:00) America/Atikokan"
   },
   {
      "value": "America/Bogota",
      "name": "(GMT-05:00) America/Bogota"
   },
   {
      "value": "America/Cancun",
      "name": "(GMT-05:00) America/Cancun"
   },
   {
      "value": "America/Cayman",
      "name": "(GMT-05:00) America/Cayman"
   },
   {
      "value": "America/Detroit",
      "name": "(GMT-05:00) America/Detroit"
   },
   {
      "value": "America/Eirunepe",
      "name": "(GMT-05:00) America/Eirunepe"
   },
   {
      "value": "America/Grand_Turk",
      "name": "(GMT-05:00) America/Grand_Turk"
   },
   {
      "value": "America/Guayaquil",
      "name": "(GMT-05:00) America/Guayaquil"
   },
   {
      "value": "America/Havana",
      "name": "(GMT-05:00) America/Havana"
   },
   {
      "value": "America/Iqaluit",
      "name": "(GMT-05:00) America/Iqaluit"
   },
   {
      "value": "America/Jamaica",
      "name": "(GMT-05:00) America/Jamaica"
   },
   {
      "value": "America/Lima",
      "name": "(GMT-05:00) America/Lima"
   },
   {
      "value": "America/Nassau",
      "name": "(GMT-05:00) America/Nassau"
   },
   {
      "value": "America/New_York",
      "name": "(GMT-05:00) America/New_York"
   },
   {
      "value": "America/Nipigon",
      "name": "(GMT-05:00) America/Nipigon"
   },
   {
      "value": "America/Panama",
      "name": "(GMT-05:00) America/Panama"
   },
   {
      "value": "America/Pangnirtung",
      "name": "(GMT-05:00) America/Pangnirtung"
   },
   {
      "value": "America/Port-au-Prince",
      "name": "(GMT-05:00) America/Port-au-Prince"
   },
   {
      "value": "America/Rio_Branco",
      "name": "(GMT-05:00) America/Rio_Branco"
   },
   {
      "value": "America/Thunder_Bay",
      "name": "(GMT-05:00) America/Thunder_Bay"
   },
   {
      "value": "America/Toronto",
      "name": "(GMT-05:00) America/Toronto"
   },
   {
      "value": "Pacific/Easter",
      "name": "(GMT-05:00) Pacific/Easter"
   },
   {
      "value": "America/Anguilla",
      "name": "(GMT-04:00) America/Anguilla"
   },
   {
      "value": "America/Antigua",
      "name": "(GMT-04:00) America/Antigua"
   },
   {
      "value": "America/Aruba",
      "name": "(GMT-04:00) America/Aruba"
   },
   {
      "value": "America/Barbados",
      "name": "(GMT-04:00) America/Barbados"
   },
   {
      "value": "America/Blanc-Sablon",
      "name": "(GMT-04:00) America/Blanc-Sablon"
   },
   {
      "value": "America/Boa_Vista",
      "name": "(GMT-04:00) America/Boa_Vista"
   },
   {
      "value": "America/Campo_Grande",
      "name": "(GMT-04:00) America/Campo_Grande"
   },
   {
      "value": "America/Caracas",
      "name": "(GMT-04:00) America/Caracas"
   },
   {
      "value": "America/Cuiaba",
      "name": "(GMT-04:00) America/Cuiaba"
   },
   {
      "value": "America/Curacao",
      "name": "(GMT-04:00) America/Curacao"
   },
   {
      "value": "America/Dominica",
      "name": "(GMT-04:00) America/Dominica"
   },
   {
      "value": "America/Glace_Bay",
      "name": "(GMT-04:00) America/Glace_Bay"
   },
   {
      "value": "America/Goose_Bay",
      "name": "(GMT-04:00) America/Goose_Bay"
   },
   {
      "value": "America/Grenada",
      "name": "(GMT-04:00) America/Grenada"
   },
   {
      "value": "America/Guadeloupe",
      "name": "(GMT-04:00) America/Guadeloupe"
   },
   {
      "value": "America/Guyana",
      "name": "(GMT-04:00) America/Guyana"
   },
   {
      "value": "America/Halifax",
      "name": "(GMT-04:00) America/Halifax"
   },
   {
      "value": "America/Kralendijk",
      "name": "(GMT-04:00) America/Kralendijk"
   },
   {
      "value": "America/La_Paz",
      "name": "(GMT-04:00) America/La_Paz"
   },
   {
      "value": "America/Lower_Princes",
      "name": "(GMT-04:00) America/Lower_Princes"
   },
   {
      "value": "America/Manaus",
      "name": "(GMT-04:00) America/Manaus"
   },
   {
      "value": "America/Marigot",
      "name": "(GMT-04:00) America/Marigot"
   },
   {
      "value": "America/Martinique",
      "name": "(GMT-04:00) America/Martinique"
   },
   {
      "value": "America/Moncton",
      "name": "(GMT-04:00) America/Moncton"
   },
   {
      "value": "America/Montserrat",
      "name": "(GMT-04:00) America/Montserrat"
   },
   {
      "value": "America/Port_of_Spain",
      "name": "(GMT-04:00) America/Port_of_Spain"
   },
   {
      "value": "America/Porto_Velho",
      "name": "(GMT-04:00) America/Porto_Velho"
   },
   {
      "value": "America/Puerto_Rico",
      "name": "(GMT-04:00) America/Puerto_Rico"
   },
   {
      "value": "America/Santo_Domingo",
      "name": "(GMT-04:00) America/Santo_Domingo"
   },
   {
      "value": "America/St_Barthelemy",
      "name": "(GMT-04:00) America/St_Barthelemy"
   },
   {
      "value": "America/St_Kitts",
      "name": "(GMT-04:00) America/St_Kitts"
   },
   {
      "value": "America/St_Lucia",
      "name": "(GMT-04:00) America/St_Lucia"
   },
   {
      "value": "America/St_Thomas",
      "name": "(GMT-04:00) America/St_Thomas"
   },
   {
      "value": "America/St_Vincent",
      "name": "(GMT-04:00) America/St_Vincent"
   },
   {
      "value": "America/Thule",
      "name": "(GMT-04:00) America/Thule"
   },
   {
      "value": "America/Tortola",
      "name": "(GMT-04:00) America/Tortola"
   },
   {
      "value": "Atlantic/Bermuda",
      "name": "(GMT-04:00) Atlantic/Bermuda"
   },
   {
      "value": "America/St_Johns",
      "name": "(GMT-03:30) America/St_Johns"
   },
   {
      "value": "America/Araguaina",
      "name": "(GMT-03:00) America/Araguaina"
   },
   {
      "value": "America/Asuncion",
      "name": "(GMT-03:00) America/Asuncion"
   },
   {
      "value": "America/Bahia",
      "name": "(GMT-03:00) America/Bahia"
   },
   {
      "value": "America/Belem",
      "name": "(GMT-03:00) America/Belem"
   },
   {
      "value": "America/Cayenne",
      "name": "(GMT-03:00) America/Cayenne"
   },
   {
      "value": "America/Fortaleza",
      "name": "(GMT-03:00) America/Fortaleza"
   },
   {
      "value": "America/Maceio",
      "name": "(GMT-03:00) America/Maceio"
   },
   {
      "value": "America/Miquelon",
      "name": "(GMT-03:00) America/Miquelon"
   },
   {
      "value": "America/Montevideo",
      "name": "(GMT-03:00) America/Montevideo"
   },
   {
      "value": "America/Nuuk",
      "name": "(GMT-03:00) America/Nuuk"
   },
   {
      "value": "America/Paramaribo",
      "name": "(GMT-03:00) America/Paramaribo"
   },
   {
      "value": "America/Punta_Arenas",
      "name": "(GMT-03:00) America/Punta_Arenas"
   },
   {
      "value": "America/Recife",
      "name": "(GMT-03:00) America/Recife"
   },
   {
      "value": "America/Santarem",
      "name": "(GMT-03:00) America/Santarem"
   },
   {
      "value": "America/Santiago",
      "name": "(GMT-03:00) America/Santiago"
   },
   {
      "value": "America/Sao_Paulo",
      "name": "(GMT-03:00) America/Sao_Paulo"
   },
   {
      "value": "Antarctica/Palmer",
      "name": "(GMT-03:00) Antarctica/Palmer"
   },
   {
      "value": "Antarctica/Rothera",
      "name": "(GMT-03:00) Antarctica/Rothera"
   },
   {
      "value": "Atlantic/Stanley",
      "name": "(GMT-03:00) Atlantic/Stanley"
   },
   {
      "value": "America/Noronha",
      "name": "(GMT-02:00) America/Noronha"
   },
   {
      "value": "Atlantic/South_Georgia",
      "name": "(GMT-02:00) Atlantic/South_Georgia"
   },
   {
      "value": "America/Scoresbysund",
      "name": "(GMT-01:00) America/Scoresbysund"
   },
   {
      "value": "Atlantic/Azores",
      "name": "(GMT-01:00) Atlantic/Azores"
   },
   {
      "value": "Atlantic/Cape_Verde",
      "name": "(GMT-01:00) Atlantic/Cape_Verde"
   },
   {
      "value": "Africa/Abidjan",
      "name": "(GMT+00:00) Africa/Abidjan"
   },
   {
      "value": "Africa/Accra",
      "name": "(GMT+00:00) Africa/Accra"
   },
   {
      "value": "Africa/Bamako",
      "name": "(GMT+00:00) Africa/Bamako"
   },
   {
      "value": "Africa/Banjul",
      "name": "(GMT+00:00) Africa/Banjul"
   },
   {
      "value": "Africa/Bissau",
      "name": "(GMT+00:00) Africa/Bissau"
   },
   {
      "value": "Africa/Conakry",
      "name": "(GMT+00:00) Africa/Conakry"
   },
   {
      "value": "Africa/Dakar",
      "name": "(GMT+00:00) Africa/Dakar"
   },
   {
      "value": "Africa/Freetown",
      "name": "(GMT+00:00) Africa/Freetown"
   },
   {
      "value": "Africa/Lome",
      "name": "(GMT+00:00) Africa/Lome"
   },
   {
      "value": "Africa/Monrovia",
      "name": "(GMT+00:00) Africa/Monrovia"
   },
   {
      "value": "Africa/Nouakchott",
      "name": "(GMT+00:00) Africa/Nouakchott"
   },
   {
      "value": "Africa/Ouagadougou",
      "name": "(GMT+00:00) Africa/Ouagadougou"
   },
   {
      "value": "Africa/Sao_Tome",
      "name": "(GMT+00:00) Africa/Sao_Tome"
   },
   {
      "value": "America/Danmarkshavn",
      "name": "(GMT+00:00) America/Danmarkshavn"
   },
   {
      "value": "Antarctica/Troll",
      "name": "(GMT+00:00) Antarctica/Troll"
   },
   {
      "value": "Atlantic/Canary",
      "name": "(GMT+00:00) Atlantic/Canary"
   },
   {
      "value": "Atlantic/Faroe",
      "name": "(GMT+00:00) Atlantic/Faroe"
   },
   {
      "value": "Atlantic/Madeira",
      "name": "(GMT+00:00) Atlantic/Madeira"
   },
   {
      "value": "Atlantic/Reykjavik",
      "name": "(GMT+00:00) Atlantic/Reykjavik"
   },
   {
      "value": "Atlantic/St_Helena",
      "name": "(GMT+00:00) Atlantic/St_Helena"
   },
   {
      "value": "Europe/Dublin",
      "name": "(GMT+00:00) Europe/Dublin"
   },
   {
      "value": "Europe/Guernsey",
      "name": "(GMT+00:00) Europe/Guernsey"
   },
   {
      "value": "Europe/Isle_of_Man",
      "name": "(GMT+00:00) Europe/Isle_of_Man"
   },
   {
      "value": "Europe/Jersey",
      "name": "(GMT+00:00) Europe/Jersey"
   },
   {
      "value": "Europe/Lisbon",
      "name": "(GMT+00:00) Europe/Lisbon"
   },
   {
      "value": "Europe/London",
      "name": "(GMT+00:00) Europe/London"
   },
   {
      "value": "Africa/Algiers",
      "name": "(GMT+01:00) Africa/Algiers"
   },
   {
      "value": "Africa/Bangui",
      "name": "(GMT+01:00) Africa/Bangui"
   },
   {
      "value": "Africa/Brazzaville",
      "name": "(GMT+01:00) Africa/Brazzaville"
   },
   {
      "value": "Africa/Casablanca",
      "name": "(GMT+01:00) Africa/Casablanca"
   },
   {
      "value": "Africa/Ceuta",
      "name": "(GMT+01:00) Africa/Ceuta"
   },
   {
      "value": "Africa/Douala",
      "name": "(GMT+01:00) Africa/Douala"
   },
   {
      "value": "Africa/El_Aaiun",
      "name": "(GMT+01:00) Africa/El_Aaiun"
   },
   {
      "value": "Africa/Kinshasa",
      "name": "(GMT+01:00) Africa/Kinshasa"
   },
   {
      "value": "Africa/Lagos",
      "name": "(GMT+01:00) Africa/Lagos"
   },
   {
      "value": "Africa/Libreville",
      "name": "(GMT+01:00) Africa/Libreville"
   },
   {
      "value": "Africa/Luanda",
      "name": "(GMT+01:00) Africa/Luanda"
   },
   {
      "value": "Africa/Malabo",
      "name": "(GMT+01:00) Africa/Malabo"
   },
   {
      "value": "Africa/Ndjamena",
      "name": "(GMT+01:00) Africa/Ndjamena"
   },
   {
      "value": "Africa/Niamey",
      "name": "(GMT+01:00) Africa/Niamey"
   },
   {
      "value": "Africa/Porto-Novo",
      "name": "(GMT+01:00) Africa/Porto-Novo"
   },
   {
      "value": "Africa/Tunis",
      "name": "(GMT+01:00) Africa/Tunis"
   },
   {
      "value": "Arctic/Longyearbyen",
      "name": "(GMT+01:00) Arctic/Longyearbyen"
   },
   {
      "value": "Europe/Amsterdam",
      "name": "(GMT+01:00) Europe/Amsterdam"
   },
   {
      "value": "Europe/Andorra",
      "name": "(GMT+01:00) Europe/Andorra"
   },
   {
      "value": "Europe/Belgrade",
      "name": "(GMT+01:00) Europe/Belgrade"
   },
   {
      "value": "Europe/Berlin",
      "name": "(GMT+01:00) Europe/Berlin"
   },
   {
      "value": "Europe/Bratislava",
      "name": "(GMT+01:00) Europe/Bratislava"
   },
   {
      "value": "Europe/Brussels",
      "name": "(GMT+01:00) Europe/Brussels"
   },
   {
      "value": "Europe/Budapest",
      "name": "(GMT+01:00) Europe/Budapest"
   },
   {
      "value": "Europe/Busingen",
      "name": "(GMT+01:00) Europe/Busingen"
   },
   {
      "value": "Europe/Copenhagen",
      "name": "(GMT+01:00) Europe/Copenhagen"
   },
   {
      "value": "Europe/Gibraltar",
      "name": "(GMT+01:00) Europe/Gibraltar"
   },
   {
      "value": "Europe/Ljubljana",
      "name": "(GMT+01:00) Europe/Ljubljana"
   },
   {
      "value": "Europe/Luxembourg",
      "name": "(GMT+01:00) Europe/Luxembourg"
   },
   {
      "value": "Europe/Madrid",
      "name": "(GMT+01:00) Europe/Madrid"
   },
   {
      "value": "Europe/Malta",
      "name": "(GMT+01:00) Europe/Malta"
   },
   {
      "value": "Europe/Monaco",
      "name": "(GMT+01:00) Europe/Monaco"
   },
   {
      "value": "Europe/Oslo",
      "name": "(GMT+01:00) Europe/Oslo"
   },
   {
      "value": "Europe/Paris",
      "name": "(GMT+01:00) Europe/Paris"
   },
   {
      "value": "Europe/Podgorica",
      "name": "(GMT+01:00) Europe/Podgorica"
   },
   {
      "value": "Europe/Prague",
      "name": "(GMT+01:00) Europe/Prague"
   },
   {
      "value": "Europe/Rome",
      "name": "(GMT+01:00) Europe/Rome"
   },
   {
      "value": "Europe/San_Marino",
      "name": "(GMT+01:00) Europe/San_Marino"
   },
   {
      "value": "Europe/Sarajevo",
      "name": "(GMT+01:00) Europe/Sarajevo"
   },
   {
      "value": "Europe/Skopje",
      "name": "(GMT+01:00) Europe/Skopje"
   },
   {
      "value": "Europe/Stockholm",
      "name": "(GMT+01:00) Europe/Stockholm"
   },
   {
      "value": "Europe/Tirane",
      "name": "(GMT+01:00) Europe/Tirane"
   },
   {
      "value": "Europe/Vaduz",
      "name": "(GMT+01:00) Europe/Vaduz"
   },
   {
      "value": "Europe/Vatican",
      "name": "(GMT+01:00) Europe/Vatican"
   },
   {
      "value": "Europe/Vienna",
      "name": "(GMT+01:00) Europe/Vienna"
   },
   {
      "value": "Europe/Warsaw",
      "name": "(GMT+01:00) Europe/Warsaw"
   },
   {
      "value": "Europe/Zagreb",
      "name": "(GMT+01:00) Europe/Zagreb"
   },
   {
      "value": "Europe/Zurich",
      "name": "(GMT+01:00) Europe/Zurich"
   },
   {
      "value": "Africa/Blantyre",
      "name": "(GMT+02:00) Africa/Blantyre"
   },
   {
      "value": "Africa/Bujumbura",
      "name": "(GMT+02:00) Africa/Bujumbura"
   },
   {
      "value": "Africa/Cairo",
      "name": "(GMT+02:00) Africa/Cairo"
   },
   {
      "value": "Africa/Gaborone",
      "name": "(GMT+02:00) Africa/Gaborone"
   },
   {
      "value": "Africa/Harare",
      "name": "(GMT+02:00) Africa/Harare"
   },
   {
      "value": "Africa/Johannesburg",
      "name": "(GMT+02:00) Africa/Johannesburg"
   },
   {
      "value": "Africa/Juba",
      "name": "(GMT+02:00) Africa/Juba"
   },
   {
      "value": "Africa/Khartoum",
      "name": "(GMT+02:00) Africa/Khartoum"
   },
   {
      "value": "Africa/Kigali",
      "name": "(GMT+02:00) Africa/Kigali"
   },
   {
      "value": "Africa/Lubumbashi",
      "name": "(GMT+02:00) Africa/Lubumbashi"
   },
   {
      "value": "Africa/Lusaka",
      "name": "(GMT+02:00) Africa/Lusaka"
   },
   {
      "value": "Africa/Maputo",
      "name": "(GMT+02:00) Africa/Maputo"
   },
   {
      "value": "Africa/Maseru",
      "name": "(GMT+02:00) Africa/Maseru"
   },
   {
      "value": "Africa/Mbabane",
      "name": "(GMT+02:00) Africa/Mbabane"
   },
   {
      "value": "Africa/Tripoli",
      "name": "(GMT+02:00) Africa/Tripoli"
   },
   {
      "value": "Africa/Windhoek",
      "name": "(GMT+02:00) Africa/Windhoek"
   },
   {
      "value": "Asia/Beirut",
      "name": "(GMT+02:00) Asia/Beirut"
   },
   {
      "value": "Asia/Famagusta",
      "name": "(GMT+02:00) Asia/Famagusta"
   },
   {
      "value": "Asia/Gaza",
      "name": "(GMT+02:00) Asia/Gaza"
   },
   {
      "value": "Asia/Hebron",
      "name": "(GMT+02:00) Asia/Hebron"
   },
   {
      "value": "Asia/Jerusalem",
      "name": "(GMT+02:00) Asia/Jerusalem"
   },
   {
      "value": "Asia/Nicosia",
      "name": "(GMT+02:00) Asia/Nicosia"
   },
   {
      "value": "Europe/Athens",
      "name": "(GMT+02:00) Europe/Athens"
   },
   {
      "value": "Europe/Bucharest",
      "name": "(GMT+02:00) Europe/Bucharest"
   },
   {
      "value": "Europe/Chisinau",
      "name": "(GMT+02:00) Europe/Chisinau"
   },
   {
      "value": "Europe/Helsinki",
      "name": "(GMT+02:00) Europe/Helsinki"
   },
   {
      "value": "Europe/Kaliningrad",
      "name": "(GMT+02:00) Europe/Kaliningrad"
   },
   {
      "value": "Europe/Kiev",
      "name": "(GMT+02:00) Europe/Kiev"
   },
   {
      "value": "Europe/Mariehamn",
      "name": "(GMT+02:00) Europe/Mariehamn"
   },
   {
      "value": "Europe/Riga",
      "name": "(GMT+02:00) Europe/Riga"
   },
   {
      "value": "Europe/Sofia",
      "name": "(GMT+02:00) Europe/Sofia"
   },
   {
      "value": "Europe/Tallinn",
      "name": "(GMT+02:00) Europe/Tallinn"
   },
   {
      "value": "Europe/Uzhgorod",
      "name": "(GMT+02:00) Europe/Uzhgorod"
   },
   {
      "value": "Europe/Vilnius",
      "name": "(GMT+02:00) Europe/Vilnius"
   },
   {
      "value": "Europe/Zaporozhye",
      "name": "(GMT+02:00) Europe/Zaporozhye"
   },
   {
      "value": "Africa/Addis_Ababa",
      "name": "(GMT+03:00) Africa/Addis_Ababa"
   },
   {
      "value": "Africa/Asmara",
      "name": "(GMT+03:00) Africa/Asmara"
   },
   {
      "value": "Africa/Dar_es_Salaam",
      "name": "(GMT+03:00) Africa/Dar_es_Salaam"
   },
   {
      "value": "Africa/Djibouti",
      "name": "(GMT+03:00) Africa/Djibouti"
   },
   {
      "value": "Africa/Kampala",
      "name": "(GMT+03:00) Africa/Kampala"
   },
   {
      "value": "Africa/Mogadishu",
      "name": "(GMT+03:00) Africa/Mogadishu"
   },
   {
      "value": "Africa/Nairobi",
      "name": "(GMT+03:00) Africa/Nairobi"
   },
   {
      "value": "Antarctica/Syowa",
      "name": "(GMT+03:00) Antarctica/Syowa"
   },
   {
      "value": "Asia/Aden",
      "name": "(GMT+03:00) Asia/Aden"
   },
   {
      "value": "Asia/Amman",
      "name": "(GMT+03:00) Asia/Amman"
   },
   {
      "value": "Asia/Baghdad",
      "name": "(GMT+03:00) Asia/Baghdad"
   },
   {
      "value": "Asia/Bahrain",
      "name": "(GMT+03:00) Asia/Bahrain"
   },
   {
      "value": "Asia/Damascus",
      "name": "(GMT+03:00) Asia/Damascus"
   },
   {
      "value": "Asia/Kuwait",
      "name": "(GMT+03:00) Asia/Kuwait"
   },
   {
      "value": "Asia/Qatar",
      "name": "(GMT+03:00) Asia/Qatar"
   },
   {
      "value": "Asia/Riyadh",
      "name": "(GMT+03:00) Asia/Riyadh"
   },
   {
      "value": "Europe/Istanbul",
      "name": "(GMT+03:00) Europe/Istanbul"
   },
   {
      "value": "Europe/Kirov",
      "name": "(GMT+03:00) Europe/Kirov"
   },
   {
      "value": "Europe/Minsk",
      "name": "(GMT+03:00) Europe/Minsk"
   },
   {
      "value": "Europe/Moscow",
      "name": "(GMT+03:00) Europe/Moscow"
   },
   {
      "value": "Europe/Simferopol",
      "name": "(GMT+03:00) Europe/Simferopol"
   },
   {
      "value": "Europe/Volgograd",
      "name": "(GMT+03:00) Europe/Volgograd"
   },
   {
      "value": "Indian/Antananarivo",
      "name": "(GMT+03:00) Indian/Antananarivo"
   },
   {
      "value": "Indian/Comoro",
      "name": "(GMT+03:00) Indian/Comoro"
   },
   {
      "value": "Indian/Mayotte",
      "name": "(GMT+03:00) Indian/Mayotte"
   },
   {
      "value": "Asia/Tehran",
      "name": "(GMT+03:30) Asia/Tehran"
   },
   {
      "value": "Asia/Baku",
      "name": "(GMT+04:00) Asia/Baku"
   },
   {
      "value": "Asia/Dubai",
      "name": "(GMT+04:00) Asia/Dubai"
   },
   {
      "value": "Asia/Muscat",
      "name": "(GMT+04:00) Asia/Muscat"
   },
   {
      "value": "Asia/Tbilisi",
      "name": "(GMT+04:00) Asia/Tbilisi"
   },
   {
      "value": "Asia/Yerevan",
      "name": "(GMT+04:00) Asia/Yerevan"
   },
   {
      "value": "Europe/Astrakhan",
      "name": "(GMT+04:00) Europe/Astrakhan"
   },
   {
      "value": "Europe/Samara",
      "name": "(GMT+04:00) Europe/Samara"
   },
   {
      "value": "Europe/Saratov",
      "name": "(GMT+04:00) Europe/Saratov"
   },
   {
      "value": "Europe/Ulyanovsk",
      "name": "(GMT+04:00) Europe/Ulyanovsk"
   },
   {
      "value": "Indian/Mahe",
      "name": "(GMT+04:00) Indian/Mahe"
   },
   {
      "value": "Indian/Mauritius",
      "name": "(GMT+04:00) Indian/Mauritius"
   },
   {
      "value": "Indian/Reunion",
      "name": "(GMT+04:00) Indian/Reunion"
   },
   {
      "value": "Asia/Kabul",
      "name": "(GMT+04:30) Asia/Kabul"
   },
   {
      "value": "Antarctica/Mawson",
      "name": "(GMT+05:00) Antarctica/Mawson"
   },
   {
      "value": "Asia/Aqtau",
      "name": "(GMT+05:00) Asia/Aqtau"
   },
   {
      "value": "Asia/Aqtobe",
      "name": "(GMT+05:00) Asia/Aqtobe"
   },
   {
      "value": "Asia/Ashgabat",
      "name": "(GMT+05:00) Asia/Ashgabat"
   },
   {
      "value": "Asia/Atyrau",
      "name": "(GMT+05:00) Asia/Atyrau"
   },
   {
      "value": "Asia/Dushanbe",
      "name": "(GMT+05:00) Asia/Dushanbe"
   },
   {
      "value": "Asia/Karachi",
      "name": "(GMT+05:00) Asia/Karachi"
   },
   {
      "value": "Asia/Oral",
      "name": "(GMT+05:00) Asia/Oral"
   },
   {
      "value": "Asia/Qyzylorda",
      "name": "(GMT+05:00) Asia/Qyzylorda"
   },
   {
      "value": "Asia/Samarkand",
      "name": "(GMT+05:00) Asia/Samarkand"
   },
   {
      "value": "Asia/Tashkent",
      "name": "(GMT+05:00) Asia/Tashkent"
   },
   {
      "value": "Asia/Yekaterinburg",
      "name": "(GMT+05:00) Asia/Yekaterinburg"
   },
   {
      "value": "Indian/Kerguelen",
      "name": "(GMT+05:00) Indian/Kerguelen"
   },
   {
      "value": "Indian/Maldives",
      "name": "(GMT+05:00) Indian/Maldives"
   },
   {
      "value": "Asia/Colombo",
      "name": "(GMT+05:30) Asia/Colombo"
   },
   {
      "value": "Asia/Kolkata",
      "name": "(GMT+05:30) Asia/Kolkata"
   },
   {
      "value": "Asia/Kathmandu",
      "name": "(GMT+05:45) Asia/Kathmandu"
   },
   {
      "value": "Antarctica/Vostok",
      "name": "(GMT+06:00) Antarctica/Vostok"
   },
   {
      "value": "Asia/Almaty",
      "name": "(GMT+06:00) Asia/Almaty"
   },
   {
      "value": "Asia/Bishkek",
      "name": "(GMT+06:00) Asia/Bishkek"
   },
   {
      "value": "Asia/Dhaka",
      "name": "(GMT+06:00) Asia/Dhaka"
   },
   {
      "value": "Asia/Omsk",
      "name": "(GMT+06:00) Asia/Omsk"
   },
   {
      "value": "Asia/Qostanay",
      "name": "(GMT+06:00) Asia/Qostanay"
   },
   {
      "value": "Asia/Thimphu",
      "name": "(GMT+06:00) Asia/Thimphu"
   },
   {
      "value": "Asia/Urumqi",
      "name": "(GMT+06:00) Asia/Urumqi"
   },
   {
      "value": "Indian/Chagos",
      "name": "(GMT+06:00) Indian/Chagos"
   },
   {
      "value": "Asia/Yangon",
      "name": "(GMT+06:30) Asia/Yangon"
   },
   {
      "value": "Indian/Cocos",
      "name": "(GMT+06:30) Indian/Cocos"
   },
   {
      "value": "Antarctica/Davis",
      "name": "(GMT+07:00) Antarctica/Davis"
   },
   {
      "value": "Asia/Bangkok",
      "name": "(GMT+07:00) Asia/Bangkok"
   },
   {
      "value": "Asia/Barnaul",
      "name": "(GMT+07:00) Asia/Barnaul"
   },
   {
      "value": "Asia/Ho_Chi_Minh",
      "name": "(GMT+07:00) Asia/Ho_Chi_Minh"
   },
   {
      "value": "Asia/Hovd",
      "name": "(GMT+07:00) Asia/Hovd"
   },
   {
      "value": "Asia/Jakarta",
      "name": "(GMT+07:00) Asia/Jakarta"
   },
   {
      "value": "Asia/Krasnoyarsk",
      "name": "(GMT+07:00) Asia/Krasnoyarsk"
   },
   {
      "value": "Asia/Novokuznetsk",
      "name": "(GMT+07:00) Asia/Novokuznetsk"
   },
   {
      "value": "Asia/Novosibirsk",
      "name": "(GMT+07:00) Asia/Novosibirsk"
   },
   {
      "value": "Asia/Phnom_Penh",
      "name": "(GMT+07:00) Asia/Phnom_Penh"
   },
   {
      "value": "Asia/Pontianak",
      "name": "(GMT+07:00) Asia/Pontianak"
   },
   {
      "value": "Asia/Tomsk",
      "name": "(GMT+07:00) Asia/Tomsk"
   },
   {
      "value": "Asia/Vientiane",
      "name": "(GMT+07:00) Asia/Vientiane"
   },
   {
      "value": "Indian/Christmas",
      "name": "(GMT+07:00) Indian/Christmas"
   },
   {
      "value": "Asia/Brunei",
      "name": "(GMT+08:00) Asia/Brunei"
   },
   {
      "value": "Asia/Choibalsan",
      "name": "(GMT+08:00) Asia/Choibalsan"
   },
   {
      "value": "Asia/Hong_Kong",
      "name": "(GMT+08:00) Asia/Hong_Kong"
   },
   {
      "value": "Asia/Irkutsk",
      "name": "(GMT+08:00) Asia/Irkutsk"
   },
   {
      "value": "Asia/Kuala_Lumpur",
      "name": "(GMT+08:00) Asia/Kuala_Lumpur"
   },
   {
      "value": "Asia/Kuching",
      "name": "(GMT+08:00) Asia/Kuching"
   },
   {
      "value": "Asia/Macau",
      "name": "(GMT+08:00) Asia/Macau"
   },
   {
      "value": "Asia/Makassar",
      "name": "(GMT+08:00) Asia/Makassar"
   },
   {
      "value": "Asia/Manila",
      "name": "(GMT+08:00) Asia/Manila"
   },
   {
      "value": "Asia/Shanghai",
      "name": "(GMT+08:00) Asia/Shanghai"
   },
   {
      "value": "Asia/Singapore",
      "name": "(GMT+08:00) Asia/Singapore"
   },
   {
      "value": "Asia/Taipei",
      "name": "(GMT+08:00) Asia/Taipei"
   },
   {
      "value": "Asia/Ulaanbaatar",
      "name": "(GMT+08:00) Asia/Ulaanbaatar"
   },
   {
      "value": "Australia/Perth",
      "name": "(GMT+08:00) Australia/Perth"
   },
   {
      "value": "Australia/Eucla",
      "name": "(GMT+08:45) Australia/Eucla"
   },
   {
      "value": "Asia/Chita",
      "name": "(GMT+09:00) Asia/Chita"
   },
   {
      "value": "Asia/Dili",
      "name": "(GMT+09:00) Asia/Dili"
   },
   {
      "value": "Asia/Jayapura",
      "name": "(GMT+09:00) Asia/Jayapura"
   },
   {
      "value": "Asia/Khandyga",
      "name": "(GMT+09:00) Asia/Khandyga"
   },
   {
      "value": "Asia/Pyongyang",
      "name": "(GMT+09:00) Asia/Pyongyang"
   },
   {
      "value": "Asia/Seoul",
      "name": "(GMT+09:00) Asia/Seoul"
   },
   {
      "value": "Asia/Tokyo",
      "name": "(GMT+09:00) Asia/Tokyo"
   },
   {
      "value": "Asia/Yakutsk",
      "name": "(GMT+09:00) Asia/Yakutsk"
   },
   {
      "value": "Pacific/Palau",
      "name": "(GMT+09:00) Pacific/Palau"
   },
   {
      "value": "Australia/Darwin",
      "name": "(GMT+09:30) Australia/Darwin"
   },
   {
      "value": "Antarctica/DumontDUrville",
      "name": "(GMT+10:00) Antarctica/DumontDUrville"
   },
   {
      "value": "Asia/Ust-Nera",
      "name": "(GMT+10:00) Asia/Ust-Nera"
   },
   {
      "value": "Asia/Vladivostok",
      "name": "(GMT+10:00) Asia/Vladivostok"
   },
   {
      "value": "Australia/Brisbane",
      "name": "(GMT+10:00) Australia/Brisbane"
   },
   {
      "value": "Australia/Lindeman",
      "name": "(GMT+10:00) Australia/Lindeman"
   },
   {
      "value": "Pacific/Chuuk",
      "name": "(GMT+10:00) Pacific/Chuuk"
   },
   {
      "value": "Pacific/Guam",
      "name": "(GMT+10:00) Pacific/Guam"
   },
   {
      "value": "Pacific/Port_Moresby",
      "name": "(GMT+10:00) Pacific/Port_Moresby"
   },
   {
      "value": "Pacific/Saipan",
      "name": "(GMT+10:00) Pacific/Saipan"
   },
   {
      "value": "Australia/Adelaide",
      "name": "(GMT+10:30) Australia/Adelaide"
   },
   {
      "value": "Australia/Broken_Hill",
      "name": "(GMT+10:30) Australia/Broken_Hill"
   },
   {
      "value": "Antarctica/Casey",
      "name": "(GMT+11:00) Antarctica/Casey"
   },
   {
      "value": "Antarctica/Macquarie",
      "name": "(GMT+11:00) Antarctica/Macquarie"
   },
   {
      "value": "Asia/Magadan",
      "name": "(GMT+11:00) Asia/Magadan"
   },
   {
      "value": "Asia/Sakhalin",
      "name": "(GMT+11:00) Asia/Sakhalin"
   },
   {
      "value": "Asia/Srednekolymsk",
      "name": "(GMT+11:00) Asia/Srednekolymsk"
   },
   {
      "value": "Australia/Hobart",
      "name": "(GMT+11:00) Australia/Hobart"
   },
   {
      "value": "Australia/Lord_Howe",
      "name": "(GMT+11:00) Australia/Lord_Howe"
   },
   {
      "value": "Australia/Melbourne",
      "name": "(GMT+11:00) Australia/Melbourne"
   },
   {
      "value": "Australia/Sydney",
      "name": "(GMT+11:00) Australia/Sydney"
   },
   {
      "value": "Pacific/Bougainville",
      "name": "(GMT+11:00) Pacific/Bougainville"
   },
   {
      "value": "Pacific/Efate",
      "name": "(GMT+11:00) Pacific/Efate"
   },
   {
      "value": "Pacific/Guadalcanal",
      "name": "(GMT+11:00) Pacific/Guadalcanal"
   },
   {
      "value": "Pacific/Kosrae",
      "name": "(GMT+11:00) Pacific/Kosrae"
   },
   {
      "value": "Pacific/Noumea",
      "name": "(GMT+11:00) Pacific/Noumea"
   },
   {
      "value": "Pacific/Pohnpei",
      "name": "(GMT+11:00) Pacific/Pohnpei"
   },
   {
      "value": "Asia/Anadyr",
      "name": "(GMT+12:00) Asia/Anadyr"
   },
   {
      "value": "Asia/Kamchatka",
      "name": "(GMT+12:00) Asia/Kamchatka"
   },
   {
      "value": "Pacific/Fiji",
      "name": "(GMT+12:00) Pacific/Fiji"
   },
   {
      "value": "Pacific/Funafuti",
      "name": "(GMT+12:00) Pacific/Funafuti"
   },
   {
      "value": "Pacific/Kwajalein",
      "name": "(GMT+12:00) Pacific/Kwajalein"
   },
   {
      "value": "Pacific/Majuro",
      "name": "(GMT+12:00) Pacific/Majuro"
   },
   {
      "value": "Pacific/Nauru",
      "name": "(GMT+12:00) Pacific/Nauru"
   },
   {
      "value": "Pacific/Norfolk",
      "name": "(GMT+12:00) Pacific/Norfolk"
   },
   {
      "value": "Pacific/Tarawa",
      "name": "(GMT+12:00) Pacific/Tarawa"
   },
   {
      "value": "Pacific/Wake",
      "name": "(GMT+12:00) Pacific/Wake"
   },
   {
      "value": "Pacific/Wallis",
      "name": "(GMT+12:00) Pacific/Wallis"
   },
   {
      "value": "Antarctica/McMurdo",
      "name": "(GMT+13:00) Antarctica/McMurdo"
   },
   {
      "value": "Pacific/Apia",
      "name": "(GMT+13:00) Pacific/Apia"
   },
   {
      "value": "Pacific/Auckland",
      "name": "(GMT+13:00) Pacific/Auckland"
   },
   {
      "value": "Pacific/Enderbury",
      "name": "(GMT+13:00) Pacific/Enderbury"
   },
   {
      "value": "Pacific/Fakaofo",
      "name": "(GMT+13:00) Pacific/Fakaofo"
   },
   {
      "value": "Pacific/Tongatapu",
      "name": "(GMT+13:00) Pacific/Tongatapu"
   },
   {
      "value": "Pacific/Chatham",
      "name": "(GMT+13:45) Pacific/Chatham"
   },
   {
      "value": "Pacific/Kiritimati",
      "name": "(GMT+14:00) Pacific/Kiritimati"
   }
];

export const OPENWRT_TIME_ZONE_LIST: Array<SelectOption> = [
   {
      "value": "UTC",
      "name": "UTC"
   },
   {
      "value": "Africa/Abidjan",
      "name": "Africa/Abidjan"
   },
   {
      "value": "Africa/Accra",
      "name": "Africa/Accra"
   },
   {
      "value": "Africa/Addis Ababa",
      "name": "Africa/Addis Ababa"
   },
   {
      "value": "Africa/Algiers",
      "name": "Africa/Algiers"
   },
   {
      "value": "Africa/Asmara",
      "name": "Africa/Asmara"
   },
   {
      "value": "Africa/Bamako",
      "name": "Africa/Bamako"
   },
   {
      "value": "Africa/Bangui",
      "name": "Africa/Bangui"
   },
   {
      "value": "Africa/Banjul",
      "name": "Africa/Banjul"
   },
   {
      "value": "Africa/Bissau",
      "name": "Africa/Bissau"
   },
   {
      "value": "Africa/Blantyre",
      "name": "Africa/Blantyre"
   },
   {
      "value": "Africa/Brazzaville",
      "name": "Africa/Brazzaville"
   },
   {
      "value": "Africa/Bujumbura",
      "name": "Africa/Bujumbura"
   },
   {
      "value": "Africa/Cairo",
      "name": "Africa/Cairo"
   },
   {
      "value": "Africa/Casablanca",
      "name": "Africa/Casablanca"
   },
   {
      "value": "Africa/Ceuta",
      "name": "Africa/Ceuta"
   },
   {
      "value": "Africa/Conakry",
      "name": "Africa/Conakry"
   },
   {
      "value": "Africa/Dakar",
      "name": "Africa/Dakar"
   },
   {
      "value": "Africa/Dar es Salaam",
      "name": "Africa/Dar es Salaam"
   },
   {
      "value": "Africa/Djibouti",
      "name": "Africa/Djibouti"
   },
   {
      "value": "Africa/Douala",
      "name": "Africa/Douala"
   },
   {
      "value": "Africa/El Aaiun",
      "name": "Africa/El Aaiun"
   },
   {
      "value": "Africa/Freetown",
      "name": "Africa/Freetown"
   },
   {
      "value": "Africa/Gaborone",
      "name": "Africa/Gaborone"
   },
   {
      "value": "Africa/Harare",
      "name": "Africa/Harare"
   },
   {
      "value": "Africa/Johannesburg",
      "name": "Africa/Johannesburg"
   },
   {
      "value": "Africa/Juba",
      "name": "Africa/Juba"
   },
   {
      "value": "Africa/Kampala",
      "name": "Africa/Kampala"
   },
   {
      "value": "Africa/Khartoum",
      "name": "Africa/Khartoum"
   },
   {
      "value": "Africa/Kigali",
      "name": "Africa/Kigali"
   },
   {
      "value": "Africa/Kinshasa",
      "name": "Africa/Kinshasa"
   },
   {
      "value": "Africa/Lagos",
      "name": "Africa/Lagos"
   },
   {
      "value": "Africa/Libreville",
      "name": "Africa/Libreville"
   },
   {
      "value": "Africa/Lome",
      "name": "Africa/Lome"
   },
   {
      "value": "Africa/Luanda",
      "name": "Africa/Luanda"
   },
   {
      "value": "Africa/Lubumbashi",
      "name": "Africa/Lubumbashi"
   },
   {
      "value": "Africa/Lusaka",
      "name": "Africa/Lusaka"
   },
   {
      "value": "Africa/Malabo",
      "name": "Africa/Malabo"
   },
   {
      "value": "Africa/Maputo",
      "name": "Africa/Maputo"
   },
   {
      "value": "Africa/Maseru",
      "name": "Africa/Maseru"
   },
   {
      "value": "Africa/Mbabane",
      "name": "Africa/Mbabane"
   },
   {
      "value": "Africa/Mogadishu",
      "name": "Africa/Mogadishu"
   },
   {
      "value": "Africa/Monrovia",
      "name": "Africa/Monrovia"
   },
   {
      "value": "Africa/Nairobi",
      "name": "Africa/Nairobi"
   },
   {
      "value": "Africa/Ndjamena",
      "name": "Africa/Ndjamena"
   },
   {
      "value": "Africa/Niamey",
      "name": "Africa/Niamey"
   },
   {
      "value": "Africa/Nouakchott",
      "name": "Africa/Nouakchott"
   },
   {
      "value": "Africa/Ouagadougou",
      "name": "Africa/Ouagadougou"
   },
   {
      "value": "Africa/Porto-Novo",
      "name": "Africa/Porto-Novo"
   },
   {
      "value": "Africa/Sao Tome",
      "name": "Africa/Sao Tome"
   },
   {
      "value": "Africa/Tripoli",
      "name": "Africa/Tripoli"
   },
   {
      "value": "Africa/Tunis",
      "name": "Africa/Tunis"
   },
   {
      "value": "Africa/Windhoek",
      "name": "Africa/Windhoek"
   },
   {
      "value": "America/Adak",
      "name": "America/Adak"
   },
   {
      "value": "America/Anchorage",
      "name": "America/Anchorage"
   },
   {
      "value": "America/Anguilla",
      "name": "America/Anguilla"
   },
   {
      "value": "America/Antigua",
      "name": "America/Antigua"
   },
   {
      "value": "America/Araguaina",
      "name": "America/Araguaina"
   },
   {
      "value": "America/Argentina/Buenos Aires",
      "name": "America/Argentina/Buenos Aires"
   },
   {
      "value": "America/Argentina/Catamarca",
      "name": "America/Argentina/Catamarca"
   },
   {
      "value": "America/Argentina/Cordoba",
      "name": "America/Argentina/Cordoba"
   },
   {
      "value": "America/Argentina/Jujuy",
      "name": "America/Argentina/Jujuy"
   },
   {
      "value": "America/Argentina/La Rioja",
      "name": "America/Argentina/La Rioja"
   },
   {
      "value": "America/Argentina/Mendoza",
      "name": "America/Argentina/Mendoza"
   },
   {
      "value": "America/Argentina/Rio Gallegos",
      "name": "America/Argentina/Rio Gallegos"
   },
   {
      "value": "America/Argentina/Salta",
      "name": "America/Argentina/Salta"
   },
   {
      "value": "America/Argentina/San Juan",
      "name": "America/Argentina/San Juan"
   },
   {
      "value": "America/Argentina/San Luis",
      "name": "America/Argentina/San Luis"
   },
   {
      "value": "America/Argentina/Tucuman",
      "name": "America/Argentina/Tucuman"
   },
   {
      "value": "America/Argentina/Ushuaia",
      "name": "America/Argentina/Ushuaia"
   },
   {
      "value": "America/Aruba",
      "name": "America/Aruba"
   },
   {
      "value": "America/Asuncion",
      "name": "America/Asuncion"
   },
   {
      "value": "America/Atikokan",
      "name": "America/Atikokan"
   },
   {
      "value": "America/Bahia",
      "name": "America/Bahia"
   },
   {
      "value": "America/Bahia Banderas",
      "name": "America/Bahia Banderas"
   },
   {
      "value": "America/Barbados",
      "name": "America/Barbados"
   },
   {
      "value": "America/Belem",
      "name": "America/Belem"
   },
   {
      "value": "America/Belize",
      "name": "America/Belize"
   },
   {
      "value": "America/Blanc-Sablon",
      "name": "America/Blanc-Sablon"
   },
   {
      "value": "America/Boa Vista",
      "name": "America/Boa Vista"
   },
   {
      "value": "America/Bogota",
      "name": "America/Bogota"
   },
   {
      "value": "America/Boise",
      "name": "America/Boise"
   },
   {
      "value": "America/Cambridge Bay",
      "name": "America/Cambridge Bay"
   },
   {
      "value": "America/Campo Grande",
      "name": "America/Campo Grande"
   },
   {
      "value": "America/Cancun",
      "name": "America/Cancun"
   },
   {
      "value": "America/Caracas",
      "name": "America/Caracas"
   },
   {
      "value": "America/Cayenne",
      "name": "America/Cayenne"
   },
   {
      "value": "America/Cayman",
      "name": "America/Cayman"
   },
   {
      "value": "America/Chicago",
      "name": "America/Chicago"
   },
   {
      "value": "America/Chihuahua",
      "name": "America/Chihuahua"
   },
   {
      "value": "America/Costa Rica",
      "name": "America/Costa Rica"
   },
   {
      "value": "America/Creston",
      "name": "America/Creston"
   },
   {
      "value": "America/Cuiaba",
      "name": "America/Cuiaba"
   },
   {
      "value": "America/Curacao",
      "name": "America/Curacao"
   },
   {
      "value": "America/Danmarkshavn",
      "name": "America/Danmarkshavn"
   },
   {
      "value": "America/Dawson",
      "name": "America/Dawson"
   },
   {
      "value": "America/Dawson Creek",
      "name": "America/Dawson Creek"
   },
   {
      "value": "America/Denver",
      "name": "America/Denver"
   },
   {
      "value": "America/Detroit",
      "name": "America/Detroit"
   },
   {
      "value": "America/Dominica",
      "name": "America/Dominica"
   },
   {
      "value": "America/Edmonton",
      "name": "America/Edmonton"
   },
   {
      "value": "America/Eirunepe",
      "name": "America/Eirunepe"
   },
   {
      "value": "America/El Salvador",
      "name": "America/El Salvador"
   },
   {
      "value": "America/Fort Nelson",
      "name": "America/Fort Nelson"
   },
   {
      "value": "America/Fortaleza",
      "name": "America/Fortaleza"
   },
   {
      "value": "America/Glace Bay",
      "name": "America/Glace Bay"
   },
   {
      "value": "America/Goose Bay",
      "name": "America/Goose Bay"
   },
   {
      "value": "America/Grand Turk",
      "name": "America/Grand Turk"
   },
   {
      "value": "America/Grenada",
      "name": "America/Grenada"
   },
   {
      "value": "America/Guadeloupe",
      "name": "America/Guadeloupe"
   },
   {
      "value": "America/Guatemala",
      "name": "America/Guatemala"
   },
   {
      "value": "America/Guayaquil",
      "name": "America/Guayaquil"
   },
   {
      "value": "America/Guyana",
      "name": "America/Guyana"
   },
   {
      "value": "America/Halifax",
      "name": "America/Halifax"
   },
   {
      "value": "America/Havana",
      "name": "America/Havana"
   },
   {
      "value": "America/Hermosillo",
      "name": "America/Hermosillo"
   },
   {
      "value": "America/Indiana/Indianapolis",
      "name": "America/Indiana/Indianapolis"
   },
   {
      "value": "America/Indiana/Knox",
      "name": "America/Indiana/Knox"
   },
   {
      "value": "America/Indiana/Marengo",
      "name": "America/Indiana/Marengo"
   },
   {
      "value": "America/Indiana/Petersburg",
      "name": "America/Indiana/Petersburg"
   },
   {
      "value": "America/Indiana/Tell City",
      "name": "America/Indiana/Tell City"
   },
   {
      "value": "America/Indiana/Vevay",
      "name": "America/Indiana/Vevay"
   },
   {
      "value": "America/Indiana/Vincennes",
      "name": "America/Indiana/Vincennes"
   },
   {
      "value": "America/Indiana/Winamac",
      "name": "America/Indiana/Winamac"
   },
   {
      "value": "America/Inuvik",
      "name": "America/Inuvik"
   },
   {
      "value": "America/Iqaluit",
      "name": "America/Iqaluit"
   },
   {
      "value": "America/Jamaica",
      "name": "America/Jamaica"
   },
   {
      "value": "America/Juneau",
      "name": "America/Juneau"
   },
   {
      "value": "America/Kentucky/Louisville",
      "name": "America/Kentucky/Louisville"
   },
   {
      "value": "America/Kentucky/Monticello",
      "name": "America/Kentucky/Monticello"
   },
   {
      "value": "America/Kralendijk",
      "name": "America/Kralendijk"
   },
   {
      "value": "America/La Paz",
      "name": "America/La Paz"
   },
   {
      "value": "America/Lima",
      "name": "America/Lima"
   },
   {
      "value": "America/Los Angeles",
      "name": "America/Los Angeles"
   },
   {
      "value": "America/Lower Princes",
      "name": "America/Lower Princes"
   },
   {
      "value": "America/Maceio",
      "name": "America/Maceio"
   },
   {
      "value": "America/Managua",
      "name": "America/Managua"
   },
   {
      "value": "America/Manaus",
      "name": "America/Manaus"
   },
   {
      "value": "America/Marigot",
      "name": "America/Marigot"
   },
   {
      "value": "America/Martinique",
      "name": "America/Martinique"
   },
   {
      "value": "America/Matamoros",
      "name": "America/Matamoros"
   },
   {
      "value": "America/Mazatlan",
      "name": "America/Mazatlan"
   },
   {
      "value": "America/Menominee",
      "name": "America/Menominee"
   },
   {
      "value": "America/Merida",
      "name": "America/Merida"
   },
   {
      "value": "America/Metlakatla",
      "name": "America/Metlakatla"
   },
   {
      "value": "America/Mexico City",
      "name": "America/Mexico City"
   },
   {
      "value": "America/Miquelon",
      "name": "America/Miquelon"
   },
   {
      "value": "America/Moncton",
      "name": "America/Moncton"
   },
   {
      "value": "America/Monterrey",
      "name": "America/Monterrey"
   },
   {
      "value": "America/Montevideo",
      "name": "America/Montevideo"
   },
   {
      "value": "America/Montserrat",
      "name": "America/Montserrat"
   },
   {
      "value": "America/Nassau",
      "name": "America/Nassau"
   },
   {
      "value": "America/New York",
      "name": "America/New York"
   },
   {
      "value": "America/Nipigon",
      "name": "America/Nipigon"
   },
   {
      "value": "America/Nome",
      "name": "America/Nome"
   },
   {
      "value": "America/Noronha",
      "name": "America/Noronha"
   },
   {
      "value": "America/North Dakota/Beulah",
      "name": "America/North Dakota/Beulah"
   },
   {
      "value": "America/North Dakota/Center",
      "name": "America/North Dakota/Center"
   },
   {
      "value": "America/North Dakota/New Salem",
      "name": "America/North Dakota/New Salem"
   },
   {
      "value": "America/Nuuk",
      "name": "America/Nuuk"
   },
   {
      "value": "America/Ojinaga",
      "name": "America/Ojinaga"
   },
   {
      "value": "America/Panama",
      "name": "America/Panama"
   },
   {
      "value": "America/Pangnirtung",
      "name": "America/Pangnirtung"
   },
   {
      "value": "America/Paramaribo",
      "name": "America/Paramaribo"
   },
   {
      "value": "America/Phoenix",
      "name": "America/Phoenix"
   },
   {
      "value": "America/Port of Spain",
      "name": "America/Port of Spain"
   },
   {
      "value": "America/Port-au-Prince",
      "name": "America/Port-au-Prince"
   },
   {
      "value": "America/Porto Velho",
      "name": "America/Porto Velho"
   },
   {
      "value": "America/Puerto Rico",
      "name": "America/Puerto Rico"
   },
   {
      "value": "America/Punta Arenas",
      "name": "America/Punta Arenas"
   },
   {
      "value": "America/Rainy River",
      "name": "America/Rainy River"
   },
   {
      "value": "America/Rankin Inlet",
      "name": "America/Rankin Inlet"
   },
   {
      "value": "America/Recife",
      "name": "America/Recife"
   },
   {
      "value": "America/Regina",
      "name": "America/Regina"
   },
   {
      "value": "America/Resolute",
      "name": "America/Resolute"
   },
   {
      "value": "America/Rio Branco",
      "name": "America/Rio Branco"
   },
   {
      "value": "America/Santarem",
      "name": "America/Santarem"
   },
   {
      "value": "America/Santiago",
      "name": "America/Santiago"
   },
   {
      "value": "America/Santo Domingo",
      "name": "America/Santo Domingo"
   },
   {
      "value": "America/Sao Paulo",
      "name": "America/Sao Paulo"
   },
   {
      "value": "America/Scoresbysund",
      "name": "America/Scoresbysund"
   },
   {
      "value": "America/Sitka",
      "name": "America/Sitka"
   },
   {
      "value": "America/St Barthelemy",
      "name": "America/St Barthelemy"
   },
   {
      "value": "America/St Johns",
      "name": "America/St Johns"
   },
   {
      "value": "America/St Kitts",
      "name": "America/St Kitts"
   },
   {
      "value": "America/St Lucia",
      "name": "America/St Lucia"
   },
   {
      "value": "America/St Thomas",
      "name": "America/St Thomas"
   },
   {
      "value": "America/St Vincent",
      "name": "America/St Vincent"
   },
   {
      "value": "America/Swift Current",
      "name": "America/Swift Current"
   },
   {
      "value": "America/Tegucigalpa",
      "name": "America/Tegucigalpa"
   },
   {
      "value": "America/Thule",
      "name": "America/Thule"
   },
   {
      "value": "America/Thunder Bay",
      "name": "America/Thunder Bay"
   },
   {
      "value": "America/Tijuana",
      "name": "America/Tijuana"
   },
   {
      "value": "America/Toronto",
      "name": "America/Toronto"
   },
   {
      "value": "America/Tortola",
      "name": "America/Tortola"
   },
   {
      "value": "America/Vancouver",
      "name": "America/Vancouver"
   },
   {
      "value": "America/Whitehorse",
      "name": "America/Whitehorse"
   },
   {
      "value": "America/Winnipeg",
      "name": "America/Winnipeg"
   },
   {
      "value": "America/Yakutat",
      "name": "America/Yakutat"
   },
   {
      "value": "America/Yellowknife",
      "name": "America/Yellowknife"
   },
   {
      "value": "Antarctica/Casey",
      "name": "Antarctica/Casey"
   },
   {
      "value": "Antarctica/Davis",
      "name": "Antarctica/Davis"
   },
   {
      "value": "Antarctica/DumontDUrville",
      "name": "Antarctica/DumontDUrville"
   },
   {
      "value": "Antarctica/Macquarie",
      "name": "Antarctica/Macquarie"
   },
   {
      "value": "Antarctica/Mawson",
      "name": "Antarctica/Mawson"
   },
   {
      "value": "Antarctica/McMurdo",
      "name": "Antarctica/McMurdo"
   },
   {
      "value": "Antarctica/Palmer",
      "name": "Antarctica/Palmer"
   },
   {
      "value": "Antarctica/Rothera",
      "name": "Antarctica/Rothera"
   },
   {
      "value": "Antarctica/Syowa",
      "name": "Antarctica/Syowa"
   },
   {
      "value": "Antarctica/Troll",
      "name": "Antarctica/Troll"
   },
   {
      "value": "Antarctica/Vostok",
      "name": "Antarctica/Vostok"
   },
   {
      "value": "Arctic/Longyearbyen",
      "name": "Arctic/Longyearbyen"
   },
   {
      "value": "Asia/Aden",
      "name": "Asia/Aden"
   },
   {
      "value": "Asia/Almaty",
      "name": "Asia/Almaty"
   },
   {
      "value": "Asia/Amman",
      "name": "Asia/Amman"
   },
   {
      "value": "Asia/Anadyr",
      "name": "Asia/Anadyr"
   },
   {
      "value": "Asia/Aqtau",
      "name": "Asia/Aqtau"
   },
   {
      "value": "Asia/Aqtobe",
      "name": "Asia/Aqtobe"
   },
   {
      "value": "Asia/Ashgabat",
      "name": "Asia/Ashgabat"
   },
   {
      "value": "Asia/Atyrau",
      "name": "Asia/Atyrau"
   },
   {
      "value": "Asia/Baghdad",
      "name": "Asia/Baghdad"
   },
   {
      "value": "Asia/Bahrain",
      "name": "Asia/Bahrain"
   },
   {
      "value": "Asia/Baku",
      "name": "Asia/Baku"
   },
   {
      "value": "Asia/Bangkok",
      "name": "Asia/Bangkok"
   },
   {
      "value": "Asia/Barnaul",
      "name": "Asia/Barnaul"
   },
   {
      "value": "Asia/Beirut",
      "name": "Asia/Beirut"
   },
   {
      "value": "Asia/Bishkek",
      "name": "Asia/Bishkek"
   },
   {
      "value": "Asia/Brunei",
      "name": "Asia/Brunei"
   },
   {
      "value": "Asia/Chita",
      "name": "Asia/Chita"
   },
   {
      "value": "Asia/Choibalsan",
      "name": "Asia/Choibalsan"
   },
   {
      "value": "Asia/Colombo",
      "name": "Asia/Colombo"
   },
   {
      "value": "Asia/Damascus",
      "name": "Asia/Damascus"
   },
   {
      "value": "Asia/Dhaka",
      "name": "Asia/Dhaka"
   },
   {
      "value": "Asia/Dili",
      "name": "Asia/Dili"
   },
   {
      "value": "Asia/Dubai",
      "name": "Asia/Dubai"
   },
   {
      "value": "Asia/Dushanbe",
      "name": "Asia/Dushanbe"
   },
   {
      "value": "Asia/Famagusta",
      "name": "Asia/Famagusta"
   },
   {
      "value": "Asia/Gaza",
      "name": "Asia/Gaza"
   },
   {
      "value": "Asia/Hebron",
      "name": "Asia/Hebron"
   },
   {
      "value": "Asia/Ho Chi Minh",
      "name": "Asia/Ho Chi Minh"
   },
   {
      "value": "Asia/Hong Kong",
      "name": "Asia/Hong Kong"
   },
   {
      "value": "Asia/Hovd",
      "name": "Asia/Hovd"
   },
   {
      "value": "Asia/Irkutsk",
      "name": "Asia/Irkutsk"
   },
   {
      "value": "Asia/Jakarta",
      "name": "Asia/Jakarta"
   },
   {
      "value": "Asia/Jayapura",
      "name": "Asia/Jayapura"
   },
   {
      "value": "Asia/Jerusalem",
      "name": "Asia/Jerusalem"
   },
   {
      "value": "Asia/Kabul",
      "name": "Asia/Kabul"
   },
   {
      "value": "Asia/Kamchatka",
      "name": "Asia/Kamchatka"
   },
   {
      "value": "Asia/Karachi",
      "name": "Asia/Karachi"
   },
   {
      "value": "Asia/Kathmandu",
      "name": "Asia/Kathmandu"
   },
   {
      "value": "Asia/Khandyga",
      "name": "Asia/Khandyga"
   },
   {
      "value": "Asia/Kolkata",
      "name": "Asia/Kolkata"
   },
   {
      "value": "Asia/Krasnoyarsk",
      "name": "Asia/Krasnoyarsk"
   },
   {
      "value": "Asia/Kuala Lumpur",
      "name": "Asia/Kuala Lumpur"
   },
   {
      "value": "Asia/Kuching",
      "name": "Asia/Kuching"
   },
   {
      "value": "Asia/Kuwait",
      "name": "Asia/Kuwait"
   },
   {
      "value": "Asia/Macau",
      "name": "Asia/Macau"
   },
   {
      "value": "Asia/Magadan",
      "name": "Asia/Magadan"
   },
   {
      "value": "Asia/Makassar",
      "name": "Asia/Makassar"
   },
   {
      "value": "Asia/Manila",
      "name": "Asia/Manila"
   },
   {
      "value": "Asia/Muscat",
      "name": "Asia/Muscat"
   },
   {
      "value": "Asia/Nicosia",
      "name": "Asia/Nicosia"
   },
   {
      "value": "Asia/Novokuznetsk",
      "name": "Asia/Novokuznetsk"
   },
   {
      "value": "Asia/Novosibirsk",
      "name": "Asia/Novosibirsk"
   },
   {
      "value": "Asia/Omsk",
      "name": "Asia/Omsk"
   },
   {
      "value": "Asia/Oral",
      "name": "Asia/Oral"
   },
   {
      "value": "Asia/Phnom Penh",
      "name": "Asia/Phnom Penh"
   },
   {
      "value": "Asia/Pontianak",
      "name": "Asia/Pontianak"
   },
   {
      "value": "Asia/Pyongyang",
      "name": "Asia/Pyongyang"
   },
   {
      "value": "Asia/Qatar",
      "name": "Asia/Qatar"
   },
   {
      "value": "Asia/Qostanay",
      "name": "Asia/Qostanay"
   },
   {
      "value": "Asia/Qyzylorda",
      "name": "Asia/Qyzylorda"
   },
   {
      "value": "Asia/Riyadh",
      "name": "Asia/Riyadh"
   },
   {
      "value": "Asia/Sakhalin",
      "name": "Asia/Sakhalin"
   },
   {
      "value": "Asia/Samarkand",
      "name": "Asia/Samarkand"
   },
   {
      "value": "Asia/Seoul",
      "name": "Asia/Seoul"
   },
   {
      "value": "Asia/Shanghai",
      "name": "Asia/Shanghai"
   },
   {
      "value": "Asia/Singapore",
      "name": "Asia/Singapore"
   },
   {
      "value": "Asia/Srednekolymsk",
      "name": "Asia/Srednekolymsk"
   },
   {
      "value": "Asia/Taipei",
      "name": "Asia/Taipei"
   },
   {
      "value": "Asia/Tashkent",
      "name": "Asia/Tashkent"
   },
   {
      "value": "Asia/Tbilisi",
      "name": "Asia/Tbilisi"
   },
   {
      "value": "Asia/Tehran",
      "name": "Asia/Tehran"
   },
   {
      "value": "Asia/Thimphu",
      "name": "Asia/Thimphu"
   },
   {
      "value": "Asia/Tokyo",
      "name": "Asia/Tokyo"
   },
   {
      "value": "Asia/Tomsk",
      "name": "Asia/Tomsk"
   },
   {
      "value": "Asia/Ulaanbaatar",
      "name": "Asia/Ulaanbaatar"
   },
   {
      "value": "Asia/Urumqi",
      "name": "Asia/Urumqi"
   },
   {
      "value": "Asia/Ust-Nera",
      "name": "Asia/Ust-Nera"
   },
   {
      "value": "Asia/Vientiane",
      "name": "Asia/Vientiane"
   },
   {
      "value": "Asia/Vladivostok",
      "name": "Asia/Vladivostok"
   },
   {
      "value": "Asia/Yakutsk",
      "name": "Asia/Yakutsk"
   },
   {
      "value": "Asia/Yangon",
      "name": "Asia/Yangon"
   },
   {
      "value": "Asia/Yekaterinburg",
      "name": "Asia/Yekaterinburg"
   },
   {
      "value": "Asia/Yerevan",
      "name": "Asia/Yerevan"
   },
   {
      "value": "Atlantic/Azores",
      "name": "Atlantic/Azores"
   },
   {
      "value": "Atlantic/Bermuda",
      "name": "Atlantic/Bermuda"
   },
   {
      "value": "Atlantic/Canary",
      "name": "Atlantic/Canary"
   },
   {
      "value": "Atlantic/Cape Verde",
      "name": "Atlantic/Cape Verde"
   },
   {
      "value": "Atlantic/Faroe",
      "name": "Atlantic/Faroe"
   },
   {
      "value": "Atlantic/Madeira",
      "name": "Atlantic/Madeira"
   },
   {
      "value": "Atlantic/Reykjavik",
      "name": "Atlantic/Reykjavik"
   },
   {
      "value": "Atlantic/South Georgia",
      "name": "Atlantic/South Georgia"
   },
   {
      "value": "Atlantic/St Helena",
      "name": "Atlantic/St Helena"
   },
   {
      "value": "Atlantic/Stanley",
      "name": "Atlantic/Stanley"
   },
   {
      "value": "Australia/Adelaide",
      "name": "Australia/Adelaide"
   },
   {
      "value": "Australia/Brisbane",
      "name": "Australia/Brisbane"
   },
   {
      "value": "Australia/Broken Hill",
      "name": "Australia/Broken Hill"
   },
   {
      "value": "Australia/Darwin",
      "name": "Australia/Darwin"
   },
   {
      "value": "Australia/Eucla",
      "name": "Australia/Eucla"
   },
   {
      "value": "Australia/Hobart",
      "name": "Australia/Hobart"
   },
   {
      "value": "Australia/Lindeman",
      "name": "Australia/Lindeman"
   },
   {
      "value": "Australia/Lord Howe",
      "name": "Australia/Lord Howe"
   },
   {
      "value": "Australia/Melbourne",
      "name": "Australia/Melbourne"
   },
   {
      "value": "Australia/Perth",
      "name": "Australia/Perth"
   },
   {
      "value": "Australia/Sydney",
      "name": "Australia/Sydney"
   },
   {
      "value": "Etc/GMT",
      "name": "Etc/GMT"
   },
   {
      "value": "Etc/GMT+1",
      "name": "Etc/GMT+1"
   },
   {
      "value": "Etc/GMT+10",
      "name": "Etc/GMT+10"
   },
   {
      "value": "Etc/GMT+11",
      "name": "Etc/GMT+11"
   },
   {
      "value": "Etc/GMT+12",
      "name": "Etc/GMT+12"
   },
   {
      "value": "Etc/GMT+2",
      "name": "Etc/GMT+2"
   },
   {
      "value": "Etc/GMT+3",
      "name": "Etc/GMT+3"
   },
   {
      "value": "Etc/GMT+4",
      "name": "Etc/GMT+4"
   },
   {
      "value": "Etc/GMT+5",
      "name": "Etc/GMT+5"
   },
   {
      "value": "Etc/GMT+6",
      "name": "Etc/GMT+6"
   },
   {
      "value": "Etc/GMT+7",
      "name": "Etc/GMT+7"
   },
   {
      "value": "Etc/GMT+8",
      "name": "Etc/GMT+8"
   },
   {
      "value": "Etc/GMT+9",
      "name": "Etc/GMT+9"
   },
   {
      "value": "Etc/GMT-1",
      "name": "Etc/GMT-1"
   },
   {
      "value": "Etc/GMT-10",
      "name": "Etc/GMT-10"
   },
   {
      "value": "Etc/GMT-11",
      "name": "Etc/GMT-11"
   },
   {
      "value": "Etc/GMT-12",
      "name": "Etc/GMT-12"
   },
   {
      "value": "Etc/GMT-13",
      "name": "Etc/GMT-13"
   },
   {
      "value": "Etc/GMT-14",
      "name": "Etc/GMT-14"
   },
   {
      "value": "Etc/GMT-2",
      "name": "Etc/GMT-2"
   },
   {
      "value": "Etc/GMT-3",
      "name": "Etc/GMT-3"
   },
   {
      "value": "Etc/GMT-4",
      "name": "Etc/GMT-4"
   },
   {
      "value": "Etc/GMT-5",
      "name": "Etc/GMT-5"
   },
   {
      "value": "Etc/GMT-6",
      "name": "Etc/GMT-6"
   },
   {
      "value": "Etc/GMT-7",
      "name": "Etc/GMT-7"
   },
   {
      "value": "Etc/GMT-8",
      "name": "Etc/GMT-8"
   },
   {
      "value": "Etc/GMT-9",
      "name": "Etc/GMT-9"
   },
   {
      "value": "Europe/Amsterdam",
      "name": "Europe/Amsterdam"
   },
   {
      "value": "Europe/Andorra",
      "name": "Europe/Andorra"
   },
   {
      "value": "Europe/Astrakhan",
      "name": "Europe/Astrakhan"
   },
   {
      "value": "Europe/Athens",
      "name": "Europe/Athens"
   },
   {
      "value": "Europe/Belgrade",
      "name": "Europe/Belgrade"
   },
   {
      "value": "Europe/Berlin",
      "name": "Europe/Berlin"
   },
   {
      "value": "Europe/Bratislava",
      "name": "Europe/Bratislava"
   },
   {
      "value": "Europe/Brussels",
      "name": "Europe/Brussels"
   },
   {
      "value": "Europe/Bucharest",
      "name": "Europe/Bucharest"
   },
   {
      "value": "Europe/Budapest",
      "name": "Europe/Budapest"
   },
   {
      "value": "Europe/Busingen",
      "name": "Europe/Busingen"
   },
   {
      "value": "Europe/Chisinau",
      "name": "Europe/Chisinau"
   },
   {
      "value": "Europe/Copenhagen",
      "name": "Europe/Copenhagen"
   },
   {
      "value": "Europe/Dublin",
      "name": "Europe/Dublin"
   },
   {
      "value": "Europe/Gibraltar",
      "name": "Europe/Gibraltar"
   },
   {
      "value": "Europe/Guernsey",
      "name": "Europe/Guernsey"
   },
   {
      "value": "Europe/Helsinki",
      "name": "Europe/Helsinki"
   },
   {
      "value": "Europe/Isle of Man",
      "name": "Europe/Isle of Man"
   },
   {
      "value": "Europe/Istanbul",
      "name": "Europe/Istanbul"
   },
   {
      "value": "Europe/Jersey",
      "name": "Europe/Jersey"
   },
   {
      "value": "Europe/Kaliningrad",
      "name": "Europe/Kaliningrad"
   },
   {
      "value": "Europe/Kiev",
      "name": "Europe/Kiev"
   },
   {
      "value": "Europe/Kirov",
      "name": "Europe/Kirov"
   },
   {
      "value": "Europe/Lisbon",
      "name": "Europe/Lisbon"
   },
   {
      "value": "Europe/Ljubljana",
      "name": "Europe/Ljubljana"
   },
   {
      "value": "Europe/London",
      "name": "Europe/London"
   },
   {
      "value": "Europe/Luxembourg",
      "name": "Europe/Luxembourg"
   },
   {
      "value": "Europe/Madrid",
      "name": "Europe/Madrid"
   },
   {
      "value": "Europe/Malta",
      "name": "Europe/Malta"
   },
   {
      "value": "Europe/Mariehamn",
      "name": "Europe/Mariehamn"
   },
   {
      "value": "Europe/Minsk",
      "name": "Europe/Minsk"
   },
   {
      "value": "Europe/Monaco",
      "name": "Europe/Monaco"
   },
   {
      "value": "Europe/Moscow",
      "name": "Europe/Moscow"
   },
   {
      "value": "Europe/Oslo",
      "name": "Europe/Oslo"
   },
   {
      "value": "Europe/Paris",
      "name": "Europe/Paris"
   },
   {
      "value": "Europe/Podgorica",
      "name": "Europe/Podgorica"
   },
   {
      "value": "Europe/Prague",
      "name": "Europe/Prague"
   },
   {
      "value": "Europe/Riga",
      "name": "Europe/Riga"
   },
   {
      "value": "Europe/Rome",
      "name": "Europe/Rome"
   },
   {
      "value": "Europe/Samara",
      "name": "Europe/Samara"
   },
   {
      "value": "Europe/San Marino",
      "name": "Europe/San Marino"
   },
   {
      "value": "Europe/Sarajevo",
      "name": "Europe/Sarajevo"
   },
   {
      "value": "Europe/Saratov",
      "name": "Europe/Saratov"
   },
   {
      "value": "Europe/Simferopol",
      "name": "Europe/Simferopol"
   },
   {
      "value": "Europe/Skopje",
      "name": "Europe/Skopje"
   },
   {
      "value": "Europe/Sofia",
      "name": "Europe/Sofia"
   },
   {
      "value": "Europe/Stockholm",
      "name": "Europe/Stockholm"
   },
   {
      "value": "Europe/Tallinn",
      "name": "Europe/Tallinn"
   },
   {
      "value": "Europe/Tirane",
      "name": "Europe/Tirane"
   },
   {
      "value": "Europe/Ulyanovsk",
      "name": "Europe/Ulyanovsk"
   },
   {
      "value": "Europe/Uzhgorod",
      "name": "Europe/Uzhgorod"
   },
   {
      "value": "Europe/Vaduz",
      "name": "Europe/Vaduz"
   },
   {
      "value": "Europe/Vatican",
      "name": "Europe/Vatican"
   },
   {
      "value": "Europe/Vienna",
      "name": "Europe/Vienna"
   },
   {
      "value": "Europe/Vilnius",
      "name": "Europe/Vilnius"
   },
   {
      "value": "Europe/Volgograd",
      "name": "Europe/Volgograd"
   },
   {
      "value": "Europe/Warsaw",
      "name": "Europe/Warsaw"
   },
   {
      "value": "Europe/Zagreb",
      "name": "Europe/Zagreb"
   },
   {
      "value": "Europe/Zaporozhye",
      "name": "Europe/Zaporozhye"
   },
   {
      "value": "Europe/Zurich",
      "name": "Europe/Zurich"
   },
   {
      "value": "Indian/Antananarivo",
      "name": "Indian/Antananarivo"
   },
   {
      "value": "Indian/Chagos",
      "name": "Indian/Chagos"
   },
   {
      "value": "Indian/Christmas",
      "name": "Indian/Christmas"
   },
   {
      "value": "Indian/Cocos",
      "name": "Indian/Cocos"
   },
   {
      "value": "Indian/Comoro",
      "name": "Indian/Comoro"
   },
   {
      "value": "Indian/Kerguelen",
      "name": "Indian/Kerguelen"
   },
   {
      "value": "Indian/Mahe",
      "name": "Indian/Mahe"
   },
   {
      "value": "Indian/Maldives",
      "name": "Indian/Maldives"
   },
   {
      "value": "Indian/Mauritius",
      "name": "Indian/Mauritius"
   },
   {
      "value": "Indian/Mayotte",
      "name": "Indian/Mayotte"
   },
   {
      "value": "Indian/Reunion",
      "name": "Indian/Reunion"
   },
   {
      "value": "Pacific/Apia",
      "name": "Pacific/Apia"
   },
   {
      "value": "Pacific/Auckland",
      "name": "Pacific/Auckland"
   },
   {
      "value": "Pacific/Bougainville",
      "name": "Pacific/Bougainville"
   },
   {
      "value": "Pacific/Chatham",
      "name": "Pacific/Chatham"
   },
   {
      "value": "Pacific/Chuuk",
      "name": "Pacific/Chuuk"
   },
   {
      "value": "Pacific/Easter",
      "name": "Pacific/Easter"
   },
   {
      "value": "Pacific/Efate",
      "name": "Pacific/Efate"
   },
   {
      "value": "Pacific/Fakaofo",
      "name": "Pacific/Fakaofo"
   },
   {
      "value": "Pacific/Fiji",
      "name": "Pacific/Fiji"
   },
   {
      "value": "Pacific/Funafuti",
      "name": "Pacific/Funafuti"
   },
   {
      "value": "Pacific/Galapagos",
      "name": "Pacific/Galapagos"
   },
   {
      "value": "Pacific/Gambier",
      "name": "Pacific/Gambier"
   },
   {
      "value": "Pacific/Guadalcanal",
      "name": "Pacific/Guadalcanal"
   },
   {
      "value": "Pacific/Guam",
      "name": "Pacific/Guam"
   },
   {
      "value": "Pacific/Honolulu",
      "name": "Pacific/Honolulu"
   },
   {
      "value": "Pacific/Kanton",
      "name": "Pacific/Kanton"
   },
   {
      "value": "Pacific/Kiritimati",
      "name": "Pacific/Kiritimati"
   },
   {
      "value": "Pacific/Kosrae",
      "name": "Pacific/Kosrae"
   },
   {
      "value": "Pacific/Kwajalein",
      "name": "Pacific/Kwajalein"
   },
   {
      "value": "Pacific/Majuro",
      "name": "Pacific/Majuro"
   },
   {
      "value": "Pacific/Marquesas",
      "name": "Pacific/Marquesas"
   },
   {
      "value": "Pacific/Midway",
      "name": "Pacific/Midway"
   },
   {
      "value": "Pacific/Nauru",
      "name": "Pacific/Nauru"
   },
   {
      "value": "Pacific/Niue",
      "name": "Pacific/Niue"
   },
   {
      "value": "Pacific/Norfolk",
      "name": "Pacific/Norfolk"
   },
   {
      "value": "Pacific/Noumea",
      "name": "Pacific/Noumea"
   },
   {
      "value": "Pacific/Pago Pago",
      "name": "Pacific/Pago Pago"
   },
   {
      "value": "Pacific/Palau",
      "name": "Pacific/Palau"
   },
   {
      "value": "Pacific/Pitcairn",
      "name": "Pacific/Pitcairn"
   },
   {
      "value": "Pacific/Pohnpei",
      "name": "Pacific/Pohnpei"
   },
   {
      "value": "Pacific/Port Moresby",
      "name": "Pacific/Port Moresby"
   },
   {
      "value": "Pacific/Rarotonga",
      "name": "Pacific/Rarotonga"
   },
   {
      "value": "Pacific/Saipan",
      "name": "Pacific/Saipan"
   },
   {
      "value": "Pacific/Tahiti",
      "name": "Pacific/Tahiti"
   },
   {
      "value": "Pacific/Tarawa",
      "name": "Pacific/Tarawa"
   },
   {
      "value": "Pacific/Tongatapu",
      "name": "Pacific/Tongatapu"
   },
   {
      "value": "Pacific/Wake",
      "name": "Pacific/Wake"
   },
   {
      "value": "Pacific/Wallis",
      "name": "Pacific/Wallis"
   }
]