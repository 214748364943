import { ModelKeyObject, SupportDeviceModel } from "@core/types";

// Should Order From General to Advanced for Series Model 
export const MODEL_KEY_MAP: Array<ModelKeyObject> = [
    { model: SupportDeviceModel.M_100, modelKeyWord: "M100" },
    { model: SupportDeviceModel.M_120_N, modelKeyWord: "M120N" },
    { model: SupportDeviceModel.M_150, modelKeyWord: "M150" },
    /************ M300 series ************/
    { model: SupportDeviceModel.M_300, modelKeyWord: "M300" },
    { model: SupportDeviceModel.M_300AX, modelKeyWord: "M300AX" },
    /************ M300 series End ************/
    { model: SupportDeviceModel.M_500, modelKeyWord: "M500" },
    { model: SupportDeviceModel.M_600, modelKeyWord: "M600" },
    /************ MX-200 series ************/
    { model: SupportDeviceModel.MX_200, modelKeyWord: "MX-200" },
    { model: SupportDeviceModel.MX_200E, modelKeyWord: "MX-200E" },
    { model: SupportDeviceModel.MX_200A, modelKeyWord: "MX-200A" },
    { model: SupportDeviceModel.MX_200AE, modelKeyWord: "MX-200AE" },
    /************ MX-200 series End ************/
    { model: SupportDeviceModel.MX_210_NP, modelKeyWord: "MX-210NP" },
    { model: SupportDeviceModel.EG_210_N, modelKeyWord: "EG-210N" },
    /************ MX-220 series ************/
    { model: SupportDeviceModel.MX_220, modelKeyWord: "MX-220" },
    { model: SupportDeviceModel.MX_220_UT, modelKeyWord: "MX-220-UT" },
    /************ MX-220 series End************/
    { model: SupportDeviceModel.MX_221_P, modelKeyWord: "MX-221P" },
    { model: SupportDeviceModel.MX_230, modelKeyWord: "MX-230" },
    { model: SupportDeviceModel.MX_240, modelKeyWord: "MX-240" },
    { model: SupportDeviceModel.MX_241_NP, modelKeyWord: "MX-241NP" },
    { model: SupportDeviceModel.MX_250, modelKeyWord: "MX-250" },
    { model: SupportDeviceModel.MX_250E, modelKeyWord: "MX-250E" },
    { model: SupportDeviceModel.MX_600, modelKeyWord: "MX-600" },
    /************ MX-1000 series ************/
    { model: SupportDeviceModel.MX_1000, modelKeyWord: "MX-1000" },
    { model: SupportDeviceModel.MX_1000E, modelKeyWord: "MX-1000E" },
    /************ MX-1000 series End ************/
    { model: SupportDeviceModel.MX_1200, modelKeyWord: "MX-1200" },
    /************ 4500 series ************/
    { model: SupportDeviceModel._4500, modelKeyWord: "4500" },
    { model: SupportDeviceModel._4500AZ, modelKeyWord: "4500AZ" },
    { model: SupportDeviceModel._4500NZ, modelKeyWord: "4500NZ" },
    /************ 4500 series End ************/
    { model: SupportDeviceModel._4520AZ, modelKeyWord: "4520AZ" },
    /************ 4700 series ************/
    { model: SupportDeviceModel._4700, modelKeyWord: "4700" },
    { model: SupportDeviceModel._4700AZ, modelKeyWord: "4700AZ" },
    { model: SupportDeviceModel._4700ZU, modelKeyWord: "4700ZU" },
    /************ 4700 series End ************/
    { model: SupportDeviceModel._4900, modelKeyWord: "4900" },
    { model: SupportDeviceModel._6200, modelKeyWord: "6200" },
    { model: SupportDeviceModel._6300VNL, modelKeyWord: "6300VNL" },
    { model: SupportDeviceModel._6300NEL, modelKeyWord: "6300NEL" },
    { model: SupportDeviceModel._6500, modelKeyWord: "6500" },
    { model: SupportDeviceModel._6500AL, modelKeyWord: "6500AL" },
    { model: SupportDeviceModel._6600, modelKeyWord: "6600" },
    { model: SupportDeviceModel._6800, modelKeyWord: "6800" },
    { model: SupportDeviceModel._6900, modelKeyWord: "6900" },
    /************ 7000 series ************/
    { model: SupportDeviceModel._7000, modelKeyWord: "7000" },
    { model: SupportDeviceModel._7000_R26_W, modelKeyWord: "7000 R26-W" },
    /************ 7000 series ************/
    { model: SupportDeviceModel._8110, modelKeyWord: "8110" },
    { model: SupportDeviceModel._8112, modelKeyWord: "8112" },
    { model: SupportDeviceModel._8230, modelKeyWord: "8230" },
    { model: SupportDeviceModel._8231, modelKeyWord: "8231" },
    { model: SupportDeviceModel._8232, modelKeyWord: "8232" },
    { model: SupportDeviceModel._8235, modelKeyWord: "8235" },
    { model: SupportDeviceModel._8355, modelKeyWord: "8355" },
    { model: SupportDeviceModel._8355P, modelKeyWord: "8355P" },
    { model: SupportDeviceModel._9900, modelKeyWord: "9900" },
    { model: SupportDeviceModel._8920, modelKeyWord: "8920" },
    { model: SupportDeviceModel._8115, modelKeyWord: "8115" }
];