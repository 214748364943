
export interface SocialMedia {
    twitter: boolean;
    twitterLink: string;
    facebook: boolean;
    facebookLink: string;
    linkedIn: boolean;
    linkedInLink: string;
    instagram: boolean;
    instagramLink: string;
    youtube: boolean;
    youtubeLink: string;
};

export interface PreferanceSetting {
    headerTopperColor: string;
    headerFontColor: string;
    imageId?: string;
    imageName?: string;
    imagefile?: string;
    imageSource?: File;
    logoLink: string;
    subject: string;
    footerBackgroundColor: string;
    footerFontColor: string;
    contactInformation: string;
    socialMedia: SocialMedia
};

export interface AlertEmailHtmlTemplate {
    emailHeader: string;
    emailFooter: string;
    emailPreference: PreferanceSetting;
};