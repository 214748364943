/******* Angular Resourse *******/
import { Injectable } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";

/******* Core Resourse *******/
import { PrivilegeService } from "./privilege.service";
import { ClientToServerEvents, MessageData, MessageType, ServerToClientEvents } from "@core/types";

/******* Plug-In Resourse *******/
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { filter, take, tap } from "rxjs/operators";

/******* Environment Resourse *******/
import { environment } from 'environments/environment';
import { Observable, Subject } from "rxjs";
import { AccessTokenService } from "./access-token.service";

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    // Private
    private _socketUrl: string = environment.socketUrl;
    private _socketOptions: Partial<ManagerOptions & SocketOptions> = {} as Partial<ManagerOptions & SocketOptions>;
    private _socket: Socket<ServerToClientEvents, ClientToServerEvents>;
    private _socketMessageSubject$: Subject<MessageData>;
    private _socketIo: any = io;

    /**
     * Constructor
     * 
     * @param {Router} _router 
     * @param {PrivilegeService} _privilegeService 
     * @param {AccessTokenService} _accessTokenService
     */
    constructor(private _router: Router,
        private _privilegeService: PrivilegeService,
        private _accessTokenService: AccessTokenService) {

        // initial socket message subject
        this._socketMessageSubject$ = new Subject<MessageData>();

        // Check Access Token initial socket connection 
        this._router.events.pipe(filter(event => event instanceof RoutesRecognized), take(1)).subscribe((e: RoutesRecognized) => {
            const url = e.urlAfterRedirects;
            if (url.indexOf("/pages/") >= 0 && sessionStorage.getItem("becentralAccessToken")) {
                this.initialSocketConnection();
            }
        });
    }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get socket connected status
     */
    get isSocketFunctional() {
        return this._socket.connected
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get specific event data flow
     * 
     * @param {MessageType} eventName 
     * @returns {Observable<MessageData>}
     */
    public eventFlow(eventName: MessageType): Observable<MessageData> {
        /**
         * Device => real time device list
         * Device Alarm => real time analysis page
         */
        return this._socketMessageSubject$.asObservable().pipe(filter(x => x.eventName === eventName));
    }

    /**
     * Initial socket connection
     */
    public initialSocketConnection(): void {
        if (this._socket) return;
        this._privilegeService.currentUser.pipe(filter(x => x != null), take(1)).subscribe(() => {
            const parseUrl = new URL(this._socketUrl);
            this._socketOptions = {
                reconnectionDelayMax: 50000,
                reconnection: true, // dont do auto reconnect
                auth: { token: sessionStorage.getItem("becentralAccessToken") },
                transports: ['websocket', 'flashsocket', 'htmlfile', 'xhr-polling', 'jsonp-polling', 'polling'],
                path: `${parseUrl.pathname}/${this._privilegeService.isSuperAdminAccess ? "" : ""}socket.io` // super-admin/
            };
            this._socket = this._socketIo(parseUrl.origin, this._socketOptions); // inital socket setting

            //// Socket on connect
            this._socket.on('connect', () => {
                console.log(`Socket Connecting ...`);
            });

            //// Socket on all receiving data
            this._socket.onAny((...args) => {
                let tempMessage: MessageData = {
                    eventName: args[0],
                    type: args[0]
                };
                if (args[1]) tempMessage.data = args[1];
                this._socketMessageSubject$.next(tempMessage);
            });

            //// Socket on connect-error
            this._socket.on('connect_error', (err) => {
                if (err.message === "jwt expired") {
                    if (!this._accessTokenService.refreshFlag) {
                        this._accessTokenService.refreshFlag = true;
                        const refreshToken = sessionStorage.getItem("becentralRefreshToken");
                        this._accessTokenService.clearAllInfo();
                        this._accessTokenService.refreshAccessToken(refreshToken).subscribe((token) => {
                            this._accessTokenService.refreshFlag = false;
                            this._accessTokenService.setToken(token.data);
                        }, (error) => {
                            this._accessTokenService.refreshFlag = false;
                            console.log(`Socket Connect Error ... ${error}`);
                        });
                    }

                    this._accessTokenService.currentAccessToken.pipe(
                        filter(token => token !== null),
                        take(1)).subscribe(() => {
                            if (this._socket) {
                                this._socket.auth['token'] = sessionStorage.getItem("becentralAccessToken");
                                this._socket.connect();
                                console.log(`Socket Update Auth ...`);
                            }
                        });
                }
                else
                    console.log(`Socket Connect Error ... ${err.message}`);
            });

            //// Socket on disconnects
            this._socket.on('disconnect', () => {
                console.log(`Socket Disconnected ...`);
            });

        });
    }

    /**
     * Disconnect the socket
     */
    public disconnectSocket(): void {

        if (this._socket) {
            this._socket.disconnect();
            this._socket.removeAllListeners();
            this._socket.close();
        }
        this._socket = null;
    }
}
