import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initialUppercase'
})
export class InitialUppercasePipe implements PipeTransform {
    
    /**
	 * Transform
	 *
	 * @param {string} fullName
	 * @returns {string}
	 */
    transform(fullName: string): string {
        return `${fullName.substring(0, 1).toUpperCase()}${fullName.substring(1).toLowerCase()}`;
    }
}
