export enum TimeZone {
    PACIFIC_MIDWAY = "Pacific/Midway",
    PACIFIC_NIUE = "Pacific/Niue",
    PACIFIC_PAGO_PAGO = "Pacific/Pago_Pago",
    PACIFIC_SAMOA = "Pacific/Samoa",
    US_SAMOA = "US/Samoa",
    AMERICA_ADAK = "America/Adak",
    AMERICA_ATKA = "America/Atka",
    PACIFIC_HONOLULU = "Pacific/Honolulu",
    PACIFIC_JOHNSTON = "Pacific/Johnston",
    PACIFIC_RAROTONGA = "Pacific/Rarotonga",
    PACIFIC_TAHITI = "Pacific/Tahiti",
    US_ALEUTIAN = "US/Aleutian",
    US_HAWAII = "US/Hawaii",
    PACIFIC_MARQUESAS = "Pacific/Marquesas",
    AMERICA_ANCHORAGE = "America/Anchorage",
    AMERICA_JUNEAU = "America/Juneau",
    AMERICA_METLAKATLA = "America/Metlakatla",
    AMERICA_NOME = "America/Nome",
    AMERICA_SITKA = "America/Sitka",
    AMERICA_YAKUTAT = "America/Yakutat",
    PACIFIC_GAMBIER = "Pacific/Gambier",
    US_ALASKA = "US/Alaska",
    AMERICA_ENSENADA = "America/Ensenada",
    AMERICA_LOS_ANGELES = "America/Los_Angeles",
    AMERICA_SANTA_ISABEL = "America/Santa_Isabel",
    AMERICA_TIJUANA = "America/Tijuana",
    AMERICA_VANCOUVER = "America/Vancouver",
    CANADA_PACIFIC = "Canada/Pacific",
    MEXICO_BAJANORTE = "Mexico/BajaNorte",
    PACIFIC_PITCAIRN = "Pacific/Pitcairn",
    US_PACIFIC = "US/Pacific",
    AMERICA_BOISE = "America/Boise",
    AMERICA_CAMBRIDGE_BAY = "America/Cambridge_Bay",
    AMERICA_CIUDAD_JUAREZ = "America/Ciudad_Juarez",
    AMERICA_CRESTON = "America/Creston",
    AMERICA_DAWSON = "America/Dawson",
    AMERICA_DAWSON_CREEK = "America/Dawson_Creek",
    AMERICA_DENVER = "America/Denver",
    AMERICA_EDMONTON = "America/Edmonton",
    AMERICA_FORT_NELSON = "America/Fort_Nelson",
    AMERICA_HERMOSILLO = "America/Hermosillo",
    AMERICA_INUVIK = "America/Inuvik",
    AMERICA_MAZATLAN = "America/Mazatlan",
    AMERICA_PHOENIX = "America/Phoenix",
    AMERICA_SHIPROCK = "America/Shiprock",
    AMERICA_WHITEHORSE = "America/Whitehorse",
    AMERICA_YELLOWKNIFE = "America/Yellowknife",
    CANADA_MOUNTAIN = "Canada/Mountain",
    CANADA_YUKON = "Canada/Yukon",
    MEXICO_BAJASUR = "Mexico/BajaSur",
    US_ARIZONA = "US/Arizona",
    US_MOUNTAIN = "US/Mountain",
    AMERICA_BAHIA_BANDERAS = "America/Bahia_Banderas",
    AMERICA_BELIZE = "America/Belize",
    AMERICA_CHICAGO = "America/Chicago",
    AMERICA_CHIHUAHUA = "America/Chihuahua",
    AMERICA_COSTA_RICA = "America/Costa_Rica",
    AMERICA_EL_SALVADOR = "America/El_Salvador",
    AMERICA_GUATEMALA = "America/Guatemala",
    AMERICA_KNOX_IN = "America/Knox_IN",
    AMERICA_MANAGUA = "America/Managua",
    AMERICA_MATAMOROS = "America/Matamoros",
    AMERICA_MENOMINEE = "America/Menominee",
    AMERICA_MERIDA = "America/Merida",
    AMERICA_MEXICO_CITY = "America/Mexico_City",
    AMERICA_MONTERREY = "America/Monterrey",
    AMERICA_OJINAGA = "America/Ojinaga",
    AMERICA_RAINY_RIVER = "America/Rainy_River",
    AMERICA_RANKIN_INLET = "America/Rankin_Inlet",
    AMERICA_REGINA = "America/Regina",
    AMERICA_RESOLUTE = "America/Resolute",
    AMERICA_SWIFT_CURRENT = "America/Swift_Current",
    AMERICA_TEGUCIGALPA = "America/Tegucigalpa",
    AMERICA_WINNIPEG = "America/Winnipeg",
    CANADA_CENTRAL = "Canada/Central",
    CANADA_SASKATCHEWAN = "Canada/Saskatchewan",
    MEXICO_GENERAL = "Mexico/General",
    PACIFIC_GALAPAGOS = "Pacific/Galapagos",
    US_CENTRAL = "US/Central",
    US_INDIANA_STARKE = "US/Indiana-Starke",
    AMERICA_ATIKOKAN = "America/Atikokan",
    AMERICA_BOGOTA = "America/Bogota",
    AMERICA_CANCUN = "America/Cancun",
    AMERICA_CAYMAN = "America/Cayman",
    AMERICA_CORAL_HARBOUR = "America/Coral_Harbour",
    AMERICA_DETROIT = "America/Detroit",
    AMERICA_EIRUNEPE = "America/Eirunepe",
    AMERICA_FORT_WAYNE = "America/Fort_Wayne",
    AMERICA_GRAND_TURK = "America/Grand_Turk",
    AMERICA_GUAYAQUIL = "America/Guayaquil",
    AMERICA_HAVANA = "America/Havana",
    AMERICA_INDIANAPOLIS = "America/Indianapolis",
    AMERICA_IQALUIT = "America/Iqaluit",
    AMERICA_JAMAICA = "America/Jamaica",
    AMERICA_LIMA = "America/Lima",
    AMERICA_LOUISVILLE = "America/Louisville",
    AMERICA_MONTREAL = "America/Montreal",
    AMERICA_NASSAU = "America/Nassau",
    AMERICA_NEW_YORK = "America/New_York",
    AMERICA_NIPIGON = "America/Nipigon",
    AMERICA_PANAMA = "America/Panama",
    AMERICA_PANGNIRTUNG = "America/Pangnirtung",
    AMERICA_PORT_AU_PRINCE = "America/Port-au-Prince",
    AMERICA_PORTO_ACRE = "America/Porto_Acre",
    AMERICA_RIO_BRANCO = "America/Rio_Branco",
    AMERICA_THUNDER_BAY = "America/Thunder_Bay",
    AMERICA_TORONTO = "America/Toronto",
    BRAZIL_ACRE = "Brazil/Acre",
    CANADA_EASTERN = "Canada/Eastern",
    CHILE_EASTERISLAND = "Chile/EasterIsland",
    PACIFIC_EASTER = "Pacific/Easter",
    US_EAST_INDIANA = "US/East-Indiana",
    US_EASTERN = "US/Eastern",
    US_MICHIGAN = "US/Michigan",
    AMERICA_ANGUILLA = "America/Anguilla",
    AMERICA_ANTIGUA = "America/Antigua",
    AMERICA_ARUBA = "America/Aruba",
    AMERICA_BARBADOS = "America/Barbados",
    AMERICA_BLANC_SABLON = "America/Blanc-Sablon",
    AMERICA_BOA_VISTA = "America/Boa_Vista",
    AMERICA_CAMPO_GRANDE = "America/Campo_Grande",
    AMERICA_CARACAS = "America/Caracas",
    AMERICA_CUIABA = "America/Cuiaba",
    AMERICA_CURACAO = "America/Curacao",
    AMERICA_DOMINICA = "America/Dominica",
    AMERICA_GLACE_BAY = "America/Glace_Bay",
    AMERICA_GOOSE_BAY = "America/Goose_Bay",
    AMERICA_GRENADA = "America/Grenada",
    AMERICA_GUADELOUPE = "America/Guadeloupe",
    AMERICA_GUYANA = "America/Guyana",
    AMERICA_HALIFAX = "America/Halifax",
    AMERICA_KRALENDIJK = "America/Kralendijk",
    AMERICA_LA_PAZ = "America/La_Paz",
    AMERICA_LOWER_PRINCES = "America/Lower_Princes",
    AMERICA_MANAUS = "America/Manaus",
    AMERICA_MARIGOT = "America/Marigot",
    AMERICA_MARTINIQUE = "America/Martinique",
    AMERICA_MONCTON = "America/Moncton",
    AMERICA_MONTSERRAT = "America/Montserrat",
    AMERICA_PORT_OF_SPAIN = "America/Port_of_Spain",
    AMERICA_PORTO_VELHO = "America/Porto_Velho",
    AMERICA_PUERTO_RICO = "America/Puerto_Rico",
    AMERICA_SANTO_DOMINGO = "America/Santo_Domingo",
    AMERICA_ST_BARTHELEMY = "America/St_Barthelemy",
    AMERICA_ST_KITTS = "America/St_Kitts",
    AMERICA_ST_LUCIA = "America/St_Lucia",
    AMERICA_ST_THOMAS = "America/St_Thomas",
    AMERICA_ST_VINCENT = "America/St_Vincent",
    AMERICA_THULE = "America/Thule",
    AMERICA_TORTOLA = "America/Tortola",
    AMERICA_VIRGIN = "America/Virgin",
    ATLANTIC_BERMUDA = "Atlantic/Bermuda",
    BRAZIL_WEST = "Brazil/West",
    CANADA_ATLANTIC = "Canada/Atlantic",
    AMERICA_ST_JOHNS = "America/St_Johns",
    CANADA_NEWFOUNDLAND = "Canada/Newfoundland",
    AMERICA_ARAGUAINA = "America/Araguaina",
    AMERICA_ASUNCION = "America/Asuncion",
    AMERICA_BAHIA = "America/Bahia",
    AMERICA_BELEM = "America/Belem",
    AMERICA_BUENOS_AIRES = "America/Buenos_Aires",
    AMERICA_CATAMARCA = "America/Catamarca",
    AMERICA_CAYENNE = "America/Cayenne",
    AMERICA_CORDOBA = "America/Cordoba",
    AMERICA_FORTALEZA = "America/Fortaleza",
    AMERICA_GODTHAB = "America/Godthab",
    AMERICA_JUJUY = "America/Jujuy",
    AMERICA_MACEIO = "America/Maceio",
    AMERICA_MENDOZA = "America/Mendoza",
    AMERICA_MIQUELON = "America/Miquelon",
    AMERICA_MONTEVIDEO = "America/Montevideo",
    AMERICA_NUUK = "America/Nuuk",
    AMERICA_PARAMARIBO = "America/Paramaribo",
    AMERICA_PUNTA_ARENAS = "America/Punta_Arenas",
    AMERICA_RECIFE = "America/Recife",
    AMERICA_ROSARIO = "America/Rosario",
    AMERICA_SANTAREM = "America/Santarem",
    AMERICA_SANTIAGO = "America/Santiago",
    AMERICA_SAO_PAULO = "America/Sao_Paulo",
    ANTARCTICA_PALMER = "Antarctica/Palmer",
    ANTARCTICA_ROTHERA = "Antarctica/Rothera",
    ATLANTIC_STANLEY = "Atlantic/Stanley",
    BRAZIL_EAST = "Brazil/East",
    CHILE_CONTINENTAL = "Chile/Continental",
    AMERICA_NORONHA = "America/Noronha",
    ATLANTIC_SOUTH_GEORGIA = "Atlantic/South_Georgia",
    BRAZIL_DENORONHA = "Brazil/DeNoronha",
    AMERICA_SCORESBYSUND = "America/Scoresbysund",
    ATLANTIC_AZORES = "Atlantic/Azores",
    ATLANTIC_CAPE_VERDE = "Atlantic/Cape_Verde",
    AFRICA_ABIDJAN = "Africa/Abidjan",
    AFRICA_ACCRA = "Africa/Accra",
    AFRICA_BAMAKO = "Africa/Bamako",
    AFRICA_BANJUL = "Africa/Banjul",
    AFRICA_BISSAU = "Africa/Bissau",
    AFRICA_CONAKRY = "Africa/Conakry",
    AFRICA_DAKAR = "Africa/Dakar",
    AFRICA_FREETOWN = "Africa/Freetown",
    AFRICA_LOME = "Africa/Lome",
    AFRICA_MONROVIA = "Africa/Monrovia",
    AFRICA_NOUAKCHOTT = "Africa/Nouakchott",
    AFRICA_OUAGADOUGOU = "Africa/Ouagadougou",
    AFRICA_SAO_TOME = "Africa/Sao_Tome",
    AFRICA_TIMBUKTU = "Africa/Timbuktu",
    AMERICA_DANMARKSHAVN = "America/Danmarkshavn",
    ANTARCTICA_TROLL = "Antarctica/Troll",
    ATLANTIC_CANARY = "Atlantic/Canary",
    ATLANTIC_FAEROE = "Atlantic/Faeroe",
    ATLANTIC_FAROE = "Atlantic/Faroe",
    ATLANTIC_MADEIRA = "Atlantic/Madeira",
    ATLANTIC_REYKJAVIK = "Atlantic/Reykjavik",
    ATLANTIC_ST_HELENA = "Atlantic/St_Helena",
    EUROPE_BELFAST = "Europe/Belfast",
    EUROPE_DUBLIN = "Europe/Dublin",
    EUROPE_GUERNSEY = "Europe/Guernsey",
    EUROPE_ISLE_OF_MAN = "Europe/Isle_of_Man",
    EUROPE_JERSEY = "Europe/Jersey",
    EUROPE_LISBON = "Europe/Lisbon",
    EUROPE_LONDON = "Europe/London",
    AFRICA_ALGIERS = "Africa/Algiers",
    AFRICA_BANGUI = "Africa/Bangui",
    AFRICA_BRAZZAVILLE = "Africa/Brazzaville",
    AFRICA_CASABLANCA = "Africa/Casablanca",
    AFRICA_CEUTA = "Africa/Ceuta",
    AFRICA_DOUALA = "Africa/Douala",
    AFRICA_EL_AAIUN = "Africa/El_Aaiun",
    AFRICA_KINSHASA = "Africa/Kinshasa",
    AFRICA_LAGOS = "Africa/Lagos",
    AFRICA_LIBREVILLE = "Africa/Libreville",
    AFRICA_LUANDA = "Africa/Luanda",
    AFRICA_MALABO = "Africa/Malabo",
    AFRICA_NDJAMENA = "Africa/Ndjamena",
    AFRICA_NIAMEY = "Africa/Niamey",
    AFRICA_PORTO_NOVO = "Africa/Porto-Novo",
    AFRICA_TUNIS = "Africa/Tunis",
    ARCTIC_LONGYEARBYEN = "Arctic/Longyearbyen",
    ATLANTIC_JAN_MAYEN = "Atlantic/Jan_Mayen",
    EUROPE_AMSTERDAM = "Europe/Amsterdam",
    EUROPE_ANDORRA = "Europe/Andorra",
    EUROPE_BELGRADE = "Europe/Belgrade",
    EUROPE_BERLIN = "Europe/Berlin",
    EUROPE_BRATISLAVA = "Europe/Bratislava",
    EUROPE_BRUSSELS = "Europe/Brussels",
    EUROPE_BUDAPEST = "Europe/Budapest",
    EUROPE_BUSINGEN = "Europe/Busingen",
    EUROPE_COPENHAGEN = "Europe/Copenhagen",
    EUROPE_GIBRALTAR = "Europe/Gibraltar",
    EUROPE_LJUBLJANA = "Europe/Ljubljana",
    EUROPE_LUXEMBOURG = "Europe/Luxembourg",
    EUROPE_MADRID = "Europe/Madrid",
    EUROPE_MALTA = "Europe/Malta",
    EUROPE_MONACO = "Europe/Monaco",
    EUROPE_OSLO = "Europe/Oslo",
    EUROPE_PARIS = "Europe/Paris",
    EUROPE_PODGORICA = "Europe/Podgorica",
    EUROPE_PRAGUE = "Europe/Prague",
    EUROPE_ROME = "Europe/Rome",
    EUROPE_SAN_MARINO = "Europe/San_Marino",
    EUROPE_SARAJEVO = "Europe/Sarajevo",
    EUROPE_SKOPJE = "Europe/Skopje",
    EUROPE_STOCKHOLM = "Europe/Stockholm",
    EUROPE_TIRANE = "Europe/Tirane",
    EUROPE_VADUZ = "Europe/Vaduz",
    EUROPE_VATICAN = "Europe/Vatican",
    EUROPE_VIENNA = "Europe/Vienna",
    EUROPE_WARSAW = "Europe/Warsaw",
    EUROPE_ZAGREB = "Europe/Zagreb",
    EUROPE_ZURICH = "Europe/Zurich",
    AFRICA_BLANTYRE = "Africa/Blantyre",
    AFRICA_BUJUMBURA = "Africa/Bujumbura",
    AFRICA_CAIRO = "Africa/Cairo",
    AFRICA_GABORONE = "Africa/Gaborone",
    AFRICA_HARARE = "Africa/Harare",
    AFRICA_JOHANNESBURG = "Africa/Johannesburg",
    AFRICA_JUBA = "Africa/Juba",
    AFRICA_KHARTOUM = "Africa/Khartoum",
    AFRICA_KIGALI = "Africa/Kigali",
    AFRICA_LUBUMBASHI = "Africa/Lubumbashi",
    AFRICA_LUSAKA = "Africa/Lusaka",
    AFRICA_MAPUTO = "Africa/Maputo",
    AFRICA_MASERU = "Africa/Maseru",
    AFRICA_MBABANE = "Africa/Mbabane",
    AFRICA_TRIPOLI = "Africa/Tripoli",
    AFRICA_WINDHOEK = "Africa/Windhoek",
    ASIA_BEIRUT = "Asia/Beirut",
    ASIA_FAMAGUSTA = "Asia/Famagusta",
    ASIA_GAZA = "Asia/Gaza",
    ASIA_HEBRON = "Asia/Hebron",
    ASIA_JERUSALEM = "Asia/Jerusalem",
    ASIA_NICOSIA = "Asia/Nicosia",
    ASIA_TEL_AVIV = "Asia/Tel_Aviv",
    EUROPE_ATHENS = "Europe/Athens",
    EUROPE_BUCHAREST = "Europe/Bucharest",
    EUROPE_CHISINAU = "Europe/Chisinau",
    EUROPE_HELSINKI = "Europe/Helsinki",
    EUROPE_KALININGRAD = "Europe/Kaliningrad",
    EUROPE_KIEV = "Europe/Kiev",
    EUROPE_KYIV = "Europe/Kyiv",
    EUROPE_MARIEHAMN = "Europe/Mariehamn",
    EUROPE_NICOSIA = "Europe/Nicosia",
    EUROPE_RIGA = "Europe/Riga",
    EUROPE_SOFIA = "Europe/Sofia",
    EUROPE_TALLINN = "Europe/Tallinn",
    EUROPE_TIRASPOL = "Europe/Tiraspol",
    EUROPE_UZHGOROD = "Europe/Uzhgorod",
    EUROPE_VILNIUS = "Europe/Vilnius",
    EUROPE_ZAPOROZHYE = "Europe/Zaporozhye",
    AFRICA_ADDIS_ABABA = "Africa/Addis_Ababa",
    AFRICA_ASMARA = "Africa/Asmara",
    AFRICA_ASMERA = "Africa/Asmera",
    AFRICA_DAR_ES_SALAAM = "Africa/Dar_es_Salaam",
    AFRICA_DJIBOUTI = "Africa/Djibouti",
    AFRICA_KAMPALA = "Africa/Kampala",
    AFRICA_MOGADISHU = "Africa/Mogadishu",
    AFRICA_NAIROBI = "Africa/Nairobi",
    ANTARCTICA_SYOWA = "Antarctica/Syowa",
    ASIA_ADEN = "Asia/Aden",
    ASIA_AMMAN = "Asia/Amman",
    ASIA_BAGHDAD = "Asia/Baghdad",
    ASIA_BAHRAIN = "Asia/Bahrain",
    ASIA_DAMASCUS = "Asia/Damascus",
    ASIA_ISTANBUL = "Asia/Istanbul",
    ASIA_KUWAIT = "Asia/Kuwait",
    ASIA_QATAR = "Asia/Qatar",
    ASIA_RIYADH = "Asia/Riyadh",
    EUROPE_ISTANBUL = "Europe/Istanbul",
    EUROPE_KIROV = "Europe/Kirov",
    EUROPE_MINSK = "Europe/Minsk",
    EUROPE_MOSCOW = "Europe/Moscow",
    EUROPE_SIMFEROPOL = "Europe/Simferopol",
    EUROPE_VOLGOGRAD = "Europe/Volgograd",
    INDIAN_ANTANANARIVO = "Indian/Antananarivo",
    INDIAN_COMORO = "Indian/Comoro",
    INDIAN_MAYOTTE = "Indian/Mayotte",
    ASIA_TEHRAN = "Asia/Tehran",
    ASIA_BAKU = "Asia/Baku",
    ASIA_DUBAI = "Asia/Dubai",
    ASIA_MUSCAT = "Asia/Muscat",
    ASIA_TBILISI = "Asia/Tbilisi",
    ASIA_YEREVAN = "Asia/Yerevan",
    EUROPE_ASTRAKHAN = "Europe/Astrakhan",
    EUROPE_SAMARA = "Europe/Samara",
    EUROPE_SARATOV = "Europe/Saratov",
    EUROPE_ULYANOVSK = "Europe/Ulyanovsk",
    INDIAN_MAHE = "Indian/Mahe",
    INDIAN_MAURITIUS = "Indian/Mauritius",
    INDIAN_REUNION = "Indian/Reunion",
    ASIA_KABUL = "Asia/Kabul",
    ANTARCTICA_MAWSON = "Antarctica/Mawson",
    ASIA_AQTAU = "Asia/Aqtau",
    ASIA_AQTOBE = "Asia/Aqtobe",
    ASIA_ASHGABAT = "Asia/Ashgabat",
    ASIA_ASHKHABAD = "Asia/Ashkhabad",
    ASIA_ATYRAU = "Asia/Atyrau",
    ASIA_DUSHANBE = "Asia/Dushanbe",
    ASIA_KARACHI = "Asia/Karachi",
    ASIA_ORAL = "Asia/Oral",
    ASIA_QYZYLORDA = "Asia/Qyzylorda",
    ASIA_SAMARKAND = "Asia/Samarkand",
    ASIA_TASHKENT = "Asia/Tashkent",
    ASIA_YEKATERINBURG = "Asia/Yekaterinburg",
    INDIAN_KERGUELEN = "Indian/Kerguelen",
    INDIAN_MALDIVES = "Indian/Maldives",
    ASIA_CALCUTTA = "Asia/Calcutta",
    ASIA_COLOMBO = "Asia/Colombo",
    ASIA_KOLKATA = "Asia/Kolkata",
    ASIA_KATHMANDU = "Asia/Kathmandu",
    ASIA_KATMANDU = "Asia/Katmandu",
    ANTARCTICA_VOSTOK = "Antarctica/Vostok",
    ASIA_ALMATY = "Asia/Almaty",
    ASIA_BISHKEK = "Asia/Bishkek",
    ASIA_DACCA = "Asia/Dacca",
    ASIA_DHAKA = "Asia/Dhaka",
    ASIA_KASHGAR = "Asia/Kashgar",
    ASIA_OMSK = "Asia/Omsk",
    ASIA_QOSTANAY = "Asia/Qostanay",
    ASIA_THIMBU = "Asia/Thimbu",
    ASIA_THIMPHU = "Asia/Thimphu",
    ASIA_URUMQI = "Asia/Urumqi",
    INDIAN_CHAGOS = "Indian/Chagos",
    ASIA_RANGOON = "Asia/Rangoon",
    ASIA_YANGON = "Asia/Yangon",
    INDIAN_COCOS = "Indian/Cocos",
    ANTARCTICA_DAVIS = "Antarctica/Davis",
    ASIA_BANGKOK = "Asia/Bangkok",
    ASIA_BARNAUL = "Asia/Barnaul",
    ASIA_HO_CHI_MINH = "Asia/Ho_Chi_Minh",
    ASIA_HOVD = "Asia/Hovd",
    ASIA_JAKARTA = "Asia/Jakarta",
    ASIA_KRASNOYARSK = "Asia/Krasnoyarsk",
    ASIA_NOVOKUZNETSK = "Asia/Novokuznetsk",
    ASIA_NOVOSIBIRSK = "Asia/Novosibirsk",
    ASIA_PHNOM_PENH = "Asia/Phnom_Penh",
    ASIA_PONTIANAK = "Asia/Pontianak",
    ASIA_SAIGON = "Asia/Saigon",
    ASIA_TOMSK = "Asia/Tomsk",
    ASIA_VIENTIANE = "Asia/Vientiane",
    INDIAN_CHRISTMAS = "Indian/Christmas",
    ASIA_BRUNEI = "Asia/Brunei",
    ASIA_CHOIBALSAN = "Asia/Choibalsan",
    ASIA_CHONGQING = "Asia/Chongqing",
    ASIA_CHUNGKING = "Asia/Chungking",
    ASIA_HARBIN = "Asia/Harbin",
    ASIA_HONG_KONG = "Asia/Hong_Kong",
    ASIA_IRKUTSK = "Asia/Irkutsk",
    ASIA_KUALA_LUMPUR = "Asia/Kuala_Lumpur",
    ASIA_KUCHING = "Asia/Kuching",
    ASIA_MACAO = "Asia/Macao",
    ASIA_MACAU = "Asia/Macau",
    ASIA_MAKASSAR = "Asia/Makassar",
    ASIA_MANILA = "Asia/Manila",
    ASIA_SHANGHAI = "Asia/Shanghai",
    ASIA_SINGAPORE = "Asia/Singapore",
    ASIA_TAIPEI = "Asia/Taipei",
    ASIA_UJUNG_PANDANG = "Asia/Ujung_Pandang",
    ASIA_ULAANBAATAR = "Asia/Ulaanbaatar",
    ASIA_ULAN_BATOR = "Asia/Ulan_Bator",
    AUSTRALIA_PERTH = "Australia/Perth",
    AUSTRALIA_WEST = "Australia/West",
    AUSTRALIA_EUCLA = "Australia/Eucla",
    ASIA_CHITA = "Asia/Chita",
    ASIA_DILI = "Asia/Dili",
    ASIA_JAYAPURA = "Asia/Jayapura",
    ASIA_KHANDYGA = "Asia/Khandyga",
    ASIA_PYONGYANG = "Asia/Pyongyang",
    ASIA_SEOUL = "Asia/Seoul",
    ASIA_TOKYO = "Asia/Tokyo",
    ASIA_YAKUTSK = "Asia/Yakutsk",
    PACIFIC_PALAU = "Pacific/Palau",
    AUSTRALIA_DARWIN = "Australia/Darwin",
    AUSTRALIA_NORTH = "Australia/North",
    ANTARCTICA_DUMONTDURVILLE = "Antarctica/DumontDUrville",
    ASIA_UST_NERA = "Asia/Ust-Nera",
    ASIA_VLADIVOSTOK = "Asia/Vladivostok",
    AUSTRALIA_BRISBANE = "Australia/Brisbane",
    AUSTRALIA_LINDEMAN = "Australia/Lindeman",
    AUSTRALIA_QUEENSLAND = "Australia/Queensland",
    PACIFIC_CHUUK = "Pacific/Chuuk",
    PACIFIC_GUAM = "Pacific/Guam",
    PACIFIC_PORT_MORESBY = "Pacific/Port_Moresby",
    PACIFIC_SAIPAN = "Pacific/Saipan",
    PACIFIC_TRUK = "Pacific/Truk",
    PACIFIC_YAP = "Pacific/Yap",
    AUSTRALIA_ADELAIDE = "Australia/Adelaide",
    AUSTRALIA_BROKEN_HILL = "Australia/Broken_Hill",
    AUSTRALIA_SOUTH = "Australia/South",
    AUSTRALIA_YANCOWINNA = "Australia/Yancowinna",
    ANTARCTICA_CASEY = "Antarctica/Casey",
    ANTARCTICA_MACQUARIE = "Antarctica/Macquarie",
    ASIA_MAGADAN = "Asia/Magadan",
    ASIA_SAKHALIN = "Asia/Sakhalin",
    ASIA_SREDNEKOLYMSK = "Asia/Srednekolymsk",
    AUSTRALIA_ACT = "Australia/ACT",
    AUSTRALIA_CANBERRA = "Australia/Canberra",
    AUSTRALIA_CURRIE = "Australia/Currie",
    AUSTRALIA_HOBART = "Australia/Hobart",
    AUSTRALIA_LHI = "Australia/LHI",
    AUSTRALIA_LORD_HOWE = "Australia/Lord_Howe",
    AUSTRALIA_MELBOURNE = "Australia/Melbourne",
    AUSTRALIA_NSW = "Australia/NSW",
    AUSTRALIA_SYDNEY = "Australia/Sydney",
    AUSTRALIA_TASMANIA = "Australia/Tasmania",
    AUSTRALIA_VICTORIA = "Australia/Victoria",
    PACIFIC_BOUGAINVILLE = "Pacific/Bougainville",
    PACIFIC_EFATE = "Pacific/Efate",
    PACIFIC_GUADALCANAL = "Pacific/Guadalcanal",
    PACIFIC_KOSRAE = "Pacific/Kosrae",
    PACIFIC_NOUMEA = "Pacific/Noumea",
    PACIFIC_POHNPEI = "Pacific/Pohnpei",
    PACIFIC_PONAPE = "Pacific/Ponape",
    ASIA_ANADYR = "Asia/Anadyr",
    ASIA_KAMCHATKA = "Asia/Kamchatka",
    PACIFIC_FIJI = "Pacific/Fiji",
    PACIFIC_FUNAFUTI = "Pacific/Funafuti",
    PACIFIC_KWAJALEIN = "Pacific/Kwajalein",
    PACIFIC_MAJURO = "Pacific/Majuro",
    PACIFIC_NAURU = "Pacific/Nauru",
    PACIFIC_NORFOLK = "Pacific/Norfolk",
    PACIFIC_TARAWA = "Pacific/Tarawa",
    PACIFIC_WAKE = "Pacific/Wake",
    PACIFIC_WALLIS = "Pacific/Wallis",
    ANTARCTICA_MCMURDO = "Antarctica/McMurdo",
    ANTARCTICA_SOUTH_POLE = "Antarctica/South_Pole",
    PACIFIC_APIA = "Pacific/Apia",
    PACIFIC_AUCKLAND = "Pacific/Auckland",
    PACIFIC_ENDERBURY = "Pacific/Enderbury",
    PACIFIC_FAKAOFO = "Pacific/Fakaofo",
    PACIFIC_KANTON = "Pacific/Kanton",
    PACIFIC_TONGATAPU = "Pacific/Tongatapu",
    PACIFIC_CHATHAM = "Pacific/Chatham",
    PACIFIC_KIRITIMATI = "Pacific/Kiritimati"
};

export type TimeZoneOffsetMap = { [key in TimeZone]: number };