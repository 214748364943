import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';

import { AccessTokenService } from '@core/services/access-token.service';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, switchMap, take, tap } from 'rxjs/operators';

class ErrorInstance {

    constructor(private _errorResponse: HttpErrorResponse) { }

    get statusText() {
        return this._errorResponse.statusText;
    }

    get errorCode() {
        return this._errorResponse.status;
    }

    get errorName() {
        if (this._errorResponse.error.error) return this._errorResponse.error.error.name;
        else return this._errorResponse.name;
    }

    get errorMessage() {
        if (this._errorResponse.error.error) return this._errorResponse.error.error.message;
        else return this._errorResponse.error.error_message;
    }
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    /**
     * constructor
     * 
     * @param {Router} _router
     * @param {AccessTokenService} _accessTokenService
     */
    constructor(private _router: Router,
        private _accessTokenService: AccessTokenService) { }

    /**
     * Handle all kind of errors
     * 
     * 401 => Refresh access token
     * Others => ThrowError
     * Unknown error => Redirect to login and throwError
     * 
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(errorResponse => {
                // scheduler service exception
                if (errorResponse instanceof HttpErrorResponse) {
                    const errorInstance = new ErrorInstance(errorResponse);
                    if (errorInstance.statusText != "Unknown Error") {

                        // handle token expired
                        if (errorInstance.errorCode === 401 &&
                            (errorInstance.errorMessage === "jwt expired" ||
                                errorInstance.errorMessage === "Response status code does not indicate success: 401 (Unauthorized)."))
                            return this.handle401Error(request, next);

                        switch (errorInstance.errorCode) {
                            case 401:
                                if (!this._router.url.startsWith('/twofa')) this._router.navigate(['/login']);
                                break;
                            default:
                                break;
                        }

                        return throwError(errorInstance.errorMessage);
                    }
                    else {
                        // this._router.navigate(['/login']);
                        return throwError("Unknown Error");
                    }
                }
                else return throwError("Unknow Error");
            })
        );
    }

    /**
     * Refresh the access token
     * 
     * @param {HttpRequest<any>} request 
     * @param {HttpHandler} next 
     * @returns {Observable<HttpEvent<any>>}
     */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const lastUpdateTimeStamp = this._accessTokenService.accessTokenTimeStamp;
        const currentTimeStamp = new Date().getTime();
        // console.log(`${currentTimeStamp - lastUpdateTimeStamp} ms till last update`);
        if (!this._accessTokenService.refreshFlag && currentTimeStamp - lastUpdateTimeStamp > 600000) {
            this._accessTokenService.refreshFlag = true;
            const refreshToken = sessionStorage.getItem("becentralRefreshToken");
            this._accessTokenService.clearAllInfo();
            if (refreshToken) {
                // console.log(`${request.url}: Request Into refresh Token process.`);
                return this._accessTokenService.refreshAccessToken(refreshToken).pipe(
                    switchMap((token) => {
                        this._accessTokenService.accessTokenTimeStamp = currentTimeStamp;
                        this._accessTokenService.refreshFlag = false;
                        this._accessTokenService.setToken(token.data);
                        // console.log(`${request.url}: set Token.`, token);
                        return next.handle(this.addTokenHeader(request, token.data.accessToken));
                    }),
                    catchError((error) => {
                        this._accessTokenService.refreshFlag = false;
                        this._router.navigate(['/login']);
                        return throwError(error);
                    })
                );
            }
            else {
                this._accessTokenService.refreshFlag = false;
                this._router.navigate(['/login']);
                return throwError("becentralRefreshToken is empty.");
            }
        }

        return this._accessTokenService.currentAccessToken.pipe(
            filter(token => token !== null),
            take(1),
            // tap(() => console.log(`${request.url}: Request Hold process.`)),
            // tap((token) => console.log(`${request.url}: Get Token.`, token)),
            delay(100),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    /**
     * Set access token into header
     * 
     * @param {HttpRequest<any>} request 
     * @param {string} token 
     * @returns {HttpRequest<any>}
     */
    private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ headers: request.headers.set("Authorization", `Bearer ${token}`) });
    }
}

export const HTTPErrorHandlerProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
};