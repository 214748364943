/******* Angular Resourse *******/
import { Injectable, OnDestroy } from "@angular/core";

/******* Shared Resourse *******/
import { environment } from 'environments/environment';

/******* Plug-In Resourse *******/
import { BehaviorSubject, of, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ReCaptchaV3Service implements OnDestroy {

    // Private
    private _grecaptcha$: BehaviorSubject<any>;
    private _unsubscribeAll$: Subject<any>;
    private _enableReCaptcha: boolean = environment.internetFunction;
    private readonly _byPassToken: string = "byPassToken";

    /**
     * constructor
     */
    constructor() {
        this._unsubscribeAll$ = new Subject();
        this._grecaptcha$ = new BehaviorSubject(null);

        if (this._enableReCaptcha) {
            window['ng2recaptchaloaded'] = () => { this._grecaptcha$.next(window['grecaptcha']); };
            this.loadReCaptchaScript();
        }
    }

    //  Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * getter grecaptch
     */
    get grecaptcha() {
        return this._grecaptcha$.value;
    }

    //  Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * on Destory
     */
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    //  Private Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Dynamic loading recaptcha script to application
     * 
     * @returns 
     */
    private loadReCaptchaScript(): void {
        if (window['grecaptcha']) return;
        const script = document.createElement('script');
        script.innerHTML = "";
        const baseUrl = "https://www.recaptcha.net/recaptcha/api.js";
        script.src = `${baseUrl}?render=${environment.recaptchaSiteKey}&onload=ng2recaptchaloaded&hl=en`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }

    //  Publuc Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * execute action
     * 
     * @param {string} action 
     */
    execute(action: string): Promise<any> {
        return this._enableReCaptcha ? this.grecaptcha.execute(environment.recaptchaSiteKey, { action }) : Promise.resolve(this._byPassToken);
    }

};