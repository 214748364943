export interface ContentHeader {
	headerTitle: string;
	actionButton: boolean;
	breadcrumb?: {
		type?: string;
		links?: Array<{
			name?: string;
			isLink?: boolean;
			link?: string;
		}>;
	};
};

export interface StatusInfo {
	Online: number;
	Offline: number;
	Total: number;
};