/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Filter, UpdateDeviceAlertRequestBody } from '@core/types';

@Injectable()
export class DeviceAlarm extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /device-alarm/count - get count of device alarm
     * 
     * @param {any} where
     */
    public getDeviceAlaramCount(where: any) {
        return this.count('device-alarm', where);
    }

    /**
     * GET /device-alarm - get device alarm
     * 
     * @param {Filter} filter
     */
    public getDeviceAlaram(filter: Filter) {
        return this.find('device-alarm', filter);
    }

    /**
     * PATCH /device-alarm - update alram status
     * 
     * @param {any} where
     * @param {UpdateDeviceAlertRequestBody} data
     */
    public updateDeviceAlarm(where: any, data: UpdateDeviceAlertRequestBody) {

        let method = "PATCH",
            url = "device-alarm",
            routeParams = {},
            urlParams = { where },
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /device-alarm/readAll - update all alram status to read
     */
    public readAllDeviceAlarm() {

        let method = "PATCH",
            url = "device-alarm/readAll",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /device-alarm/all/count - get all alram count
     * 
     * @param {any} where
     */
    public getAllAlarmCount(where: any) {

        let method = "GET",
            url = "device-alarm/all/count",
            routeParams = {},
            urlParams = { where },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /device-alarm/count/{id} - get device's alram count by id
     * 
     * @param {string} id
     */
    public getAlarmCount(id: string) {

        let method = "GET",
            url = "device-alarm/count/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

}