import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * OSPF Area ID shoud be Integer or valid IP
 * 
 * @returns {ValidatorFn}
 */
export function areaIdValidator(): ValidatorFn {

    let numOnlyFormat: RegExp = /^[0-9]*$/;
    let ipFormat: RegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    return (control: AbstractControl): ValidationErrors | null => {

        if (!control.value) return null;

        // if is number
        if (numOnlyFormat.test(control.value)) {

            const areaNumber = parseInt(control.value);

            if (areaNumber > 4294967295) return { max: true };
            else if (areaNumber < 0) return { min: true };
            else return null;
        }
        else if (ipFormat.test(control.value))
            return null;
        else 
            return { format : true };
    };
}