export const DEVICE_OPTIONS: Array<any> = [
    { name: "Model", value: "MODEL" },
    { name: "Name", value: "name" },
    { name: "Address", value: "address" },
    { name: "Custom Field", value: "customField" },
    { name: "CONN. UPTIME", value: "LTE_UPTIME" },
    { name: "SYS. UPTIME", value: "SYSUPTIME" },
    { name: "Last Connect", value: "lastPublishedAt" }
];

export const HARDWARE_OPTIONS: Array<any> = [
    { name: "F/W", value: "FW" },
    { name: "CardFw", value: "CardFw" }
];

export const NETWORK_OPTIONS: Array<any> = [
    { name: "IP", value: "IP" },
    { name: "IPv6", value: "IPV6" },
    { name: "APN2 IP", value: "APN2_IP" },
    { name: "IMEI", value: "IMEI" },
    { name: "IMSI", value: "IMSI" },
    { name: "NETWORK", value: "NETWORK" },
    { name: "MDN", value: "LTE_MDN" },
    { name: "PCI", value: "PCI" },
    { name: "ECI", value: "ECI" }, // ECI/NCI
    { name: "SECTOR ID", value: "CELL_ID" },
    { name: "ENB", value: "ENB_ID" }, // ENB/GNB
    { name: "BAND", value: "BAND" },
    { name: "RI", value: "RI" },
    { name: "DL MCS", value: "DL_MCS" },
    { name: "CQI", value: "CQI" },
    { name: "ICCID", value: "ICCID" },
    { name: "CBSD", value: "CBSD" }
];

export const SIGNAL_OPTIONS: Array<any> = [
    { name: "RSRP", value: "RSRP" },
    { name: "RSRQ", value: "RSRQ" },
    { name: "RSSI", value: "RSSI" },
    { name: "SINR", value: "SINR" }
];