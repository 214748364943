/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { assignTagsRequest, tagRequest } from '@core/types';

@Injectable()
export class Tags extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /tags - get tags list
     * 
     * @param filter
     */
    public getTags(filter) {
        return this.find("tags", filter);
    }

    /**
     * POST /tags - create new tag
     * 
     * @param {tagRequest} data
     */
    public createNewTag(data: tagRequest) {

        let method = "POST",
            url = "tags",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /tags/{id} - delete Tags by id
     * 
     * @param {string} id
     */
    public deleteTags(id: string) {

        let method = "DELETE",
            url = "tags/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /tags/multiDevice - assign Tags to multiple device
     * 
     * @param {assignTagsRequest} data
     */
    public assignTagsToMultipleDevice(data: assignTagsRequest) {

        let method = "PATCH",
            url = "tags/multiDevice",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

}