export interface DeviceGeoInformation {
    cpeLocationAvailable: boolean;
    cpeManuallyAddressAvailable: boolean
    cpeAddress: string;
    cpeGpsAvailable: boolean;
    cpeCoordinate?: Array<number>; // [Lat, Long]
    mainCellAvailable: boolean;
    mainCellCoordinate?: Array<number>; // [Lat, Long]
    mainCellDistance?: number;
    mainCellAzimuth?: number;
};