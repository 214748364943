export enum ProfileTemplateType {
    CONFIGURATION = 'configuration',
    ALERT_POLICY = 'alert-policy'
};

export enum ProfilePageType {
    MAIN = 'main',
    PROFILE_FORM = 'profileForm'
};

export interface ProfileTemplateInterface {
    id: string;
    type: ProfileTemplateType,
    name: string;
    lastUpdatedAt: string;
    profile: any;
    tenantId: string;
    groupId: string;
};