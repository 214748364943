/* Signal Range */
export const SIGNAL_CAP = {
    RSRP: {
        maxValue: -30,
        minValue: -150,
        MAX: -20,
        MIN: -160,
        thresholds: [
            { larger: -150, less: -120, color: '#EA5455', comment: "Poor", mapColor: "RED" },
            { larger: -120, less: -105, color: '#FF9F43', comment: "Ok", mapColor: "ORANGE" },
            { larger: -105, less: -90, color: '#00CFE8', comment: "Good", mapColor: "BLUE" },
            { larger: -90, less: -30, color: '#219653', comment: "Excellent", mapColor: "GREEN" }
        ]
    },
    RSRQ: {
        maxValue: -3,
        minValue: -20,
        MAX: 0,
        MIN: -20,
        thresholds: [
            { larger: -20, less: -12, color: '#EA5455', comment: "Poor" },
            { larger: -12, less: -9, color: '#00CFE8', comment: "Good" },
            { larger: -9, less: -3, color: '#219653', comment: "Excellent" }
        ]
    },
    SINR: {
        maxValue: 45,
        minValue: -5,
        MAX: 45,
        MIN: 0,
        thresholds: [
            { larger: -6, less: 0, color: '#EA5455', comment: "Poor" },
            { larger: 0, less: 5, color: '#FF9F43', comment: "Ok" },
            { larger: 5, less: 10, color: '#00CFE8', comment: "Good" },
            { larger: 10, less: 45, color: '#219653', comment: "Excellent" }
        ]
    },
    RSSI: {
        maxValue: -20,
        minValue: -120,
        MAX: -20,
        MIN: -120,
        thresholds: [
            { larger: -120, less: -100, color: '#EA5455', comment: "Poor" },
            { larger: -100, less: -85, color: '#FF9F43', comment: "Ok" },
            { larger: -85, less: -70, color: '#00CFE8', comment: "Good" },
            { larger: -70, less: -20, color: '#219653', comment: "Excellent" }
        ]
    }
};
