import { PipeTransform, Pipe } from '@angular/core'
import { ITEM_NAME_MAP } from '@core/constant';

@Pipe({
	name: 'diagnosticItems'
})
export class DiagnosticItemsPipe implements PipeTransform {
    
    /**
     * Transform
     * 
     * @param {string} value 
     * @returns {string}
     */
    transform(value: string): string {
        if(!value) return "";
        return ITEM_NAME_MAP[value];
	}
}