import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'subString'
})
export class SubStringPipe implements PipeTransform {

    /**
     * SubString
     * 
     * @param {string} value 
     * @returns {string}
     */
    transform(value: string, startIndex: number, endIndex): string {
        return value.substring(startIndex, endIndex);
    }
}
