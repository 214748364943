/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { RoleRequest } from '@core/types';

@Injectable()
export class Tenants extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /tenants - get tenants list
     * 
     * @param filter
     */
    public getTenants(filter) {
        return this.find("tenants", filter);
    }

    /**
     * GET /tenants/count - get tenants list count
     * 
     * @param where
     */
    public getTenantsCount(where) {
        return this.count("tenants", where);
    }

    /**
     * POST /tenants - create new tenant
     * 
     * @param {any} data
     */
    public createNewTenant(data: any) {

        let method = "POST",
            url = "tenants",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /tenants/{id} - get tenant info
     * 
     * @param {string} id
     */
    public getTenantInformation(id: string) {

        let method = "GET",
            url = "tenants/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /tenants/{id} - update tenant
     * 
     * @param {any} data
     * @param {string} id
     */
    public updateTenant(data: any, id: string) {

        let method = "PATCH",
            url = "tenants/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /tenants/{id} - delete tenant
     * 
     * @param {string} id
     */
    public deleteTenant(id: string) {

        let method = "DELETE",
            url = "tenants/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /tenants/{id}/roles - get tenant's roles
     * 
     * @param {string} id
     */
    public getTenantRoles(id: string) {

        let method = "GET",
            url = "tenants/:id/roles",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /tenants/{id}/users - get tenant's users
     * 
     * @param {string} id
     */
    public getTenantUsers(id: string) {

        let method = "GET",
            url = "tenants/:id/users",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /tenants/{id}/users - invite user into tenant
     * 
     * @param {any} data
     * @param {string} id
     */
    public inviteUserToTenant(data: any, id: string) {

        let method = "POST",
            url = "tenants/:id/users",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /tenants/{id}/users/{userId} - update user from tenant
     * 
     * @param {string} id tenant id 
     * @param {string} userId user id
     * @param {any} data user data
     */
    public updateUserFromTenant(id: string, userId: string, data: any) {
        
        let method = "PATCH",
            url = "tenants/:id/users/:userId",
            routeParams = { id, userId },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /tenants/{id}/users/{userId} - delete user from tenant
     * 
     * @param {string} id tenant id 
     * @param {string} userId user id
     */
    public deleteUserFromTenant(id: string, userId: string) {
        
        let method = "DELETE",
            url = "tenants/:id/users/:userId",
            routeParams = { id, userId },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /tenants/{id}/status/{actionStatus} - set tenant's status
     * 
     * @param {string} id tenant id 
     * @param {string} actionStatus action status
     */
    public updateTenantStatus(id: string, actionStatus: string) {
        
        let method = "POST",
            url = "tenants/:id/status/:actionStatus",
            routeParams = { id, actionStatus },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PUT /tenants/{id}/logo - upload tenant's logo
     * 
     * @param {string} id tenant id 
     * @param {any} data form data
     */
    public uploadTenantLogo(id: string, data: any) {
        
        let method = "PUT_FILE",
            url = "tenants/:id/logo",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /tenants/{id}/access - get Tenant access token
     * 
     * @param {string} id tenant id 
     */
    public getTenantAccess(id: string) {

        let method = "POST",
            url = "tenants/:id/access",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /tenants/{id}/roles - create new role in tenant
     * 
     * @param {string} id tenant id 
     * @param {RoleRequest} data 
     */
    public createNewRole(id: string, data: RoleRequest) {

        let method = "POST",
            url = "tenants/:id/roles",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /tenants/{id}/roles/{roleId} - update the role
     * 
     * @param {string} id tenant id 
     * @param {string} roleId role id
     * @param {RoleRequest} data 
     */
    public updateRole(id: string, roleId: string, data: RoleRequest) {

        let method = "PATCH",
            url = "tenants/:id/roles/:roleId",
            routeParams = { id, roleId },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /tenants/{id}/roles/{roleId} - delete user from tenant
     * 
     * @param {string} id tenant id 
     * @param {string} roleId role id
     */
    public deleteRoleFromTenant(id: string, roleId: string) {
        
        let method = "DELETE",
            url = "tenants/:id/roles/:roleId",
            routeParams = { id, roleId },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /tenants/licenses - get tenants with license status
     * 
     * @param filter
     */
    public getTenantsWithLicenseStatus(filter) {

        let method = "GET",
            url = "tenants/licenses",
            routeParams = {},
            urlParams = {
                filter: filter
            },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

}