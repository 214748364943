export enum RoleName {
    /* Tenant Default Role */
    SUPER_ADMIN = 'super-admin',
    ADMIN = 'admin',
    TENANT_GROUP_ADMIN = 'tenant-group-admin',
    BANNED = 'banned'
};

export enum GroupRoleName {
    /* Group Default Role */
    GROUP_ADMIN = 'group-admin',
    GROUP_USER = 'group-user'
};