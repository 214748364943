export interface licenseAssignToTenantRequest {
    licenseType: "trial" | "one_year" | "three_years" | "monthly_subscription";
    tenantId: string;
    amount?: number;
};

export interface licenseActionToDeviceRequest {
    MAC_array?: string[];
    MAC?: string;
    licenseType?: 'monthly_subscription' | 'trial' | 'one_year' | 'three_years' | string;
    groupId?: string;
    action: 'assign' | 'unassign' | 'renew' | 'transfer'
};
