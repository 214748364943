/******* Angular Resourse *******/
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

/******* Shared Resourse *******/
import { UtilsService } from '@core/services/utils.service';
import { GlobalUIBlockingService } from './global-ui-blocking.service';
import { HttpCancelService } from '@core/services/http-cancel.service';
import { PrivilegeService } from './privilege.service';
import { SocketService } from './socket.service';

/******* Plug-In Resourse *******/
import { fromEvent, Subscription, interval, Observable } from 'rxjs';
import Swal from 'sweetalert2';

/******* API Resourse *******/
import { Auth } from '@api';

const MS_IN_ONE_MINUTE = 60000;

@Injectable({
    providedIn: 'root',
})
export class IdleCheckService {

    // Public
    public timeOutInMS: number = 15 * MS_IN_ONE_MINUTE;

    // Private
    private clickEventSubscription: Subscription;
    private intervalSubscription: Subscription;
    private lastAction: number = 0;
    private _everySecond$: Observable<number> = interval(1000);

    /**
     * Constructor
     *
     * @param {Auth} _auth
     * @param {UtilsService} _utilsService
     * @param {NgZone} _NgZone
     * @param {Router} _router
     * @param {PrivilegeService} _privilegeService
     * @param {GlobalUIBlockingService} _globalUIBlockingService
     * @param {HttpCancelService} _httpCancelService
     * @param {SocketService} _socketService
     */
    constructor(private _auth: Auth,
        private _utilsService: UtilsService,
        private _NgZone: NgZone,
        private _router: Router,
        private _privilegeService: PrivilegeService,
        private _globalUIBlockingService: GlobalUIBlockingService,
        private _httpCancelService: HttpCancelService,
        private _socketService: SocketService) { }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set Last Action Time
     */
    private setLastActionTime() {
        this.lastAction = Date.now();
    }

    /**
     * Initial The Listener
     */
    private initialTheListener(): void {
        this._NgZone.runOutsideAngular(() => {
            this.clickEventSubscription = fromEvent(document.body, 'click').subscribe((event: any) => {
                this.setLastActionTime();
            });
        });
    }

    /**
     * Initial The Timer
     * 
     * @param timeoutMins
     */
    private initialTheTimer(timeoutMins: number): void {
        this._NgZone.runOutsideAngular(() => {
            this.timeOutInMS = timeoutMins * MS_IN_ONE_MINUTE;
            this.intervalSubscription = this._everySecond$.subscribe((data) => {
                const idleStartAt = this.lastAction;
                if (idleStartAt) {
                    const idleTime = Date.now() - idleStartAt; // check idle time parseInt(idleStartAt)
                    if (idleTime >= this.timeOutInMS - 100) { // minus 100 for adjust
                        this.lastAction = 0; // remove last action
                        this._NgZone.run(() => {
                            if (this._router.url.startsWith('/pages')) this.alertTimeout();
                            this.destroyTimerService();
                        });
                    }
                }
                else this.setLastActionTime();
            });
        });
    }

    /**
     * Pop out the alert Timeout alert
     */
    private async alertTimeout(): Promise<void> {
        try {
            const { dismiss } = await this._utilsService.swalMixin.fire({
                title: 'Activity Checker',
                html: `Your Session is about to expire`,
                timer: 60000,
                timerProgressBar: true,
                icon: 'warning',
                confirmButtonText: 'Sign out now',
                cancelButtonText: 'Stay signed in',
                customClass: {
                    container: 'max-z-index',
                    confirmButton: 'btn btn-secondary loading-border-transparent',
                    cancelButton: 'btn btn-primary loading-display-none'
                }
            });

            if (dismiss && dismiss != Swal.DismissReason.timer) this.initialTimerService(this.timeOutInMS / MS_IN_ONE_MINUTE);
            else this.logout();
        }
        catch (error) {
            this._utilsService.alert("error", "Auto Logout Timer", "");
            this.logout();
        }
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initial timer service
     * 
     * @param {number} timeoutMins
     */
    public initialTimerService(timeoutMins: number): void {
        this.destroyTimerService();
        this.setLastActionTime();
        this.initialTheListener();
        this.initialTheTimer(timeoutMins);
    }

    /**
     * Destroy timer service
     */
    public destroyTimerService(): void {
        if (this.clickEventSubscription) this.clickEventSubscription.unsubscribe();
        if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    }

    /**
     * Logout method
     */
    public logout() {

        // Logout preparation
        this._privilegeService.clearUserInfo();
        this._socketService.disconnectSocket();
        this._httpCancelService.cancelPendingRequests();
        this.destroyTimerService();

        this._globalUIBlockingService.start();
        this._auth.logout().subscribe(() => {
            this._globalUIBlockingService.stop();
            this._router.navigate(['/login']);
        }, () => {
            this._globalUIBlockingService.stop();
            this._router.navigate(['/login']);
        });
    }
}