import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'initials'
})
export class InitialsPipe implements PipeTransform {
	
	/**
	 * Transform
	 *
	 * @param {string} fullName
	 * @returns {string}
	 */
	transform(fullName: string): string {
		return fullName
			?.split(' ')
			.map(n => n[0] ? n[0].toUpperCase() : "U")
			.join('');
	}
}
