import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * All Signal Standard In Order
 * 
 * @returns {ValidatorFn}
 */
export function allSignalStandardInOrder(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let error = {
            'RSSI' : true,
            'RSRP' : true,
            'RSRQ' : true,
            'SINR' : true
        };

        ['RSSI','RSRP','RSRQ','SINR'].forEach((signalType) => {
            let FGValue = (<UntypedFormGroup>control.get(signalType)).value;
            if(!FGValue.excellent || !FGValue.good || !FGValue.midcell || !FGValue.celledge)
                delete error[signalType];
            else if(FGValue.excellent >= FGValue.good && FGValue.good >= FGValue.midcell && FGValue.midcell >= FGValue.celledge)
                delete error[signalType]
        });
        return Object.keys(error).length == 0 ? null : error;
    };
}