import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * License Availble Count should be good enohugh
 * 
 * @returns {ValidatorFn}
 */
export function licenseAvailableCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const AvailableCount = control.get('licenseAvailable').value;
        const RequiredCount = control.get('licenseRequired').value;
        return AvailableCount >= RequiredCount ? null : { licenseNotEnough: true };
    };
}