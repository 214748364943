/******* Angular Resourse *******/
import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

/******* Core Resourse *******/
import { CoreConfigService } from '@core/services/config.service';
import { UtilsService } from '@core/services/utils.service';

/******* Plug-In Resourse *******/
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/******* API Resourse *******/
import { Users } from '@api';
import { CLIENT_ID, CLIENT_SECRET } from '@core/constant';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {

	// Public
	public coreConfig: any; // Layout Setting Config
	public forgotPasswordForm: UntypedFormGroup; // Forgot Password Form Group
	public submitted: boolean = false; // Submitted Flag
	public loading: boolean = false; // Loading flag

	// Private
	private _unsubscribeAll$: Subject<any>; // UnsubscribeAll Observable

	/**
	 * constructor
	 * 
	 * @param {Router} _router 
	 * @param {FormBuilder} _formBuilder 
	 * @param {ElementRef} _elementRef
	 * @param {Users} _users 
	 * @param {CoreConfigService} _coreConfigService
	 * @param {UtilsService} _utilsService
	 */
	constructor(private _router: Router,
		private _formBuilder: UntypedFormBuilder,
		private _elementRef: ElementRef,
		private _users: Users,
		private _coreConfigService: CoreConfigService,
		private _utilsService: UtilsService) {

		// Initial Observable
		this._unsubscribeAll$ = new Subject();

		// Build up the Form
		this.forgotPasswordForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]]
		});

		// Set Blank Page
		this._coreConfigService.setConfig({
			layout: {
				footer: { hidden: true },
				menu: { hidden: true },
				navbar: { hidden: true },
				buyNow: false,
				customizer: false,
				scrollTop: false,
				enableLocalStorage: false,
				skin: "dark",
				type: "vertical"
			}
		});
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll$)).subscribe(config => {
			this.coreConfig = config;
		});
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll$.next();
		this._unsubscribeAll$.complete();
	}

	// Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Submit the form
	 */
	public async onSubmit(): Promise<void> {

		this.submitted = true;

		if (this.forgotPasswordForm.invalid) {
			this._utilsService.scrollToInvalid(this._elementRef);
			return;
		}

		/* Fire Alert */
		const { isConfirmed } = await this._utilsService.swalMixin.fire({
			width: "600px",
			title: 'Send reset link',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: "Confirm",
			html: `<p>Are you sure you want to Reset the Password of ${this.forgotPasswordForm.value.email}?</p>`,
			showClass: { popup: 'animate__animated animate__bounceIn' },
			customClass: {
				input: 'remove-from-role mt-2 mb-2',
				title: 'mt-2 mb-2',
				content: 'role-text',
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-secondary'
			}
		})

		if (isConfirmed) {
			this.loading = true;
			let inputData = {
				"clientSecret": CLIENT_SECRET,
				"clientId": CLIENT_ID,
				email: this.forgotPasswordForm.value.email
			};

			this._users.forgotPassword(inputData).subscribe(
				(results) => {
					this.loading = false;
					this._utilsService.alert("success", 'Success!', results.data);
					this._router.navigate(['/login']);
				},
				(error) => {
					this.loading = false;
					this._utilsService.alert("error", 'Fail to Reset Passowrd', error);
				}
			)
		}
	}
}
