export interface DevcieTableDefination {
    key: string;
    name: string;
    sortable: boolean;
    colvis?: boolean;
    defaultDisplay: boolean;
    filterable: boolean;
    filterType?: string;
    custom?: boolean;
    width: number;
    class: string;
    placeholder?: string;
    searchable: boolean;
};