import { TaskTypeNameMapping, TaskTypeValue } from "@core/types";

export const TASK_TYPE_MAPPING: TaskTypeNameMapping = {
    [TaskTypeValue.REBOOT]: "Reboot",
    [TaskTypeValue.UPGRADE_FIRMWARE_WITH_CURRENT_SETTING]: "Upgrade Firmware",
    [TaskTypeValue.UPGRADE_FIRMWARE_WITH_FACTORY_DEFAULT_SETTING]: "Upgrade Firmware (FAC-RST)",
    [TaskTypeValue.SPEED_TEST_TO_EMAIL]: "Speed Test",
    [TaskTypeValue.PROFILE_IMPLEMENT]: "Profile Implement",
    [TaskTypeValue.RECURSIVE_REPORT_TO_EMAIL]: "Recursive Report"
};

export const TASK_TYPE_MAPPING_DETAIL: TaskTypeNameMapping = {
    [TaskTypeValue.REBOOT]: "Reboot",
    [TaskTypeValue.UPGRADE_FIRMWARE_WITH_CURRENT_SETTING]: "Upgrade Firmware with Current Settings",
    [TaskTypeValue.UPGRADE_FIRMWARE_WITH_FACTORY_DEFAULT_SETTING]: "Upgrade Firmware with Factory Settings",
    [TaskTypeValue.SPEED_TEST_TO_EMAIL]: "Speed Test to Email",
    [TaskTypeValue.PROFILE_IMPLEMENT]: "Profile Implement",
    [TaskTypeValue.RECURSIVE_REPORT_TO_EMAIL]: "Recursive Report to Email"
};