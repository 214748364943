<div [ngClass]="containerClass">
    <label [ngClass]="labelClass" [for]="id">{{label}}</label>
    <div [ngClass]="inputClass">
        <div class="input-group input-group-merge" [ngClass]="{ 'is-invalid': invalid }">
            <input [type]="type" class="form-control" [ngClass]="{ 'is-invalid': invalid }" [id]="id"
                [(ngModel)]='value' [placeholder]="placeholder" [disabled]="isDisabled" (blur)="onTouch()"
                [autocomplete]="id">
            <div class="input-group-append" (click)="toggleDisplay()">
                <span class="input-group-text cursor-pointer">
                    <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': isDisplay, 'icon-eye': !isDisplay }">
                    </i>
                </span>
            </div>
        </div>
        <ng-container *ngIf="invalid && displayErrorMessage">
            <div class="invalid-feedback">
                <ng-container *ngIf="isRequired && errors?.required">{{label}} is required</ng-container>
            </div>
        </ng-container>
    </div>
</div>