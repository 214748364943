/******* Angular Resourse *******/
import { Injectable } from '@angular/core';

/******* Core Resourse *******/
import { AccessTokenPackage, AuthTokenRequestBody } from '@core/types';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable } from 'rxjs';

/******* API Resourse *******/
import { Auth } from '@api';

@Injectable({
    providedIn: 'root',
})
export class AccessTokenService {

    // public
    public currentAccessToken: Observable<string>;
    public currentRefreshToken: Observable<string>;
    public refreshFlag: boolean = false;
    public accessTokenTimeStamp: number = 0;

    // private
    private _currentAccessTokenSubject$: BehaviorSubject<string>;
    private _currentRefreshTokenSubject$: BehaviorSubject<string>;

    /**
     * Constructor
     *
     * @param {Auth} _auth
     */
    constructor(private _auth: Auth) {

        // Access Token Subject Initial
        this._currentAccessTokenSubject$ = new BehaviorSubject<string>(null);
        this.currentAccessToken = this._currentAccessTokenSubject$.asObservable();

        // Refresh Token Subject Initial
        this._currentRefreshTokenSubject$ = new BehaviorSubject<string>(null);
        this.currentRefreshToken = this._currentRefreshTokenSubject$.asObservable();
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get current value of access token
     */
    get currentAccessTokenValue() {
        return this._currentAccessTokenSubject$.value;
    }

    /**
     * Get current value of refresh token
     */
    get currentRefreshTokenValue() {
        return this._currentRefreshTokenSubject$.value;
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initial AccessToken
     * 
     * @param {AuthTokenRequestBody} accessTokenRequest
     */
    public initialAccessToken(accessTokenRequest: AuthTokenRequestBody): Observable<any> {
        this.clearAllInfo();
        sessionStorage.clear();
        return this._auth.getToken(accessTokenRequest);
    }

    /**
     * refresh AccessToken
     * 
     * @param {string} _refreshToken 
     */
    public refreshAccessToken(_refreshToken: string): Observable<any> {
        return this._auth.refreshToken({ refreshToken: _refreshToken });
    }

    /**
     * set token into storage
     * 
     * @param {AccessTokenPackage} inputData 
     */
    public setToken(inputData: AccessTokenPackage): void {

        // Set session Storage
        sessionStorage.setItem("becentralAccessToken", inputData.accessToken);
        sessionStorage.setItem("becentralRefreshToken", inputData.refreshToken);

        // Push the access token
        this._currentAccessTokenSubject$.next(inputData.accessToken);
        this._currentRefreshTokenSubject$.next(inputData.refreshToken);
    }

    /**
     * Clear the Current User Data
     */
    public clearAllInfo(): void {
        this._currentAccessTokenSubject$.next(null);
        this._currentRefreshTokenSubject$.next(null);
    }
}