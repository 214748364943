/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { GetBandwidthUsageRequestBody, GetUsagesSummaryRequestBody } from '@core/types';

@Injectable()
export class Usages extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * POST /usages/bandwidth-summary - retireve bandwidth summary data
     * 
     * @param {GetUsagesSummaryRequestBody} data
     */
    public getBandwidthSummary(data: GetUsagesSummaryRequestBody) {

        let method = "POST",
            url = "usages/bandwidth-summary",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /usages/{id}/bandwidth-usage - retireve bandwidth data for single device in row data
     * 
     * @param {string} id
     * @param {GetBandwidthUsageRequestBody} data
     */
    public getBandwidth(id: string, data: GetBandwidthUsageRequestBody) {

        let method = "POST",
            url = "usages/:id/bandwidth-usage",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /usages/{id}/get-usage - retireve bandwidth data for single device in sum
     * 
     * @param {string} id
     * @param {GetBandwidthUsageRequestBody} data
     */
    public getBandwidthInSum(id: string, data: GetBandwidthUsageRequestBody) {

        let method = "POST",
            url = "usages/:id/get-usage",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}