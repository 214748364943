import { ModelNameMap, SupportDeviceModel } from "@core/types";

export const MODEL_NAME_MAP: ModelNameMap = {
    [SupportDeviceModel.M_100]: "M100",
    [SupportDeviceModel.M_120_N]: "M120N",
    [SupportDeviceModel.M_150]: "M150",
    [SupportDeviceModel.M_300]: "M300",
    [SupportDeviceModel.M_300AX]: "M300AX",
    [SupportDeviceModel.M_500]: "M500",
    [SupportDeviceModel.M_600]: "M600",
    [SupportDeviceModel.MX_200]: "MX-200",
    [SupportDeviceModel.MX_200E]: "MX-200E",
    [SupportDeviceModel.MX_200A]: "MX-200A",
    [SupportDeviceModel.MX_200AE]: "MX-200AE",
    [SupportDeviceModel.MX_210_NP]: "MX-210NP",
    [SupportDeviceModel.EG_210_N]: "EG-210N",
    [SupportDeviceModel.MX_220]: "MX-220",
    [SupportDeviceModel.MX_220_UT]: "MX-220-UT-5G",
    [SupportDeviceModel.MX_221_P]: "MX-221P",
    [SupportDeviceModel.MX_230]: "MX-230",
    [SupportDeviceModel.MX_240]: "MX-240",
    [SupportDeviceModel.MX_241_NP]: "MX-241NP",
    [SupportDeviceModel.MX_250]: "MX-250",
    [SupportDeviceModel.MX_250E]: "MX-250E",
    [SupportDeviceModel.MX_600]: "MX-600",
    [SupportDeviceModel.MX_1000]: "MX-1000",
    [SupportDeviceModel.MX_1000E]: "MX-1000E",
    [SupportDeviceModel.MX_1200]: "MX-1200",
    [SupportDeviceModel._4500]: "4500",
    [SupportDeviceModel._4500AZ]: "4500AZ",
    [SupportDeviceModel._4520AZ]: "4520AZ",
    [SupportDeviceModel._4700]: "4700",
    [SupportDeviceModel._4700AZ]: "4700AZ",
    [SupportDeviceModel._4500NZ]: "4700NZ",
    [SupportDeviceModel._4700ZU]: "4700ZU",
    [SupportDeviceModel._4900]: "4900",
    [SupportDeviceModel._6200]: "6200",
    [SupportDeviceModel._6300VNL]: "6300VNL",
    [SupportDeviceModel._6300NEL]: "6300NEL",
    [SupportDeviceModel._6500]: "6500",
    [SupportDeviceModel._6500AL]: "6500AL",
    [SupportDeviceModel._6600]: "6600",
    [SupportDeviceModel._6800]: "6800",
    [SupportDeviceModel._6900]: "6900",
    [SupportDeviceModel._7000]: "7000",
    [SupportDeviceModel._7000_R26_W]: "7000 R26-W",
    [SupportDeviceModel._8110]: "8110",
    [SupportDeviceModel._8112]: "8112",
    [SupportDeviceModel._8230]: "8230",
    [SupportDeviceModel._8231]: "8231",
    [SupportDeviceModel._8232]: "8232",
    [SupportDeviceModel._8235]: "8235",
    [SupportDeviceModel._8355]: "8355",
    [SupportDeviceModel._8355P]: "8355P",
    [SupportDeviceModel._9900]: "9900",
    [SupportDeviceModel._8920]: "8920",
    [SupportDeviceModel._8115]: "8115"
};