import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'static-text',
    templateUrl: './static-text.component.html',
    styleUrls: ['./form.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class StaticTextComponent {

    // Public
    @Input() label: string = "";
    @Input() id: string = "";
    @Input() text: string = "";
    @Input() disabled: boolean = false;
}