<!-- Main Layout -->
<main-layout></main-layout>

<!-- block UI -->
<block-ui></block-ui>

<!-- theme customizer -->
<core-sidebar name="themeCustomizer" class="customizer d-none d-md-block" overlayClass="modal-backdrop"
	[invisibleOverlay]="false" *ngIf="coreConfig.layout.customizer">
	<a class="customizer-toggle d-flex align-items-center justify-content-center"
		(click)="toggleSidebar('themeCustomizer')">
		<span [data-feather]="'settings'" [class]="'spinner'"></span>
	</a>
	<core-theme-customizer></core-theme-customizer>
</core-sidebar>
<!-- / theme customizer -->