import { environment } from 'environments/environment';

const BASE_URL: string = environment.apiUrl;

export class APIConfig {

    private static path: string = BASE_URL;

    public static setBaseURL(url: string = environment.apiUrl): void {
        APIConfig.path = url;
    }
    
    public static getPath(): string {
        return APIConfig.path;
    }
}