export const RF_HISTORY_LOGS_HEADER: Array<string> = [
    "timestamp",
    "MAC",
    "RSRP",
    "RSRP_DIV1",
    "RSRP_DIV2",
    "RSRP_DIV3",
    "RSRQ",
    "RSRQ_DIV1",
    "RSRQ_DIV2",
    "RSRQ_DIV3",
    "RSSI",
    "RSSI_DIV1",
    "RSSI_DIV2",
    "RSSI_DIV3",
    "SINR",
    "SINR_DIV1",
    "SINR_DIV2",
    "SINR_DIV3",
    "PCI",
    "BAND",
    "CELLID",
    "RI",
    "DL_MCS",
    "CQI",
    "BW",
    "NETMODE",
    "NETWORK",
    "NR_RSRP",
    "NR_DIV_RSRP",
    "NR_DIV2_RSRP",
    "NR_DIV3_RSRP",
    "NR_RSRQ",
    "NR_SINR",
    "NR_DIV_SINR",
    "NR_DIV2_SINR",
    "NR_DIV3_SINR",
    "NR_ARFCN",
    "NR_BAND",
    "NR_BW",
    "CA_SBAND",
    "CA_SBW",
    "CA_RSSI",
    "CA_DIVRSSI",
    "CA_RSRP",
    "CA_DIVRSRP",
    "CA2_SBAND",
    "CA2_SBW",
    "CA2_RSSI",
    "CA2_DIVRSSI",
    "CA2_RSRP",
    "CA2_DIVRSRP",
    "CA3_SBAND",
    "CA3_SBW",
    "CA3_RSSI",
    "CA3_DIVRSSI",
    "CA3_RSRP",
    "CA3_DIVRSRP",
    "NR_CA_DIVRSRP",
    "NR_CA_DIVRSRQ",
    "NR_CA_DIVRSSI",
    "NR_CA_DIVSINR",
    "NR_CA_NUMBER",
    "NR_CA_RSRP",
    "NR_CA_RSRQ",
    "NR_CA_RSSI",
    "NR_CA_SBAND",
    "NR_CA_SBW",
    "NR_CA_SCHAN",
    "NR_CA_SDIR",
    "NR_CA_SINR",
    "NR_CA_STATUS",
];

export const SERIES_COLORS: string[] = [
    "#800160",
    "#3276b1",
    "#005f19",
    "#ffbf00",
    "#DB6EF5",
    "#6EE0F5",
    "#F5C36E"];

export const RF_HISTORY_COLUMN: Array<string> = ["RSRP", "RSRQ", "RSSI", "SINR"];

export const INTERVAL = 20;

export const SIGNAL_ATTRIBUTES: Array<string> = [
    "MAC", "VOLT", "CURRENT", "TEMP", "timestamp", "NR_CA_SDIR", "CELLID", "BAND", "RSRQ_DIV2", "RSRQ", "NETMODE", "SINR_DIV1", "NR_SINR", "NR_DIV_SINR", "RSRP_DIV2", "NETWORK", "RSSI_DIV2", "NR_CA_RSRP", "NR_RSRP", "NR_BAND", "NR_CA_DIVSINR", "NR_CA_SBAND", "SINR_DIV3", "NR_CA_SBW", "RSRP", "RSRQ_DIV1", "RSRQ_DIV3", "SINR_DIV2", "PCI", "RSSI_DIV1", "NR_DIV3_RSRP", "RSRP_DIV1", "NR_CA_RSSI", "RSSI_DIV3", "NR_CA_NUMBER", "NR_CA_STATUS", "NR_CA_SINR", "RSSI", "NR_CA_DIVRSRP", "RI", "NR_ARFCN", "NR_DIV_RSRP", "NR_RSRQ", "NR_BW", "NR_CA_SCHAN", "CQI", "NR_CA2_STATUS", "SINR", "BW", "NR_CA_DIVRSRQ", "DL_MCS", "RSRP_DIV3", "NR_DIV2_RSRP", "NR_CA_RSRQ", "NR_DIV2_SINR", "NR_CA_DIVRSSI", "NR_DIV3_SINR"
];