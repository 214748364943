/******* Angular Resourse *******/
import { Injectable } from '@angular/core';

/******* Core Resourse *******/
import { AlertGlobalCountings, AlertNotification, Filter, DeviceAlertType, AlertCategory, AlertNotificationLoadingCount, DeviceAlert, AlertReadingStatus, UpdateDeviceAlertRequestBody, MessageData } from '@core/types';
import { UtilsService } from './utils.service';
import { PrivilegeService } from './privilege.service';
import { GlobalUIBlockingService } from './global-ui-blocking.service';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/******* API Resourse *******/
import { DeviceAlarm } from '@api';
import { DEVICE_ALERT_TYPE_NAME_MAP } from '@core/constant';

@Injectable({
    providedIn: 'root',
})
export class AlertService {

    // public
    public currentAlertGlobalCountings: Observable<AlertGlobalCountings>;
    public currentAlert: Observable<AlertNotification>;

    // private
    private _currentAlertGlobalCountingsSubject: BehaviorSubject<AlertGlobalCountings>;
    private _currentAlertSubject: BehaviorSubject<AlertNotification>;
    private _currentDate: Date;
    private _currentAlertLoadedCount: AlertNotificationLoadingCount = {
        [AlertCategory.DEVICE]: 0,
        [AlertCategory.SIGNAL]: 0,
        [AlertCategory.LICENSE]: 0
    };

    /**
     * Constructor
     *
     * @param {DeviceAlarm} _deviceAlarm
     * @param {UtilsService} _utilsService
     * @param {PrivilegeService} _privilegeService
     * @param {GlobalUIBlockingService} _globalUIBlockingService
     */
    constructor(private _deviceAlarm: DeviceAlarm,
        private _utilsService: UtilsService,
        private _privilegeService: PrivilegeService,
        private _globalUIBlockingService: GlobalUIBlockingService) {

        // Alert Global Countings Subject Initial
        this._currentAlertGlobalCountingsSubject = new BehaviorSubject<AlertGlobalCountings>(null);
        this.currentAlertGlobalCountings = this._currentAlertGlobalCountingsSubject.asObservable()
            .pipe(tap((x) => { if (!x) this._queryAlarmCount(); }));

        // Alert Subject Initial
        this._currentAlertSubject = new BehaviorSubject<AlertNotification>(null);
        this.currentAlert = this._currentAlertSubject.asObservable();

        // Get current Data
        this._currentDate = new Date();
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get current alert global countings Value
     */
    get currentAlertGlobalCountingsValue() {
        return this._currentAlertGlobalCountingsSubject.value;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query Alarm Count
     */
    private _queryAlarmCount(resolve = null, rejects = null): void {
        this._currentDate = new Date(); // reset current data
        this._globalUIBlockingService.start();
        this._deviceAlarm.getAllAlarmCount({ status: "unread" }).subscribe((returnData) => {
            const alertCount: AlertGlobalCountings = {
                total: returnData.data.total ? returnData.data.total : 0,
                device: returnData.data.device ? returnData.data.device : 0,
                signal: returnData.data.signal ? returnData.data.signal : 0,
                hardware: returnData.data.hardware ? returnData.data.hardware : 0,
                license: returnData.data.license ? returnData.data.license : 0
            };
            this._globalUIBlockingService.stop();
            this._currentAlertGlobalCountingsSubject.next(alertCount);
            if (resolve) resolve(alertCount);
        }, (error) => {
            this._globalUIBlockingService.stop();
            this._currentAlertGlobalCountingsSubject.next({ total: 0, device: 0, signal: 0, hardware: 0, license: 0 });
            if (rejects) rejects(error);
        });
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh Alarm
     * 
     * @returns {Promise<AlertGlobalCountings>}
     */
    public refreshAlarmCount(): Promise<AlertGlobalCountings> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._queryAlarmCount(resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Load 10 more alarm
     * 
     * @param {AlertCategory} alertCategory 
     */
    public loadAlarm(alertCategory: AlertCategory): void {

        let deviceAlertTypeArray: Array<DeviceAlertType> = [];
        switch (alertCategory) {
            case AlertCategory.LICENSE:
                deviceAlertTypeArray = [
                    DeviceAlertType.LICENSE
                ];
                break;
            case AlertCategory.SIGNAL:
                deviceAlertTypeArray = [
                    DeviceAlertType.RSRP,
                    DeviceAlertType.RSRQ,
                    DeviceAlertType.SINR,
                    DeviceAlertType.RSSI,
                    DeviceAlertType.NR_RSRP,
                    DeviceAlertType.NR_RSRQ,
                    DeviceAlertType.NR_SINR
                ];
                break;
            case AlertCategory.DEVICE:
                deviceAlertTypeArray = [
                    DeviceAlertType.BANDWIDTH,
                    DeviceAlertType.CELL_BOUNCING,
                    DeviceAlertType.CONFIGURATION,
                    DeviceAlertType.DOWNTIME,
                    DeviceAlertType.ETHERNET_INTERFACE_CHAGNE,
                    DeviceAlertType.FAILOVER,
                    DeviceAlertType.FIRMWARE,
                    DeviceAlertType.IP_CHANGE,
                    DeviceAlertType.UNIT_POWERED_UP,
                    DeviceAlertType.WEB_UI_LOGIN_FAILURE,
                    DeviceAlertType.NETWORK_TECHNOLOGY_CHANGE,
                    DeviceAlertType.DRY_CONTACT,
                    DeviceAlertType.SERIAL_CONNECTOR
                ];
                break;
            default:
                deviceAlertTypeArray = [
                    DeviceAlertType.TEMPERATURE
                ];
                break;
        }

        let where: any = {
            and: [
                { timestamp: { "lte": this._currentDate.toISOString() } },
                { type: { "inq": deviceAlertTypeArray } },
                { status: "unread" }
            ]
        };

        let filter: Filter = {
            where,
            order: "id DESC",
            skip: this._currentAlertLoadedCount[alertCategory],
            limit: 10,
            include: [{
                relation: 'device',
                scope: { fields: ["id", "name", "IMSI", "FW", "RI", "DL_MCS"] }
            }]
        };

        this._deviceAlarm.getDeviceAlaram(filter).subscribe((returnData) => {
            let alertContainer = this._currentAlertSubject.value ? this._currentAlertSubject.value : { device: [], signal: [], license: [] };
            alertContainer[alertCategory] = [...alertContainer[alertCategory], ...returnData.data.map((plainAlert) => {
                return {
                    ...plainAlert,
                    description: this._utilsService.alertDescription(plainAlert.type, plainAlert.value, plainAlert.level)
                }
            })];
            this._currentAlertLoadedCount[alertCategory] = alertContainer[alertCategory].length;
            this._currentAlertSubject.next(alertContainer);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to load Alarm Data', error);
        });
    }

    /**
     * Set alert as read
     * 
     * @param {DeviceAlert} alertMessage 
     */
    public setAlertRead(alertMessage: DeviceAlert): void {
        let where = {
            id: alertMessage.id
        };

        let inputData: UpdateDeviceAlertRequestBody = {
            status: AlertReadingStatus.READ,
            timestamp: alertMessage.timestamp,
            deviceId: alertMessage.deviceId
        };

        this._deviceAlarm.updateDeviceAlarm(where, inputData)
            .subscribe(() => { }, (error) => { this._utilsService.alert("error", "Fail to set read", error); })
    }

    /**
     * Set all alert as read
     */
    public readAllAlerts(): void {
        this._deviceAlarm.readAllDeviceAlarm()
            .subscribe(() => {
                this._queryAlarmCount();
            }, (error) => { this._utilsService.alert("error", "Fail to set read", error); })
    }

    /**
     * Clear the Current Alert Data
     */
    public clearAllInfo(): void {
        this._currentAlertGlobalCountingsSubject.next(null);
        this._currentAlertSubject.next(null);
    }

    /**
     * Update from live alert
     * 
     * @param {MessageData} deviceAlarmMessage 
     */
    public updateFromLiveAlert(deviceAlarmMessage: MessageData): void {
        const alarms = Array.isArray(deviceAlarmMessage.data) ? deviceAlarmMessage.data : [deviceAlarmMessage.data];
        const timestamp = new Date().toISOString();
        let tempAlertCount = this._currentAlertGlobalCountingsSubject.value;
        let tempAlertContainer = this._currentAlertSubject.value;
        if (!tempAlertCount || !tempAlertContainer) return; // block the set before load
        tempAlertCount.total += alarms.length;
        alarms.forEach((alarm) => {
            switch (alarm.type) {
                case DeviceAlertType.LICENSE:
                    tempAlertCount.license++;
                    tempAlertContainer?.license.unshift({
                        ...alarm,
                        timestamp,
                        description: this._utilsService.alertDescription(alarm.type, alarm.value, alarm.level)
                    });
                    break;
                case DeviceAlertType.RSRP:
                case DeviceAlertType.RSRQ:
                case DeviceAlertType.SINR:
                case DeviceAlertType.RSSI:
                case DeviceAlertType.NR_RSRP:
                case DeviceAlertType.NR_RSRQ:
                case DeviceAlertType.NR_SINR:
                    tempAlertCount.signal++;
                    tempAlertContainer?.signal.unshift({
                        ...alarm,
                        timestamp,
                        description: this._utilsService.alertDescription(alarm.type, alarm.value, alarm.level)
                    });
                    break;
                case DeviceAlertType.BANDWIDTH:
                case DeviceAlertType.CELL_BOUNCING:
                case DeviceAlertType.CONFIGURATION:
                case DeviceAlertType.DOWNTIME:
                case DeviceAlertType.ETHERNET_INTERFACE_CHAGNE:
                case DeviceAlertType.FAILOVER:
                case DeviceAlertType.FIRMWARE:
                case DeviceAlertType.IP_CHANGE:
                case DeviceAlertType.UNIT_POWERED_UP:
                case DeviceAlertType.WEB_UI_LOGIN_FAILURE:
                case DeviceAlertType.DRY_CONTACT:
                case DeviceAlertType.SERIAL_CONNECTOR:
                case DeviceAlertType.NETWORK_TECHNOLOGY_CHANGE:
                    tempAlertCount.device++;
                    tempAlertContainer?.device.unshift({
                        ...alarm,
                        timestamp,
                        description: this._utilsService.alertDescription(alarm.type, alarm.value, alarm.level)
                    });
                    break;
                default:
                    tempAlertCount.hardware++;
                    break;
            }
            // Avoid keep poping alert when all alerts are on for tracking
            const userValue = this._privilegeService.currentUserValue;
            if (userValue.preferenceSettings?.AlertPopUp)
                this._utilsService.alert('warning', 'Alert Trigger', DEVICE_ALERT_TYPE_NAME_MAP[alarm.type]);
        });
        this._currentAlertGlobalCountingsSubject.next(tempAlertCount);
        this._currentAlertSubject.next(tempAlertContainer);
    }
}