import { RolePermission } from "./role-permissions";

export enum TenantType {
    ISP = "ISP",
    ISP_PLUS = "ISP-Plus",
    ISP_PRO = "ISP-Pro",
    MSP_PARTNER_ENTERPRISE = "partner",
    EVAL = "eval",
    DEFAULT = "root",
    TRIAL = "trial"
};

export type TentTypeNameMap = {
    [key in TenantType]: string
};

export type TentTypeBlockPermisson = {
    [key in TenantType]: Array<RolePermission>
};