import { Directive, ElementRef } from '@angular/core';


@Directive({
  selector: '[clickAndDragScrolling]'
})
export class ClickAndDragScrollingDirective {
    
    // Private

    // Native Element ref
    private _nativeElement: any;

    // is Down check
    private isDown: boolean = false;

    // start X offset
    private startX: any;

    // X scroll Left
    private scrollLeft: any;

    /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     */
    constructor(private _elementRef: ElementRef) {}

    ngOnInit(): void {
        
        // Get the native element
        this._nativeElement = this._elementRef.nativeElement;
        
        // Drop the pin
        this._nativeElement.addEventListener('mousedown', (e) => {this.pinMouse(e)});

        // Move the mouse
        this._nativeElement.addEventListener('mousemove', (e) => {this.moveMouse(e)});

        // Release Mouse Event
        this._nativeElement.addEventListener('mouseup', () => {this.releaseMouse()});
        this._nativeElement.addEventListener('mouseleave', () => {this.releaseMouse()});
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Pin the Mouse
     * 
     * @param e event
     */
    private pinMouse(e) : void {
        
        // set Down true
        this.isDown = true;

        // add active class
        this._nativeElement.classList.add('active');

        // set Start X
        this.startX = e.pageX - this._nativeElement.offsetLeft;

        // set Scroll Left
        this.scrollLeft = this._nativeElement.scrollLeft;
    }

    /**
     * Move the Mouse
     * 
     * @param e event
     */
    private moveMouse(e) : void {
        
        // check Down true
        if(!this.isDown) return;

        e.preventDefault();

        const x = e.pageX - this._nativeElement.offsetLeft;
        
        const walk = (x - this.startX) * 1; //scroll-fast
        
        this._nativeElement.scrollLeft = this.scrollLeft - walk;
    }

    /**
     * Release Mouse
     */
    private releaseMouse() : void {
        
        // set Down false
        this.isDown = false;

        // remove active class
        this._nativeElement.classList.remove('active');
    }


}