/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { CreateProfileTemplateRequestBody } from '@core/types/profile-template-types/profile-template-request-body';

@Injectable()
export class ProfileTemplate extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /profile-template - get profile template list 
     */
    public getProfileTemplateList() {
        return this.find("profile-template", { order: "lastUpdatedAt DESC" });
    }

    /**
     * POST /profile-template - create an profile template
     * 
     * @param {CreateProfileTemplateRequestBody} data
     */
    public createProfileTemplate(data: CreateProfileTemplateRequestBody) {

        let method = "POST",
            url = "profile-template",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /profile-template/{id} - update an profile template
     * 
     * @param {string} id 
     * @param {CreateProfileTemplateRequestBody} data 
     */
    public updateProfileTemplate(id: string, data: CreateProfileTemplateRequestBody) {

        let method = "PATCH",
            url = "profile-template/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /profile-template/{id} - delete profile template by id
     * 
     * @param {string} id 
     */
    public deleteProfileTemplate(id: string) {

        let method = "DELETE",
            url = "profile-template/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}