import { AlertPolicy } from "@core/types";

export const ALERT_POLICY_INITIAL: AlertPolicy = {
    email: {
        enable: false,
        threshold: 1,
        recipients: [],
        note: ""
    },
    sms: {
        enable: false,
        threshold: 1,
        recipients: []
    },
    call: {
        enable: false,
        threshold: 1,
        recipients: []
    },
    downtime: {
        enable: false,
        threshold: 5
    },
    reboot: false,
    failover: false,
    updateFW: false,
    updateConfig: false,
    ipChange: false,
    ethernetInterfaceChange: false,
    webUILoginFailure: false,
    networkTechnologyChange: false,
    dryContact: false,
    serialConnector: false,
    bandwidth: {
        enable: false,
        /* startDay: "",
        usageThreshold: 1,
        usageThresholdUnit: "MB",
        alertInterval: 14400,
        usageThresholdPercentage: [] */
    },
    RSRP: {
        enable: false,
        caution: -80,
        alert: -100
    },
    RSSI: {
        enable: false,
        caution: -80,
        alert: -100
    },
    SINR: {
        enable: false,
        caution: 10,
        alert: 5
    },
    RSRQ: {
        enable: false,
        caution: -15,
        alert: -19
    },
    NR_RSRP: {
        enable: false,
        caution: -80,
        alert: -100
    },
    NR_SINR: {
        enable: false,
        caution: 10,
        alert: 5
    },
    NR_RSRQ: {
        enable: false,
        caution: -15,
        alert: -19
    },
    temperature: {
        enable: false,
        caution: 53,
        alert: 60
    },
    cellBouncing: {
        enable: false,
        caution: 5,
        alert: 10
    }
}