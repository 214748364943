/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Signal } from '@core/types';

@Injectable()
export class RfStandard extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /rf-standard - get rf standard
     */
    public getRfStandard() {
        return this.find("rf-standard", {});
    }

    /**
     * POST /rf-standard - create / edit rf standard
     * 
     * @param {Signal} data 
     */
    public updateRfStandard(data: Signal) {

        let method = "POST",
            url = "rf-standard",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}