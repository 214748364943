import { TenantType } from "./tenant-type";

export interface MenuPermissions {
    DASHBOARDS?: boolean;
    ANALYTICS?: boolean;
    CELLANALYSIS?: boolean;
    DEVICES?: boolean;
    ALERTS?: boolean;
    HISTORY?: boolean;
    EMAILTEMPLATE?: boolean;
    SCHEDULER?: boolean;
    GROUPBUILDER?: boolean;
    PROFILETEMPLATE?: boolean;
    MAP?: boolean;
    ACCOUNTSETTINGS?: boolean;
    RESTAPI?: boolean;
    LOGS?: boolean;
    AUDITLOGS?: boolean;
    DOMAINPROXYLOGS?: boolean;
};

export type AccountTypePermissions = {
    [key in TenantType]: MenuPermissions
};