export interface ServerToClientEvents {
    // Topic: callback
};

export interface ClientToServerEvents {
    // Topic: callback
};

export enum MessageType {
    DEVICE = "Device",
    DEVICE_ALARM = "DeviceAlarm",
    DEVICE_CONNECTION = "DeviceConnection",
    SChEDULER_STATUS = "Scheduler:Status",
    REPORT_STATUS = "ReportScheduler:Status",
    LICENSES_PERCENTAGE = "LicensesPercentage"
};

export interface MessageData {
    eventName: string;
    type: MessageType;
    data?: any;
};