/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { BatchUpdateDeviceRequestBody, DeleteDeviceRequestBody, LicenseStatusValue, UpdateConfigRequestBody, UpdateDataUsageSettingsRequestBody, UpdateDeviceRequestBody } from '@core/types';

@Injectable()
export class Devices extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /devices - get devices list
     * 
     * @param filter
     */
    public getDevices(filter) {
        return this.find("devices", filter);
    }

    /**
     * GET /devices/count - get devices list count
     * 
     * @param where
     */
    public getDevicesCount(where) {
        return this.count("devices", where);
    }

    /**
     * GET /devices/{id} - get single device information by id
     * 
     * @param id
     */
    public getDeviceInfo(id) {
        return this.findById("devices", id);
    }

    /**
     * GET /devices/{MAC}/find-cell-tower - get cell tower data by MAC
     * 
     * @param {string} MAC
     */
    public getCellDataByMac(MAC: string) {

        let method = "GET",
            url = "devices/:MAC/find-cell-tower",
            routeParams = { MAC },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /devices - delete device(s)
     * 
     * @param {DeleteDeviceRequestBody} data
     */
    public deleteDeviceInBatch(data: DeleteDeviceRequestBody) {

        let method = "DELETE_WITH_BODY",
            url = "devices",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /devices/{id} - update single device information by id
     * 
     * @param {string} id
     * @param {UpdateDeviceRequestBody} data
     */
    public updateDevice(id: string, data: UpdateDeviceRequestBody) {

        let method = "PATCH",
            url = "devices/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{id}/configuration - get configuration by id
     * 
     * @param {string} id
     */
    public getConfiguration(id: string) {

        let method = "GET",
            url = "devices/:id/configuration",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /devices/{id}/configuration - set configuration by id
     * 
     * @param {string} id
     * @param {UpdateConfigRequestBody} data
     */
    public setConfiguration(id: string, data: UpdateConfigRequestBody) {

        let method = "PATCH",
            url = "devices/:id/configuration",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{id}/configuration/sync - forcing pulling configuration from device
     * 
     * @param {string} id
     */
    public getConfigurationFromLocal(id: string) {

        let method = "GET",
            url = "devices/:id/configuration/sync",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/devCarrierSummary - get connected devices by carrier
     */
    public getConnectedDeviceByCarrier() {

        let method = "GET",
            url = "devices/devCarrierSummary",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/devModelSummary - get connected devices by model
     */
    public getConnectedDeviceByModel() {

        let method = "GET",
            url = "devices/devModelSummary",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/devNetModeSummary - get connected devices by network type
     */
    public getConnectedDeviceByNetworkType() {

        let method = "GET",
            url = "devices/devNetModeSummary",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/revisionStats - get connected devices by radio type
     */
    public getConnectedDeviceByRadioType() {

        let method = "GET",
            url = "devices/revisionStats",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{attr}/unique - get unique value in devices
     * 
     * @param attr
     */
    public getUniqueKey(attr: string) {

        let method = "GET",
            url = "devices/:attr/unique",
            routeParams = { attr },
            urlParams = {
                filter: {
                    "where": {
                        "$and": [
                            { "connected": { "$in": [true, false] } },
                            { "licenseStatus": LicenseStatusValue.ACTIVE },
                            { "MODEL": { "$nin": [null, ""] } },
                            { "MAC": { "$nin": [null, ""] } }
                        ]
                    }
                }
            },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/tags - get devices list with tags
     * 
     * @param filter
     */
    public getDevicesWithTags(filter: any) {

        let method = "GET",
            url = "devices/tags",
            routeParams = {},
            urlParams = { filter },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/tags/count - get devices list with tags
     * 
     * @param filter
     */
    public getDevicesWithTagsCount(filter: any) {

        let method = "GET",
            url = "devices/tags/count",
            routeParams = {},
            urlParams = { filter },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/cell-analysis/count - get count in cell analysis of devices
     */
    public getDevicesCellAnalysisCount() {

        let method = "GET",
            url = "devices/cell-analysis/count",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/cell-analysis - get cell analysis of devices
     * 
     * @param filter
     */
    public getDevicesCellAnalysis(filter: any) {

        let method = "GET",
            url = "devices/cell-analysis",
            routeParams = {},
            urlParams = { filter },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{id}/getTrackDataUsages - get device data usage
     * 
     * @param {string} id 
     */
    public getDeviceDataUsage(id: string) {

        let method = "GET",
            url = "devices/:id/getTrackDataUsages",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /alert-config/{id}/updateDataUsageSetting - update device data usage settings
     * 
     * @param {string} id
     * @param {UpdateDataUsageSettingsRequestBody} data
     */
    public updateDeviceDataUsage(id: string, data: UpdateDataUsageSettingsRequestBody) {

        let method = "POST",
            url = "alert-config/:id/updateDataUsageSetting",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{MAC}/speedtest/count - get speed test records count
     * 
     * @param {string} MAC 
     */
    public getDeviceSpeedTestRecordsCount(MAC: string) {

        let method = "GET",
            url = "devices/:MAC/speedtest/count",
            routeParams = { MAC },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{MAC}/speedtest - get speed test records 
     * 
     * @param {string} MAC 
     * @param filter
     */
    public getDeviceSpeedTestRecords(MAC: string, filter: any) {

        let method = "GET",
            url = "devices/:MAC/speedtest",
            routeParams = { MAC },
            urlParams = { filter },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /devices/{MAC}/speedtest - remove all speed test records by MAC
     * 
     * @param {string} MAC
     */
    public deleteDeviceSpeedTestRecords(MAC: string) {

        let method = "DELETE",
            url = "devices/:MAC/speedtest",
            routeParams = { MAC },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{MAC}/histories - get rf histories 
     * 
     * @param {string} MAC 
     * @param filter
     */
    public getDeviceRfHistories(MAC: string, startDate: string, endDate: string) {

        let method = "GET",
            url = "devices/:MAC/histories",
            routeParams = { MAC },
            urlParams = { startDate, endDate },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /devices/{MAC}/remote-access - get remote-access from device 
     * 
     * @param {string} MAC
     */
    public getDeviceRemoteAccess(MAC: string) {

        let method = "GET",
            url = "devices/:MAC/remote-access",
            routeParams = { MAC },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /devices/{MAC}/remote-access - disable remote-access from device 
     * 
     * @param {string} MAC
     */
    public deleteDeviceRemoteAccess(MAC: string) {

        let method = "DELETE",
            url = "devices/:MAC/remote-access",
            routeParams = { MAC },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /devices - update multiple device information
     * 
     * @param {BatchUpdateDeviceRequestBody} data
     */
    public batchUpdateDevice(data: BatchUpdateDeviceRequestBody) {

        let method = "PATCH",
            url = "devices",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

}