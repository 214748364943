import { TimeZone, TimeZoneOffsetMap } from "@core/types";

export const TIME_ZONE_OFFSET: TimeZoneOffsetMap = {
    [TimeZone.PACIFIC_MIDWAY]: -660,
    [TimeZone.PACIFIC_NIUE]: -660,
    [TimeZone.PACIFIC_PAGO_PAGO]: -660,
    [TimeZone.PACIFIC_SAMOA]: -660,
    [TimeZone.US_SAMOA]: -660,
    [TimeZone.AMERICA_ADAK]: -600,
    [TimeZone.AMERICA_ATKA]: -600,
    [TimeZone.PACIFIC_HONOLULU]: -600,
    [TimeZone.PACIFIC_JOHNSTON]: -600,
    [TimeZone.PACIFIC_RAROTONGA]: -600,
    [TimeZone.PACIFIC_TAHITI]: -600,
    [TimeZone.US_ALEUTIAN]: -600,
    [TimeZone.US_HAWAII]: -600,
    [TimeZone.PACIFIC_MARQUESAS]: -510,
    [TimeZone.AMERICA_ANCHORAGE]: -540,
    [TimeZone.AMERICA_JUNEAU]: -540,
    [TimeZone.AMERICA_METLAKATLA]: -540,
    [TimeZone.AMERICA_NOME]: -540,
    [TimeZone.AMERICA_SITKA]: -540,
    [TimeZone.AMERICA_YAKUTAT]: -540,
    [TimeZone.PACIFIC_GAMBIER]: -540,
    [TimeZone.US_ALASKA]: -540,
    [TimeZone.AMERICA_ENSENADA]: -480,
    [TimeZone.AMERICA_LOS_ANGELES]: -480,
    [TimeZone.AMERICA_SANTA_ISABEL]: -480,
    [TimeZone.AMERICA_TIJUANA]: -480,
    [TimeZone.AMERICA_VANCOUVER]: -480,
    [TimeZone.CANADA_PACIFIC]: -480,
    [TimeZone.MEXICO_BAJANORTE]: -480,
    [TimeZone.PACIFIC_PITCAIRN]: -480,
    [TimeZone.US_PACIFIC]: -480,
    [TimeZone.AMERICA_BOISE]: -420,
    [TimeZone.AMERICA_CAMBRIDGE_BAY]: -420,
    [TimeZone.AMERICA_CIUDAD_JUAREZ]: -420,
    [TimeZone.AMERICA_CRESTON]: -420,
    [TimeZone.AMERICA_DAWSON]: -420,
    [TimeZone.AMERICA_DAWSON_CREEK]: -420,
    [TimeZone.AMERICA_DENVER]: -420,
    [TimeZone.AMERICA_EDMONTON]: -420,
    [TimeZone.AMERICA_FORT_NELSON]: -420,
    [TimeZone.AMERICA_HERMOSILLO]: -420,
    [TimeZone.AMERICA_INUVIK]: -420,
    [TimeZone.AMERICA_MAZATLAN]: -420,
    [TimeZone.AMERICA_PHOENIX]: -420,
    [TimeZone.AMERICA_SHIPROCK]: -420,
    [TimeZone.AMERICA_WHITEHORSE]: -420,
    [TimeZone.AMERICA_YELLOWKNIFE]: -420,
    [TimeZone.CANADA_MOUNTAIN]: -420,
    [TimeZone.CANADA_YUKON]: -420,
    [TimeZone.MEXICO_BAJASUR]: -420,
    [TimeZone.US_ARIZONA]: -420,
    [TimeZone.US_MOUNTAIN]: -420,
    [TimeZone.AMERICA_BAHIA_BANDERAS]: -360,
    [TimeZone.AMERICA_BELIZE]: -360,
    [TimeZone.AMERICA_CHICAGO]: -360,
    [TimeZone.AMERICA_CHIHUAHUA]: -360,
    [TimeZone.AMERICA_COSTA_RICA]: -360,
    [TimeZone.AMERICA_EL_SALVADOR]: -360,
    [TimeZone.AMERICA_GUATEMALA]: -360,
    [TimeZone.AMERICA_KNOX_IN]: -360,
    [TimeZone.AMERICA_MANAGUA]: -360,
    [TimeZone.AMERICA_MATAMOROS]: -360,
    [TimeZone.AMERICA_MENOMINEE]: -360,
    [TimeZone.AMERICA_MERIDA]: -360,
    [TimeZone.AMERICA_MEXICO_CITY]: -360,
    [TimeZone.AMERICA_MONTERREY]: -360,
    [TimeZone.AMERICA_OJINAGA]: -360,
    [TimeZone.AMERICA_RAINY_RIVER]: -360,
    [TimeZone.AMERICA_RANKIN_INLET]: -360,
    [TimeZone.AMERICA_REGINA]: -360,
    [TimeZone.AMERICA_RESOLUTE]: -360,
    [TimeZone.AMERICA_SWIFT_CURRENT]: -360,
    [TimeZone.AMERICA_TEGUCIGALPA]: -360,
    [TimeZone.AMERICA_WINNIPEG]: -360,
    [TimeZone.CANADA_CENTRAL]: -360,
    [TimeZone.CANADA_SASKATCHEWAN]: -360,
    [TimeZone.MEXICO_GENERAL]: -360,
    [TimeZone.PACIFIC_GALAPAGOS]: -360,
    [TimeZone.US_CENTRAL]: -360,
    [TimeZone.US_INDIANA_STARKE]: -360,
    [TimeZone.AMERICA_ATIKOKAN]: -300,
    [TimeZone.AMERICA_BOGOTA]: -300,
    [TimeZone.AMERICA_CANCUN]: -300,
    [TimeZone.AMERICA_CAYMAN]: -300,
    [TimeZone.AMERICA_CORAL_HARBOUR]: -300,
    [TimeZone.AMERICA_DETROIT]: -300,
    [TimeZone.AMERICA_EIRUNEPE]: -300,
    [TimeZone.AMERICA_FORT_WAYNE]: -300,
    [TimeZone.AMERICA_GRAND_TURK]: -300,
    [TimeZone.AMERICA_GUAYAQUIL]: -300,
    [TimeZone.AMERICA_HAVANA]: -300,
    [TimeZone.AMERICA_INDIANAPOLIS]: -300,
    [TimeZone.AMERICA_IQALUIT]: -300,
    [TimeZone.AMERICA_JAMAICA]: -300,
    [TimeZone.AMERICA_LIMA]: -300,
    [TimeZone.AMERICA_LOUISVILLE]: -300,
    [TimeZone.AMERICA_MONTREAL]: -300,
    [TimeZone.AMERICA_NASSAU]: -300,
    [TimeZone.AMERICA_NEW_YORK]: -300,
    [TimeZone.AMERICA_NIPIGON]: -300,
    [TimeZone.AMERICA_PANAMA]: -300,
    [TimeZone.AMERICA_PANGNIRTUNG]: -300,
    [TimeZone.AMERICA_PORT_AU_PRINCE]: -300,
    [TimeZone.AMERICA_PORTO_ACRE]: -300,
    [TimeZone.AMERICA_RIO_BRANCO]: -300,
    [TimeZone.AMERICA_THUNDER_BAY]: -300,
    [TimeZone.AMERICA_TORONTO]: -300,
    [TimeZone.BRAZIL_ACRE]: -300,
    [TimeZone.CANADA_EASTERN]: -300,
    [TimeZone.CHILE_EASTERISLAND]: -300,
    [TimeZone.PACIFIC_EASTER]: -300,
    [TimeZone.US_EAST_INDIANA]: -300,
    [TimeZone.US_EASTERN]: -300,
    [TimeZone.US_MICHIGAN]: -300,
    [TimeZone.AMERICA_ANGUILLA]: -240,
    [TimeZone.AMERICA_ANTIGUA]: -240,
    [TimeZone.AMERICA_ARUBA]: -240,
    [TimeZone.AMERICA_BARBADOS]: -240,
    [TimeZone.AMERICA_BLANC_SABLON]: -240,
    [TimeZone.AMERICA_BOA_VISTA]: -240,
    [TimeZone.AMERICA_CAMPO_GRANDE]: -240,
    [TimeZone.AMERICA_CARACAS]: -240,
    [TimeZone.AMERICA_CUIABA]: -240,
    [TimeZone.AMERICA_CURACAO]: -240,
    [TimeZone.AMERICA_DOMINICA]: -240,
    [TimeZone.AMERICA_GLACE_BAY]: -240,
    [TimeZone.AMERICA_GOOSE_BAY]: -240,
    [TimeZone.AMERICA_GRENADA]: -240,
    [TimeZone.AMERICA_GUADELOUPE]: -240,
    [TimeZone.AMERICA_GUYANA]: -240,
    [TimeZone.AMERICA_HALIFAX]: -240,
    [TimeZone.AMERICA_KRALENDIJK]: -240,
    [TimeZone.AMERICA_LA_PAZ]: -240,
    [TimeZone.AMERICA_LOWER_PRINCES]: -240,
    [TimeZone.AMERICA_MANAUS]: -240,
    [TimeZone.AMERICA_MARIGOT]: -240,
    [TimeZone.AMERICA_MARTINIQUE]: -240,
    [TimeZone.AMERICA_MONCTON]: -240,
    [TimeZone.AMERICA_MONTSERRAT]: -240,
    [TimeZone.AMERICA_PORT_OF_SPAIN]: -240,
    [TimeZone.AMERICA_PORTO_VELHO]: -240,
    [TimeZone.AMERICA_PUERTO_RICO]: -240,
    [TimeZone.AMERICA_SANTO_DOMINGO]: -240,
    [TimeZone.AMERICA_ST_BARTHELEMY]: -240,
    [TimeZone.AMERICA_ST_KITTS]: -240,
    [TimeZone.AMERICA_ST_LUCIA]: -240,
    [TimeZone.AMERICA_ST_THOMAS]: -240,
    [TimeZone.AMERICA_ST_VINCENT]: -240,
    [TimeZone.AMERICA_THULE]: -240,
    [TimeZone.AMERICA_TORTOLA]: -240,
    [TimeZone.AMERICA_VIRGIN]: -240,
    [TimeZone.ATLANTIC_BERMUDA]: -240,
    [TimeZone.BRAZIL_WEST]: -240,
    [TimeZone.CANADA_ATLANTIC]: -240,
    [TimeZone.AMERICA_ST_JOHNS]: -150,
    [TimeZone.CANADA_NEWFOUNDLAND]: -150,
    [TimeZone.AMERICA_ARAGUAINA]: -180,
    [TimeZone.AMERICA_ASUNCION]: -180,
    [TimeZone.AMERICA_BAHIA]: -180,
    [TimeZone.AMERICA_BELEM]: -180,
    [TimeZone.AMERICA_BUENOS_AIRES]: -180,
    [TimeZone.AMERICA_CATAMARCA]: -180,
    [TimeZone.AMERICA_CAYENNE]: -180,
    [TimeZone.AMERICA_CORDOBA]: -180,
    [TimeZone.AMERICA_FORTALEZA]: -180,
    [TimeZone.AMERICA_GODTHAB]: -180,
    [TimeZone.AMERICA_JUJUY]: -180,
    [TimeZone.AMERICA_MACEIO]: -180,
    [TimeZone.AMERICA_MENDOZA]: -180,
    [TimeZone.AMERICA_MIQUELON]: -180,
    [TimeZone.AMERICA_MONTEVIDEO]: -180,
    [TimeZone.AMERICA_NUUK]: -180,
    [TimeZone.AMERICA_PARAMARIBO]: -180,
    [TimeZone.AMERICA_PUNTA_ARENAS]: -180,
    [TimeZone.AMERICA_RECIFE]: -180,
    [TimeZone.AMERICA_ROSARIO]: -180,
    [TimeZone.AMERICA_SANTAREM]: -180,
    [TimeZone.AMERICA_SANTIAGO]: -180,
    [TimeZone.AMERICA_SAO_PAULO]: -180,
    [TimeZone.ANTARCTICA_PALMER]: -180,
    [TimeZone.ANTARCTICA_ROTHERA]: -180,
    [TimeZone.ATLANTIC_STANLEY]: -180,
    [TimeZone.BRAZIL_EAST]: -180,
    [TimeZone.CHILE_CONTINENTAL]: -180,
    [TimeZone.AMERICA_NORONHA]: -120,
    [TimeZone.ATLANTIC_SOUTH_GEORGIA]: -120,
    [TimeZone.BRAZIL_DENORONHA]: -120,
    [TimeZone.AMERICA_SCORESBYSUND]: -60,
    [TimeZone.ATLANTIC_AZORES]: -60,
    [TimeZone.ATLANTIC_CAPE_VERDE]: -60,
    [TimeZone.AFRICA_ABIDJAN]: 0,
    [TimeZone.AFRICA_ACCRA]: 0,
    [TimeZone.AFRICA_BAMAKO]: 0,
    [TimeZone.AFRICA_BANJUL]: 0,
    [TimeZone.AFRICA_BISSAU]: 0,
    [TimeZone.AFRICA_CONAKRY]: 0,
    [TimeZone.AFRICA_DAKAR]: 0,
    [TimeZone.AFRICA_FREETOWN]: 0,
    [TimeZone.AFRICA_LOME]: 0,
    [TimeZone.AFRICA_MONROVIA]: 0,
    [TimeZone.AFRICA_NOUAKCHOTT]: 0,
    [TimeZone.AFRICA_OUAGADOUGOU]: 0,
    [TimeZone.AFRICA_SAO_TOME]: 0,
    [TimeZone.AFRICA_TIMBUKTU]: 0,
    [TimeZone.AMERICA_DANMARKSHAVN]: 0,
    [TimeZone.ANTARCTICA_TROLL]: 0,
    [TimeZone.ATLANTIC_CANARY]: 0,
    [TimeZone.ATLANTIC_FAEROE]: 0,
    [TimeZone.ATLANTIC_FAROE]: 0,
    [TimeZone.ATLANTIC_MADEIRA]: 0,
    [TimeZone.ATLANTIC_REYKJAVIK]: 0,
    [TimeZone.ATLANTIC_ST_HELENA]: 0,
    [TimeZone.EUROPE_BELFAST]: 0,
    [TimeZone.EUROPE_DUBLIN]: 0,
    [TimeZone.EUROPE_GUERNSEY]: 0,
    [TimeZone.EUROPE_ISLE_OF_MAN]: 0,
    [TimeZone.EUROPE_JERSEY]: 0,
    [TimeZone.EUROPE_LISBON]: 0,
    [TimeZone.EUROPE_LONDON]: 0,
    [TimeZone.AFRICA_ALGIERS]: 60,
    [TimeZone.AFRICA_BANGUI]: 60,
    [TimeZone.AFRICA_BRAZZAVILLE]: 60,
    [TimeZone.AFRICA_CASABLANCA]: 60,
    [TimeZone.AFRICA_CEUTA]: 60,
    [TimeZone.AFRICA_DOUALA]: 60,
    [TimeZone.AFRICA_EL_AAIUN]: 60,
    [TimeZone.AFRICA_KINSHASA]: 60,
    [TimeZone.AFRICA_LAGOS]: 60,
    [TimeZone.AFRICA_LIBREVILLE]: 60,
    [TimeZone.AFRICA_LUANDA]: 60,
    [TimeZone.AFRICA_MALABO]: 60,
    [TimeZone.AFRICA_NDJAMENA]: 60,
    [TimeZone.AFRICA_NIAMEY]: 60,
    [TimeZone.AFRICA_PORTO_NOVO]: 60,
    [TimeZone.AFRICA_TUNIS]: 60,
    [TimeZone.ARCTIC_LONGYEARBYEN]: 60,
    [TimeZone.ATLANTIC_JAN_MAYEN]: 60,
    [TimeZone.EUROPE_AMSTERDAM]: 60,
    [TimeZone.EUROPE_ANDORRA]: 60,
    [TimeZone.EUROPE_BELGRADE]: 60,
    [TimeZone.EUROPE_BERLIN]: 60,
    [TimeZone.EUROPE_BRATISLAVA]: 60,
    [TimeZone.EUROPE_BRUSSELS]: 60,
    [TimeZone.EUROPE_BUDAPEST]: 60,
    [TimeZone.EUROPE_BUSINGEN]: 60,
    [TimeZone.EUROPE_COPENHAGEN]: 60,
    [TimeZone.EUROPE_GIBRALTAR]: 60,
    [TimeZone.EUROPE_LJUBLJANA]: 60,
    [TimeZone.EUROPE_LUXEMBOURG]: 60,
    [TimeZone.EUROPE_MADRID]: 60,
    [TimeZone.EUROPE_MALTA]: 60,
    [TimeZone.EUROPE_MONACO]: 60,
    [TimeZone.EUROPE_OSLO]: 60,
    [TimeZone.EUROPE_PARIS]: 60,
    [TimeZone.EUROPE_PODGORICA]: 60,
    [TimeZone.EUROPE_PRAGUE]: 60,
    [TimeZone.EUROPE_ROME]: 60,
    [TimeZone.EUROPE_SAN_MARINO]: 60,
    [TimeZone.EUROPE_SARAJEVO]: 60,
    [TimeZone.EUROPE_SKOPJE]: 60,
    [TimeZone.EUROPE_STOCKHOLM]: 60,
    [TimeZone.EUROPE_TIRANE]: 60,
    [TimeZone.EUROPE_VADUZ]: 60,
    [TimeZone.EUROPE_VATICAN]: 60,
    [TimeZone.EUROPE_VIENNA]: 60,
    [TimeZone.EUROPE_WARSAW]: 60,
    [TimeZone.EUROPE_ZAGREB]: 60,
    [TimeZone.EUROPE_ZURICH]: 60,
    [TimeZone.AFRICA_BLANTYRE]: 120,
    [TimeZone.AFRICA_BUJUMBURA]: 120,
    [TimeZone.AFRICA_CAIRO]: 120,
    [TimeZone.AFRICA_GABORONE]: 120,
    [TimeZone.AFRICA_HARARE]: 120,
    [TimeZone.AFRICA_JOHANNESBURG]: 120,
    [TimeZone.AFRICA_JUBA]: 120,
    [TimeZone.AFRICA_KHARTOUM]: 120,
    [TimeZone.AFRICA_KIGALI]: 120,
    [TimeZone.AFRICA_LUBUMBASHI]: 120,
    [TimeZone.AFRICA_LUSAKA]: 120,
    [TimeZone.AFRICA_MAPUTO]: 120,
    [TimeZone.AFRICA_MASERU]: 120,
    [TimeZone.AFRICA_MBABANE]: 120,
    [TimeZone.AFRICA_TRIPOLI]: 120,
    [TimeZone.AFRICA_WINDHOEK]: 120,
    [TimeZone.ASIA_BEIRUT]: 120,
    [TimeZone.ASIA_FAMAGUSTA]: 120,
    [TimeZone.ASIA_GAZA]: 120,
    [TimeZone.ASIA_HEBRON]: 120,
    [TimeZone.ASIA_JERUSALEM]: 120,
    [TimeZone.ASIA_NICOSIA]: 120,
    [TimeZone.ASIA_TEL_AVIV]: 120,
    [TimeZone.EUROPE_ATHENS]: 120,
    [TimeZone.EUROPE_BUCHAREST]: 120,
    [TimeZone.EUROPE_CHISINAU]: 120,
    [TimeZone.EUROPE_HELSINKI]: 120,
    [TimeZone.EUROPE_KALININGRAD]: 120,
    [TimeZone.EUROPE_KIEV]: 120,
    [TimeZone.EUROPE_KYIV]: 120,
    [TimeZone.EUROPE_MARIEHAMN]: 120,
    [TimeZone.EUROPE_NICOSIA]: 120,
    [TimeZone.EUROPE_RIGA]: 120,
    [TimeZone.EUROPE_SOFIA]: 120,
    [TimeZone.EUROPE_TALLINN]: 120,
    [TimeZone.EUROPE_TIRASPOL]: 120,
    [TimeZone.EUROPE_UZHGOROD]: 120,
    [TimeZone.EUROPE_VILNIUS]: 120,
    [TimeZone.EUROPE_ZAPOROZHYE]: 120,
    [TimeZone.AFRICA_ADDIS_ABABA]: 180,
    [TimeZone.AFRICA_ASMARA]: 180,
    [TimeZone.AFRICA_ASMERA]: 180,
    [TimeZone.AFRICA_DAR_ES_SALAAM]: 180,
    [TimeZone.AFRICA_DJIBOUTI]: 180,
    [TimeZone.AFRICA_KAMPALA]: 180,
    [TimeZone.AFRICA_MOGADISHU]: 180,
    [TimeZone.AFRICA_NAIROBI]: 180,
    [TimeZone.ANTARCTICA_SYOWA]: 180,
    [TimeZone.ASIA_ADEN]: 180,
    [TimeZone.ASIA_AMMAN]: 180,
    [TimeZone.ASIA_BAGHDAD]: 180,
    [TimeZone.ASIA_BAHRAIN]: 180,
    [TimeZone.ASIA_DAMASCUS]: 180,
    [TimeZone.ASIA_ISTANBUL]: 180,
    [TimeZone.ASIA_KUWAIT]: 180,
    [TimeZone.ASIA_QATAR]: 180,
    [TimeZone.ASIA_RIYADH]: 180,
    [TimeZone.EUROPE_ISTANBUL]: 180,
    [TimeZone.EUROPE_KIROV]: 180,
    [TimeZone.EUROPE_MINSK]: 180,
    [TimeZone.EUROPE_MOSCOW]: 180,
    [TimeZone.EUROPE_SIMFEROPOL]: 180,
    [TimeZone.EUROPE_VOLGOGRAD]: 180,
    [TimeZone.INDIAN_ANTANANARIVO]: 180,
    [TimeZone.INDIAN_COMORO]: 180,
    [TimeZone.INDIAN_MAYOTTE]: 180,
    [TimeZone.ASIA_TEHRAN]: 210,
    [TimeZone.ASIA_BAKU]: 240,
    [TimeZone.ASIA_DUBAI]: 240,
    [TimeZone.ASIA_MUSCAT]: 240,
    [TimeZone.ASIA_TBILISI]: 240,
    [TimeZone.ASIA_YEREVAN]: 240,
    [TimeZone.EUROPE_ASTRAKHAN]: 240,
    [TimeZone.EUROPE_SAMARA]: 240,
    [TimeZone.EUROPE_SARATOV]: 240,
    [TimeZone.EUROPE_ULYANOVSK]: 240,
    [TimeZone.INDIAN_MAHE]: 240,
    [TimeZone.INDIAN_MAURITIUS]: 240,
    [TimeZone.INDIAN_REUNION]: 240,
    [TimeZone.ASIA_KABUL]: 270,
    [TimeZone.ANTARCTICA_MAWSON]: 300,
    [TimeZone.ASIA_AQTAU]: 300,
    [TimeZone.ASIA_AQTOBE]: 300,
    [TimeZone.ASIA_ASHGABAT]: 300,
    [TimeZone.ASIA_ASHKHABAD]: 300,
    [TimeZone.ASIA_ATYRAU]: 300,
    [TimeZone.ASIA_DUSHANBE]: 300,
    [TimeZone.ASIA_KARACHI]: 300,
    [TimeZone.ASIA_ORAL]: 300,
    [TimeZone.ASIA_QYZYLORDA]: 300,
    [TimeZone.ASIA_SAMARKAND]: 300,
    [TimeZone.ASIA_TASHKENT]: 300,
    [TimeZone.ASIA_YEKATERINBURG]: 300,
    [TimeZone.INDIAN_KERGUELEN]: 300,
    [TimeZone.INDIAN_MALDIVES]: 300,
    [TimeZone.ASIA_CALCUTTA]: 330,
    [TimeZone.ASIA_COLOMBO]: 330,
    [TimeZone.ASIA_KOLKATA]: 330,
    [TimeZone.ASIA_KATHMANDU]: 345,
    [TimeZone.ASIA_KATMANDU]: 345,
    [TimeZone.ANTARCTICA_VOSTOK]: 360,
    [TimeZone.ASIA_ALMATY]: 360,
    [TimeZone.ASIA_BISHKEK]: 360,
    [TimeZone.ASIA_DACCA]: 360,
    [TimeZone.ASIA_DHAKA]: 360,
    [TimeZone.ASIA_KASHGAR]: 360,
    [TimeZone.ASIA_OMSK]: 360,
    [TimeZone.ASIA_QOSTANAY]: 360,
    [TimeZone.ASIA_THIMBU]: 360,
    [TimeZone.ASIA_THIMPHU]: 360,
    [TimeZone.ASIA_URUMQI]: 360,
    [TimeZone.INDIAN_CHAGOS]: 360,
    [TimeZone.ASIA_RANGOON]: 390,
    [TimeZone.ASIA_YANGON]: 390,
    [TimeZone.INDIAN_COCOS]: 390,
    [TimeZone.ANTARCTICA_DAVIS]: 420,
    [TimeZone.ASIA_BANGKOK]: 420,
    [TimeZone.ASIA_BARNAUL]: 420,
    [TimeZone.ASIA_HO_CHI_MINH]: 420,
    [TimeZone.ASIA_HOVD]: 420,
    [TimeZone.ASIA_JAKARTA]: 420,
    [TimeZone.ASIA_KRASNOYARSK]: 420,
    [TimeZone.ASIA_NOVOKUZNETSK]: 420,
    [TimeZone.ASIA_NOVOSIBIRSK]: 420,
    [TimeZone.ASIA_PHNOM_PENH]: 420,
    [TimeZone.ASIA_PONTIANAK]: 420,
    [TimeZone.ASIA_SAIGON]: 420,
    [TimeZone.ASIA_TOMSK]: 420,
    [TimeZone.ASIA_VIENTIANE]: 420,
    [TimeZone.INDIAN_CHRISTMAS]: 420,
    [TimeZone.ASIA_BRUNEI]: 480,
    [TimeZone.ASIA_CHOIBALSAN]: 480,
    [TimeZone.ASIA_CHONGQING]: 480,
    [TimeZone.ASIA_CHUNGKING]: 480,
    [TimeZone.ASIA_HARBIN]: 480,
    [TimeZone.ASIA_HONG_KONG]: 480,
    [TimeZone.ASIA_IRKUTSK]: 480,
    [TimeZone.ASIA_KUALA_LUMPUR]: 480,
    [TimeZone.ASIA_KUCHING]: 480,
    [TimeZone.ASIA_MACAO]: 480,
    [TimeZone.ASIA_MACAU]: 480,
    [TimeZone.ASIA_MAKASSAR]: 480,
    [TimeZone.ASIA_MANILA]: 480,
    [TimeZone.ASIA_SHANGHAI]: 480,
    [TimeZone.ASIA_SINGAPORE]: 480,
    [TimeZone.ASIA_TAIPEI]: 480,
    [TimeZone.ASIA_UJUNG_PANDANG]: 480,
    [TimeZone.ASIA_ULAANBAATAR]: 480,
    [TimeZone.ASIA_ULAN_BATOR]: 480,
    [TimeZone.AUSTRALIA_PERTH]: 480,
    [TimeZone.AUSTRALIA_WEST]: 480,
    [TimeZone.AUSTRALIA_EUCLA]: 525,
    [TimeZone.ASIA_CHITA]: 540,
    [TimeZone.ASIA_DILI]: 540,
    [TimeZone.ASIA_JAYAPURA]: 540,
    [TimeZone.ASIA_KHANDYGA]: 540,
    [TimeZone.ASIA_PYONGYANG]: 540,
    [TimeZone.ASIA_SEOUL]: 540,
    [TimeZone.ASIA_TOKYO]: 540,
    [TimeZone.ASIA_YAKUTSK]: 540,
    [TimeZone.PACIFIC_PALAU]: 540,
    [TimeZone.AUSTRALIA_DARWIN]: 570,
    [TimeZone.AUSTRALIA_NORTH]: 570,
    [TimeZone.ANTARCTICA_DUMONTDURVILLE]: 600,
    [TimeZone.ASIA_UST_NERA]: 600,
    [TimeZone.ASIA_VLADIVOSTOK]: 600,
    [TimeZone.AUSTRALIA_BRISBANE]: 600,
    [TimeZone.AUSTRALIA_LINDEMAN]: 600,
    [TimeZone.AUSTRALIA_QUEENSLAND]: 600,
    [TimeZone.PACIFIC_CHUUK]: 600,
    [TimeZone.PACIFIC_GUAM]: 600,
    [TimeZone.PACIFIC_PORT_MORESBY]: 600,
    [TimeZone.PACIFIC_SAIPAN]: 600,
    [TimeZone.PACIFIC_TRUK]: 600,
    [TimeZone.PACIFIC_YAP]: 600,
    [TimeZone.AUSTRALIA_ADELAIDE]: 630,
    [TimeZone.AUSTRALIA_BROKEN_HILL]: 630,
    [TimeZone.AUSTRALIA_SOUTH]: 630,
    [TimeZone.AUSTRALIA_YANCOWINNA]: 630,
    [TimeZone.ANTARCTICA_CASEY]: 660,
    [TimeZone.ANTARCTICA_MACQUARIE]: 660,
    [TimeZone.ASIA_MAGADAN]: 660,
    [TimeZone.ASIA_SAKHALIN]: 660,
    [TimeZone.ASIA_SREDNEKOLYMSK]: 660,
    [TimeZone.AUSTRALIA_ACT]: 660,
    [TimeZone.AUSTRALIA_CANBERRA]: 660,
    [TimeZone.AUSTRALIA_CURRIE]: 660,
    [TimeZone.AUSTRALIA_HOBART]: 660,
    [TimeZone.AUSTRALIA_LHI]: 660,
    [TimeZone.AUSTRALIA_LORD_HOWE]: 660,
    [TimeZone.AUSTRALIA_MELBOURNE]: 660,
    [TimeZone.AUSTRALIA_NSW]: 660,
    [TimeZone.AUSTRALIA_SYDNEY]: 660,
    [TimeZone.AUSTRALIA_TASMANIA]: 660,
    [TimeZone.AUSTRALIA_VICTORIA]: 660,
    [TimeZone.PACIFIC_BOUGAINVILLE]: 660,
    [TimeZone.PACIFIC_EFATE]: 660,
    [TimeZone.PACIFIC_GUADALCANAL]: 660,
    [TimeZone.PACIFIC_KOSRAE]: 660,
    [TimeZone.PACIFIC_NOUMEA]: 660,
    [TimeZone.PACIFIC_POHNPEI]: 660,
    [TimeZone.PACIFIC_PONAPE]: 660,
    [TimeZone.ASIA_ANADYR]: 720,
    [TimeZone.ASIA_KAMCHATKA]: 720,
    [TimeZone.PACIFIC_FIJI]: 720,
    [TimeZone.PACIFIC_FUNAFUTI]: 720,
    [TimeZone.PACIFIC_KWAJALEIN]: 720,
    [TimeZone.PACIFIC_MAJURO]: 720,
    [TimeZone.PACIFIC_NAURU]: 720,
    [TimeZone.PACIFIC_NORFOLK]: 720,
    [TimeZone.PACIFIC_TARAWA]: 720,
    [TimeZone.PACIFIC_WAKE]: 720,
    [TimeZone.PACIFIC_WALLIS]: 720,
    [TimeZone.ANTARCTICA_MCMURDO]: 780,
    [TimeZone.ANTARCTICA_SOUTH_POLE]: 780,
    [TimeZone.PACIFIC_APIA]: 780,
    [TimeZone.PACIFIC_AUCKLAND]: 780,
    [TimeZone.PACIFIC_ENDERBURY]: 780,
    [TimeZone.PACIFIC_FAKAOFO]: 780,
    [TimeZone.PACIFIC_KANTON]: 780,
    [TimeZone.PACIFIC_TONGATAPU]: 780,
    [TimeZone.PACIFIC_CHATHAM]: 825,
    [TimeZone.PACIFIC_KIRITIMATI]: 840
};