export const DEVICE_ACTION_LIST = {
    configuration: {
        name: "Configuration",
        icon: "icon-settings",
        privilegedId: 'DeviceCfg'
    },
    analysis: {
        name: "Analysis",
        icon: "icon-bar-chart",
        privilegedId: 'DeviceAnalysis'
    },
    editDeviceInfo: {
        name: "Edit Device Information",
        icon: "icon-edit",
        privilegedId: 'DeviceEditInfo'
    },
    alertPolicy: {
        name: "Alert Policy",
        icon: "icon-alert-circle",
        privilegedId: 'FeatureAlert'
    },
    editTags: {
        name: "Edit Tags",
        icon: "icon-tag",
        privilegedId: 'DeviceEditInfo'
    },
    assignGroup: {
        name: "Assign Group",
        icon: "icon-briefcase",
        privilegedId: 'AdministatorAccess'
    },
    deleteDevice: {
        name: "Delete Device(s)",
        icon: "icon-delete",
        privilegedId: 'DeleteDevice'
    },
    webGui: {
        name: "Remote Access GUI",
        icon: "icon-chrome",
        privilegedId: 'DeviceRemoteAccess'
    }
};