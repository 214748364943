/******* Angular Resourse *******/
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DisclaimerComponent implements OnInit {

    //Public 
    @Input('alertType') alertType: string = "warning";
    @Input('headerText') headerText: string = "* DISCLAIMER *";
    @Input('contentType') contentType: string = "Data Usage";
    public isContentDisplay: boolean = false;

    /**
     * Constructor
     */
    constructor() { }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * get content
     */
    get content() {
        switch(this.contentType) {
            case 'Data Usage': return this.dataUsageDisclaimerContent;
            case 'Speed Test': return this.speedTestDisclaimerContent;
            default: return "";
        }
    }

    /**
     * getter Data Usage Disclaimer content
     */
    get dataUsageDisclaimerContent() {
        return `Data Usage displayed in the BECentral is gathered periodically and may
		not reflect recent changes to the network. The data-usage amounts are strictly
		estimates and may vary from the final numbers the carrier uses for billing
		purposes. In no event, shall BEC Technologies Inc. be held liable for any
		fees charged by the carrier for customer usage.`;
    }

    /**
     * getter Speed Test Disclaimer content
     */
    get speedTestDisclaimerContent() {
        return `Speed test functionality is provided for general information purposes only. We do not make any warranties about the reliability or accuracy of this information.`;
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void { }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle Content Display flag
     */
    public toggleContentDisplay() {
        this.isContentDisplay = !this.isContentDisplay;
    }

}
