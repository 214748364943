<div class="auth-wrapper auth-v2">
	<div class="auth-inner row m-0 pb-0" style="overflow-x: hidden;">
		<div class="global-rllipse d-block d-lg-none"></div>

		<!-- Left Section-->
		<div class="d-flex col-lg-5 align-items-center auth-bg px-3 p-lg-5 forgot-password-form-section">
			<div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="forgot-password-form-header">
					<h1>Forgot Password</h1>
					<p>To reset your password, enter your username below and submit. An email will be sent to you with
						instructions about how to complete the process.</p>
				</div>
				<div class="d-flex align-items-center justify-content-center">
					<form class="auth-forgot-password-form mt-50" [formGroup]="forgotPasswordForm"
						(ngSubmit)="onSubmit()">
						<text-input [id]="'forgot-password-email'" [label]="'Email'" formControlName="email"
							[placeholder]="'john@example.com'" [submitted]="submitted"></text-input>
						<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block mt-2"
							tabindex="2" rippleEffect>
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
							Send Reset Link
						</button>
					</form>
				</div>
				<p class="text-center mt-2">
					<a id="back-to-login" routerLink="/login">
						<i data-feather="chevron-left" class="mb-25"></i> Back to login
					</a>
				</p>
			</div>
		</div>

		<!-- Right Section -->
		<div class="d-none d-lg-flex col-lg-7 forgot-password-illustration-section">
			<div class="w-100 forgot-password-illustration-image">
				<div class="rllipse"></div>
				<img src="assets/images/pages/forgot-password-v2-dark.svg" alt="Forgot password" />
			</div>
		</div>
	</div>
</div>