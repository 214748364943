import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UtilsService } from '@core/services/utils.service';

@Injectable({ providedIn: 'root' })
export class TwoFactorAuthenticationGuard implements CanActivate {

	/**
	 * constructor
	 * 
	 * @param {Router} _router
	 * @param {UtilsService} _utilsService
	 */
	constructor(private _router: Router, private _utilsService: UtilsService) { }

	/**
     * canActivate : to check if a user token as query params.
     * 
     * @param {ActivatedRouteSnapshot} route 
     * @param {RouterStateSnapshot} state 
     * @returns {Observable | Promise | boolean}
     */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		
		if (route.queryParams.token)
			return true;
		else {
			this._utilsService.alert("error", "You lose your session.", 'Please Sign in again.');
			this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
			return false;
		}
	}
}
