export interface AuthTokenRequestBody {
    code: string;
    clientId: string;
    username: string;
};

export interface AuthRefreshTokenRequestBody {
    refreshToken: string;
};

export interface TokenResponse {
    accessToken: string;
    refreshToken: string;
};