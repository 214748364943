import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Password Requirement
 * 
 * @param {string} type
 * @returns {ValidatorFn}
 */
export function passwordValidator(type: string): ValidatorFn {
    let passwordFormat: RegExp = null;
    let returnObject: any;

    switch (type) {
        case "digit":
            passwordFormat = /^(?=.*\d)(?!.*\s).*$/;
            returnObject = { digit: true };
            break;
        case "capital":
            passwordFormat = /^(?=.*[A-Z])(?!.*\s).*$/;
            returnObject = { capital: true };
            break;
        case "special":
            passwordFormat = /^(?=.*[!@#$%^*()_+}{:;'"?/.,&<>])(?!.*\s).*$/;
            returnObject = { special: true };
            break;
    }

    return (control: AbstractControl): ValidationErrors | null => {
        const format = passwordFormat.test(control.value);
        return format ? null : returnObject;
    };
}

/**
 * Confirm Password Match
 * 
 * @param {string} New 
 * @param {string} Confirm 
 * @returns {ValidatorFn}
 */
export function passwordMatch(New: string, Confirm: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const newPassword = control.get(New).value;
        const confirmPassword = control.get(Confirm).value;
        return newPassword == confirmPassword ? null : { Mismatch: true };
    };
}