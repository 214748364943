/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RestApi extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /openapi/swagger/openapi.json - get json object from open api
     */
    public getApiJson() {

        let method = "GET",
            url = "openapi/swagger/openapi.json",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}