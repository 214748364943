/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { AuthRefreshTokenRequestBody, AuthTokenRequestBody, LoginRequestBody, SendingAuthCodeRequestBody, TwoFactorType } from '@core/types';

@Injectable()
export class Auth extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * POST /auth/login - becentral login
     * 
     * @param {LoginRequestBody} data  
     */
    public login(data: LoginRequestBody) {

        let method = "POST",
            url = "auth/login",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /auth/sendSMS2FACode - becentral sending Auth Code thru SMS
     * 
     * @param {LoginRequestBody} data  
     */
    public send2faCodeThruSMS(data: LoginRequestBody) {

        let method = "POST",
            url = "auth/sendSMS2FACode",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /auth/enable2FA - becentral enable 2fa
     * 
     * @param {LoginRequestBody} data  
     */
    public enable2fa(data: Array<TwoFactorType>) {

        let method = "POST",
            url = "auth/enable2FA",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /auth/token - get the access token at first time
     * 
     * @param {AuthTokenRequestBody} data  
     */
    public getToken(data: AuthTokenRequestBody) {

        let method = "POST",
            url = "auth/token",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /auth/token-refresh - refresh the access token
     * 
     * @param {AuthRefreshTokenRequestBody } data  
     */
    public refreshToken(data: AuthRefreshTokenRequestBody) {

        let method = "POST",
            url = "auth/token-refresh",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /auth/logout - becentral logout
     */
    public logout() {

        let method = "POST",
            url = "auth/logout",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /auth/2fa/email - send auth code thru email due to reCAPTCHA low score
     * 
     * @param {SendingAuthCodeRequestBody} data
     */
    public sendingAuthCode(data: SendingAuthCodeRequestBody) {

        let method = "POST",
            url = "auth/2fa/email",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /auth/2fa/check - check auth code
     * 
     * @param {SendingAuthCodeRequestBody} data
     */
    public checkAuthCode(data: SendingAuthCodeRequestBody) {

        let method = "POST",
            url = "auth/2fa/check",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}