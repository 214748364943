import { SubscriberInfo } from "./subscriber-info";

export interface DeleteDeviceRequestBody {
    MAC_array: string[];
};

export interface UpdateDeviceRequestBody {
    MAC: string;
    name?: string;
    customField?: string;
    address?: string;
    subscriberInfo?: SubscriberInfo;
};

export interface tagRequest {
    title: string;
    color: string;
    textColor: string;
};

export interface assignTagsRequest {
    deviceIds: Array<string>;
    tagIds: Array<string>;
};

export interface DeviceInformation {
    name?: string;
    customField?: string;
    address?: string;
};

export interface BatchUpdateDeviceFormat {
    MAC: string;
    device: DeviceInformation
};

export interface BatchUpdateDeviceRequestBody {
    devices: Array<BatchUpdateDeviceFormat>
};