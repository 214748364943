import { PipeTransform, Pipe } from '@angular/core'

@Pipe({
    name: 'sortByColumn'
})
export class SortByColumnPipe implements PipeTransform {

    /**
     * Comparation function
     * 
     * @param {any} a 
     * @param {any} b 
     * @returns {number}
     */
    static _orderByComparator(a: any, b: any): number {

        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            //Isn't a number so lowercase the string to properly compare
            if (a.toLowerCase() < b.toLowerCase()) return -1;
            if (a.toLowerCase() > b.toLowerCase()) return 1;
        }
        else {
            //Parse strings as numbers to compare properly
            if (parseFloat(a) < parseFloat(b)) return -1;
            if (parseFloat(a) > parseFloat(b)) return 1;
        }

        return 0; //equal each other
    }

    /**
     * Transform
     * 
     * @param {any} input 
     * @param {[string?]} config 
     * @returns {any}
     */
    transform(input: any, [config = '+']): any {
        if (!Array.isArray(input)) return input;
        if (!Array.isArray(config) || (Array.isArray(config) && config.length == 1)) {
            var propertyToCheck: string = !Array.isArray(config) ? config : config[0];
            var desc = propertyToCheck.substring(0, 1) == '-';

            //Basic array
            if (!propertyToCheck || propertyToCheck == '-' || propertyToCheck == '+') {
                return !desc ? input.sort() : input.sort().reverse();
            }
            else {
                var property: string = propertyToCheck.substring(0, 1) == '+' || propertyToCheck.substring(0, 1) == '-'
                    ? propertyToCheck.substring(1)
                    : propertyToCheck;

                return input.sort(function (a: any, b: any) {
                    return !desc
                        ? SortByColumnPipe._orderByComparator(a[property], b[property])
                        : -SortByColumnPipe._orderByComparator(a[property], b[property]);
                });
            }
        } else return input;
    }
}