/******* Angular Resourse *******/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/******* Plug-In Resourse *******/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgTerminalModule } from 'ng-terminal';

/******* Core Resourse *******/
import { CoreCommonModule } from '@core/common.module';

/******* Component Resourse *******/
import { TerminalComponent } from './terminal.component';



@NgModule({
    declarations: [
        TerminalComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        CoreCommonModule,
        NgTerminalModule
    ],
    providers: [],
    exports: [
        TerminalComponent
    ]
})
export class TerminalModule { }
