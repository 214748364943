import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toLocalString'
})
export class ToLocalStringPipe implements PipeTransform {

	/**
	 * Convert To local String date string wth timezone
	 * 
     * @param {number | string} timestamp
	 * @param {string} timeZone 
	 * @returns {string}
	 */
	static convertToLocalString(timestamp: number | string, timeZone: string): string {
		if (!timestamp) return "";
		let speedtestTimestamp = timestamp.toString();
		if (speedtestTimestamp.length < 12) speedtestTimestamp += "000";
		const DataObject = speedtestTimestamp.length <= 13 ? new Date(parseInt(speedtestTimestamp)) : new Date(speedtestTimestamp);
		return DataObject.toLocaleString("en-US", { timeZone });
	}

	/**
	 * Transform
	 * 
	 * @param {number | string} timestamp 
     * @param {string} timeZone 
	 * @returns {string}
	 */
	transform(timestamp: number | string, timeZone: string): string {
		return ToLocalStringPipe.convertToLocalString(timestamp, timeZone);
	}
}