/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { CreateApiConsumerRequestBody } from '@core/types';

@Injectable()
export class AuditLogs extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /audit-logs - get audit logs
     * 
     * @param filter
     */
    public getAuditLogs(filter) {
        return this.find("audit-logs", filter);
    }

    /**
     * GET /audit-logs/count - get devices list count
     * 
     * @param where
     */
    public getAuditLogsCount(where) {
        return this.count("audit-logs", where);
    }
}