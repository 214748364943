import { AlertItem, AlertItemMap } from "@core/types";

export const ALERT_ITEM_MAP: AlertItemMap = {
    [AlertItem.EMAIL]: {
        title: "Email On-Demand",
        icon: "icon-mail",
        content: "Send Email right away when alert triggered"
    },
    [AlertItem.SMS]: {
        title: "SMS On-Demand",
        icon: "icon-message-square",
        content: "Send SMS right away when alert triggered"
    },
    [AlertItem.CALL]: {
        title: "CALL On-Demand",
        icon: "icon-phone-call",
        content: "Make a call right away when alert triggered"
    },
    [AlertItem.ETHERNET_INTERFACE_CHAGNE]: {
        title: "Ethernet Interface Change",
        icon: "icon-hash",
        content: "Alert whenever device port(s) status go up or down"
    },
    [AlertItem.WEB_UI_LOGIN_FAILURE]: {
        title: "Web UI Login Failure",
        icon: "icon-hash",
        content: "Alert when there is a device Web UI Login Failure"
    },
    [AlertItem.BANDWIDTH]: {
        title: "Bandwidth Data Usage",
        icon: "icon-hash",
        content: "Alert when usage reaches the data cap"
    },
    [AlertItem.CELL_BOUNCING]: {
        title: "Cell Bouncing",
        icon: "icon-hash",
        content: "Alert when times higher than Caution or Alert value"
    },
    [AlertItem.CONFIGURATION]: {
        title: "Update Configuration",
        icon: "icon-hash",
        content: "Alert when the device updates the Configuration"
    },
    [AlertItem.DOWNTIME]: {
        title: "Downtime",
        icon: "icon-hash",
        content: "Alert when the device stops reporting"
    },
    [AlertItem.FAILOVER]: {
        title: "Failover",
        icon: "icon-hash",
        content: "Alert when the device do Failover"
    },
    [AlertItem.FIRMWARE]: {
        title: "Update Firmware",
        icon: "icon-hash",
        content: "Alert when the device updates the Firmware"
    },
    [AlertItem.IP_CHANGE]: {
        title: "IP Change",
        icon: "icon-hash",
        content: "Alert when the device IP changes"
    },
    [AlertItem.UNIT_POWERED_UP]: {
        title: "Unit powered up",
        icon: "icon-hash",
        content: "Alert when the device has been powered up or soft reset"
    },
    [AlertItem.TEMPERATURE]: {
        title: "Temperature",
        icon: "icon-hash",
        content: "Alert when Temperature higher than Caution or Alert value"
    },
    [AlertItem.NETWORK_TECHNOLOGY_CHANGE]: {
        title: "Netwok Technology Change",
        icon: "icon-hash",
        content: "Alert when network technology changes."
    },
    [AlertItem.DRY_CONTACT]: {
        title: "Dry Contact",
        icon: "icon-hash",
        content: "Alert when dry contact trigger."
    },
    [AlertItem.SERIAL_CONNECTOR]: {
        title: "Serial Connector",
        icon: "icon-hash",
        content: "Alert when serial connectort"
    },
    [AlertItem.RSRP]: {
        title: "RSRP",
        icon: "icon-radio",
        content: "Alert when signal lower than Caution or Alert value"
    },
    [AlertItem.RSRQ]: {
        title: "RSRQ",
        icon: "icon-radio",
        content: "Alert when signal lower than Caution or Alert value"
    },
    [AlertItem.RSSI]: {
        title: "RSSI",
        icon: "icon-radio",
        content: "Alert when signal lower than Caution or Alert value"
    },
    [AlertItem.SINR]: {
        title: "SINR",
        icon: "icon-radio",
        content: "Alert when signal lower than Caution or Alert value"
    },
    [AlertItem.NR_RSRP]: {
        title: "NR RSRP",
        icon: "icon-radio",
        content: "Alert when signal lower than Caution or Alert value"
    },
    [AlertItem.NR_RSRQ]: {
        title: "NR RSRQ",
        icon: "icon-radio",
        content: "Alert when signal lower than Caution or Alert value"
    },
    [AlertItem.NR_SINR]: {
        title: "NR SINR",
        icon: "icon-radio",
        content: "Alert when signal lower than Caution or Alert value"
    }
};