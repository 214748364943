/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { BackupRequestBody, CommandRequestBody, WiFiCommand } from '@core/types';

@Injectable()
export class DomainProxyLogs extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /domain-proxy-logs/count - get domain proxy logs count
     * 
     * @param {any} where
     */
    public getDomainProxyLogsCount(where: any) {
        return this.count('domain-proxy-logs', where);
    }

    /**
     * GET /domain-proxy-logs - get domain proxy logs
     * 
     * @param {any} filter
     */
    public getDomainProxyLogs(filter: any) {
        return this.find("domain-proxy-logs", filter);
    }
}