export interface TaskOptionsList {
    subAccount: Array<any>;
    group: Array<any>;
    pci: Array<any>;
    model: Array<any>;
    fw: Array<any>;
    eNodeBID: Array<any>;
    fwFile: Array<any>;
    cfgFile: Array<any>;
    report: Array<any>;
    profileTemplate: Array<any>;
};