// Type and Interface

export interface FilterCollapse {
    collapse: boolean;
    filters: Array<{ [key: string]: any }>
};

export interface DeviceFilterType {
    device: FilterCollapse;
    hardware: FilterCollapse;
    network: FilterCollapse;
};

export interface CBSDGeoInformation {
    latitude: number;
    longitude: number;
    band: string;
    pci: string;
    cellId: string;
};

export interface GeoPCI {
    latitude: number;
    longitude: number;
    pci: string;
    cellId: string;
    address: string;
    id: string;
    name: string;
    owner: string;
};

export interface StepperEventDetail {
    from: number;
    to: number;
    indexStep: number;
};

export interface DataUsage {
    Download: string;
    Upload: string;
    Usage: any[];
};

export interface SpeedTest {
    latency: string;
    url: string;
    timestamp: string;
    download: Array<number>;
    upload: Array<number>;
};

export interface AlertCount {
    danger: Array<any>;
    danger_count: number;
    warning: Array<any>;
    warning_count: number;
    info: Array<any>;
    info_count: number;
};

export enum WanNode {
    "EWAN" = "Wan_PVC10",
    "LTE1" = "Wan_PVC11",
    "LTE2" = "Wan_PVC12",
    "WirelessClient" = "Wan_PVC14",
    "LTE3" = "Wan_PVC15",
    "EWAN2" = "Wan_PVC16",
};

export enum UsageNode {
    "LTE1" = "Usage_PVC11",
    "LTE2" = "Usage_PVC12",
    "LTE3" = "Usage_PVC15"
};

export type PacketListName = 'IpMacFilterList' | 'UrlFilterList' | 'DomainFilterList';




