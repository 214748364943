/******* Angular Resourse *******/
import { Injectable } from '@angular/core';

/******* Shared Resourse *******/
import { UtilsService } from './utils.service';
import { PrivilegeService } from './privilege.service';
import { GlobalUIBlockingService } from './global-ui-blocking.service';
import { LicenseStatusValue, StatusInfo } from '@core/types';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

/******* API Resourse *******/
import { Devices } from '@api';

@Injectable({
    providedIn: 'root',
})
export class DeviceStatusService {

    // Public
    public currentStatus: Observable<StatusInfo>;

    // Private
    private currentStatusSubject: BehaviorSubject<StatusInfo>;

    /**
     * Constructor
     *
     * @param {Devices} _devices
     * @param {UtilsService} _utilsService
     * @param {PrivilegeService} _privilegeService
     * @param {GlobalUIBlockingService} _globalUIBlockingService
     */
    constructor(private _devices: Devices,
        private _utilsService: UtilsService,
        private _privilegeService: PrivilegeService,
        private _globalUIBlockingService: GlobalUIBlockingService) {

        // User initial
        this.currentStatusSubject = new BehaviorSubject<StatusInfo>(null);
        this.currentStatus = this.currentStatusSubject.asObservable().pipe(tap((x) => { if (!x) this._queryDeviceStatus(); }));
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get current device's status value
     */
    get currentStatusValue(): any {
        return this.currentStatusSubject.value;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query Device Status Data
     */
    private _queryDeviceStatus(resolve = null, rejects = null): void {
        
        this._privilegeService.currentUser.pipe(filter(x => x != null), take(1)).subscribe((returnData) => {
            const permissionSet = new Set(returnData?.permissions);
            const requiredFilter: any = permissionSet.has('SuperAdminAccess') ? [] : [
                { licenseStatus: LicenseStatusValue.ACTIVE },
                { MODEL: { nin: [null, ""] } },
                { MAC: { nin: [null, ""] } }
            ];

            // Add Group filter
            if (returnData.groups) {
                const gorupArray = returnData.groups ? returnData.groups.map(x => x.id) : [];
                if (gorupArray.length > 0) requiredFilter.push({ groupId: { inq: gorupArray } });
            }

            // Initial all promise
            const onlinePromise = this._devices.getDevicesCount({ "where": { "and": [{ "connected": { "inq": [true] } }, ...requiredFilter] } }).toPromise();
            const offlinePromise = this._devices.getDevicesCount({ "where": { "and": [{ "connected": { "inq": [false] } }, ...requiredFilter] } }).toPromise();
            const totalPromise = this._devices.getDevicesCount({ "where": { "and": [{ "connected": { "inq": [true, false] } }, ...requiredFilter] } }).toPromise();
            
            // Wait for Query complete
            this._globalUIBlockingService.start();
            Promise.all([onlinePromise, offlinePromise, totalPromise]).then(values => {
                let StatusInfo: StatusInfo = {
                    Online: values[0]?.data?.count,
                    Offline: values[1]?.data?.count,
                    Total: values[2]?.data?.count,
                };

                // assign to Privilege item
                this._globalUIBlockingService.stop();
                this.currentStatusSubject.next(StatusInfo);
                if (resolve) resolve(StatusInfo);
            }, (error) => {
                this._globalUIBlockingService.stop();
                this._utilsService.alert('error', 'Fail to load Device Status', error);
                if (rejects) rejects(false);
            });
        });
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh device count
     * 
     * @returns {Promise<StatusInfo>}
     */
    public refreshDeviceStatus(): Promise<StatusInfo> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._queryDeviceStatus(resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Clear the current device count
     */
    public clearAllInfo(): void {
        this.currentStatusSubject.next(null);
    }
}