import { ElementRef, EventEmitter, Injectable, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CoreMediaService {
	currentMediaQuery: string;
	onMediaUpdate: BehaviorSubject<string> = new BehaviorSubject<string>('');

	/**
	 * Constructor
	 *
	 * @param {MediaObserver} _mediaObserver
	 */
	constructor(private _mediaObserver: MediaObserver) {
		this.currentMediaQuery = ''; // Set the defaults
		this._init(); // Initialize
	}

	// Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Initialize
	 */
	private _init(): void {
		this._mediaObserver.asObservable().pipe(debounceTime(500), distinctUntilChanged()).subscribe((change: MediaChange[]) => {
			const mqAlias = change[0].mqAlias;
			if (this.currentMediaQuery !== mqAlias) {
				this.currentMediaQuery = mqAlias;
				this.onMediaUpdate.next(mqAlias);
			}
		});
	}
}
