import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';

import { PrivilegeService } from '@core/services/privilege.service';
import { UtilsService } from '@core/services/utils.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate, CanActivateChild {

    /**
     * constructor
     * 
     * @param {Router} _router
     * @param {UtilsService} _utilsService
     * @param {PrivilegeService} _privilegeService
     */
    constructor(private _router: Router,
        private _utilsService: UtilsService,
        private _privilegeService: PrivilegeService) { }

    /**
     * canActivate : to check if a user can visit a route.
     * 
     * @param {ActivatedRouteSnapshot} route 
     * @param {RouterStateSnapshot} state 
     * @returns {Observable | Promise | boolean}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.checkAccessToken()) return this.checkAuthForRouteByRole(state);
        this._utilsService.alert("error", "Error", "You have loss your Authorization, Please login again!");
        // clear all storage
        sessionStorage.clear();
        localStorage.clear();
        // not logged in so redirect to login page with the return url
        this._router.navigate(['/login']);
        return false;
    }

    /**
     * canActivateChild : to check if a user can visit a route children.
     * 
     * @param {ActivatedRouteSnapshot} route 
     * @param {RouterStateSnapshot} state 
     * @returns {Observable | Promise | boolean}
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    /**
     * Check Path accessibility
     * 
     * @param {RouterStateSnapshot} state 
     * @returns {Observable<boolean>}
     */
    private checkAuthForRouteByRole(state: RouterStateSnapshot): Observable<boolean> {
        return this._privilegeService.currentBlockPath.pipe(filter(data => data != null), take(1), map((data) => {
            if (!data.has(state.url.substring(1))) return true;
            this._router.navigate(['/miscellaneous/not-authorized']);
            return false;
        }));
    }

    /**
     * Check whether having access token and refresh token
     * 
     * @returns {boolean}
     */
    private checkAccessToken(): boolean {
        return sessionStorage.getItem("becentralAccessToken") && sessionStorage.getItem("becentralRefreshToken") ? true : false;
    }
}
