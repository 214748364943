export const DEVICE_INFO_INCLUDED_IN_EMAIL = [
    "MODEL",
    "name",
    "IP",
    "FW",
    "IMEI",
    "IMSI",
    "SYSUPTIME",
    "CustID",
    "address"
] as const;

export const DEVICE_INFO_INCLUDED_IN_EMAIL_NAME = {
    "MODEL": "Model",
    "name": "Name",
    "IP": "IP",
    "FW": "Firmware",
    "IMEI": "IMEI",
    "IMSI": "IMSI",
    "SYSUPTIME": "Uptime",
    "CustID": "Owner",
    "address": "Address"
};