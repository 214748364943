/******* Angular Resourse *******/
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS
} from '@angular/common/http';

/******* Core Resourse *******/
import { HttpCancelService } from '@core/services/http-cancel.service';

/******* Plug In Resourse *******/
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {

    /**
     * Constructor
     * 
     * @param {HttpCancelService} httpCancelService 
     * 
     *  @param {Router} router
     */
    constructor(private httpCancelService: HttpCancelService) { }

    /**
     * Add takeUntil to enable cancel pending request feature
     * 
     * @param {HttpRequest<any>} req 
     * @param {HttpHandler} next 
     * @returns {Observable<HttpEvent<any>>}
     */
    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
    }
}

export const ManageHttpProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ManageHttpInterceptor,
    multi: true
};