

export interface bandwidthPercentage {
    Percentage: number;
    Sent_Flag: boolean;
    Sent_Once: boolean;
};

export interface billStartDate {
    startday: string;
    timezone: string;
};

export interface bandWidthSettings {
    MAC?: string; // for normal 
    type?: boolean; // for alert
    alertInterval: number;
    usageThreshold: number;
    usageThresholdUnit: string;
    usageThresholdPercentage: Array<bandwidthPercentage>;
    billingStartDay: billStartDate;
};