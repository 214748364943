import { TimeZone } from "../time-zone-types";

export interface GetUsagesSummaryRequestBody {
    timezone: string;
    bandwidthThreshold: number;
    bandwidthThresholdUnit: string;
    startDate: string;
    endDate: string;
};

export interface GetBandwidthUsageRequestBody {
    startDate: string;
    endDate: string;
    timezone: string;
};

export interface UsageThreshold {
    percentage: number;
    sentFlag: boolean;
    sentOnce: boolean;
};

export interface UpdateDataUsageSettingsRequestBody {
    id?: string;
    startDay: string;
    usageThreshold: number;
    usageThresholdUnit: "MB" | "GB",
    alertInterval: number;
    usageThresholdPercentage: Array<UsageThreshold>;
};