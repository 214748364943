export enum AlertItem {
    EMAIL = "email",
    SMS = "sms",
    CALL = "call",
    ETHERNET_INTERFACE_CHAGNE = "ethernetInterfaceChange",
    WEB_UI_LOGIN_FAILURE = "webUILoginFailure",
    BANDWIDTH = "bandwidth",
    CELL_BOUNCING = "cellBouncing",
    CONFIGURATION = "updateConfig",
    DOWNTIME = "downtime",
    FAILOVER = "failover",
    FIRMWARE = "updateFW",
    IP_CHANGE = "ipChange",
    UNIT_POWERED_UP = "reboot",
    TEMPERATURE = "temperature",
    RSRP = "RSRP",
    RSRQ = "RSRQ",
    RSSI = "RSSI",
    SINR = "SINR",
    NR_RSRP = "NR_RSRP",
    NR_RSRQ = "NR_RSRQ",
    NR_SINR = "NR_SINR",
    NETWORK_TECHNOLOGY_CHANGE = "networkTechnologyChange",
    DRY_CONTACT = "dryContact",
    SERIAL_CONNECTOR = "serialConnector"
};

export interface AlertItemContent {
    title: string;
    icon: string;
    content: string;
    appendString?: string;
};

export type AlertItemMap = {
    [key in AlertItem]: AlertItemContent
};