import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UtilsService } from '@core/services/utils.service';

@Injectable({ providedIn: 'root' })
export class ResetPasswordGuard implements CanActivate {

	/**
	 * constructor
	 * 
	 * @param {Router} _router
	 * @param {UtilsService} _utilsService
	 */
	constructor(private _router: Router, private _utilsService: UtilsService) { }

	/**
     * canActivate : to check if a user email and token as query params.
     * 
     * @param {ActivatedRouteSnapshot} route 
     * @param {RouterStateSnapshot} state 
     * @returns {Observable | Promise | boolean}
     */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		// check if email and token is append
		if (route.queryParams.email && route.queryParams.token) {
			
			if (typeof route.queryParams.valid != "undefined") {
				this._utilsService.alert("error", 'The Reset Password Session was Expired !', 'Please send the request again');
				this._router.navigate(['/login']);
				return false;
			}

			return true;
		}
		else {
			this._utilsService.alert("error", 'You lose your session.', 'Please Sign in again.');
			this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
			return false;
		}
	}
}
