<ngb-alert [type]="alertType" [dismissible]="false">
    <h4 class="alert-heading toggle-button" (click)="toggleContentDisplay()"
        [ngClass]="{'none-box-shadow': !isContentDisplay}">
        {{headerText}}
        <i class="feather font-medium-2"
            [ngClass]="{'icon-plus': !isContentDisplay, 'icon-minus': isContentDisplay}"></i>
    </h4>
    <div class="alert-body font-small-3" *ngIf="isContentDisplay">
        <p>{{content}}</p>
    </div>
</ngb-alert>