import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberAbbreviation'
})
export class NumberAbbreviationPipe implements PipeTransform {

	/**
	 * Transform
	 * 
	 * @param {string}input 
	 * @returns {string}
	 */
	transform(input: string): string {
		const number = parseInt(input);

		// K
		let numberK = Math.floor(number / 1000);
		if (numberK == 0) return number.toString();

		// M
		let numberM = Math.floor(numberK / 1000);
		return numberM == 0 ? (numberK + "K") : (numberM + "M");
	}
}
