export enum Operation {
    GET = 'GET',
    SET = 'SET',
    UPDATE = 'UPDATE',
    SAVE = 'SAVE',
    COMMIT = 'COMMIT',
    DELETE = 'DELETE'
};

export enum ConfigLayer {
    MODULE = 'module',
    NODE = 'node',
    ATTRIBUTE = 'attribute'
};

export interface ConfigCommand {
    operation: Operation;
    module: string;
    node: string;
    attribute: string;
    value: string | number;
};

export interface UpdateConfigRequestBody {
    command: Array<Partial<ConfigCommand>>;
}