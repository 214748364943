import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

	/**
	 * Strip html input
	 * 
	 * @param {string} value 
	 * @returns {string}
	 */
	static stripHTML(value: string): string {
		return value.replace(/<(?:.|\n)*?>/gm, ' ');
	}

	/**
	 * Transform
	 *
	 * @param {string} input
	 * @param {string} key
	 * @returns {string}
	 */
	transform(input: string, key: string = ''): string {

		// Filter out HTML Tag 
		const safeKey = HighlightPipe.stripHTML(key);
		const safeInput = HighlightPipe.stripHTML(input);

		const test = new RegExp(safeKey.replace(/[<>*()\[\]\\?+-]/g, "\\$&"), 'igm');
		return key ? safeInput.replace(test, '<span class="text-highlight">$&</span>') : safeInput;
	}
}
