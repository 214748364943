/******* Angular Resourse *******/
import { Directive, TemplateRef, ViewContainerRef, Input } from "@angular/core";

/******* Core Resourse *******/
import { PrivilegeService } from '@core/services/privilege.service';
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Directive({
    selector: '[privilegedAccessControlItem]'
})
export class PrivilegedAccessControlItemDirective {

    // Private
    private _unsubscribeAll$: Subject<any>; // UnsubscribeAll Observable

    /**
     * constructor
     * 
     * @param {PrivilegeService} _privilegeService
     * @param {TemplateRef<any>} _templateRef
     * @param {ViewContainerRef} _viewContainerRef
     */
    constructor(private _privilegeService: PrivilegeService,
        private readonly _templateRef: TemplateRef<any>,
        private readonly _viewContainerRef: ViewContainerRef) {

        // Initial Observable
        this._unsubscribeAll$ = new Subject();
    }

    @Input() set privilegedAccessControlItem(key: string) {
        this._viewContainerRef.clear(); // Clear First
        this._privilegeService.currentUser.pipe(filter(x => x != null), takeUntil(this._unsubscribeAll$)).subscribe(() => {
            this._viewContainerRef.clear(); // && this._privilegeService.checkAccessByGroupRole(this.groupId, key)
            if (this._privilegeService.checkAccessByRole(key))
                this._viewContainerRef.createEmbeddedView(this._templateRef);
        });
    };

    /**
     * On Destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }
}