import { AfterViewInit, Component, OnDestroy, Renderer2 } from "@angular/core";
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ExternalRequestService } from "@api";

@Component({
    selector: "becentral-recaptcha",
    templateUrl: "./recaptcha.component.html",
    styleUrls: ["./recaptcha.component.scss"]
})
export class BecentralRecaptchaComponent implements AfterViewInit, OnDestroy {

    // Private
    private _grecaptcha$: BehaviorSubject<any>;
    private _unsubscribeAll$: Subject<any>;

    /**
     * constructor
     * 
     * @param {ExternalRequestService} _externalRequestService
     */
    constructor(private _externalRequestService: ExternalRequestService) {
        this._unsubscribeAll$ = new Subject();
        this._grecaptcha$ = new BehaviorSubject(null);

        window['ng2recaptchaloaded'] = () => { this._grecaptcha$.next(window['grecaptcha']); };
        this.loadReCaptchaScript();
    }

    //  Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * getter grecaptch
     */
    get grecaptcha() {
        return this._grecaptcha$.value;
    }

    //  Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * after view initial
     */
    ngAfterViewInit(): void {

        this._grecaptcha$.pipe(takeUntil(this._unsubscribeAll$), filter(x => x != null)).subscribe((grecaptcha) => {
            console.log(this.grecaptcha);

            /* grecaptcha.render('recaptch-container', {
                sitekey: environment.recaptchaSiteKey,
                callback: (response) => {
                    this._externalRequestService.post('https://www.google.com/recaptcha/api/siteverify', {
                        secret: environment.recaptchaKey,
                        response
                    }).subscribe((returnData) => {
                        console.log(returnData);
                    });
                },
                'expired-callback': (error) => {
                    console.log("expired:", error);
                },
                'error-callback': (error) => {
                    console.log("error:", error);
                }
            }); */
        });
    }

    /**
     * on Destory
     */
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    //  Private Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Dynamic loading recaptcha script to application
     * 
     * @returns 
     */
    private loadReCaptchaScript(): void {
        if (window['grecaptcha']) return;
        const script = document.createElement('script');
        script.innerHTML = "";
        const baseUrl = "https://www.recaptcha.net/recaptcha/api.js"; 
        script.src = `${baseUrl}?render=${environment.recaptchaSiteKey}&onload=ng2recaptchaloaded&hl=en`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }
}