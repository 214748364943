import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toUTCString'
})
export class ToUTCStringPipe implements PipeTransform {

	/**
	 * Convert To iso date string in UTC
	 * 
	 * @param {number | string} timestamp 
	 * @returns {string}
	 */
	static convertToDateString(timestamp: number | string): string {
		return new Date(timestamp).toLocaleString("en-US", { timeZone: 'UTC' });
	}

	/**
	 * Transform
	 * 
	 * @param {number | string} timestamp 
	 * @returns {string}
	 */
	transform(timestamp: number | string): string {
		return ToUTCStringPipe.convertToDateString(timestamp);
	}
}