<div [ngClass]="containerClass">
    <label [ngClass]="labelClass">{{label}}</label>
    <div [ngClass]="inputClass">
        <div class="custom-group">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [ngClass]="{ 'is-invalid': invalid }" [id]="id"
                    [(ngModel)]='value' [disabled]="isDisabled" (blur)="onTouch()"/>
                <label class="custom-control-label" [for]="id">{{checkBoxLabel}}</label>
            </div>
        </div>
    </div>
</div>