import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

	/**
	 * Transform
	 * 
	 * @param {string}input 
	 * @returns {string}
	 */
	transform(input: string): string {
		if (input.length != 12) return input;
		else return input.substring(2, 5) + "-" + input.substring(5, 8) + "-" + input.substring(8, 12);
	}
}
