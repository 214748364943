import { PipeTransform, Pipe } from '@angular/core'
import { DEVICE_ALERT_TYPE_NAME_MAP } from '@core/constant';

@Pipe({
    name: 'alertTypeName'
})
export class AlertTypeNamePipe implements PipeTransform {
    
    /**
     * Transform
     * 
     * @param {string} value 
     * @returns {string}
     */
    transform(value: string): string {
        if (!value) return "";
        return DEVICE_ALERT_TYPE_NAME_MAP[value];
    }
}