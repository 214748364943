export interface RfHistoryDataPoint {
    x: Date;
    y: number;
    PCI: string;
    BAND: string;
    CELLID: string;
    RI: string;
    DL_MCS: string;
    CQI: string;
};

export interface RfHistoryDataPointLabel {
    date: string;
    RSRP?: number;
    RSRQ?: number;
    RSSI?: number;
    SINR?: number;
    isSinrAvailable?: boolean;
    NR_RSRP?: number;
    NR_RSRQ?: number;
    NR_SINR?: number;
    isNrSinrAvailable?: boolean;
    PCI: string;
    BAND: string;
    CELLID: string;
    RI: string;
    DL_MCS: string;
    CQI: string;
};