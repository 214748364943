import { NgModule } from '@angular/core';
import {
	DiagnosticItemsPipe,
	AlertTypeNamePipe,
	TenantTypeMappingPipe,
	RadioTypeFilterPipe,
	ToApiPathPipe,
	RoleNameFilterPipe
} from './conversion-pipe';
import {
	EllipsisBlockPipe,
	HighlightPipe,
	InitialUppercasePipe,
	InitialsPipe,
	ItemCountPipe,
	NumberAbbreviationPipe,
	PhoneNumberPipe,
	SafePipe,
	StripHtmlPipe,
	ToUTCStringPipe,
	ToISOStringPipe,
	ToLocalStringPipe,
	SecsToMinsPipe,
	SubStringPipe
} from './modification-pipe';
import {
	FilterPipe,
	SortByColumnPipe,
	SortByMethodPipe
} from './filter-pipe';

import { TaskTypePipe } from './scheduler-task-type-mapping.pipe';

@NgModule({
	declarations: [
		/* conversion-pipe */
		DiagnosticItemsPipe,
		AlertTypeNamePipe,
		TenantTypeMappingPipe,
		RadioTypeFilterPipe,
		ToApiPathPipe,
		RoleNameFilterPipe,

		/* modification-pipe */
		EllipsisBlockPipe,
		HighlightPipe,
		InitialUppercasePipe,
		InitialsPipe,
		ItemCountPipe,
		NumberAbbreviationPipe,
		PhoneNumberPipe,
		SafePipe,
		StripHtmlPipe,
		ToUTCStringPipe,
		ToISOStringPipe,
		ToLocalStringPipe,
		SecsToMinsPipe,
		SubStringPipe,

		/* filter-pipe */
		FilterPipe,
		SortByColumnPipe,
		SortByMethodPipe,

		/* other-pipe */
		TaskTypePipe
	],
	imports: [],
	exports: [
		/* conversion-pipe */
		DiagnosticItemsPipe,
		AlertTypeNamePipe,
		TenantTypeMappingPipe,
		RadioTypeFilterPipe,
		ToApiPathPipe,
		RoleNameFilterPipe,

		/* modification-pipe */
		EllipsisBlockPipe,
		HighlightPipe,
		InitialUppercasePipe,
		InitialsPipe,
		ItemCountPipe,
		NumberAbbreviationPipe,
		PhoneNumberPipe,
		SafePipe,
		StripHtmlPipe,
		ToUTCStringPipe,
		ToISOStringPipe,
		ToLocalStringPipe,
		SecsToMinsPipe,
		SubStringPipe,

		/* filter-pipe */
		FilterPipe,
		SortByColumnPipe,
		SortByMethodPipe,

		/* other-pipe */
		TaskTypePipe
	]
})
export class CorePipesModule { }
