import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { tenantSwitchingAnimation } from "@core/animations/core.animation";
import { CoreConfigService } from "@core/services/config.service";
import { HttpCancelService } from "@core/services/http-cancel.service";
import { UtilsService } from "@core/services/utils.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-tenant-access",
    templateUrl: "./tenant-access.component.html",
    styleUrls: ['./tenant-access.component.scss'],
    animations: [
        tenantSwitchingAnimation
    ]
})
export class TenantAccessComponent implements OnInit, AfterViewInit {

    // Public
    public coreConfig: any;
    public imageStatus: string = 'initial';

    // Private
    private _unsubscribeAll$: Subject<any>;
    private _counter: number = 0;

    /**
     * Constructor
     * 
     * @param {CoreConfigService} _coreConfigService
     * @param {Router} _router
     */
    constructor(private _coreConfigService: CoreConfigService,
        private _router: Router,
        private _httpCancelService: HttpCancelService,
        private _utilsService: UtilsService) {
        this._unsubscribeAll$ = new Subject();
        
        // Set Blank Page
        this._coreConfigService.setConfig({
            layout: {
                footer: { hidden: true },
                menu: { hidden: true },
                navbar: { hidden: true },
                buyNow: false,
                customizer: false,
                scrollTop: false,
                enableLocalStorage: false,
                // skin: "dark",
                type: "vertical"
            }
        });
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * on initial
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll$)).subscribe(config => {
            this.coreConfig = config;
        });
    }

    /**
     * after view initial
     */
    ngAfterViewInit(): void {
        this.imageStatus = 'end';
    }

    /**
     * On animtion completed
     */
    public onAnimationDone(event: any): void {
        if (event.fromState === "initial" && event.toState === "end") {
            this.imageStatus = 'initial';
            this._counter++;
            if (this._counter < 15)
                setTimeout(() => { this.imageStatus = 'end'; });
            else {
                this._httpCancelService.cancelPendingRequests();
                this._router.navigate(['/login'], { replaceUrl: true });
                this._utilsService.alert('error', 'Fail to switching tenant', "");
            }
        }
        else return;
    }
}