<div [ngClass]="containerClass">
    <label [ngClass]="labelClass" [for]="id">{{label}}</label>
    <div [ngClass]="inputClass">
        <select class="form-control" [ngClass]="{ 'is-invalid': invalid }" [id]="id" [(ngModel)]='value'
            [disabled]="isDisabled" (blur)="onTouch()" [autocomplete]="id"> <!-- (change)="onChange($event)" -->
            <option *ngIf="placeholder" value="" disabled>{{placeholder}}</option>
            <ng-container *ngIf="isPlainOption else groupOption">
                <option *ngFor="let elemt of options" [value]="elemt.value" [disabled]="elemt.disabled">
                    {{elemt.name}}
                </option>
            </ng-container>
            <ng-template #groupOption>
                <optgroup *ngFor="let group of options | keyvalue" [label]="group.key">
                    <option *ngFor="let elemt of group.value" [value]="elemt.value" [disabled]="elemt.disabled">
                        {{elemt.name}}
                    </option>
                </optgroup>
            </ng-template>
        </select>
        <div *ngIf="invalid && displayErrorMessage" class="invalid-feedback">
            <ng-container *ngIf="isRequired && errors?.required">{{label}} is required</ng-container>
        </div>
    </div>
</div>