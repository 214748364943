import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewEncapsulation } from "@angular/core";
import { ControlContainer, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators, } from "@angular/forms";

@Component({
    selector: 'number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['form.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => NumberInputComponent)
        }
    ]
})
export class NumberInputComponent implements ControlValueAccessor, OnInit {

    // Public
    @Input() id: string = "";
    @Input() label: string = "";
    @Input() placeholder: string = "";
    @Input() submitted: boolean = false;
    @Input() formControlName: string;
    @Input() displayErrorMessage: boolean = true;
    @Input() sameRow: boolean = false;

    // Private
    private _onChange: (value: number) => void = (_: any) => { };
    private _onTouched: () => void = () => { };
    private _thisIsDisabled: boolean = false;
    private _thisValue: number = 0;
    private _control: UntypedFormControl;

    /**
     * constructor
     * 
     * @param {ControlContainer} _controlContainer 
     */
    constructor(@Optional() @Host() @SkipSelf()
    private _controlContainer: ControlContainer) { }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    get containerClass(): string {
        return this.sameRow ? 'form-group row' : 'form-group';
    }

    get labelClass(): string {
        return this.sameRow ? 'col-sm-4 col-form-label' : 'form-label';
    }

    get inputClass(): string {
        return this.sameRow ? 'col-sm-8' : '';
    }

    get value(): number {
        return this._thisValue;
    }

    set value(_value: number) {
        this._thisValue = _value;
        this._onChange(this._thisValue);
    }

    get isDisabled(): boolean {
        return this._thisIsDisabled;
    }

    get invalid(): boolean {
        return this._control ? this._control.invalid && this.submitted : false;
    }

    get errors(): any {
        return this._control.errors;
    }

    get isRequired(): boolean {
        return this._control.hasValidator(Validators.required);
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        if (this._controlContainer) {
            if (this.formControlName) this._control = this._controlContainer.control.get(this.formControlName) as UntypedFormControl;
            else console.warn('Missing FormControlName directive from host element of the component');
        } else console.warn('Can\'t find parent FormGroup directive');
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * writing value handler 
     * 
     * @param _value 
     */
    public writeValue(_value: number) {
        let writeInValue = typeof _value != 'undefined' ? _value : 0;
        this.value = writeInValue;
    }

    /**
     * on change trigger 
     * 
     * @param fn 
     */
    public registerOnChange(fn: (value: number) => void): void {
        this._onChange = fn;
    }

    /**
     * on touched trigger 
     * 
     * @param fn 
     */
    public registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    /**
     * set disabled
     * 
     * @param {boolean} isDisabled 
     */
    public setDisabledState?(isDisabled: boolean): void {
        this._thisIsDisabled = isDisabled;
    }

    /**
     * On Touch event handler
     */
    public onTouch(): void {
        this._onTouched();
    }
}