/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Filter } from '@core/types';

@Injectable()
export class CellHistory extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /cell-history/count - get count of cell history
     * 
     * @param {any} where
     */
    public getCellHistoryCount(where: any) {
        return this.count('cell-history', where);
    }

    /**
     * GET /cell-history - get cell history
     * 
     * @param {Filter} filter
     */
    public getCellHistory(filter: Filter) {
        return this.find('cell-history', filter);
    }
}