import { Pipe, PipeTransform } from '@angular/core';
import { TENANT_TYPE_NAME_MAP } from '@core/constant';

@Pipe({
    name: 'tenantTypeMapping'
})
export class TenantTypeMappingPipe implements PipeTransform {
    
    /**
	 * Transform
	 * 
	 * @param {string} value 
	 * @returns {string}
	 */
    transform(value: string): any {
        return TENANT_TYPE_NAME_MAP.hasOwnProperty(value) ? TENANT_TYPE_NAME_MAP[value] : value.toUpperCase();
    }
}
