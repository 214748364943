/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { CreateApiConsumerRequestBody, ForgotPasswordRequestBody, ResetPasswordRequestBody, UpdatePasswordRequestBody, UpdateUserMeRequestBody, UserRegisterRequestBody, VerifyResetPasswordRequestBody } from '@core/types';

@Injectable()
export class Users extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * POST /users/verify-reset-password - verify email
     * 
     * @param {VerifyResetPasswordRequestBody} data  
     */
    public verifyResetPassword(data: VerifyResetPasswordRequestBody) {

        let method = "POST",
            url = "users/verify-reset-password",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /users/reset-password - update to the new password - auth page
     * 
     * @param {ResetPasswordRequestBody} data  
     */
    public resetPassword(data: ResetPasswordRequestBody) {

        let method = "POST",
            url = "users/reset-password",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PUT /users/update-password - update to the new password - profile page
     * 
     * @param {UpdatePasswordRequestBody} data  
     */
    public updatePassword(data: UpdatePasswordRequestBody) {

        let method = "PUT",
            url = "users/update-password",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /users/forgot-password - send email to reset the password
     * 
     * @param {ForgotPasswordRequestBody} data  
     */
    public forgotPassword(data: ForgotPasswordRequestBody) {

        let method = "POST",
            url = "users/forgot-password",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /users/me - get self personal information
     */
    public getPersonalInformation() {

        let method = "GET",
            url = "users/me",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /users/me - update self personal information
     * 
     * @param {UpdateUserMeRequestBody} data
     */
    public updatePersonalInformation(data: UpdateUserMeRequestBody) {

        let method = "PATCH",
            url = "users/me",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /users/me/tenants - get self tenants information
     */
    public getTenantsInformation() {

        let method = "GET",
            url = "users/me/tenants",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /users - create new user into tenant
     * 
     * @param {UserRegisterRequestBody} data
     */
    public createNewUserInTenant(data: UserRegisterRequestBody) {
        return this.create("users", data);
    }

    /**
     * GET /users/{id} - Get user info
     * 
     * @param {string} id
     */
    public getUser(id: string) {

        let method = "GET",
            url = "users/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /users/{id} - update user info
     * 
     * @param {any} data
     * @param {string} id
     */
    public updateUser(data: any, id: string) {

        let method = "PATCH",
            url = "users/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /users/{id} - delete user data
     * 
     * @param {string} id
     */
    public deleteUser(id: string) {
        return this.deleteById("users", id);
    }

    /**
     * GET /users - get users list
     * 
     * @param {any} filter
     */
    public getUsers(filter: any = {}) {
        return this.find("users", filter);
    }

    /**
     * PUT /users/logo - upload self's logo
     * 
     * @param {any} data form data
     */
    public uploadUserLogo(data: any) {

        let method = "PUT_FILE",
            url = "users/logo",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /users/all/count - get all users only super admin
     * 
     * @param where
     */
    /* public getAllUsersCount(where: any) {

        let method = "GET",
            url = "users/all",
            routeParams = {},
            urlParams = { where },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    } */

    /**
     * GET /users/all - get all users only super admin
     * 
     * @param filter
     */
    public getAllUsers(filter: any) {

        let method = "GET",
            url = "users/all",
            routeParams = {},
            urlParams = { filter },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /users/{id}/group-role - get all group-role by user
     * 
     * @param {string} id
     */
    public getAllGroupRoles(id: string) {

        let method = "GET",
            url = "users/:id/group-role",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /users/me/api-consumer - get api consumer data
     */
    public getApiConsumer() {

        let method = "GET",
            url = "users/me/api-consumer",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /users/me/api-consumer - Sign up the api key
     * 
     * @param {CreateApiConsumerRequestBody} data  
     */
    public createApiConsumer(data: CreateApiConsumerRequestBody) {

        let method = "POST",
            url = "users/me/api-consumer",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /users/me/api-consumer - delete the api key
     */
    public delteApiConsumer() {

        let method = "DELETE",
            url = "users/me/api-consumer",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /users/qrcode - get qrcode
     * 
     * @param {any} data
     */
    public getQrCode(data: any) {

        let method = "POST",
            url = "users/qrcode",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /users/re-invite-email - regenerate the new invatation link
     * 
     * @param {any} data
     */
    public regenerateInvatation(data: any) {

        let method = "POST",
            url = "users/re-invite-email",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}