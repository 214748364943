import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * CustId Validation
 * 
 * @returns {ValidatorFn}
 */
export function custIdValidation(): ValidatorFn {
    let custIdFormat: RegExp = /^[a-zA-Z0-9_.-]*$/;
    return (control: AbstractControl): ValidationErrors | null => {
        const format = custIdFormat.test(control.value);
        return format ? null  : {custIdFormat: true};
    };
}