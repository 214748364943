/******* Angular Resourse *******/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/******* Plug-In Resourse *******/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/******* Core Resourse *******/
import { CoreCommonModule } from '@core/common.module';

/******* Component Resourse *******/
import { SignalBarComponent } from './signal-bar.component';


@NgModule({
  declarations: [SignalBarComponent],
  imports: [
    CommonModule, 
    NgbModule,
    CoreCommonModule
  ],
  providers: [],
  exports: [SignalBarComponent]
})
export class SignalBarModule {}
