import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';
import { ImageWithLoaderComponent } from './image-with-loader.component';


@NgModule({
  declarations: [ImageWithLoaderComponent],
  imports: [CommonModule, NgbModule, CoreCommonModule],
  providers: [],
  exports: [ImageWithLoaderComponent]
})
export class ImageWithLoaderModule {}
