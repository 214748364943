import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'terms-of-use-modal',
    templateUrl: './terms-of-use.component.html'
})
export class TermsOfUseModalComponent implements OnInit {

    /**
     * constructor
     * 
     * @param {NgbActiveModal} activeModal 
     */
    constructor(private _ngbActiveModal: NgbActiveModal) { }

    ngOnInit(): void { }

    /**
     * output close with key
     * 
     * @param key 
     */
    public close(key): void {
        this._ngbActiveModal.close(key);
    }
}