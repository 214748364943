export interface Statistics {
    pci: number;
    eNodeBID: number;
    model: number;
    firmware: number;
    report: number;
    pendingScheduler: number;
    cbsdAuthorized: number;
    group: number;
};

export interface DeviceStatus {
	Online: { count: number, percentage: number };
	Offline: { count: number, percentage: number };
	Total: number;
};

export interface DataSet {
	labels: Array<string>;
	data: Array<number>;
	backgroundColor: Array<string>;
	borderWidth?: number,
	hoverBorderWidth?: number
};

export interface DataSets {
	Carrier: DataSet | null;
	Model: DataSet | null;
	Network_Type: DataSet | null;
	Radio_Type: DataSet | null;
};