import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TaskTypeValue } from "@core/types";

/* profile template at Least one is required */
export function profileTemplateRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.get('taskType').value === TaskTypeValue.PROFILE_IMPLEMENT) {
            if(control.get('profileTemplate').value === "" && control.get('alertPolicyTemplate').value === "")
                return { ProfileImplement: true }
            else return null;
        }
        else return null;
    };
}