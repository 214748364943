import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secsToMins'
})
export class SecsToMinsPipe implements PipeTransform {

    /**
     * Transform
     * 
     * @param {string} timestamp 
     * @returns {number}
     */
    transform(timestamp: string): number {
        return parseInt(timestamp) / 60;
    }
}