import { DEVICE_INFO_INCLUDED_IN_EMAIL } from "@core/constant";
import { UsageThreshold } from "../bandwidth-types";

export type AlertWithThreshold = {
    enable: boolean;
    threshold: number;
};

export interface ProactiveNotificationConfig extends AlertWithThreshold {
    recipients: Array<string>;
    note?: string;
};

export interface AlertWithLevelThreshold {
    enable: boolean;
    caution: number;
    alert: number;
};

export interface BandwidthUsageSettings {
    enable?: boolean;
    startDay: string;
    usageThreshold: number;
    usageThresholdUnit: "MB" | "GB";
    alertInterval: number;
    usageThresholdPercentage: Array<UsageThreshold>;
};

export interface DeviceInfoIncluded {
    enable: boolean;
    name: string;
};

export type EmailInfoIncluded = {
    [K in typeof DEVICE_INFO_INCLUDED_IN_EMAIL[number]]?: DeviceInfoIncluded
};

export type AlertPolicy = {
    email: ProactiveNotificationConfig;
    sms: ProactiveNotificationConfig;
    call: ProactiveNotificationConfig;
    downtime: AlertWithThreshold;
    reboot: boolean;
    failover: boolean;
    updateFW: boolean;
    updateConfig: boolean;
    ipChange: boolean;
    ethernetInterfaceChange: boolean;
    webUILoginFailure: boolean;
    networkTechnologyChange: boolean;
    dryContact: boolean;
    serialConnector: boolean;
    infoIncluded?: EmailInfoIncluded;
    bandwidth: Partial<BandwidthUsageSettings>;
    RSRP: AlertWithLevelThreshold;
    RSSI: AlertWithLevelThreshold;
    SINR: AlertWithLevelThreshold;
    RSRQ: AlertWithLevelThreshold;
    NR_RSRP: AlertWithLevelThreshold;
    NR_SINR: AlertWithLevelThreshold;
    NR_RSRQ: AlertWithLevelThreshold;
    temperature: AlertWithLevelThreshold;
    cellBouncing: AlertWithLevelThreshold;
};

export interface AlertPolicyRequestBody extends AlertPolicy {
    MAC?: string;
    timezone: string;
};

export type EmailInfoIncludedValue = {
    [K in typeof DEVICE_INFO_INCLUDED_IN_EMAIL[number]]: boolean
};

export interface ProactiveNotificationConfigFormGroup {
    emailOnDemand: boolean;
    emailThresholdForSending: number;
    emailLoop: Array<string>;
    emailCustomizeNote: string;
    smsOnDemand: boolean;
    smsThresholdForSending: number;
    smsLoop: Array<string>;
    callOnDemand: boolean;
    callThresholdForSending: number;
    callLoop: Array<string>;
    emailInfoIncluded: EmailInfoIncludedValue;
};

export interface AlertPolicyForm {
    proactiveNotification: ProactiveNotificationConfigFormGroup;
    downtime: boolean;
    downtime_threshold: number;
    reboot: boolean;
    failover: boolean;
    updateFW: boolean;
    updateConfig: boolean;
    ipChange: boolean;
    ethernetInterfaceChange: boolean;
    webUILoginFailure: boolean;
    networkTechnologyChange:boolean;
    dryContact: boolean;
    serialConnector: boolean;
    bandwidth: boolean;
    rsrp: boolean;
    rsrp_value: Array<number>;
    rssi: boolean;
    rssi_value: Array<number>;
    sinr: boolean;
    sinr_value: Array<number>;
    rsrq: boolean;
    rsrq_value: Array<number>;
    nr_rsrp: boolean;
    nr_rsrp_value: Array<number>;
    nr_sinr: boolean;
    nr_sinr_value: Array<number>;
    nr_rsrq: boolean;
    nr_rsrq_value: Array<number>;
    temperature: boolean;
    temperature_value: Array<number>;
    cellBouncing: boolean;
    cellBouncing_value: Array<number>;
};