import { SupportDeviceModel } from "../device-list-types";

export const enum ReportType {
    GENERAL = 'general',
    PCI_LOCK = "pci_lock"
};

export interface ModelOptions {
    key: SupportDeviceModel;
    name: string;
};

export enum ReportPage {
    MAIN = 'main',
    REPORT_PAGE = 'report_page'
};

export interface DeviceField {
    connected: boolean;
    MAC: boolean;
    MODEL: boolean;
    name: boolean;
    address: boolean;
    // Loaction
    latitude: boolean;
    longitude: boolean;
    customField: boolean;
    IP: boolean;
    IPV6: boolean;
    APN2_IP: boolean;
    FW: boolean;
    IMEI: boolean;
    IMSI: boolean;
    RSRP: boolean;
    RSRP_DIV1: boolean;
    RSRP_DIV2: boolean;
    RSRP_DIV3: boolean;
    RSRP2: boolean;
    RSRP2_DIV1: boolean;
    RSRP2_DIV2: boolean;
    RSRP2_DIV3: boolean;
    RSRP3: boolean;
    RSRP3_DIV1: boolean;
    RSRP3_DIV2: boolean;
    RSRP3_DIV3: boolean;
    CA_RSRP: boolean;
    CA2_RSRP: boolean;
    CA3_RSRP: boolean;
    CA4_RSRP: boolean;
    NR_RSRP: boolean;
    NR_DIV_RSRP: boolean;
    NR_DIV2_RSRP: boolean;
    NR_DIV3_RSRP: boolean;
    NR_CA_RSRP: boolean;
    NR_CA2_RSRP: boolean;
    RSRQ: boolean;
    RSRQ_DIV1: boolean;
    RSRQ_DIV2: boolean;
    RSRQ_DIV3: boolean;
    RSRQ2: boolean;
    RSRQ2_DIV1: boolean;
    RSRQ2_DIV2: boolean;
    RSRQ2_DIV3: boolean;
    RSRQ3: boolean;
    RSRQ3_DIV1: boolean;
    RSRQ3_DIV2: boolean;
    RSRQ3_DIV3: boolean;
    CA_RSRQ: boolean;
    CA2_RSRQ: boolean;
    CA3_RSRQ: boolean;
    CA4_RSRQ: boolean;
    NR_RSRQ: boolean;
    NR_DIV_RSRQ: boolean;
    NR_DIV2_RSRQ: boolean;
    NR_DIV3_RSRQ: boolean;
    NR_CA_RSRQ: boolean;
    NR_CA2_RSRQ: boolean;
    RSSI: boolean;
    RSSI_DIV1: boolean;
    RSSI_DIV2: boolean;
    RSSI_DIV3: boolean;
    RSSI2: boolean;
    RSSI2_DIV1: boolean;
    RSSI2_DIV2: boolean;
    RSSI2_DIV3: boolean;
    RSSI3: boolean;
    RSSI3_DIV1: boolean;
    RSSI3_DIV2: boolean;
    RSSI3_DIV3: boolean;
    CA_RSSI: boolean;
    CA2_RSSI: boolean;
    CA3_RSSI: boolean;
    CA4_RSSI: boolean;
    SINR: boolean;
    SINR_DIV1: boolean;
    SINR_DIV2: boolean;
    SINR_DIV3: boolean;
    SINR2: boolean;
    SINR2_DIV1: boolean;
    SINR2_DIV2: boolean;
    SINR2_DIV3: boolean;
    SINR3: boolean;
    SINR3_DIV1: boolean;
    SINR3_DIV2: boolean;
    SINR3_DIV3: boolean;
    CA_SINR: boolean;
    CA2_SINR: boolean;
    CA3_SINR: boolean;
    CA4_SINR: boolean;
    NR_SINR: boolean;
    NR_DIV_SINR: boolean;
    NR_DIV2_SINR: boolean;
    NR_DIV3_SINR: boolean;
    NR_CA_SINR: boolean;
    NR_CA2_SINR: boolean;
    NETWORK: boolean;
    LTE_MDN: boolean;
    PCI: boolean;
    ECI: boolean;
    CELL_ID: boolean;
    ENB_ID: boolean;
    BAND: boolean;
    CardFw: boolean;
    RI: boolean;
    NR_RI: boolean;
    DL_MCS: boolean;
    NR_DLMCS: boolean;
    NR_ULMCS: boolean;
    CQI: boolean;
    NR_CQI: boolean;
    ICCID: boolean;
    CBSD_STATUS: boolean;
    CBSD_ID: boolean;
    CBSD_GRANTID: boolean;
    LTE_UPTIME: boolean;
    SYSUPTIME: boolean;
    lastPublishedAt: boolean;
};