import { Pipe, PipeTransform } from '@angular/core';
import { TAC_TO_RADIO_TYPE_MAP, R17_ADVANCED_MAP } from '@core/constant';

@Pipe({
	name: 'radioTypeFilter'
})
export class RadioTypeFilterPipe implements PipeTransform {

	/**
	 * MAP TAC to Radio Type
	 * 
	 * @param {string} value 
	 * @returns {string}
	 */
	static mapRadioType(value: string): string {
		return TAC_TO_RADIO_TYPE_MAP.hasOwnProperty(value) ? TAC_TO_RADIO_TYPE_MAP[value] : "";
	}

	/**
	 * Transform
	 * 
	 * @param {string} value 
	 * @returns {string}
	 */
	transform(value: string): string {
		
		let RadioType = RadioTypeFilterPipe.mapRadioType(value.substring(0, 6));
		if (RadioType == "R17") {
			let advance_TAC = value.substring(0, 8);
			return (R17_ADVANCED_MAP.hasOwnProperty(advance_TAC) ? R17_ADVANCED_MAP[advance_TAC] : "R17") + ` (${value}+)`;
		}
		else if (RadioType) return RadioType + ` (${value}+)`;
		else return `${value}+ (Unrecognized)`;
	}
}