export enum FileStage {
    PENDING = "Pending",
    IN_PROGRESS = "In Progress",
    SAVING = "Saving",
    DOWNLOAD = "Downloading",
    ERROR = "Error",
    UPDATE = "Updating"
};

export interface FileTransferingProgress {
    progress: number;
    state: FileStage;
};

export enum FileType {
    FIRMWARE = "Firmware",
    CONFIGURATION = "Configuration",
    BACTCH_UPDATE = "Batch Update",
    PCI_UPLOAD = "PCI Upload"
};

export interface GeneralFile {
    createdAt: string;
    id: string;
    owner: string;
    sharedBy: string;
    originalname: string;
    mimetype: string;
    type: string;
    fileType: string;
    tenantId: string;
};

export interface UserToShareFile {
    name: string;
    username: string;
};

export interface TenantToSearchUsers {
    name: string;
    CustID: string;
    id: string;
};

export interface PciInfoUploadResult {
    ['Sector Name']: string;
    PCI: string;
    ['Service Address']: string;
    ECI: string;
    CELLID: string | number;
    failed: boolean;
    latitude?: number;
    longitude?: number;
    failedReason?: string;
};

export interface DeviceInfoUploadResult {
    MAC: string;
    Address: string;
    Name: string;
    ['Custom Field']: string;
    CELLID: string | number;
    failed: boolean;
    latitude?: number;
    longitude?: number;
    failedReason?: string;
};