/******* Angular Resourse *******/
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

/******* Core Resourse *******/
import { PrivilegeService } from '@core/services/privilege.service';
import { UtilsService } from '@core/services/utils.service';
import { AlertGlobalCountings, TenantType, AlertNotification, AlertCategory, IsAlertNotificationLoading, DeviceAlert, AlertReadingStatus, MessageType, MessageData, UserPreferanceSettingsItem } from '@core/types';
import { AlertService } from '@core/services/alert.service';
import { SocketService } from '@core/services/socket.service';
/* import { alertCount, alertNotification, AlertService } from '@core/services/alert.service';
import { emitMessage, socketMessage, SocketService } from '@core/services/socket.service'; */

/******* Plug-In Resourse *******/
import { Subject } from 'rxjs';
import { filter, takeUntil, tap, throttleTime } from 'rxjs/operators';

@Component({
	selector: 'app-navbar-notification',
	templateUrl: './navbar-notification.component.html',
	styleUrls: ['./navbar-notification.component.scss']
})
export class NavbarNotificationComponent implements OnInit {

	// Public
	public alertNotificationCount: AlertGlobalCountings = {
		total: 0,
		signal: 0,
		hardware: 0,
		device: 0,
		license: 0
	}; // alert notification counting 
	public isLicenseActivate: boolean = false;
	public alertCategory: AlertCategory = AlertCategory.DEVICE; // Alert Category
	public alertNotification: AlertNotification = {
		device: [],
		signal: [],
		license: []
	}; // Alert Notification
	public isLoading: IsAlertNotificationLoading = {
		device: false,
		signal: false,
		license: false
	};
	public allowPopUpAlert: boolean = false;

	// Private
	private _unsubscribeAll$: Subject<any>; // unsubscribe subject
	@ViewChild('alertPool') private alertPool: ElementRef;
	private _currentPrefernceSettings: any;

	/**
	 * constructor
	 * 
	 * @param {PrivilegeService} _privilegeService
	 * @param {UtilsService} _utilsService
	 * @param {AlertService} _AlertService
	 * @param {Title} _titleService
	 * @param {SocketService} _socketService
	 */
	constructor(private _privilegeService: PrivilegeService,
		private _utilsService: UtilsService,
		private _alertService: AlertService,
		private _titleService: Title,
		private _socketService: SocketService) {

		// Set the private defaults
		this._unsubscribeAll$ = new Subject();
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		this._privilegeService.currentUser
			.pipe(takeUntil(this._unsubscribeAll$), filter(x => x != null))
			.subscribe((userData) => {
				const tenantType = userData.tenant.type;
				this.isLicenseActivate = tenantType === TenantType.MSP_PARTNER_ENTERPRISE || tenantType === TenantType.EVAL;
				this._currentPrefernceSettings = { ...userData.preferenceSettings };
				this.allowPopUpAlert = typeof userData.preferenceSettings?.AlertPopUp != 'undefined' ? userData.preferenceSettings.AlertPopUp : false;
				this._socketService.eventFlow(MessageType.DEVICE_ALARM).pipe(
					takeUntil(this._unsubscribeAll$)).subscribe((deviceAlarmMessage: MessageData) => {
						this._alertService.updateFromLiveAlert(deviceAlarmMessage);
					});
			});

		this._alertService.currentAlertGlobalCountings
			.pipe(takeUntil(this._unsubscribeAll$), filter(x => x != null))
			.subscribe((alertCount: AlertGlobalCountings) => {
				this.alertNotificationCount = { ...alertCount };
				const title = this.alertNotificationCount.total > 0 ? `(${this.alertNotificationCount.total}) BECentral` : "BECentral - BEC Technologies Inc.";
				this._titleService.setTitle(title);
			});

		this._alertService.currentAlert
			.pipe(takeUntil(this._unsubscribeAll$), filter(x => x != null))
			.subscribe((alertNotification: AlertNotification) => {
				this.alertNotification = { ...alertNotification };
				this.isLoading = {
					device: false,
					signal: false,
					license: false
				};
			});
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {

		// Unsubscribe from all subscriptions
		this._unsubscribeAll$.next();
		this._unsubscribeAll$.complete();
	}

	// Private Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * initial alert loading
	 */
	private _initAlert(loadingType: AlertCategory) {
		if (this.alertNotification[loadingType].length === 0 && this.alertNotificationCount[loadingType] > 0)
			this.loadAlertByPiece(loadingType);
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * handle event when dropdown open/close
	 * 
	 * @param {boolean} idDropdownOpen 
	 */
	public openEvent(idDropdownOpen: boolean): void {
		if (idDropdownOpen) this._initAlert(this.alertCategory);
	}

	/**
	 * handle event when tab changed
	 */
	public tabChanged(): void {
		this._initAlert(this.alertCategory);
		this.scrollToTop();
	}

	/**
	 * Alert set Read
	 * 
	 * @param {DeviceAlert} message
	 */
	public setAlertRead(message: DeviceAlert): void {
		this._alertService.setAlertRead(message);
		message.status = AlertReadingStatus.READ;
	}

	/**
	 * Set Read to All Alerts
	 */
	public readAllAlerts(): void {
		this.alertCategory = AlertCategory.DEVICE;
		this._alertService.readAllAlerts();
	}

	/**
	 * Scroll To Top
	 */
	public scrollToTop(): void {

		if (this.alertPool) {
			const divElement = this.alertPool.nativeElement;
			(function smoothscroll() {
				const currentScroll = divElement.scrollTop;
				if (currentScroll > 0) {
					window.requestAnimationFrame(smoothscroll);
					divElement.scrollTop = currentScroll - currentScroll / 4;
				}
			})();
		}
	}

	/**
	 * Load Alerts By Piece
	 * 
	 * @param type 
	 */
	public loadAlertByPiece(type: AlertCategory): void {
		this.isLoading[type] = true;
		this._alertService.loadAlarm(type);
	}

	/**
	 * handle allow poop up swtch change
	 */
	public allowPopUpAlertChange(): void {
		let preferenceSettings: UserPreferanceSettingsItem = {
			...this._currentPrefernceSettings,
			AlertPopUp: this.allowPopUpAlert
		};
		this._privilegeService.updateUserData({ preferenceSettings });
	}
}
