import { Pipe, PipeTransform } from "@angular/core";
import { GROUP_ROLE_NAME_MAPPING, ROLE_NAME_MAPPING } from "@core/constant/role-constant/role-name-mapping";
import { GroupRoleName, RoleName } from "@core/types";

@Pipe({
    name: 'roleNameFilter'
})
export class RoleNameFilterPipe implements PipeTransform {

    /**
     * Map role key to the name
     * 
     * @param {RoleName | string} roleKey 
     * @returns {string}
     */
    static mapRoleName(roleKey: RoleName | string): string {
        return ROLE_NAME_MAPPING.hasOwnProperty(roleKey) ? ROLE_NAME_MAPPING[roleKey] : roleKey;
    };

    /**
     * Map Group role key to the name
     * 
     * @param {RoleName | string} roleKey 
     * @returns {string}
     */
    static mapGroupRoleName(roleKey: GroupRoleName | string): string {
        return GROUP_ROLE_NAME_MAPPING.hasOwnProperty(roleKey) ? GROUP_ROLE_NAME_MAPPING[roleKey] : roleKey;
    };

    /**
     * First Letter Uppercase
     * 
     * @param {string} roleName 
     * @returns {string}
     */
    static firstLetterUpperCase(roleName: string): string {
        return roleName ? `${roleName.substring(0, 1).toUpperCase()}${roleName.substring(1).toLowerCase()}` : "";
    };

    /**
     * trandform role key to the readable name
     * 
     * @param {string} value
     * @returns {string} 
     */
    transform(value: string, isGroup: boolean = false): string {
        return isGroup ? RoleNameFilterPipe.mapGroupRoleName(value) : RoleNameFilterPipe.mapRoleName(value);
    }
};