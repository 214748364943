export interface UploadUrlRequestBody {
    name: string;
    type: string;
    fileType?: string;
    forceReplace?: boolean;
};

export interface NoticedBackendRequestBody {
    name: string;
    replace: boolean;
    fileType?: string;
    modelKey?: string;
};

export interface ShareFileRequestBody {
    usernames: Array<string>;
};