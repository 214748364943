import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'itemCount'
})
export class ItemCountPipe implements PipeTransform {
    
    /**
	 * Transform
	 *
	 * @param {string} input
     * @param {string} search
	 * @returns {number}
	 */
    transform(input: string, search: string): number {
        let test = search.trim();
        return test ? input.toUpperCase().split(test.toUpperCase()).length - 1 : 0;
    }
}
