/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { BackupRequestBody, CommandRequestBody, WiFiCommand } from '@core/types';

@Injectable()
export class DeviceCmd extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * POST /device-cmd - send command to device
     * 
     * @param {CommandRequestBody} data
     */
    public sendDeviceCommand(data: CommandRequestBody) {

        let method = "POST",
            url = "device-cmd",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /device-cmd/{id}/networkDevices - send command to get network devices
     * 
     * @param {string} id
     */
    public getNetworkDevices(id: string) {

        let method = "GET",
            url = "device-cmd/:id/networkDevices",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /device-cmd/{id}/{command}/wifiInfo - send command to get wifi info
     * 
     * @param {string} id
     * @param {WiFiCommand} command
     */
    public getWiFiInfo(id: string, command: WiFiCommand) {

        let method = "GET",
            url = "device-cmd/:id/:command/wifiInfo",
            routeParams = { id, command },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /device-cmd/{id}/configuration/backup
     * 
     * @param {string} id 
     * @param {BackupRequestBody} data
     */
    public backupConfiguration(id: string, data: BackupRequestBody) {

        let method = "POST",
            url = "device-cmd/:id/configuration/backup",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}