/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { ForgotPasswordRequestBody, ResetPasswordRequestBody, RoleRequest, UpdatePasswordRequestBody, UserRegisterRequestBody, VerifyResetPasswordRequestBody } from '@core/types';

@Injectable()
export class Roles extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /roles - get roles list
     * 
     * @param {any} filter
     */
    public getRoles(filter: any = {}) {
        return this.find("roles", filter);
    }

    /**
     * POST /roles - create new role
     * 
     * @param {RoleRequest} data
     */
    public createNewRole(data: RoleRequest) {

        let method = "POST",
            url = "roles",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /roles/{id} - update new role
     * 
     * @param {string} id
     * @param {RoleRequest} data
     */
    public updateRole(id: string, data: RoleRequest) {

        let method = "PATCH",
            url = "roles/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /roles/{id} - delete the role
     * 
     * @param {string} id
     */
    public deleteRole(id: string) {

        let method = "DELETE",
            url = "roles/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

}