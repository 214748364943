<div [ngClass]="containerClass">
    <label [ngClass]="labelClass" [for]="id">{{label}}</label>
    <div [ngClass]="inputClass">
        <div class="input-group input-group-merge" [ngClass]="{ 'is-invalid': invalid }">
            <input type="number" class="form-control" [ngClass]="{ 'is-invalid': invalid }" [id]="id" [(ngModel)]='value'
                [placeholder]="placeholder" [disabled]="isDisabled" (blur)="onTouch()" [autocomplete]="id">
        </div>
        <div *ngIf="invalid && displayErrorMessage" class="invalid-feedback">
            <ng-container *ngIf="isRequired && errors?.required">{{label}} is required</ng-container>
            <ng-container *ngIf="errors?.min">{{errors?.min?.actual}} is lower than {{errors?.min?.min}}</ng-container>
            <ng-container *ngIf="errors?.max">{{errors?.max?.actual}} is larger than {{errors?.max?.max}}</ng-container>
        </div>
    </div>
</div>