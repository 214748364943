import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'image-with-loader',
    templateUrl: './image-with-loader.component.html',
    styleUrls: ['./image-with-loader.component.scss']
})
export class ImageWithLoaderComponent implements OnInit {

    // public
    public imageloading: boolean = false; // image loader

    @Input() src: string = "";
    @Input() width: number = -1;
    @Input() height: number = -1;
    @Input() unit: string = "px";
    @Input() rounded: boolean = true;
    @Input() transparentBackground: boolean = false;

    constructor() {
        this.imageloading = true;
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------
    
    /**
     * On init
     */
    ngOnInit(): void {
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Hide Loader
     */
    public hideLoader() {
        this.imageloading = false;
    }
}
