import {
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexTitleSubtitle,
    ApexAnnotations,
    ApexNonAxisChartSeries,
    ApexDataLabels,
    ApexStroke,
    ApexLegend,
    ApexFill,
    ApexTooltip,
    ApexPlotOptions,
    ApexResponsive,
    ApexYAxis,
    ApexGrid,
    ApexStates,
    ApexTheme,
    ApexNoData,
    ApexMarkers
} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    chart: ApexChart;
    colors: string[];
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
    annotations: ApexAnnotations;
    dataLabels: ApexDataLabels;
    stroke: ApexStroke;
    labels: string[];
    legend: ApexLegend;
    fill: ApexFill;
    tooltip: ApexTooltip;
    plotOptions: ApexPlotOptions;
    responsive: ApexResponsive[];
    yaxis: ApexYAxis | ApexYAxis[];
    grid: ApexGrid;
    states: ApexStates;
    subtitle: ApexTitleSubtitle;
    theme: ApexTheme;
    noData: ApexNoData;
    markers: ApexMarkers;
};

