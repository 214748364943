import { PipeTransform, Pipe } from '@angular/core'

const Method_Order = {
	get : 0,
	post : 1,
	patch : 2,
	put : 3,
	delete : 4
};

@Pipe({
	name: 'sortByMethod'
})
export class SortByMethodPipe implements PipeTransform {
	
	transform(input:any): any{
		
        if(!Array.isArray(input)) return input;

        return input.sort(function(a:any,b:any){
            return Method_Order[a.method] - Method_Order[b.method];
        });
    }
}