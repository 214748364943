import { Language, LanguageObject } from "@core/types";

export const SUPPORT_LANGUAGE: Array<LanguageObject> = [
    {
        name: "English",
        value: Language.ENGILSH,
        flag: 'us'
    },
    {
        name: "繁體中文",
        value: Language.MANDARIN,
        flag: 'tw'
    },
    {
        name: "日本語",
        value: Language.JAPANESE,
        flag: 'jp'
    }
];