import { Pipe, PipeTransform } from '@angular/core';
import { TASK_TYPE_MAPPING, TASK_TYPE_MAPPING_DETAIL } from '@core/constant';

@Pipe({
	name: 'taskType'
})
export class TaskTypePipe implements PipeTransform {

	transform(inputData: string, abbreviation: boolean = true): string {
		const mapping = abbreviation ? TASK_TYPE_MAPPING : TASK_TYPE_MAPPING_DETAIL;
		return mapping.hasOwnProperty(inputData) ? mapping[inputData] : inputData;
	}
}