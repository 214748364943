export interface CellHistory {
    "id": string;
    "MAC": string;
    "timestamp": string;
    "NETMODE": string;
    "BAND": string;
    "ECI": string;
    "CELLID": string;
    "BW": number;
    "CELL_LATITUDE": number;
    "CELL_LONGITUDE": number;
    "RSRP": number;
    "RSRQ": number;
    "RSSI": number;
    "SINR": number;
    "RSRP_DIV1": number;
    "RSRP_DIV2": number;
    "RSRP_DIV3": number;
    "RSRQ_DIV1": number;
    "RSRQ_DIV2": number;
    "RSRQ_DIV3": number;
    "RSSI_DIV1": number;
    "RSSI_DIV2": number;
    "RSSI_DIV3": number;
    "SINR_DIV1": number;
    "SINR_DIV2": number;
    "SINR_DIV3": number;
    "PCI": string | number;
    "NETWORK": string;
    "NR_RSRP": number;
    "NR_SINR": number;
};