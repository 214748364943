import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "environments/environment";

const USER_SERVICE_URL: string = environment.userServiceUrl.substring(0);
const DEVICE_GATEWAY_URL: string = environment.deviceGatewayUrl.substring(-1);
const SCHEDULE_GATEWAY_URL: string = environment.scheduleGatewayUrl.substring(-1);

@Pipe({
    name: 'toApiPath'
})
export class ToApiPathPipe implements PipeTransform {
    
    /**
     * Transform - map api to service url
     * 
     * @param {string} url 
     * @returns {string}
     */
    transform(url: string): string {
        if (url.startsWith('/auth') ||
            url.startsWith('/roles') ||
            url.startsWith('/tenants') ||
            url.startsWith('/users') ||
            url.startsWith('/groups')) return USER_SERVICE_URL + url.substring(1);
        else if (url.startsWith('/Schedules')) return SCHEDULE_GATEWAY_URL + url.substring(1);
        else return DEVICE_GATEWAY_URL + url.substring(1);
    }    
}