/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { licenseActionToDeviceRequest, licenseAssignToTenantRequest } from '@core/types';

@Injectable()
export class Licenses extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /licenses - get licenses list
     * 
     * @param filter
     */
    public getlicenses(filter) {
        return this.find("licenses", filter);
    }

    /**
     * GET /licenses/count - get licenses list count
     * 
     * @param where
     */
    public getlicensesCount(where) {
        return this.count("licenses", where);
    }

    /**
     * POST /licenses - assign Licenses to tenants
     * 
     * @param {licenseAssignToTenantRequest} data
     */
    public assignLicenseToTenant(data: licenseAssignToTenantRequest) {

        let method = "POST",
            url = "licenses",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /licenses - action on license with MAC (assign, unclaim)
     * 
     * @param {licenseActionToDeviceRequest} data
     */
    public actionOnLicenseToDevice(data: licenseActionToDeviceRequest) {

        let method = "PATCH",
            url = "licenses",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /licenses/{id} - action on license with single MAC (renew, transfer)
     * 
     * @param {string} id
     * @param {licenseActionToDeviceRequest} data
     */
    public actionOnLicenseToSingleDevice(id: string, data: licenseActionToDeviceRequest) {

        let method = "PATCH",
            url = "licenses/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /licenses/devices/count - get all devices including unlicensed
     * 
     * @param {any} where
     */
    public getDevicesCount(where: any) {

        let method = "GET",
            url = "licenses/devices/count",
            routeParams = {},
            urlParams = { where },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /licenses/devices - get all devices including unlicensed
     * 
     * @param {any} filter
     */
    public getDevices(filter: any) {

        let method = "GET",
            url = "licenses/devices",
            routeParams = {},
            urlParams = { filter },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}