import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'striphtml'
})
export class StripHtmlPipe implements PipeTransform {
	
	/**
	 * Strip html input
	 * 
	 * @param {string} value 
	 * @returns {string}
	 */
	transform(value: string): string {
		return value.replace(/<(?:.|\n)*?>/gm, ' ');
	}
}
