import { GroupRoleName, GroupRoleNameMap, RoleName, RoleNameMap } from "@core/types";

export const ROLE_NAME_MAPPING: RoleNameMap = {
    [RoleName.SUPER_ADMIN]: "Super Admin",
    [RoleName.ADMIN]: "Admin",
    [RoleName.TENANT_GROUP_ADMIN]: "Group Admin",
    [RoleName.BANNED]: "Banned"
};

export const GROUP_ROLE_NAME_MAPPING: GroupRoleNameMap = {
    [GroupRoleName.GROUP_ADMIN]: "Group Admin",
    [GroupRoleName.GROUP_USER]: "Group User"
};

