export const COLORS = {
	solid: {
		primary: '#7367F0',
		secondary: '#82868b',
		success: '#28C76F',
		info: '#00cfe8',
		warning: '#FF9F43',
		danger: '#EA5455',
		dark: '#4b4b4b',
		black: '#000',
		white: '#fff',
		body: '#f8f8f8'
	},
	light: {
		primary: '#7367F01a',
		secondary: '#82868b1a',
		success: '#28C76F1a',
		info: '#00cfe81a',
		warning: '#FF9F431a',
		danger: '#EA54551a',
		dark: '#4b4b4b1a'
	}
};

export const COLORS_SET = [
	"#00876c",
	"#3b966c",
	"#5fa46b",
	"#80b16b",
	"#a2be6d",
	"#c4ca72",
	"#e8d57b",
	"#e9be66",
	"#e9a657",
	"#e88e4e",
	"#e4744b",
	"#de5a4c",
	"#d43d51",
	"#003f5c",
	"#2f4b7c",
	"#665191",
	"#a05195",
	"#d45087",
	"#f95d6a",
	"#ff7c43",
	"#ffa600"
];