import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { PrivilegeService } from '@core/services/privilege.service';

@Component({
	selector: '[core-menu]',
	templateUrl: './core-menu.component.html',
	styleUrls: ['./core-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {

	@Input() layout = 'vertical';

	@Input() menu: any;

	// Private
	private _unsubscribeAll$: Subject<any>;

	/**
	 * Constructor
	 * 
	 * @param {ChangeDetectorRef} _changeDetectorRef
	 * @param {CoreMenuService} _coreMenuService
	 * @param {PrivilegeService} _privilegeService
	 */
	constructor(private _changeDetectorRef: ChangeDetectorRef,
		private _coreMenuService: CoreMenuService,
		private _privilegeService: PrivilegeService) {

		// Set the private defaults
		this._unsubscribeAll$ = new Subject();
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		// Set the menu either from the input or from the service
		this.menu = this.menu || this._coreMenuService.getCurrentMenu();

		// Subscribe to the current menu changes
		this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll$)).subscribe(() => {
			this.menu = this._coreMenuService.getCurrentMenu(); // Load menu
			this._changeDetectorRef.markForCheck();
		});

		// Subscribe to the current user changes
		this._privilegeService.currentUser.pipe(takeUntil(this._unsubscribeAll$), filter(x => x != null)).subscribe((userData) => {
			this._coreMenuService.unregister('main');
			this._coreMenuService.register('main', this._privilegeService.privilegedMenu); // Register the menu to the menu service
			this._coreMenuService.setCurrentMenu('main'); // Set the main menu as our current menu
		});
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {

		// Unsubscribe from all subscriptions
		this._unsubscribeAll$.next();
		this._unsubscribeAll$.complete();
	}

	// Private Method
	// -----------------------------------------------------------------------------------------------------
}
