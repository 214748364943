export interface LoginRequestBody {
    clientId: string;
    clientSecret: string;
    CustID?: string;
    username: string;
    password: string;
    authCode?: string;
};

export interface VerifyResetPasswordRequestBody {
    email: string;
    token: string;
};

export interface ResetPasswordRequestBody {
    email: string;
    token: string;
    password: string;
};

export interface UpdatePasswordRequestBody {
    password: string;
    newPassword: string;
};

export interface ForgotPasswordRequestBody {
    email: string;
};

export interface UserRegisterRequestBody {
    defaultTenant: string;
    apiConsume?: string;
    roleId: string;
    password: string;
    email: string;
    phone?: string;
    firstName: string;
    lastName: string;
    timeZone: string;
};

export interface CreateApiConsumerRequestBody {
    apiKeyName: string;
};