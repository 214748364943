/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Filter } from '@core/types';

@Injectable()
export class DeviceReports extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /device-reports/count - get count of device-reports
     * 
     * @param {any} where
     */
    public getDeviceReportsCount(where: any) {
        return this.count('device-reports', where);
    }

    /**
     * GET /device-reports - get device-reports
     * 
     * @param {Filter} filter
     */
    public getDeviceReports(filter: Filter) {
        return this.find('device-reports', filter);
    }

    /**
     * GET /device-reports/{id} - get device-report by id
     * 
     * @param {string} id
     */
    public getDeviceReportById(id: string) {
        return this.findById('device-reports', id);
    }

    /**
     * POST /device-reports - create device-reports
     * 
     * @param {any} data
     */
    public createDeviceReport(data: any) {

        let method = "POST",
            url = "device-reports",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /device-reports/{id} - update device-report by id
     * 
     * @param {string} id
     * @param {any} data
     */
    public updateDeviceReport(id: string, data: any) {

        let method = "PATCH",
            url = "device-reports/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /device-reports/{id} - delete device-reports by id
     * 
     * @param {string} id
     */
    public deleteDeviceReport(id: string) {

        let method = "DELETE",
            url = "device-reports/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /device-reports/{id}/export - export device-reports by id
     * 
     * @param {string} id
     */
    public exportDeviceReport(id: string, data: any) {

        let method = "POST",
            url = "device-reports/:id/export",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}