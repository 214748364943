/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AlertCounter extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /alert-counter/count - get alert countings
     */
    public getGlobalAlertCountings() {
        return this.count('alert-counter', {});
    }

    /**
     * GET /alert-counter/{id} - get alert countings by device id
     * 
     * @param {string} id
     */
    public getAlertCountings(id) {

        let method = "GET",
            url = "alert-counter/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /alert-counter/{id}/reset - delete alert countings by device id
     * 
     * @param {string} id
     */
    public deleteAlertCountings(id) {

        let method = "DELETE",
            url = "alert-counter/:id/reset",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}