import { ConfigurationFunction, ConfigurationFunctionNameMap } from "@core/types";

export const CONFIGURATION_FUNCTION_NAME_MAP: ConfigurationFunctionNameMap = {
    [ConfigurationFunction.INTERNET_LTE]: "LTE",
    [ConfigurationFunction.INTERNET_5G_NR]: "5G NR",
    [ConfigurationFunction.BECENTRAL]: "BECentral",
    [ConfigurationFunction.CBSD]: "CBSD",
    // [ConfigurationFunction.WIFI_SETTINGS]: "2.4g Wi-Fi",
    // [ConfigurationFunction.WIFI_5G_SETTINGS]: "5g Wi-Fi",
    [ConfigurationFunction.SERIAL_1]: "Serial Port 1",
    [ConfigurationFunction.SERIAL_2]: "Serial Port 2",
    [ConfigurationFunction.RGOOSE]: "RGOOSE",
    [ConfigurationFunction.PTP]: "PTP",
    [ConfigurationFunction.DEVICE_MANAGMENT]: "Device Management",
    [ConfigurationFunction.ACCESS_CONTROL]: "Access Control",
    [ConfigurationFunction.USER_MANAGEMENT]: "User Management"
}