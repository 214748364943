/******* Angular Resourse *******/
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'signal-bar',
  templateUrl: './signal-bar.component.html',
  styleUrls: ['./signal-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignalBarComponent implements OnInit {
  
    //Public 

    /* Component Input */
    @Input('color') background_color: string = "#b1b1b1";
    @Input('maxLevel') maxLevel: number = 4;
    @Input('level') level: number = 0;
    @Input('strength') strength: string = "-106";
    @Input('comment') comment: string = "OK";

    /**
     * Constructor
     */
    constructor() {}
    
    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    // Public methods
    // -----------------------------------------------------------------------------------------------------
    
    /**
     * Return the array with max length and fill 0
     */
    randomArray() {
        return new Array(this.maxLevel).fill(0);
    }
    
}
