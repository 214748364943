import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { NgxDatatableResizeWatcherDirective } from '@core/directives/ngx-datatable-resize-watcher/ngx-datatable-resize-watcher.directive';
import { HiddenOnWidthDirective } from '@core/directives/hidden-on-width/hidden-on-width.directive';
import { ClickAndDragScrollingDirective } from '@core/directives/horizontal-click-and-scroll/horizontal-click-and-scroll.directive';
import { PrivilegedAccessControlItemDirective } from './privileged-access-control-item/privileged-access-control-item.directive';

@NgModule({
	declarations: [
		RippleEffectDirective,
		FeatherIconDirective,
		NgxDatatableResizeWatcherDirective,
		HiddenOnWidthDirective,
		ClickAndDragScrollingDirective,
		PrivilegedAccessControlItemDirective],
	exports: [
		RippleEffectDirective,
		FeatherIconDirective,
		NgxDatatableResizeWatcherDirective,
		HiddenOnWidthDirective,
		ClickAndDragScrollingDirective,
		PrivilegedAccessControlItemDirective]
})
export class CoreDirectivesModule { }
