import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ellipsisBlock'
})
export class EllipsisBlockPipe implements PipeTransform {
	
	/**
     * Transform
     * 
     * @param {string} input
	 * @param {number} length 
     * @returns {string}
     */
	transform(input: string, length: number = 30): string {
		if (input) return input.length <= length ? input : input.substring(0, length) + "...";
		else return "";
	}
}
