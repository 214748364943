<div [ngClass]="containerClass">
    <label [ngClass]="labelClass" [for]="id">{{label}}</label>
    <div [ngClass]="inputClass">
        <div class="input-group input-group-merge" [ngClass]="{ 'is-invalid': invalid }">
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': invalid }" [id]="id" [(ngModel)]='value'
                [placeholder]="placeholder" [disabled]="isDisabled" (blur)="onTouch()" [autocomplete]="id"
                [maxlength]="maxlength">
        </div>
        <div *ngIf="invalid && displayErrorMessage" class="invalid-feedback">
            <ng-container *ngIf="isRequired && errors?.required">{{label}} is required</ng-container>
            <ng-container *ngIf="isEmail && errors?.email">Email format is incorrect</ng-container>
            <ng-container *ngIf="errors?.requiredIpFormat">IP format is incorrect</ng-container>
            <ng-container *ngIf="errors?.requiredDhcpLeaseTimeFormat">Leasetime format is incorrect</ng-container>
            <ng-container *ngIf="errors?.url">Url format is incorrect</ng-container>
            <ng-container *ngIf="errors?.speedTestUrl">
                Url format is incorrect (ex. www.speedtest.myserver.com:8080)</ng-container>
            <ng-container *ngIf="errors?.emailLoop">
                Email loop format is incorrect (ex. john.doe&#64;gmail.com;jane.doe&#64;hotmail.com;)</ng-container>
        </div>
    </div>
</div>