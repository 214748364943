<div class="auth-wrapper auth-v1 px-2 twofa-section">
    <div class="global-rllipse"></div>
    <div class="auth-inner py-2">
        <div class="card mb-0">
            <div class="card-body">
                <div class="twofa-form-header">
                    <h5 class="mb-0">1. Scan this QR code with your Google Authenticator </h5>
                    <p><small>Once your app reads the QR coe, you'll get 6-digit code</small></p>
                </div>

                <div class="row">
                    <div class="col-sm-6 mb-1 d-flex align-items-center">
                        <div class="w-100 text-center" *ngIf="qrCodeContent">
                            <img height="125" width="125" alt="qrCode" [src]="qrCodeContent" />
                        </div>
                    </div>
                    <div class="col-sm-6 mb-1 d-none d-sm-flex">
                        <div class="w-100 text-center">
                            <img src="assets/images/pages/scanning.png" alt="logo" width="250" />
                        </div>
                    </div>
                </div>

                <div class="divider mb-0">
                    <div class="divider-text">Google Authenticator</div>
                </div>
                <div class="d-flex align-items-center justify-content-center mb-1">
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        target="_blank">
                        <img src="assets/images/pages/download_google.png" alt="logo" width="120" height="50" />
                    </a>
                    <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                        <img src="assets/images/pages/download_apple.png" alt="logo" width="100" />
                    </a>
                </div>

                <div class="twofa-form-header">
                    <h5 class="mb-0">2. Enter the 6-digit code here </h5>
                    <p><small>Enter the code from the app below</small></p>
                </div>

                <form [formGroup]="codeForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-sm-7">
                            <text-input [id]="'verify-twofa-code'" [label]="'6-digit code'" formControlName="code"
                                [submitted]="submitted" [displayErrorMessage]="false" [maxlength]="6"></text-input>
                        </div>
                        <div class="col-sm-5 d-flex align-items-end mb-1">
                            <button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block"
                                rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Verify
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>