import { MarkerClusterGroup, LayerGroup } from "leaflet";

export enum MapIcons {
    DEVICE = 'pin_device',
    DEVICE_GREY = 'pin_device-grey',
    CELL = 'pin_cell',
    CELL_4G_BLUE = 'pin_cell-4G-Blue',
    CELL_4G_GREEN = 'pin_cell-4G-Green',
    CELL_4G_ORANGE = 'pin_cell-4G-Orange',
    CELL_4G_RED = 'pin_cell-4G-Red',
    CELL_5G_BLUE = 'pin_cell-5G-Blue',
    CELL_5G_GREEN = 'pin_cell-5G-Green',
    CELL_5G_ORANGE = 'pin_cell-5G-Orange',
    CELL_5G_RED = 'pin_cell-5G-Red'
};

export enum OverlayMapsType {
    MARKERS = "markers",
    CELL_MARKERS = "cellMarkers",
    CELL_MARKERS_HISTORY = "cellMarkersHistory",
    POLY = "poly"
};

export type OverlayMaps = {
    [key in OverlayMapsType]: LayerGroup | MarkerClusterGroup;
};

export interface PopupOptions {
    keepInView: boolean; // false
    closeButton: boolean; // true
    autoClose: boolean; // true
    closeOnClick: boolean; // 
    className: string; // ''
};

export interface Popup {
    content: string;
    options?: Partial<PopupOptions>
};

export interface MapType {
    "Bright": string;
    "Bright-Smooth": string;
    "Dark": string;
    "Liberty": string;
};