import { UntypedFormGroup } from "@angular/forms";

export enum RequestMethods {
    GET = "get",
    POST = "post",
    DELETE = "delete",
    PATCH = "patch"
};

export interface ApiItem {
    name: string;
    method: RequestMethods;
    description: string;
    path?: Array<any>;
    body?: Array<any>;
    filter?: Array<any>;
    form: UntypedFormGroup;
    submitted: boolean;
    isLoading: boolean;
    response: any;
};