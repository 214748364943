/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { AlertPolicyRequestBody, GroupRegisterRequestBody, GroupUserRequestBody, RoleRequest } from '@core/types';

@Injectable()
export class Groups extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /groups - get groups list
     * 
     * @param filter
     */
    public getGroups(filter) {
        return this.find("groups", filter);
    }

    /**
     * GET /groups/{id} - get group data
     * 
     * @param id
     */
    public getGroupOne(id) {
        return this.findById("groups", id);
    }

    /**
     * GET /groups/stats - get groups list with device info
     */
    public getGroupsWithDevice() {

        let method = "GET",
            url = "groups/stats",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /groups/{id}/roles - get groups role
     * 
     * @param id
     */
    public getGroupRoles(id) {

        let method = "GET",
            url = "groups/:id/roles",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /groups/{id}/roles - save groups role
     * 
     * @param {string} id
     * @param {RoleRequest} data
     */
    public saveGroupRole(id: string, data: RoleRequest) {

        let method = "POST",
            url = "groups/:id/roles",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /groups/{id}/roles/{roleId} - update groups role
     * 
     * @param {string} id
     * @param {string} roleId
     * @param {RoleRequest} data
     */
    public updateGroupRole(id: string, roleId: string, data: RoleRequest) {

        let method = "PATCH",
            url = "groups/:id/roles/:roleId",
            routeParams = { id, roleId },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /groups/{id}/roles/{roleId} - delete groups role
     * 
     * @param {string} id
     * @param {string} roleId
     */
    public deleteGroupRole(id: string, roleId: string) {

        let method = "DELETE",
            url = "groups/:id/roles/:roleId",
            routeParams = { id, roleId },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /groups/{id}/users - get groups role
     * 
     * @param id
     */
    public getGroupUsers(id) {

        let method = "GET",
            url = "groups/:id/users",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /groups - create new group
     * 
     * @param {GroupRegisterRequestBody} data 
     */
    public createGroup(data: GroupRegisterRequestBody) {

        let method = "POST",
            url = "groups",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /groups/{id} - update group by id
     * 
     * @param id 
     * @param data 
     */
    public updateGroup(id: string, data: GroupRegisterRequestBody) {

        let method = "PATCH",
            url = "groups/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST groups/{id}/users/{userId} - assign group to devices
     * 
     * @param id 
     * @param data 
     */
    public addingUserIntoGroup(id: string, userId: string[]) {

        let method = "POST",
            url = "groups/:id/users/:userId",
            routeParams = { id, userId },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST device-group/groups/{id}/devices - assign group to devices
     * 
     * @param id 
     * @param data 
     */
    public assignGroupToDevice(id: string, data: string[]) {

        let method = "POST",
            url = "device-group/:id/devices",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE_WITH_BODY device-group/devices - assign group to devices
     * 
     * @param {string[]} data 
     */
    public unassignGroupFromDevice(data: string[]) {

        let method = "DELETE_WITH_BODY",
            url = "device-group/devices",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /groups/{id} - delete group
     * 
     * @param {string} id 
     */
    public deleteGroup(id: string) {
        return this.deleteById("groups", id);
    }

    /**
     * POST groups/{id}/users - add group user only for group admin
     * 
     * @param {string} id
     * @param {GroupUserRequestBody} data 
     */
    public addGroupUserByGroupAdmin(id: string, data: GroupUserRequestBody) {

        let method = "POST",
            url = "groups/:id/users",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH groups/{id}/users/{userId} - add group user only for group admin
     * 
     * @param {string} id
     * @param {GroupUserRequestBody} data 
     */
    public updateGroupUserByGroupAdmin(id: string, userId: string, data: GroupUserRequestBody) {

        let method = "PATCH",
            url = "groups/:id/users/:userId",
            routeParams = { id, userId },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST groups/{id}/users/{userId} - remove group user only for group admin
     * 
     * @param {string} id
     * @param {string} userId 
     */
    public deleteGroupUserByGroupAdmin(id: string, userId: string) {

        let method = "DELETE",
            url = "groups/:id/users/:userId",
            routeParams = { id, userId },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}