interface NameValueObject {
    name : string,
    value : any
};

export const AlertType : Array<NameValueObject> = [
    {name : "All", value : ""},
    {name : "License", value : "License"},
    {name : "RSSI", value : "RSSI"},
    {name : "RSRP", value : "RSRP"},
    {name : "RSRQ", value : "RSRQ"},
    {name : "SINR", value : "SINR"},
    {name : "Temperature", value : "TEMP"},
    {name : "Bandwidth", value : "Bandwidth"},
    {name : "Unit Powered Up", value : "Reboot"},
    {name : "Update Firmware", value : "Update Firmware"},
    {name : "Update Configuration", value : "Configuration Changes"},
    {name : "Cell Bouncing", value : "Cell Bouncing"},
    {name : "IP Change", value : "IP Change"},
    {name : "Failover", value : "Failover"},
    {name : "Downtime", value : "Downtime"},
    {name : "Ethernet Interface Change", value : "Ethernet interface change"},
    {name : "Web UI login failure", value : "Web UI login failure"}
];

export const WebhookEventType: any = [
    {name : "All", value : ""},
    {name : "RSSI", value : "RSSI"},
    {name : "RSRP", value : "RSRP"},
    {name : "RSRQ", value : "RSRQ"},
    {name : "SINR", value : "SINR"},
    {name : "Temperature", value : "TEMP"},
    {name : "Bandwidth", value : "Bandwidth"},
    {name : "Unit Powered Up", value : "Reboot"},
    {name : "Update Firmware", value : "UpdateFirmware"},
    {name : "Update Configuration", value : "ConfigurationChanges"},
    {name : "Cell Bouncing", value : "CellBouncing"},
    {name : "IP Change", value : "IPChange"},
    {name : "Failover", value : "Failover"},
    {name : "Downtime", value : "Downtime"},
    {name : "Ethernet Interface Change", value : "Ethernetinterfacechange"},
    {name : "Web UI login failure", value : "WebUIloginfailure"},
    {name : "Device Information", value : "device_info"}
];
