
import { TwoFactorType } from "../auth-types";

export interface UserPreferanceSettingsItem {
    PageLength: number;
    SortingName: number;
    SortingOrder: "ASC" | "DESC";
    Visibility: string;
    Skin: "default" | "bordered" | "dark" | "semi-dark"; 
    AlertPopUp?: boolean;
};

export interface UpdateUserMeRequestBody {
    // profile
    firstName?: string;
    lastName?: string;
    timeZone?: string;
    autoLogoutTimer?: number;
    // 2fa
    twoFactorAuth?: Array<TwoFactorType>;
    // bandwidth settings
    bandwidthThreshold?: number;
    bandwidthThresholdUnit?: "GB" | "MB";
    // preferenceSettings
    preferenceSettings?: UserPreferanceSettingsItem
};