/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ExternalRequestService extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * POST URL
     * 
     * @param {string} url 
     * @param {any} data
     */
    public post(url: string, data: any) {
        return this.postRequest(url, data);
    }
}