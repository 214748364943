import { TimeZone } from "../time-zone-types";

export enum ExportAction {
    EXPORT = 'export',
    RETRY = 'retry'
};

export interface ExportDeviceReportRequestBody {
    name: string;
    type: string;
    description: string;
    timezone: TimeZone;
    action: ExportAction;
    emailLoop: string;
    jobIds?: Array<any>;
};