export const NETWORKMODE = {
    Sequans: {
        Advanced: false,
        Mode: [
            { name: "LTE Only", value: "6" }
        ]
    },
    ALT_MC7475: {
        Advanced: false,
        Mode: [
            { name: "LTE Only", value: "6" }
        ]
    },
    ALT_LP42: {
        Advanced: false,
        Mode: [
            { name: "LTE Only", value: "6" }
        ]
    },
    Cellient: {
        Advanced: false,
        Mode: [
            { name: "LTE Only", value: "6" }
        ]
    },
    MLG6772: {
        Advanced: false,
        Mode: [
            { name: "LTE Only", value: "6" }
        ]
    },
    SIMCOM: {
        Advanced: false,
        Mode: [
            { name: "Automatic", value: "0" },
            { name: "UMTS 3G Only", value: "1" },
            { name: "LTE Only", value: "6" }
        ]
    },
    MC7700: {
        Advanced: false,
        Mode: [
            { name: "Automatic", value: "0" },
            { name: "UMTS 3G Only", value: "1" },
            { name: "GSM 2G Only", value: "2" },
            { name: "UMTS 3G Preferred", value: "3" },
            { name: "GSM 2G Preferred", value: "4" },
            { name: "GSM and UMTS Only", value: "5" },
            { name: "LTE Only", value: "6" },
            { name: "GSM, UMTS, LTE", value: "7" }
        ]
    },
    MC7354: {
        Advanced: true,
        Name: "MC7355_PROFILE",
        Map: {
            GENERIC: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "GSM 2G Only", value: "2" },
                { name: "UMTS 3G Preferred", value: "3" },
                { name: "GSM 2G Preferred", value: "4" },
                { name: "GSM and UMTS Only", value: "5" },
                { name: "LTE Only", value: "6" },
                { name: "GSM, UMTS, LTE", value: "7" },
                { name: "LTE All", value: "9" },
                { name: "LTE B25 and CDMA", value: "11" },
                { name: "LTE and North America CDMA", value: "0C" },
                { name: "LTE B17 and 3G B4", value: "0D" },
                { name: "LTE B2 and 3G B5", value: "0E" },
                { name: "LTE B2 and B25", value: "1C" },
                { name: "3G Band 5", value: "1D" },
                { name: "3G Band 2", value: "24" },
                { name: "CDMA Only", value: "23" }
            ],
            DEFAULT: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "GSM 2G Only", value: "2" },
                { name: "UMTS 3G Preferred", value: "3" },
                { name: "GSM 2G Preferred", value: "4" },
                { name: "GSM and UMTS Only", value: "5" },
                { name: "LTE Only", value: "6" },
                { name: "GSM, UMTS, LTE", value: "7" }
            ]
        }
    },
    MC7455: {
        Advanced: true,
        Name: "CARRIER_FW_CURRENT_NAME",
        Map: {
            GENERIC: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "GSM 2G Only", value: "2" },
                { name: "UMTS 3G Preferred", value: "3" },
                { name: "GSM 2G Preferred", value: "4" },
                { name: "GSM and UMTS Only", value: "5" },
                { name: "LTE Only", value: "6" },
                { name: "GSM, UMTS, LTE", value: "7" },
                { name: "LTE All", value: "9" },
                { name: "LTE B25 and CDMA", value: "11" },
                { name: "LTE and North America CDMA", value: "0C" },
                { name: "LTE B17 and 3G B4", value: "0D" },
                { name: "LTE B2 and 3G B5", value: "0E" },
                { name: "LTE B2 and B25", value: "1C" },
                { name: "3G Band 5", value: "1D" },
                { name: "3G Band 2", value: "24" },
                { name: "CDMA Only", value: "23" }
            ],
            DEFAULT: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "LTE Only", value: "6" }
            ]
        }
    },
    Quectel: {
        Advanced: true,
        Name: "QUECTEL_MODEL",
        Map: {
            EC25E: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "GSM 2G Only", value: "2" },
                { name: "LTE Only", value: "6" }
            ],
            EC25J: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "LTE Only", value: "6" }
            ],
            EC25A: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "LTE Only", value: "6" }
            ],
            EC25AF: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "LTE Only", value: "6" }
            ],
            EC25AU: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "GSM 2G Only", value: "2" },
                { name: "LTE Only", value: "6" }
            ],
            BG96: [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" },
                { name: "Cat. M1", value: "M1" },
                { name: "Cat. NB1 ", value: "NB" }
            ],
            EG18NA: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "LTE Only", value: "6" }
            ],
            EM12G: [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" }
            ],
            EC25B41: [
                { name: "LTE Only", value: "6" }
            ],
            "RM500Q-GL": [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" },
                { name: "LTE + 5G NR", value: "56" },
                { name: "5G NR", value: "50" }
            ],
            "RM500Q-AE": [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" },
                { name: "LTE + 5G NR", value: "56" },
                { name: "5G NR", value: "50" }
            ],
            "RM520N-GL": [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" },
                { name: "LTE + 5G NR", value: "56" },
                { name: "5G NR", value: "50" }
            ],
            "RG520N-EB": [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" },
                { name: "LTE + 5G NR", value: "56" },
                { name: "5G NR", value: "50" }
            ],
            "RG520N-GT": [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" },
                { name: "LTE + 5G NR", value: "56" },
                { name: "5G NR", value: "50" }
            ],
            DEFAULT: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "GSM 2G Only", value: "2" },
                { name: "UMTS 3G Preferred", value: "3" },
                { name: "GSM 2G Preferred", value: "4" },
                { name: "GSM and UMTS Only", value: "5" },
                { name: "LTE Only", value: "6" },
                { name: "GSM, UMTS, LTE", value: "7" }
            ]
        }
    },
    Telit: {
        Advanced: true,
        Name: "MODULE_NAME_SUB",
        Map: {
            FN990: [
                { name: "Automatic", value: "0" },
                { name: "LTE Only", value: "6" },
                { name: "LTE+5G NR", value: "56" },
                { name: "5G NR", value: "50" }
            ],
            DEFAULT: [
                { name: "Automatic", value: "0" },
                { name: "UMTS 3G Only", value: "1" },
                { name: "GSM 2G Only", value: "2" },
                { name: "UMTS 3G Preferred", value: "3" },
                { name: "GSM 2G Preferred", value: "4" },
                { name: "GSM and UMTS Only", value: "5" },
                { name: "LTE Only", value: "6" },
                { name: "GSM, UMTS, LTE", value: "7" }
            ]
        }
    },
    DEFAULT: [
        { name: "Automatic", value: "0" },
        { name: "UMTS 3G Only", value: "1" },
        { name: "GSM 2G Only", value: "2" },
        { name: "UMTS 3G Preferred", value: "3" },
        { name: "GSM 2G Preferred", value: "4" },
        { name: "GSM and UMTS Only", value: "5" },
        { name: "LTE Only", value: "6" },
        { name: "GSM, UMTS, LTE", value: "7" }
    ]
};

export const BANDLIST = {
    Quectel: {
        Advanced: true,
        Name: "QUECTEL_MODEL",
        Map: {
            EC25A: [
                { name: 'Automatic', value: '0' },
                { name: "B2", value: "10" },
                { name: "B4", value: "0B" },
                { name: "B12", value: "12" },
                { name: "B17", value: "0A" }
            ],
            EC25E: [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B3', value: '3' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B20', value: '20' },
                { name: 'B38', value: '38' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' }
            ],
            EC25AU: [
                { name: 'B1', value: '1' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B28', value: '28' },
                { name: 'B40', value: '40' }
            ],
            EC25AF: [
                { name: 'Automatic', value: '0' },
                { name: 'B2', value: '2' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ],
            EC25J: [
                { name: 'Automatic', value: '0' }
            ],
            EC25V: [
                { name: 'Automatic', value: '0' }
            ],
            EC25B41: [
                { name: 'B41', value: 'B41' }
            ],
            EG12GT: [
                { name: 'Automatic', value: '0' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B48', value: '48' }
            ],
            EG18NA: [
                { name: 'B2', value: '2' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B41', value: '41' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ],
            EM12G: [
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B9', value: '9' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B21', value: '21' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B28', value: '28' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B32', value: '32' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B66', value: '66' }

            ],
            /* 5G NR */
            "RM500Q-GL": [
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B28', value: '28' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B32', value: '32' },
                { name: 'B34', value: '34' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B48', value: '48' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ],
            "RM500Q-AE": [
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B28', value: '28' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B32', value: '32' },
                { name: 'B34', value: '34' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B48', value: '48' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ],
            "RM520N-GL": [
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B6', value: '6' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B9', value: '9' },
                { name: 'B10', value: '10' },
                { name: 'B11', value: '11' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B21', value: '21' },
                { name: 'B23', value: '23' },
                { name: 'B24', value: '24' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B27', value: '27' },
                { name: 'B28', value: '28' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B31', value: '31' },
                { name: 'B32', value: '32' },
                { name: 'B33', value: '33' },
                { name: 'B34', value: '34' },
                { name: 'B35', value: '35' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B46', value: '46' },
                { name: 'B47', value: '47' },
                { name: 'B48', value: '48' },
                { name: 'B53', value: '53' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ],
            "RG520N-EB": [
                { name: 'B1', value: '1' },
                { name: 'B3', value: '3' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B20', value: '20' },
                { name: 'B28', value: '28' },
                { name: 'B32', value: '32' },
                { name: 'B38', value: '38' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B71', value: '71' }
            ],
            "RG520N-GT": [
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B48', value: '48' }
            ],
            EG25G: [
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B28', value: '28' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' }
            ],
            DEFAULT: [
                { name: 'Automatic', value: '0' }
            ]
        }
    },
    SIMCOM: {
        Advanced: false,
        Band: [
            { name: 'Automatic', value: '0' }
        ]
    },
    MC7304: {
        Advanced: false,
        Band: [
            { name: 'Automatic', value: '0' },
            { name: "B1", value: "10" },
            { name: "B3", value: "11" },
            { name: "B7", value: "12" },
            { name: "B8", value: "13" },
            { name: "B20", value: "0A" },
        ]
    },
    MC7354: {
        Advanced: true,
        Name: "MC7355_PROFILE",
        Map: {
            GENERIC: [
                { name: 'Automatic', value: '0' },
                { name: 'B2', value: '10' },
                { name: 'B4', value: '0B' },
                { name: 'B5', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B17', value: '0A' },
                { name: 'B25', value: '9' }
            ],
            ATT: [
                { name: 'Automatic', value: '0' },
                { name: 'B2', value: '10' },
                { name: 'B4', value: '0B' },
                { name: 'B17', value: '0A' }
            ],
            DEFAULT: [
                { name: 'Automatic', value: '0' },
                { name: 'B2', value: '10' },
                { name: 'B4', value: '0B' },
                { name: 'B5', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B17', value: '0A' },
                { name: 'B25', value: '9' }
            ]
        }
    },
    MC7700: {
        Advanced: false,
        Band: [
            { name: 'Automatic', value: '0' },
            { name: "B4", value: "0B" },
            { name: "B17", value: "0A" }
        ]
    },
    MC7455: {
        Advanced: true,
        Name: "CARRIER_FW_CURRENT_NAME",
        Map: {
            GENERIC: [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B41', value: '41' }
            ],
            ATT: [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B41', value: '41' }
            ],
            SPRINT: [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B41', value: '41' }
            ],
            "US-Cellular": [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B41', value: '41' }
            ],
            VZW: [
                { name: 'Automatic', value: '0' },
                { name: "B2", value: "2" },
                { name: "B4", value: "3" },
                { name: "B5", value: "4" },
                { name: "B13", value: "13" }
            ],
            VERIZON: [
                { name: 'Automatic', value: '0' },
                { name: "B2", value: "2" },
                { name: "B4", value: "3" },
                { name: "B5", value: "4" },
                { name: "B13", value: "13" }
            ],
            DEFAULT: [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B41', value: '41' }
            ]
        }
    },
    GCT: {
        Advanced: true,
        Name: "GCTMODULETYPE",
        Map: {
            B12B17: [
                { name: 'B12', value: '12' },
                { name: 'B17', value: '17' },
            ],
            B3B7: [
                { name: 'B3', value: '3' },
                { name: 'B7', value: '7' },
            ],
            DEFAULT: [
                { name: 'B12', value: '12' },
                { name: 'B17', value: '17' },
            ]
        }
    },
    ALT_LP42: {
        Advanced: false,
        Band: [
            { name: 'Automatic', value: '0' },
            { name: "B31", value: "31" },
            { name: "B38", value: "38" }
        ]
    },
    MLG6772: {
        Advanced: true,
        Name: "MODULE_NAME_SUB",
        Map: {
            MLG714C: [
                { name: 'Automatic', value: '0' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B48', value: '48' },
            ],
            MLG703: [
                { name: 'Automatic', value: '0' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B48', value: '48' },
            ],
            DEFAULT: [
                { name: 'Automatic', value: '0' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B48', value: '48' }
            ]
        }
    },
    EM7511: {
        Advanced: false,
        Band: [
            { name: 'Automatic', value: '0' },
            { name: 'B1', value: '1' },
            { name: 'B2', value: '2' },
            { name: 'B4', value: '4' },
            { name: 'B5', value: '5' },
            { name: 'B12', value: '12' },
            { name: 'B13', value: '13' },
            { name: 'B14', value: '14' },
            { name: 'B18', value: '18' },
            { name: 'B19', value: '19' },
            { name: 'B26', value: '26' },
            { name: 'B29', value: '29' },
            { name: 'B30', value: '30' },
            { name: 'B32', value: '32' },
            { name: 'B41', value: '41' },
            { name: 'B42', value: '42' },
            { name: 'B43', value: '43' },
            { name: 'B46', value: '46' },
            { name: 'B48', value: '48' },
            { name: 'B66', value: '66' }
        ]
    },
    EM7565: {
        Advanced: false,
        Band: [
            { name: 'Automatic', value: '0' },
            { name: 'B1', value: '1' },
            { name: 'B2', value: '2' },
            { name: 'B4', value: '4' },
            { name: 'B5', value: '5' },
            { name: 'B12', value: '12' },
            { name: 'B13', value: '13' },
            { name: 'B14', value: '14' },
            { name: 'B18', value: '18' },
            { name: 'B19', value: '19' },
            { name: 'B26', value: '26' },
            { name: 'B28', value: '28' },
            { name: 'B29', value: '29' },
            { name: 'B30', value: '30' },
            { name: 'B32', value: '32' },
            { name: 'B41', value: '41' },
            { name: 'B42', value: '42' },
            { name: 'B43', value: '43' },
            { name: 'B46', value: '46' },
            { name: 'B48', value: '48' },
            { name: 'B66', value: '66' }
        ]
    },
    Telit: {
        Advanced: true,
        Name: "MODULE_NAME_SUB",
        Map: {
            LM960A18: [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B28', value: '28' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B32', value: '32' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B46', value: '46' },
                { name: 'B48', value: '48' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ],
            FN990: [
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B28', value: '28' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B32', value: '32' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B46', value: '46' },
                { name: 'B48', value: '48' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ],
            DEFAULT: [
                { name: 'Automatic', value: '0' },
                { name: 'B1', value: '1' },
                { name: 'B2', value: '2' },
                { name: 'B3', value: '3' },
                { name: 'B4', value: '4' },
                { name: 'B5', value: '5' },
                { name: 'B7', value: '7' },
                { name: 'B8', value: '8' },
                { name: 'B12', value: '12' },
                { name: 'B13', value: '13' },
                { name: 'B14', value: '14' },
                { name: 'B17', value: '17' },
                { name: 'B18', value: '18' },
                { name: 'B19', value: '19' },
                { name: 'B20', value: '20' },
                { name: 'B25', value: '25' },
                { name: 'B26', value: '26' },
                { name: 'B28', value: '28' },
                { name: 'B29', value: '29' },
                { name: 'B30', value: '30' },
                { name: 'B32', value: '32' },
                { name: 'B38', value: '38' },
                { name: 'B39', value: '39' },
                { name: 'B40', value: '40' },
                { name: 'B41', value: '41' },
                { name: 'B42', value: '42' },
                { name: 'B43', value: '43' },
                { name: 'B46', value: '46' },
                { name: 'B48', value: '48' },
                { name: 'B66', value: '66' },
                { name: 'B71', value: '71' }
            ]
        }
    },
    DEFAULT: [
        { name: 'Automatic', value: '0' },
        { name: "B2", value: "2" },
        { name: "B4", value: "4" },
        { name: "B5", value: "5" },
        { name: "B13", value: "13" },
        { name: "B17", value: "17" },
        { name: "B25", value: "25" }
    ]
}

export const NSA_5G_NR_BAND = {
    "RM500Q-GL": [
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        },
        {
            "name": "n79",
            "value": "79"
        }
    ],
    "RM500Q-AE": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n2",
            "value": "2"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n12",
            "value": "12"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n25",
            "value": "25"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n66",
            "value": "66"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        },
        {
            "name": "n79",
            "value": "79"
        }
    ],
    "RM520N-GL": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n2",
            "value": "2"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n12",
            "value": "12"
        },
        {
            "name": "n13",
            "value": "13"
        },
        {
            "name": "n14",
            "value": "14"
        },
        {
            "name": "n18",
            "value": "18"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n25",
            "value": "25"
        },
        {
            "name": "n26",
            "value": "26"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n29",
            "value": "29"
        },
        {
            "name": "n30",
            "value": "30"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n66",
            "value": "66"
        },
        {
            "name": "n70",
            "value": "70"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n75",
            "value": "75"
        },
        {
            "name": "n76",
            "value": "76"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        },
        {
            "name": "n79",
            "value": "79"
        }
    ],
    "RG520N-EB": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n75",
            "value": "75"
        },
        {
            "name": "n76",
            "value": "76"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        }
    ],
    "RG520N-GT": [
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        }
    ],
    "FN990": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n25",
            "value": "25"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n30",
            "value": "30"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n66",
            "value": "66"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n76",
            "value": "76"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        }
    ]
};

export const SA_5G_NR_BAND = {
    "RM500Q-GL": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n2",
            "value": "2"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n12",
            "value": "12"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n25",
            "value": "25"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n66",
            "value": "66"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        },
        {
            "name": "n79",
            "value": "79"
        }
    ],
    "RM500Q-AE": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n2",
            "value": "2"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n12",
            "value": "12"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n25",
            "value": "25"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n66",
            "value": "66"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        },
        {
            "name": "n79",
            "value": "79"
        }
    ],
    "RM520N-GL": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n2",
            "value": "2"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n12",
            "value": "12"
        },
        {
            "name": "n13",
            "value": "13"
        },
        {
            "name": "n14",
            "value": "14"
        },
        {
            "name": "n18",
            "value": "18"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n25",
            "value": "25"
        },
        {
            "name": "n26",
            "value": "26"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n29",
            "value": "29"
        },
        {
            "name": "n30",
            "value": "30"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n66",
            "value": "66"
        },
        {
            "name": "n70",
            "value": "70"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n75",
            "value": "75"
        },
        {
            "name": "n76",
            "value": "76"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        },
        {
            "name": "n79",
            "value": "79"
        }
    ],
    "RG520N-EB": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n75",
            "value": "75"
        },
        {
            "name": "n76",
            "value": "76"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        }
    ],
    "RG520N-GT": [
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        }
    ],
    "FN990": [
        {
            "name": "n1",
            "value": "1"
        },
        {
            "name": "n2",
            "value": "2"
        },
        {
            "name": "n3",
            "value": "3"
        },
        {
            "name": "n5",
            "value": "5"
        },
        {
            "name": "n7",
            "value": "7"
        },
        {
            "name": "n8",
            "value": "8"
        },
        {
            "name": "n20",
            "value": "20"
        },
        {
            "name": "n25",
            "value": "25"
        },
        {
            "name": "n28",
            "value": "28"
        },
        {
            "name": "n30",
            "value": "30"
        },
        {
            "name": "n38",
            "value": "38"
        },
        {
            "name": "n40",
            "value": "40"
        },
        {
            "name": "n41",
            "value": "41"
        },
        {
            "name": "n48",
            "value": "48"
        },
        {
            "name": "n66",
            "value": "66"
        },
        {
            "name": "n71",
            "value": "71"
        },
        {
            "name": "n75",
            "value": "75"
        },
        {
            "name": "n77",
            "value": "77"
        },
        {
            "name": "n78",
            "value": "78"
        },
        {
            "name": "n79",
            "value": "79"
        }
    ]
};

export const NR_MODULE = [
    "RM500Q-GL",
    "RM500Q-AE",
    "RM520N-GL",
    "RG520N-EB",
    "RG520N-GT",
    "FN990"
];

export const ALL_NETWORK_MODE = [
    { name: "Automatic", value: "0" },
    { name: "UMTS 3G Only", value: "1" },
    { name: "GSM 2G Only", value: "2" },
    { name: "UMTS 3G Preferred", value: "3" },
    { name: "GSM 2G Preferred", value: "4" },
    { name: "GSM and UMTS Only", value: "5" },
    { name: "LTE Only", value: "6" },
    { name: "GSM, UMTS, LTE", value: "7" }
];

export const ALL_NETWORK_MODE_5G = [
    { name: "Automatic", value: "0" },
    { name: "LTE Only", value: "6" },
    { name: "LTE + 5G NR", value: "56" },
    { name: "5G NR", value: "50" }
];

export const ALL_BAND = [
    { name: 'B1', value: '1' },
    { name: 'B2', value: '2' },
    { name: 'B3', value: '3' },
    { name: 'B4', value: '4' },
    { name: 'B5', value: '5' },
    { name: 'B6', value: '6' },
    { name: 'B7', value: '7' },
    { name: 'B8', value: '8' },
    { name: 'B9', value: '9' },
    { name: 'B10', value: '10' },
    { name: 'B11', value: '11' },
    { name: 'B12', value: '12' },
    { name: 'B13', value: '13' },
    { name: 'B14', value: '14' },
    { name: 'B17', value: '17' },
    { name: 'B18', value: '18' },
    { name: 'B19', value: '19' },
    { name: 'B20', value: '20' },
    { name: 'B21', value: '21' },
    { name: 'B23', value: '23' },
    { name: 'B24', value: '24' },
    { name: 'B25', value: '25' },
    { name: 'B26', value: '26' },
    { name: 'B27', value: '27' },
    { name: 'B28', value: '28' },
    { name: 'B29', value: '29' },
    { name: 'B30', value: '30' },
    { name: 'B31', value: '31' },
    { name: 'B32', value: '32' },
    { name: 'B33', value: '33' },
    { name: 'B34', value: '34' },
    { name: 'B35', value: '35' },
    { name: 'B38', value: '38' },
    { name: 'B39', value: '39' },
    { name: 'B40', value: '40' },
    { name: 'B41', value: '41' },
    { name: 'B42', value: '42' },
    { name: 'B43', value: '43' },
    { name: 'B46', value: '46' },
    { name: 'B47', value: '47' },
    { name: 'B48', value: '48' },
    { name: 'B53', value: '53' },
    { name: 'B66', value: '66' },
    { name: 'B71', value: '71' }
];

export const ALL_NSA_5G_NR_BAND = [
    {
        "name": "n1",
        "value": "1"
    },
    {
        "name": "n2",
        "value": "2"
    },
    {
        "name": "n3",
        "value": "3"
    },
    {
        "name": "n5",
        "value": "5"
    },
    {
        "name": "n7",
        "value": "7"
    },
    {
        "name": "n8",
        "value": "8"
    },
    {
        "name": "n12",
        "value": "12"
    },
    {
        "name": "n13",
        "value": "13"
    },
    {
        "name": "n14",
        "value": "14"
    },
    {
        "name": "n18",
        "value": "18"
    },
    {
        "name": "n20",
        "value": "20"
    },
    {
        "name": "n25",
        "value": "25"
    },
    {
        "name": "n26",
        "value": "26"
    },
    {
        "name": "n28",
        "value": "28"
    },
    {
        "name": "n29",
        "value": "29"
    },
    {
        "name": "n30",
        "value": "30"
    },
    {
        "name": "n38",
        "value": "38"
    },
    {
        "name": "n40",
        "value": "40"
    },
    {
        "name": "n41",
        "value": "41"
    },
    {
        "name": "n48",
        "value": "48"
    },
    {
        "name": "n66",
        "value": "66"
    },
    {
        "name": "n70",
        "value": "70"
    },
    {
        "name": "n71",
        "value": "71"
    },
    {
        "name": "n75",
        "value": "75"
    },
    {
        "name": "n76",
        "value": "76"
    },
    {
        "name": "n77",
        "value": "77"
    },
    {
        "name": "n78",
        "value": "78"
    },
    {
        "name": "n79",
        "value": "79"
    }
];

export const ALL_SA_5G_NR_BAND = [
    {
        "name": "n1",
        "value": "1"
    },
    {
        "name": "n2",
        "value": "2"
    },
    {
        "name": "n3",
        "value": "3"
    },
    {
        "name": "n5",
        "value": "5"
    },
    {
        "name": "n7",
        "value": "7"
    },
    {
        "name": "n8",
        "value": "8"
    },
    {
        "name": "n12",
        "value": "12"
    },
    {
        "name": "n13",
        "value": "13"
    },
    {
        "name": "n14",
        "value": "14"
    },
    {
        "name": "n18",
        "value": "18"
    },
    {
        "name": "n20",
        "value": "20"
    },
    {
        "name": "n25",
        "value": "25"
    },
    {
        "name": "n26",
        "value": "26"
    },
    {
        "name": "n28",
        "value": "28"
    },
    {
        "name": "n29",
        "value": "29"
    },
    {
        "name": "n30",
        "value": "30"
    },
    {
        "name": "n38",
        "value": "38"
    },
    {
        "name": "n40",
        "value": "40"
    },
    {
        "name": "n41",
        "value": "41"
    },
    {
        "name": "n48",
        "value": "48"
    },
    {
        "name": "n66",
        "value": "66"
    },
    {
        "name": "n70",
        "value": "70"
    },
    {
        "name": "n71",
        "value": "71"
    },
    {
        "name": "n75",
        "value": "75"
    },
    {
        "name": "n76",
        "value": "76"
    },
    {
        "name": "n77",
        "value": "77"
    },
    {
        "name": "n78",
        "value": "78"
    },
    {
        "name": "n79",
        "value": "79"
    }
];