export enum TaskTypeValue {
    REBOOT = "reboot",
    // RESET = "reset",
    UPGRADE_FIRMWARE_WITH_CURRENT_SETTING = "updateFW",
    UPGRADE_FIRMWARE_WITH_FACTORY_DEFAULT_SETTING = "updateFWFactory",
    // UPGRADE_CONFIGURATION = "updateConfig",
    // AUTO_CONFIGURATION_BACKUP = "autoCfgBackup",
    RECURSIVE_REPORT_TO_EMAIL = "recursiveReport",
    // FCC_SPEED_TEST = "FCCTests",
    SPEED_TEST_TO_EMAIL = "FCCTests_MSP",
    PROFILE_IMPLEMENT = "profileImplement"
};

export enum ProfileImplementType {
    BY_GROUP = "byGroup",
    BY_Filter = "byFilter"
};

export type TaskTypeNameMapping = {
    [key in TaskTypeValue]: string
};

export enum RecursiveTermType {
    DAILY = "daily",
    WEEKLY = "weekly",
    MONTHLY = "monthly"
};

export enum WeekDay {
    MONDAY = "1",
    TUESDAY = "2",
    WEDNESDAY = "3",
    THURSDAY = "4",
    FRIDAY = "5",
    SATURADAY = "6",
    SUNDAY = "0"
};

export enum Month {
    JANUARY = "1",
    FEBRUARY = "2",
    MARCH = "3",
    APRIL = "4",
    MAY = "5",
    JUNE = "6",
    JULY = "7",
    AUGUST = "8",
    SEPTEMBER = "9",
    OCTOBER = "10",
    NOVEMBER = "11",
    DECEMBER = "12"
};