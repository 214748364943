import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/* Scheduler Time at Least ten Minute Later */
export function schedulerTimeValidate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if(control.get('scheduleDate').disabled) return null;
        const scheduleDate = new Date(`${control.get('scheduleDate').value} ${control.get('scheduleTime').value}`);
        const currentDate = new Date();
        return scheduleDate.getTime() - currentDate.getTime() < 600000 ? {ImmediateTask: true} : null;
    };
}

/* Time Window Time at Least later than schedule */
export function timeWindowTimeValidate(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

        if(!control.get('timeWindow').value) return null;

        const scheduleDate = new Date(`${control.get('scheduleDate').value} ${control.get('scheduleTime').value}`);
        
        const timeWindowDate = new Date(`${control.get('timeWindowDate').value} ${control.get('timeWindowTime').value}`);
        
        return timeWindowDate.getTime() - scheduleDate.getTime() <= 0 ? {ImmediateEndTask: true} : null;
    };
}

/* Email Recipient(s) Requirement */
export function emailLoopValidator(): ValidatorFn {
    
    let emailLoopFormat: RegExp = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;

    return (control: AbstractControl): ValidationErrors | null => {
      
        if(!control.value) return null;

        const format = emailLoopFormat.test(control.value);
        
        return format ? null  : {emailLoop: true};
    };
}

/* At Least One Item Requirement */
export function atLeasetOneItemValidate(): ValidatorFn {
    
    return (control: AbstractControl): ValidationErrors | null => {
      
        if(!control.value) return null;

        return control.value.length > 0 ? null  : {ItemRequired: true};
    };
}