/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { AlertPolicyRequestBody } from '@core/types';

@Injectable()
export class AlertConfig extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /alert-config/{id} - get alert config by device id
     * 
     * @param {string} id
     */
    public getAlertConfig(id) {

        let method = "GET",
            url = "alert-config/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /alert-config/{id} - save alert config by device id
     * 
     * @param {string} id
     * @param {AlertPolicyRequestBody} data
     */
    public saveAlertConfig(id: string, data: AlertPolicyRequestBody) {

        let method = "PATCH",
            url = "alert-config/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}