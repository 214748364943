import { Component, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { PrivilegeService } from '@core/services/privilege.service';
import { IdleCheckService } from '@core/services/idle-check.service';
import { fadeInLeft, zoomIn, fadeIn } from '@core/animations/core.animation';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'content',
	templateUrl: './content.component.html',
	encapsulation: ViewEncapsulation.None,
	animations: [fadeInLeft, zoomIn, fadeIn]
})
export class ContentComponent {
	public coreConfig: any;
	public animate;

	// Private
	private _unsubscribeAll$: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {Router} _router
	 * @param {CoreConfigService} _coreConfigService
	 * @param {PrivilegeService} _privilegeService
	 * @param {IdleCheckService} _idleCheckService
	 */
	constructor(private _router: Router,
		private _coreConfigService: CoreConfigService,
		private _privilegeService: PrivilegeService,
		private _idleCheckService: IdleCheckService) {
		this._unsubscribeAll$ = new Subject(); // Set the private defaults
	}

	/**
	 * Fade In Left Animation
	 *
	 * @param outlet
	 */
	fadeInLeft(outlet) {
		if (this.animate === 'fadeInLeft') {
			return outlet.activatedRouteData.animation;
		}
		return null;
	}

	/**
	 * Zoom In Animation
	 *
	 * @param outlet
	 */
	zoomIn(outlet) {
		if (this.animate === 'zoomIn') {
			return outlet.activatedRouteData.animation;
		}
		return null;
	}

	/**
	 * Fade In Animation
	 *
	 * @param outlet
	 */
	fadeIn(outlet) {
		if (this.animate === 'fadeIn') {
			return outlet.activatedRouteData.animation;
		}
		return null;
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On Init
	 */
	ngOnInit(): void {
		// Subscribe config change
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll$)).subscribe(config => {
			this.coreConfig = config;
			this.animate = this.coreConfig.layout.animation;
		});

		// Subscribe Privilege chagne to activate Timer Service 
		this._privilegeService.currentUser.pipe(takeUntil(this._unsubscribeAll$), filter(item => item != null))
			.subscribe((currentUser) => {
				this._idleCheckService.initialTimerService(currentUser.autoLogoutTimer);
			});
	}
}
