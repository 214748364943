<!-- Tenant Switching -->
<div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
            <!-- <h2 class="mb-1">You are switching! 👉</h2> -->
            <div class="animation-img-container">
                <img src="{{ coreConfig.app.appLogoImage }}" class="img-fluid" alt="BECentral-icon" 
                    [@tenantSwitchingTrigger]="imageStatus" (@tenantSwitchingTrigger.done)="onAnimationDone($event)"/>
            </div>
        </div>
    </div>
</div>
<!-- / Tenant Switching -->