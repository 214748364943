export const enum Language {
    "ENGILSH" = "en",
    "MANDARIN" = "zh",
    "JAPANESE" = "ja"
};

export interface LanguageObject {
    name: string;
    value: Language;
    flag?: string;
};