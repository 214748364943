/******* Angular Resourse *******/
import { ElementRef, Directive, HostListener, Input, AfterViewInit } from "@angular/core";

@Directive({ 
    selector: '[hiddenOnWidth]' 
})
export class HiddenOnWidthDirective implements AfterViewInit  {
    
    // Public
    @Input() hiddenOnWidth: number = 0;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(event.target.innerWidth <= this.hiddenOnWidth) this.ref.nativeElement.classList.add('d-none');
        else this.ref.nativeElement.classList.remove('d-none');
    }

    /**
     * Constructor
     * 
     * @param {ElementRef} ref 
     */
    constructor(private ref: ElementRef) {}

    ngAfterViewInit() {
        window.dispatchEvent(new Event('resize'));
    }
}