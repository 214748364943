/******* Angular Resourse *******/
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from "@angular/router";

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import * as _ from 'lodash';

/******* API Resourse *******/
import { ProfileTemplate } from '@api';
import { ProfileTemplateInterface } from '@core/types';
import { GlobalUIBlockingService } from './global-ui-blocking.service';
import { CreateProfileTemplateRequestBody } from '@core/types/profile-template-types/profile-template-request-body';

@Injectable({
    providedIn: 'root',
})
export class ProfileTemplateService {

    // Public
    public currentProfileTemplateList: Observable<Array<ProfileTemplateInterface> | null>;

    // Private
    private currentProfileTemplateListSubject$: BehaviorSubject<Array<ProfileTemplateInterface> | null>;
    private _profileTemplateMap: Map<string, string>;

    /**
     * Constructor
     *
     * @param {Router} _router
     * @param {ProfileTemplate} _profileTemplate
     * @param {GlobalUIBlockingService} _globalUIBlockingService 
     */
    constructor(private _router: Router,
        private _profileTemplate: ProfileTemplate,
        private _globalUIBlockingService: GlobalUIBlockingService) {

        // Profile Template subject initial
        this.currentProfileTemplateListSubject$ = new BehaviorSubject<Array<ProfileTemplateInterface>>(null);
        this.currentProfileTemplateList = this.currentProfileTemplateListSubject$.asObservable().pipe(tap((x) => { if (!x) this._refreshProfileTemplate(); }));

        // Profile Tempalte map iniital
        this._profileTemplateMap = new Map<string, any>();
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get current Profile Template List value
     */
    get currentProfileTemplateListValue() {
        return this.currentProfileTemplateListSubject$.value;
    }

    /**
     * get current Porfile template map
     */
    get currentProfileTemplateMapValue() {
        return this._profileTemplateMap;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query Profile Template Data
     */
    private _refreshProfileTemplate(resolve = null, rejects = null): void {
        this._globalUIBlockingService.start();
        this._profileTemplate.getProfileTemplateList().subscribe((returnData) => {
            const profileTemplateList = returnData.data;
            profileTemplateList.forEach(x => this._profileTemplateMap.set(x.id, x));
            this._globalUIBlockingService.stop();
            this.currentProfileTemplateListSubject$.next(profileTemplateList);
            if (resolve) resolve(profileTemplateList);
        }, (error) => {
            this._globalUIBlockingService.stop();
            if (rejects) rejects(false);
        });
    }

    /**
     * create profile template
     * 
     * @param {CreateProfileTemplateRequestBody} inputData 
     * @param resolve 
     * @param rejects 
     */
    private _createProfileTemplate(inputData: CreateProfileTemplateRequestBody, resolve = null, rejects = null): void {
        this._globalUIBlockingService.start();
        this._profileTemplate.createProfileTemplate(inputData).subscribe(() => {
            this._globalUIBlockingService.stop();
            this._refreshProfileTemplate(resolve, rejects);
        }, (error) => {
            this._globalUIBlockingService.stop();
            if (resolve) rejects(error);
        });
    }

    /**
     * update profile template
     * 
     * @param {string} id 
     * @param {CreateProfileTemplateRequestBody} inputData 
     * @param resolve 
     * @param rejects 
     */
    private _updateProfileTemplate(id: string, inputData: CreateProfileTemplateRequestBody, resolve = null, rejects = null): void {
        this._globalUIBlockingService.start();
        this._profileTemplate.updateProfileTemplate(id, inputData).subscribe(() => {
            this._globalUIBlockingService.stop();
            this._refreshProfileTemplate(resolve, rejects);
        }, (error) => {
            this._globalUIBlockingService.stop();
            if (resolve) rejects(error);
        });
    }

    /**
     * delete profile template by id
     * 
     * @param {string} id 
     * @param resolve 
     * @param rejects 
     */
    private _deleteProfileTemplate(id: string, resolve = null, rejects = null): void {
        this._globalUIBlockingService.start();
        this._profileTemplate.deleteProfileTemplate(id).subscribe(() => {
            this._globalUIBlockingService.stop();
            this._refreshProfileTemplate(resolve, rejects);
        }, (error) => {
            this._globalUIBlockingService.stop();
            if (resolve) rejects(error);
        });
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh Profile Template List
     * 
     * @returns {Promise<Array<ProfileTemplateInterface>>}
     */
    public refreshProfileTemplateList(): Promise<Array<ProfileTemplateInterface>> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._refreshProfileTemplate(resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Create Profile Template
     * 
     * @param {CreateProfileTemplateRequestBody} inputData
     * @returns {Promise<Array<ProfileTemplateInterface>>} 
     */
    public createProfileTemplate(inputData: CreateProfileTemplateRequestBody): Promise<Array<ProfileTemplateInterface>> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._createProfileTemplate(inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Update Profile Template
     * 
     * @param {string} id
     * @param {CreateProfileTemplateRequestBody} inputData
     * @returns {Promise<Array<ProfileTemplateInterface>>} 
     */
    public updateProfileTemplate(id: string, inputData: CreateProfileTemplateRequestBody): Promise<Array<ProfileTemplateInterface>> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._updateProfileTemplate(id, inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Delete Profile Template
     * 
     * @param {string} id
     * @returns {Promise<Array<ProfileTemplateInterface>>} 
     */
    public deleteProfileTemplate(id: string): Promise<Array<ProfileTemplateInterface>> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._deleteProfileTemplate(id, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Clear the current rf standard data
     */
    public clearAllInfo(): void {
        this.currentProfileTemplateListSubject$.next(null);
    }
}