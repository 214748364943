import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CustomBreakPointsProvider } from 'app/layout/custom-breakpoints';
import { MainLayoutModule } from './main/main-layout.module';

@NgModule({
  imports: [FlexLayoutModule.withConfig({ disableDefaultBps: true }), MainLayoutModule],
  providers: [CustomBreakPointsProvider],
  exports: [MainLayoutModule]
})
export class LayoutModule {}
