<li ngbDropdown class="nav-item dropdown-notification mr-25" (openChange)="openEvent($event)">
	<a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
		<i class="ficon" data-feather="alert-triangle"></i>
		<span class="badge badge-pill badge-danger badge-up">{{alertNotificationCount.total |
			numberAbbreviation}}</span>
	</a>
	<ul ngbDropdownMenu aria-labelledby="navbarNotificationDropdown"
		class="dropdown-menu dropdown-menu-media dropdown-menu-left pt-0">
		<li class="scrollable-container media-list">
			<div class="media d-flex align-items-center">
				<h6 class="font-weight-bolder mr-auto mb-0">Pop-up Notification</h6>
				<div class="custom-control custom-switch custom-switch-success">
					<input type="checkbox" class="custom-control-input" id="allow-popup-alert"
						[(ngModel)]="allowPopUpAlert" (change)="allowPopUpAlertChange()" />
					<label class="custom-control-label" for="allow-popup-alert">
						<span class="switch-icon-left"><i data-feather="check"></i></span>
						<span class="switch-icon-right"><i data-feather="slash"></i></span>
					</label>
				</div>
			</div>
		</li>

		<li class="dropdown-menu-header">
			<div class="btn-group btn-group-toggle w-100 p-1" ngbRadioGroup name="radioBasic"
				[(ngModel)]="alertCategory" (change)="tabChanged();">
				<label ngbButtonLabel class="btn-outline-primary" rippleEffect><input ngbButton type="radio"
						[value]="'device'" />
					Device ({{alertNotificationCount.device | numberAbbreviation}})
				</label>
				<label ngbButtonLabel class="btn-outline-primary" rippleEffect><input ngbButton type="radio"
						[value]="'signal'" />
					Signal ({{alertNotificationCount.signal | numberAbbreviation}})
				</label>
				<label ngbButtonLabel *ngIf="isLicenseActivate" class="btn-outline-primary" rippleEffect>
					<input ngbButton type="radio" [value]="'license'" />
					License ({{alertNotificationCount.license | numberAbbreviation}})
				</label>
			</div>
		</li>

		<ng-container *ngIf="alertNotification">
			<li #alertPool class="scrollable-container media-list" [perfectScrollbar]>
				<a class="d-flex alert-item" (click)="setAlertRead(message)"
					*ngFor="let message of alertNotification[alertCategory]">
					<div class="media d-flex align-items-start">
						<div class="media-left">
							<div class="avatar" [ngClass]="{
								'bg-light-danger': message.level === 'Alert',
								'bg-light-warning': message.level === 'Caution'
							}">
								<div class="avatar-content"><i class="avatar-icon"
										[data-feather]="message.level === 'Caution' ? 'alert-triangle': 'alert-circle'"></i>
								</div>
							</div>
						</div>
						<div class="media-body">
							<p class="media-heading font-weight-bolder">
								{{message.type | alertTypeName}}
							</p>
							<p class="text-muted my-50"><small>{{message.description}}</small></p>
							<div class="my-50">
								<div class="badge badge-light-primary mr-1">{{message.MAC}}</div>
								<div class="badge badge-light-secondary">{{message?.device?.IMSI}}</div>
							</div>
							<div class="badge badge-light-info my-50">{{ message.timestamp | toISOString }}</div>
							<!-- <p class="mb-0">{{message.Name}}</p> -->
						</div>
					</div>
					<div class="read-sign" *ngIf="message.status == 'read'">
						<small class="text-muted">
							<span data-feather="check-circle" class="mr-25"></span>
							Read
						</small>
					</div>
				</a>
				<ng-container *ngIf="isLoading[alertCategory] else alertContent">
					<div class="w-100 text-center p-1">
						<span class="font-italic">
							Loading Data
							<div class="spinner-border spinner-border-sm ml-50" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</span>
					</div>
				</ng-container>
				<ng-template #alertContent>
					<div class="w-100 text-center font-weight-bold p-1"
						*ngIf="alertNotification[alertCategory].length <= 0">
						<span class="font-italic">No Record</span>
					</div>
					<div class="py-50 px-1 border-top"
						*ngIf="alertNotification[alertCategory].length < alertNotificationCount[alertCategory]">
						<button type="button" class="btn btn-flat-primary btn-block"
							(click)="loadAlertByPiece(alertCategory)" rippleEffect>
							Load More
						</button>
					</div>
				</ng-template>
			</li>
		</ng-container>

		<li class="dropdown-menu-footer" *ngIf="alertNotificationCount.total > 0">
			<a class="btn btn-primary btn-block" (click)="readAllAlerts()">Read all alerts</a>
		</li>
	</ul>
</li>