/******* Angular Resourse *******/
import { Component, OnInit, Input, Output, ViewChild, ElementRef, SimpleChanges } from '@angular/core';

/******* Type Resourse *******/
import { OverlayMaps, MapIcons, Popup, OverlayMapsType, MapType } from '@core/types';

/******* Plug-In Resourse *******/
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.fullscreen';

/******* Const Resourse *******/
const GEOAPIFY_API_KEY: string = 'd2981e8678e845fda45633d22373783d';
import { environment } from 'environments/environment';

@Component({
    selector: 'geoapify-map',
    templateUrl: './core-map.component.html',
    styleUrls: ['core-map.component.scss']
})
export class MapComponent implements OnInit {

    /******* Element Resourse *******/

    // Input data

    // Location
    @Input() Latitude: number = 0.0;
    @Input() Longitude: number = 0.0;

    // Zoom
    @Input() Zoom: number = 13;

    // With Map Attribution
    @Input() withMapAttribution: boolean = false;

    // With Layers Switcher
    @Input() withLayerSwitcher: boolean = false;

    // With Marker Cluster
    @Input() withMarkerCluster: boolean = true;

    // With Cell Cluster
    @Input('withCellCluster') withCellCluster: boolean = false;

    // With Device
    @Input() withDeviceDisplay: boolean = false;

    // Map Container
    private lefletMap: L.Map;

    // Layer Control
    private layerControl: L.Control.Layers;

    // OverlayMaps
    private overlayMaps: Partial<OverlayMaps>;

    // Map Element
    @ViewChild('map') private mapContainer: ElementRef<HTMLElement>;

    // Map Type
    private readonly mapType: MapType = {
        "Bright": `https://maps.geoapify.com/v1/tile/osm-bright/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`,
        "Bright-Smooth": `https://maps.geoapify.com/v1/tile/osm-bright-smooth/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`,
        "Dark": `https://maps.geoapify.com/v1/tile/dark-matter-purple-roads/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`,
        "Liberty": `https://maps.geoapify.com/v1/tile/osm-liberty/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`
    };

    /**
     * constructor
     */
    constructor() { }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter : access Map Zoom
     */
    public get mapZoom() {
        return this.lefletMap ? this.lefletMap.getZoom() : 0;
    }

    /**
     * Get the map instance
     */
    get map() {
        return this.lefletMap ? this.lefletMap : null;
    }

    /**
     * is map function available
     */
    get isMapFeatureAvailable() {
        return environment.internetFunction;
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {

        // initial Overlay Map
        this.overlayMaps = {
            [OverlayMapsType.MARKERS]: this.withMarkerCluster ? L.markerClusterGroup() : L.layerGroup(),
            [OverlayMapsType.CELL_MARKERS]: this.withCellCluster ? L.markerClusterGroup() : L.layerGroup(),
            [OverlayMapsType.CELL_MARKERS_HISTORY]: this.withCellCluster ? L.markerClusterGroup() : L.layerGroup(),
            [OverlayMapsType.POLY]: L.layerGroup()
        };
    }

    /**
     * ngOnChanges
     * 
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges) {

        // detect change
        if (changes.Latitude || changes.Longitude || changes.Zoom) {
            if (this.lefletMap) this.setView(this.Latitude, this.Longitude, this.Zoom);
        }

    }

    /**
     * ngAfterViewInit
     */
    ngAfterViewInit() {

        if (environment.internetFunction) {

            // Create Base Layer Maps Option
            let baseMaps = this.renderBaseMaps();

            let overlayMapOptions = { // map layer option
                'Markers': this.overlayMaps[OverlayMapsType.MARKERS],
                'Cell-Markers': this.overlayMaps[OverlayMapsType.CELL_MARKERS],
                'Poly': this.overlayMaps[OverlayMapsType.POLY]
            };

            // Initial the Map
            this.lefletMap = L.map(this.mapContainer.nativeElement, {
                center: [this.Latitude, this.Longitude],
                zoom: this.Zoom,
                layers: [
                    baseMaps?.Liberty,
                    this.overlayMaps[OverlayMapsType.MARKERS],
                    this.overlayMaps[OverlayMapsType.CELL_MARKERS],
                    this.overlayMaps[OverlayMapsType.CELL_MARKERS_HISTORY],
                    this.overlayMaps[OverlayMapsType.POLY]], // set Liberty as default base
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: 'topleft',
                    title: 'Full Screen',
                    titleCancel: 'Back',
                    forcePseudoFullscreen: true, // limit fullscreen to window of map
                }
            });

            // Create layer control
            this.layerControl = L.control.layers(baseMaps, overlayMapOptions, { hideSingleBase: true }).addTo(this.lefletMap);

            // Add Center Device Marker
            if (this.withDeviceDisplay) {
                this.clearLayerById(OverlayMapsType.MARKERS);
                this.addMarkerLayer("Device", MapIcons.DEVICE_GREY, this.Latitude, this.Longitude, OverlayMapsType.MARKERS);
            }

            // Remove Attribution
            if (!this.withMapAttribution) {
                let ele = document.getElementsByClassName('leaflet-control-attribution');

                for (let index = 0; index < ele.length; index++)
                    (<HTMLElement>ele[index]).style.display = 'none';
            }

            // Remove Layer Switcher
            if (!this.withLayerSwitcher) {
                let ele = document.getElementsByClassName('leaflet-control-layers');

                for (let index = 0; index < ele.length; index++)
                    (<HTMLElement>ele[index]).style.display = 'none';
            }

            // Full Screen enter and leave call back
            this.lefletMap.on('enterFullscreen', () => {
                this.fitBounds(50);
                this.closeAllPopup();
            });

            this.lefletMap.on('exitFullscreen', () => {
                this.fitBounds(50);
                this.closeAllPopup();
            });
        }
    }

    /******* Map load *******/

    /**
     * Render Base Layer Map
     * 
     * @returns 
     */
    private renderBaseMaps(): L.Control.LayersObject {

        let baseMaps = {};

        for (let key in this.mapType) {

            baseMaps[key] = L.tileLayer(this.mapType[key], {
                maxZoom: 20,
                id: key + "_id",
                attribution: '© OpenStreetMap'
            });
        }

        return baseMaps;
    }

    /**
     * Get build in maps marker
     * 
     * @param {string} markerName
     */
    private buildInMapsMarker(markerName: string): L.IconOptions {
        return {
            iconUrl: `assets/images/map/${markerName}.png`,
            iconSize: [48, 48],
            iconAnchor: [24, 45],
            tooltipAnchor: [18, -24],
            popupAnchor: [0, -45]
        };
    }

    /******* Map Method *******/

    /**
     * add Marker Layer into Marker Group
     * 
     * @param {string} id 
     * @param {MapIcons | string} iconType 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {string} tootip 
     * @param {Popup} popup 
     */
    public addMarkerLayer(id: string,
        iconType: MapIcons | string,
        latitude: number,
        longitude: number,
        layer: OverlayMapsType,
        tootip: string = "",
        popup: Popup = {
            content: "",
            options: {}
        }): void {

        if (!environment.internetFunction) return;

        // Customize Icon
        let icon;
        iconType = iconType ? iconType : MapIcons.CELL;
        if (!Object.keys(MapIcons).map(x => MapIcons[x]).includes(iconType)) {
            const cutomizeIcon: L.IconOptions = {
                iconUrl: iconType,
                iconSize: [30, 30],
                popupAnchor: [0, -7.5],
                iconAnchor: [15, 15],
                tooltipAnchor: [15, 0]
            };
            icon = L.icon(cutomizeIcon);
        } else icon = L.icon(this.buildInMapsMarker(iconType));

        // Validate Coordinate

        let markerTemplate = L.marker([latitude, longitude], {
            icon: icon,
            zIndexOffset: 3,
            title: id
        });

        if (tootip) markerTemplate.bindTooltip(tootip);
        if (popup.content) markerTemplate.bindPopup(popup.content, popup.options);

        // Add to Marker Layer
        this.overlayMaps[layer].addLayer(markerTemplate);
        // if (iconType === MapIcons.DEVICE || iconType === MapIcons.DEVICE_GREY) this.overlayMaps.markers.addLayer(markerTemplate);
        // else this.overlayMaps.cellMarkers.addLayer(markerTemplate);
    }

    /**
     * Drawing Line
     * 
     * @param polyline_ [[Latitude,Longtitude],[Latitude,Longtitude]]
     * @param tootip 
     * @param popup
     * @returns 
     */
    public drawLine(polyline_: any,
        tootip: string = "",
        popup: string = "",
        options: any = {
            color: "#ea5455",
            weight: 2,
            opacity: 0.7
        }): boolean | void {

        if (!environment.internetFunction) return;

        let polyLineTemplate = L.polyline(polyline_, {
            ...options
        });

        if (tootip) polyLineTemplate.bindTooltip(tootip);
        if (popup) polyLineTemplate.bindPopup(popup);

        // Add to Marker Layer
        this.overlayMaps.poly.addLayer(polyLineTemplate);
    }

    /**
     * Drawing Polygon
     * 
     * @param {Array<L.Latlng>} polygon_ 
     * @param {string} tootip 
     * @param {string} popup 
     * @param {string} color 
     * @param {number} weight 
     * @param {number} opacity 
     * @param {string} fillColor 
     * @param {number} fillOpacity 
     * @returns {boolean | void}
     */
    public drawPolygon(polygon_: Array<L.Latlng>,
        tootip: string = "",
        popup: string = "",
        color: string = "#ea5455",
        weight: number = 2,
        opacity: number = 0.7,
        fillColor: string = "#7367f0",
        fillOpacity: number = 0.2): boolean | void {

        if (!environment.internetFunction) return;

        let polygonTemplate = L.polygon(polygon_, {
            color: color ? color : "#ea5455",
            weight: weight ? weight : 2,
            opacity: opacity ? opacity : 0.7,
            fillColor: fillColor ? fillColor : "#7367f0",
            fillOpacity: fillOpacity ? fillOpacity : 0.2
        });

        if (tootip) polygonTemplate.bindTooltip(tootip);
        if (popup) polygonTemplate.bindPopup(popup);

        // Add to Marker Layer
        this.overlayMaps.poly.addLayer(polygonTemplate);
    }

    /**
     * Drawing circle
     * 
     * @param {L.Latlng} latLng 
     * @param {number} radius
     * @param {any} options // #circle-option
     * @returns {boolean | void}
     */
    public drawCircle(latLng: L.Latlng,
        radius: number,
        options?: any): boolean | void {
        if (!environment.internetFunction) return;
        let circleTemplate = L.circle(latLng, radius, options);
        this.overlayMaps.poly.addLayer(circleTemplate); // Add to Marker Layer
    }

    /**
     * Update Popup content by id
     * 
     * @param {string} id 
     * @param {string} updateContent 
     */
    public updatePopupFromMarker(id: string, updateContent: string, fromOverlay: OverlayMapsType = OverlayMapsType.MARKERS): void {
        if (!environment.internetFunction) return;
        this.overlayMaps[fromOverlay].eachLayer((layer: L.Marker) => {
            if (layer.options.title === id) {
                layer.setPopupContent(updateContent);
            };
        });
    }

    /**
     * Fit Bounds
     * 
     * @param paddingValue
     */
    public fitBounds(paddingValue: number): void {
        
        if (!environment.internetFunction) return;
        
        // Bounds Aarray
        let boundArray: Array<L.LatLng> = [];

        // Marker Bounds
        this.overlayMaps.markers.eachLayer((layer: L.Marker) => {
            boundArray.push(layer.getLatLng());
        });

        this.overlayMaps.cellMarkers.eachLayer((layer: L.Marker) => {
            boundArray.push(layer.getLatLng());
        });

        // Calculate Bounds
        let bounds = L.latLngBounds(boundArray);
        let polyFlag = false;

        // Polyline Bounds
        this.overlayMaps.poly.eachLayer((layer: L.Polyline) => {
            bounds.extend(layer.getBounds());
            polyFlag = true;
        });

        if (boundArray.length > 0)
            this.lefletMap.fitBounds(bounds, { padding: [paddingValue + 20, paddingValue + 20] }); // Fit Bounds with padding

        if (!polyFlag && boundArray.length == 1) this.lefletMap.setView(boundArray[0], 14);
    }

    /**
     * Clear the Map Object
     */
    public clearMap(): void {

        if (!environment.internetFunction) return;

        // Clear Marker Layer
        for (let laygroup in this.overlayMaps) {
            this.overlayMaps[laygroup].clearLayers();
        }
    }

    /**
     * Move the Map View
     * 
     * @param lat 
     * @param long 
     * @param zoom 
     * @returns 
     */
    public setView(lat, long, zoom): boolean | void {

        if (!environment.internetFunction) return;

        if (lat > 90.0 || lat < -90.0 || long > 180.0 || long < -180.0 || zoom > 20 || zoom < 1) {
            console.log("SetView with incorrect Value");

            return false;
        }

        this.lefletMap.setView([lat, long], zoom);
    }

    /**
     * Resize the Map
     */
    public invalidateSize(): void {
        if (!environment.internetFunction) return;
        this.lefletMap.invalidateSize();
    }

    /**
     * Clear assigned layed
     * 
     * @param {string} id
     */
    public clearLayerById(id): void {
        if (!environment.internetFunction) return;
        this.overlayMaps[id].clearLayers();
    }

    /**
     * Close all popup in marker
     */
    public closeAllPopup(): void {
        if (!environment.internetFunction) return;

        this.overlayMaps.markers.eachLayer((layer: L.Marker) => {
            layer.closePopup();
        });

        this.overlayMaps.cellMarkers.eachLayer((layer: L.Marker) => {
            layer.closePopup();
        });
    }

}
